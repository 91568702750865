import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import { saveSellOff } from './SellOff.actions';
import SellOff from './SellOff.component';

const mapStateToProps = ({ createStockReducer, masterDataReducer, languageReducer }) => ({
  canEditStock: createStockReducer.canEditStock,
  stockDetails: createStockReducer.stockDetails,
  usersList: masterDataReducer.usersList,
  stockViewDuration: masterDataReducer.stockViewDuration,
  language: languageReducer,
  addressList: createStockReducer.addressList,
});

export default connect(mapStateToProps, { saveSellOff, fetchMasterData })(SellOff);
