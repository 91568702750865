import React, { useEffect, useState } from 'react';
import strings from 'resources/locales/Translate';
import moment from 'moment';
import Icon from '../../../../library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import { ViewTypes } from 'react-big-scheduler';
import DatePicker from 'react-date-picker';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

function CalendarHeader({
  onViewChange,
  selectedViewType,
  prevClick,
  nextClick,
  todayClick,
  selectedDate,
  onSelectDate,
}) {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [dateWidth, setDateWidth] = useState(0);
  const dateRef = useRef(null);
  const language = useSelector(state => state.languageReducer)

  useEffect(() => {
    let currentDateRef = document.querySelector('.iqs-display-date-time');
    if (currentDateRef) {
      setTimeout(() => {
        setDateWidth(currentDateRef.offsetWidth);
      }, 0);
    }
  }, [selectedDate, selectedViewType]);

  const toggleCalendar = () => {
    setIsCalendarVisible(prevState => !prevState);
  };

  const dateForWeekView = () => {
    let monday = moment(selectedDate).startOf('week').day(1);
    let startDate = monday.clone().add(0, 'weeks');
    let endDate = startDate.clone().add(4, 'days');
    return `${startDate.format('DD MMM')} - ${endDate.format('DD MMM')} ${moment(selectedDate).format('YYYY')}  (Week ${moment(selectedDate).week()})`;
  };

  const dateForMonthView = () => {
    const startOfMonth = moment(selectedDate).startOf('month');
    const endOfMonth = moment(selectedDate).endOf('month');
    const startWeek = startOfMonth.week();
    const endWeek = endOfMonth.week();
    return `${startOfMonth.format('MMMM YYYY')} (Week ${startWeek} - ${endWeek})`;
  };

  const onChange = date => {
    onSelectDate(date);
    setIsCalendarVisible(false);
  };
  return (
    <div className='d-flex align-items-center justify-content-between mb-3'>
      <div className='d-flex align-items-center cursor-pointer position-relative'>
        <div className='app-datepicker cursor-pointer' style={{ border: 'none' }}>
          <div
            className={'d-flex datepicker-view align-items-center text-overflow-ellipsis' + (true && 'calendar-shadow')}
            onClick={toggleCalendar}
            style={{ whiteSpace: 'nowrap' }}
          >
            {selectedViewType == ViewTypes.Custom2 && (
              <h4 className='iqs-display-date-time'>{dateForMonthView()}</h4>
            )}
            {selectedViewType === ViewTypes.Custom && (
              <h4 className='iqs-display-date-time' ref={dateRef}>
                {dateForWeekView()}
              </h4>
            )}
            {selectedViewType === ViewTypes.Custom1 && (
              <h4 className='iqs-display-date-time' ref={dateRef}>
                {<h4 className='iqs-display-date-time'>{`${moment(selectedDate).format('DD MMMM YYYY')} (Week ${moment(selectedDate).week()})`}</h4>}
              </h4>
            )}
          </div>
          <DatePicker
            minDetail='decade'
            className='datepicker-component-container date-adjust'
            calendarClassName='datepicker-calendar-container calendar-shadow'
            clearIcon={null}
            showLeadingZeros={true}
            isOpen={isCalendarVisible}
            onChange={onChange}
          />
        </div>
        <div className='d-flex align-items-center calendar-navigation-container' style={{ left: selectedViewType === ViewTypes.Custom ? 290 : 260 }}>
          <div className='zmcc-date-switch-button'>
            <Icon name={'arrow'} width={27} height={27} fill={'#0088D0'} onClick={prevClick} />
          </div>
          <p className='today-button-text' onClick={todayClick}>
            {strings.todayText}
          </p>
          <Icon name={'arrow'} width={27} height={27} fill={'#0088D0'} onClick={nextClick} />
        </div>
      </div>
      <div className='d-flex align-items-center left-header-container'>
        <Button
          value={strings.day}
          styleClass={'left-header-toggle-btn'}
          bgFill={selectedViewType === ViewTypes.Custom1}
          onClick={() => onViewChange(ViewTypes.Custom1)}
        />
        <Button
          value={strings.week}
          styleClass={'left-header-toggle-btn'}
          bgFill={selectedViewType === ViewTypes.Custom}
          onClick={() => onViewChange(ViewTypes.Custom)}
        />
        <Button
          value={strings.month}
          styleClass={'left-header-toggle-btn'}
          bgFill={selectedViewType === ViewTypes.Custom2}
          onClick={() => onViewChange(ViewTypes.Custom2)}
        />
      </div>
    </div>
  );
}

export default CalendarHeader;
