import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { deliveryDocumentsModel, returnDeliverAddressModel } from './DeliveryDocuments.constants';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { fetchStockAddress } from 'modules/Stock/CreateStock/CreateStock.actions';
import Icon from "../../../../../library/common/commonComponents/Icon/Icon"

import './DeliveryDocuments.style.scss';
import Validators from 'library/utilities/Validators';

class DeliveryDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressForm: { formData: {}, isFormValid: false },
      lawForm: { formData: {}, isFormValid: false },
      managementForm: { formData: {}, isFormValid: false },
      deliveryDocuments: null,
      loading: false,
      signatureForm: { formData: {}, isFormValid: false },
      legalParagraphForm: { formData: {}, isFormValid: false },
      returnDeliveryAddressForm: {formData: {}, isFormValid: false},
      addressOptions: [],
      selectedReturnDeliveryAddress: [],
      addresses: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.deliveryDocuments && this.props.isActive && !prevProps.isActive) {
      if (!this.state.loading) {
        this.setState({ loading: true }, async () => {
          const { success, data } = await this.props.fetchDeliveryDocumentFooter();
          await this.props.fetchStockAddress(this.props.createStockReducer.stockDetails.id);
          const deliveryAddress = this.props.createStockReducer.addressList;
          let addressOptions = deliveryAddress.map(address => ({
            id: address.addressId,
            name: address.addressType,
          }));
          this.setState({ addressOptions, addresses: addressOptions });
          if (success) {
            let filteredOptions = [];
            let filteredAddress = [];
            deliveryAddress.forEach(address => {
              if(address.returnDeliveryAddressText){
                filteredAddress.push ({
                  returnDeliveryAddress: address.addressId,
              returnDeliveryAddressText: address.returnDeliveryAddressText,
              returnDeliveryAddressFullValue: {id:address.addressId,
                name: address.addressType
              }
                })

              } else {
                filteredOptions.push({
                  id: address.addressId,
                 name: address.addressType,
                })
              }
            })
            this.setState({
            selectedReturnDeliveryAddress: filteredAddress,
            addressOptions: filteredOptions,
              deliveryDocuments: data,
              loading: false,
              addressForm: {
                formData: {
                  companyName: data.companyName,
                  groupName: data.groupName,
                  streetAndHouseNumber: this.props?.isIqsSscDe ? data.streetAndHouseNumber.replace(/,/g, '') : data.streetAndHouseNumber,
                  postalCodeAndCity: this.props?.isIqsSscDe ? data.postalCodeAndCity.replace(/,/g, ''): data.postalCodeAndCity ,
                  webAddress: data.webAddress,
                },
                isFormValid: false,
              },
              lawForm: {
                formData: {
                  placeOfJurisdiction: data.placeOfJurisdiction,
                  vatIdentificationNumber: data.vatIdentificationNumber,
                  taxNumber: data.taxNumber,
                  weeeNumber: data.weeeNumber,
                },
                isFormValid: false,
              },
              managementForm: {
                formData: {
                  name1: data.name1,
                  name2: data.name2,
                  name3: data.name3,
                  name4: data.name4,
                },
                isFormValid: false,
              },
              signatureForm: {
                formData: {
                  signature: data.signature,
                  showCustomerInfoPage: data.showCustomerInfoPage,
                  showDeliveryDocument: data.showDeliveryDocument,
                  showStorageLocation: data.showStorageLocation,
                  returnDeliveryAddressFullValue: deliveryAddress,
                  additionalInformation: data.additionalInformation,
                },
                isFormValid: true,
              },
              legalParagraphForm: {
                formData: {
                  legalParagraph: data.legalParagraph,
                  legalParagraphDe: data.legalParagraphDe,
                },
                isFormValid: true,
              },

            });
          }
        });
      }
    }
  }

 handleForm = ({ values, field, formName }) => {
   this.setState(prevState => ({
     [formName]: {
       ...prevState[formName],
       formData: {
         ...((prevState[formName] && prevState[formName].formData) || {}),
         ...values,
       },
     },
   }));
 };

 generateDataForAPI = async () => {
   let { addressForm, lawForm, managementForm, signatureForm, legalParagraphForm, returnDeliveryAddressForm, selectedReturnDeliveryAddress } = this.state;
   const { id: stockId } = this.props.createStockReducer.stockDetails;

   // Validate forms
   addressForm = validateForm({ form: addressForm, formName: 'addressForm', model: deliveryDocumentsModel.address });
   managementForm = validateForm({ form: managementForm, formName: 'managementForm', model: deliveryDocumentsModel.management });

   if (!selectedReturnDeliveryAddress.length && signatureForm.formData.additionalInformation === true) {
     returnDeliveryAddressForm = validateForm({ form: returnDeliveryAddressForm, formName: 'returnDeliveryAddressForm', model: returnDeliverAddressModel });
     this.setState({ returnDeliveryAddressForm });
     this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
     scrollToTop(500);
     return;
   }

   if (selectedReturnDeliveryAddress.length > 0 && !selectedReturnDeliveryAddress[0].returnDeliveryAddressText && signatureForm.formData.additionalInformation === true) {
     selectedReturnDeliveryAddress[0].returnDeliveryAddressTextError = 'requiredErrorMessage';
     this.setState({ selectedReturnDeliveryAddress });
     this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
     scrollToTop(500);
     return;
   }

   if (!addressForm.isFormValid || !managementForm.isFormValid) {
     this.setState({ addressForm, managementForm });
     if (!managementForm.isFormValid) {
       this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
     } else if (!addressForm.isFormValid) {
       if (!addressForm.formData.webAddressError || !addressForm.formData.webAddress) {
         this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
       } else {
         this.props.toggleActionMessage(true, 'error', 'webAddressErrorMessage');
       }
     }
     scrollToTop(500);
     return;
   } else {
     const valuesToSend = {};
     const data = {
       ...addressForm.formData,
       ...managementForm.formData,
       ...lawForm.formData,
       ...signatureForm.formData,
       ...legalParagraphForm.formData,
       returnDeliveryAddressText: this.state.selectedReturnDeliveryAddress.map(address => ({
         id: address.returnDeliveryAddress,
         name: address.returnDeliveryAddressText,
       })),
     };

     Object.keys(data).forEach(key => {
       if (!key.includes('Error')) {
         if (
           key === 'showCustomerInfoPage' ||
           key === 'signature' ||
           key === 'showDeliveryDocument' ||
           key === 'showStorageLocation' ||
           key === 'returnDeliveryAddress' ||
           key === 'additionalInformation'
         ) {
           valuesToSend[key] = data[key];
         } else {
           valuesToSend[key] = data[key] || '';
         }
       }
     });

     this.props.createDeliveryDocumentFooter(valuesToSend, stockId);
   }
 };
  cancelStockCreation = () => {
    this.props.cancelStockCreation();
    this.props.history.replace('/stocks');
  };

  signatureModel = () => {
    const model = [...deliveryDocumentsModel.signature];
    model[4].description = strings.returnDeliveryNoteText;
    return model;
  };

  getReturnDeliveryModel = () => {
    const model = [...returnDeliverAddressModel];
    model[0].options = this.state.addressOptions;
    model[1].value = this.state.returnDeliveryAddressForm.formData.returnDeliveryAddressText;
    return [...model]
  }

 onAddressAdd = () => {
   const { returnDeliveryAddressForm } = this.state;
   let selectedValue = this.state.selectedReturnDeliveryAddress;
   const validateAddress = validateForm({ form: returnDeliveryAddressForm, formName: 'returnDeliveryAddressForm', model: returnDeliverAddressModel});
   if(!validateAddress.isFormValid){
    return this.setState({returnDeliveryAddressForm: validateAddress});
   }
   selectedValue = [...selectedValue, { ...returnDeliveryAddressForm.formData }];

   let dropdownAddress = this.state.addressOptions;
   if (dropdownAddress?.length) {
     dropdownAddress = dropdownAddress.filter(address => address.id !== returnDeliveryAddressForm.formData?.returnDeliveryAddress);
   }
   this.setState({
     selectedReturnDeliveryAddress: selectedValue,
     returnDeliveryAddressForm: { formData: {}, isFormValid: false },
     addressOptions: dropdownAddress,
   });
 };

onRemoveAddress = (addressIndex) => {
  this.setState(prev => {
    let addresses = prev.selectedReturnDeliveryAddress.map((value, index) => {
      if (index === addressIndex) {
        return {
          ...value,
          returnDeliveryAddressText: '',
          returnDeliveryAddressFullValue: '',
 };
      }
      return value;
    });

    return { ...prev, selectedReturnDeliveryAddress: addresses };
  });
};


  onChangeSelectedAddress = ({ values }, addressIndex) => {
    this.setState(prev => {
      let addresses = prev.selectedReturnDeliveryAddress;
      addresses = addresses.map((address, index) => {
        if(index === addressIndex){
          return {...address, ...values};
        }
        return {...address};
      });
      return {...prev, selectedReturnDeliveryAddress: addresses};
    })
  }

  render() {
    const { canEditStock } = this.props.createStockReducer;
    const { addressForm, lawForm, managementForm, signatureForm, legalParagraphForm, addressOptions, returnDeliveryAddressForm, selectedReturnDeliveryAddress } = this.state;
    return (
      <div className='delivery-document-container'>
        <div className='container-fluid form-container'>
          <div className='mb-3'>
            <FormComponent
              disableForm={!canEditStock}
              formName='signatureForm'
              model={this.signatureModel()}
              formValue={signatureForm.formData}
              onChange={this.handleForm}
              dataTest='signatureForm'
            />
            {this.state.signatureForm?.formData?.additionalInformation === true &&
              selectedReturnDeliveryAddress && selectedReturnDeliveryAddress?.length ?
              selectedReturnDeliveryAddress.map((eachAddress, index) => {
                return <div className='d-flex align-items-center' key={`key-${index}`}>
                  <FormComponent
                    disableForm={!canEditStock}
                    formName='returnDeliveryAddressForm'
                    model={this.getReturnDeliveryModel()}
                    formValue={eachAddress}
                    onChange={(value) => this.onChangeSelectedAddress(value, index)}
                    className="col-6"
                  />
                  {index !== 0 && (
                    <div className='col-sm-1'>
                      <button
                        className={'remove-button'}
                        onClick={() => this.onRemoveAddress(index)}
                      >
                        <Icon
                          name={'delete'}
                          width={24}
                          height={24}
                          fill={'#fff'}
                        />
                      </button>
                    </div>
                  )}
                </div>
              }): null
            }
            {this.state.signatureForm?.formData?.additionalInformation === true && this.state.addressOptions.length > 0 && (
              <div className='d-flex align-items-center'>
              <FormComponent
                disableForm={!canEditStock}
                formName='returnDeliveryAddressForm'
                model={this.getReturnDeliveryModel()}
                formValue={returnDeliveryAddressForm.formData}
                onChange={this.handleForm}
                className="col-6"
              />
             {this.state.addressOptions.length > 0 && (
               <div className='col-sm-1'>
                 <button
                  className={`add-button ${(returnDeliveryAddressForm.formData.returnDeliveryAddressError === 'requiredErrorMessage'
                  || returnDeliveryAddressForm.formData.returnDeliveryAddressTextError === 'requiredErrorMessage') ? 'mb-4' : ''}`}
                   onClick={this.onAddressAdd}
                 >
                   <Icon
                     name={'plus'}
                     width={24}
                     height={24}
                     fill={'#fff'}
                   />
                 </button>
               </div>
             )}
            </div>
            )}
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <h4 className='form-title'>{strings.deliveryDocumentsHeading}</h4>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-12 col-md-4'>
              <h6 className='delivery-address-section'>{strings.addressText}</h6>
              <FormComponent
                disableForm={!canEditStock}
                formName='addressForm'
                model={deliveryDocumentsModel.address}
                formValue={addressForm.formData}
                onChange={this.handleForm}
              />
            </div>
            <div className='col-12 col-md-4 mt-3 mt-md-0'>
              <h6 className='delivery-address-section'>{strings.management}</h6>
              <FormComponent
                disableForm={!canEditStock}
                formName='managementForm'
                model={deliveryDocumentsModel.management}
                formValue={managementForm.formData}
                onChange={this.handleForm}
              />
            </div>
            <div className='col-12 col-md-4 mt-3 mt-md-0'>
              <h6 className='delivery-address-section'>{strings.law}</h6>
              <FormComponent
                disableForm={!canEditStock}
                formName='lawForm'
                model={deliveryDocumentsModel.law}
                formValue={lawForm.formData}
                onChange={this.handleForm}
              />
            </div>
          </div>
          <div className='mb-2'>
            <FormComponent
              disableForm={!canEditStock}
              formName='legalParagraphForm'
              model={deliveryDocumentsModel.legalParagraph}
              formValue={legalParagraphForm.formData}
              onChange={this.handleForm}
            />
          </div>
          {canEditStock && (
            <CreateStockActionButtons onSave={this.generateDataForAPI} onCancel={this.cancelStockCreation} styleClass={'save-next-pev-button'}/>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ createStockReducer, languageReducer }) => ({
  createStockReducer: createStockReducer,
  language: languageReducer,
});
export default connect(mapStateToProps, {
  fetchStockAddress,
})(DeliveryDocuments);
