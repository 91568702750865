import React from 'react';
import { useIqsBooking } from './IqsBooking.hook';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Section from 'library/common/commonComponents/Section/Section';
import Button from 'library/common/commonComponents/Button/Button';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import {
  fetchProductHeirarchyDropdownValues,
  fetchResourceTypesDropdownValues,
} from 'modules/Kits/CreateZmccKit/CreateZmccKit.actions';
import { connect } from 'react-redux';
import { saveRequestedEquipments } from 'modules/IqsNewRequest/IqsNewRequest.actions';
import moment from 'moment';
import TextArea from 'library/common/commonComponents/FormComponent/Optimized/TextArea';

export const IqsBooking = props => {
  const { productHierarchyValues, iqsBookingMasterData, iqsBookingTabsBookngs, iqsSystemClass } = props;
  const { equipmentRequestList, addEquipmentRequest, handleChangeValues, onSaveClick, onAddBookingClick } =
    useIqsBooking(props);
  return (
    <div className='mt-3'>
      <Section sectionTitle={strings.requestedEquipment}>
        {equipmentRequestList.length > 0 &&
          equipmentRequestList.map((item, index) => (
            <div className='col-12 row mt-3'>
              <div className='col-3 mb-4'>
                <Dropdown
                  label={strings.scheduleType}
                  placeholder='Please select'
                  idKey='staticCode'
                  field='resourceType'
                  data={props.resourceTypeValues || []}
                  hasSection={false}
                  multiSelect={false}
                  filter={true}
                  isRequired={true}
                  displayValue={[{ key: 'name', separator: '' }]}
                  onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                  value={item.resourceType}
                  error={item.resourceTypeError}
                  fullValue={item.resourceTypeFullValue}
                />
              </div>
              {item.resourceType === 'SYSTEM' && (
                <div className='col-3 mb-4'>
                  <Dropdown
                    label={strings.category}
                    placeholder='Please select'
                    idKey='id'
                    field='productHierarchyId'
                    data={productHierarchyValues || []}
                    hasSection={false}
                    multiSelect={false}
                    filter={true}
                    isRequired={true}
                    displayValue={[{ key: 'name', separator: '' }]}
                    onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                    value={item.productHierarchyId}
                    error={item.productHierarchyIdError}
                    fullValue={item.productHierarchyIdFullValue}
                  />
                </div>
              )}
              {item.resourceType === 'SYSTEM' && (
                <div className='col-3 mb-4'>
                  <Dropdown
                    label={strings.iqsSystemClassLabel}
                    placeholder='Please select'
                    idKey='systemClassId'
                    field='systemClassId'
                    data={
                      iqsSystemClass.filter(
                        systemClass => systemClass.productHierarchyId === item.productHierarchyId,
                      ) || []
                    }
                    hasSection={false}
                    multiSelect={false}
                    filter={true}
                    isRequired={true}
                    displayValue={[{ key: 'systemClassName', separator: '' }]}
                    onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                    value={item.systemClassId}
                    error={item.systemClassIdError}
                    fullValue={item.systemClassIdFullValue}
                  />
                </div>
              )}
              {item.resourceType === 'SYSTEM' && (
                <div className='col-3 mb-4'>
                  <Dropdown
                    label={strings.component}
                    placeholder='Please select'
                    idKey='systemMainComponentId'
                    field='systemMainComponentId'
                    data={
                      iqsSystemClass?.find(each => each.systemClassId === item.systemClassId)?.systemMainComponent || []
                    }
                    hasSection={false}
                    multiSelect={true}
                    filter={true}
                    isRequired={true}
                    displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
                    onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                    value={item.systemMainComponentId}
                    error={item.systemMainComponentIdError}
                    fullValue={item.systemMainComponentIdFullValue}
                  />
                </div>
              )}
              {item.resourceType === 'SYSTEM' && (
                <div className='col-3 mb-4'>
                  <Dropdown
                    label={strings.alternativeComponent}
                    placeholder='Please select'
                    idKey='systemMainComponentId'
                    field='alternativeSystemMainComponentIds'
                    data={iqsSystemClass?.find(each => each.systemClassId === item.systemClassId)?.systemMainComponent}
                    hasSection={false}
                    multiSelect={true}
                    filter={true}
                    isRequired={true}
                    displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
                    onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                    value={item.alternativeSystemMainComponentIds}
                    error={item.alternativeSystemMainComponentIdsError}
                    fullValue={item.alternativeSystemMainComponentIdsFullValue}
                  />
                </div>
              )}
              {item.resourceType !== 'SYSTEM' && (
                <div className='col-3 mb-4'>
                  <InputField
                    label={strings.component}
                    field='component'
                    placeholder=''
                    isRequired={true}
                    type='text'
                    value={item.component}
                    error={item.componentError}
                    onChange={(e, field) => handleChangeValues(e, field, null, index)}
                  />
                </div>
              )}
              <div className='col-3 mb-4'>
                <Dropdown
                  label={strings.sensors}
                  placeholder='Please select'
                  idKey='staticCode'
                  field='sensors'
                  data={iqsBookingMasterData?.sensors || []}
                  hasSection={false}
                  multiSelect={true}
                  filter={true}
                  isRequired={false}
                  displayValue={[{ key: 'name', separator: '' }]}
                  onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                  value={item.sensors}
                  error={item.sensorsError}
                  fullValue={item.sensorsFullValue}
                />
              </div>
              <div className='col-3 mb-4'>
                <Dropdown
                  label={strings.rotationTable}
                  placeholder='Please select'
                  idKey='staticCode'
                  field='rotationTables'
                  data={iqsBookingMasterData?.rotationTables || []}
                  hasSection={false}
                  multiSelect={true}
                  filter={true}
                  isRequired={false}
                  displayValue={[{ key: 'name', separator: '' }]}
                  onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                  value={item.rotationTables}
                  error={item.rotationTablesError}
                  fullValue={item.rotationTablesFullValue}
                />
              </div>
              <div className='col-3 mb-4'>
                <Dropdown
                  label={strings.assets}
                  placeholder='Please select'
                  idKey='staticCode'
                  field='assets'
                  data={iqsBookingMasterData?.assetTypes || []}
                  hasSection={false}
                  multiSelect={true}
                  filter={true}
                  isRequired={false}
                  displayValue={[{ key: 'name', separator: '' }]}
                  onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                  value={item.assets}
                  error={item.assetsError}
                  fullValue={item.assetsFullValue}
                />
              </div>
              <div className='col-3 mb-4'>
                <Dropdown
                  label={strings.software}
                  placeholder='Please select'
                  idKey='staticCode'
                  field='software'
                  data={iqsBookingMasterData?.software || []}
                  hasSection={false}
                  multiSelect={true}
                  filter={true}
                  isRequired={false}
                  displayValue={[{ key: 'name', separator: '' }]}
                  onChange={(e, field, fullValue) => handleChangeValues(e, field, fullValue, index)}
                  value={item.software}
                  error={item.softwareError}
                  fullValue={item.softwareFullValue}
                />
              </div>

              <div className={`${item.resourceType === 'SYSTEM' ? 'col-9' : 'col-6'} mb-4`}>
                <TextArea
                  label={strings.additionalEquipment}
                  field='additionalEquipment'
                  placeholder=''
                  isRequired={false}
                  type='text'
                  value={item.additionalEquipment}
                  error={item.additionalEquipmentError}
                  onChange={(e, field) => handleChangeValues(e, field, null, index)}
                  bigSizeInput={'bigger-input-field'}
                />
              </div>
              <div className='w-100'>
                <hr className='line-between-booking'></hr>
              </div>
            </div>
          ))}
        <div className='d-flex justify-content-end'>
          <Button
            value={strings.addEquipmentRequest}
            onClick={addEquipmentRequest}
            bgFill={false}
            styleClass='add-button adjust-add-location-manager-btn adjust-button-width'
            iconName='plus'
            newVersionUi={true}
          />
        </div>
      </Section>
      <div className='d-flex'>
        <Button
          value={strings.save}
          onClick={onSaveClick}
          bgFill={true}
          styleClass='add-button adjust-add-location-manager-btn save-next-pev-button'
          newVersionUi={true}
        />
        <Button
          value={strings.cancel}
          onClick={() => {}}
          bgFill={false}
          styleClass='add-button adjust-add-location-manager-btn save-next-pev-button ml-4'
          newVersionUi={true}
        />
      </div>

      <div className='mt-4'>
        <Section sectionTitle={strings.bookings}>
          <div className='col-12 row m-2 p-0'>
            {iqsBookingTabsBookngs?.map((item, index) => (
              <div className='col-6 booking-tab-booking-data pl-0 mb-3'>
                <div className='border-for-booking-data'>
                  <strong>{`${item.bookingTypeTranslated} for ${item.bookingCategoryTranslated}`}</strong>
                  <p className='mt-2'>{`${strings.from} ${moment(item.bookingFrom).format('YYYY-MM-DD hh:mm')} ${
                    strings.to
                  } ${moment(item.bookingTo).format('YYYY-MM-DD hh:mm')}`}</p>
                  <p className='mt-2'>{`${item?.bookedResources.map(resource => resource.kitName)}`}</p>
                  <p>
                    {item.bookedPersons.map(person => `${person.firstName} ${person.lastName} (${person.phoneNumber}) `)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <Button
            value={strings.addBooking}
            onClick={onAddBookingClick}
            bgFill={false}
            styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
            iconName='plus'
            newVersionUi={true}
          />
        </Section>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, iqsRequestReducer, masterDataReducer, createZmccKitReducer }) => ({
  auth: authReducer.auth,
  iqsRequest: iqsRequestReducer.iqsRequest,
  masterData: masterDataReducer.masterData,
  resourceTypeValues: createZmccKitReducer.resourceTypeValues,
  productHierarchyValues: createZmccKitReducer.productHierarchyValues,
  iqsBookingMasterData: iqsRequestReducer.iqsBookingMasterData,
  iqsrequestedEquipmentsData: iqsRequestReducer.iqsrequestedEquipmentsData,
  iqsBookingTabsBookngs: iqsRequestReducer.iqsBookingTabsBookngs,
  iqsSystemClass: iqsRequestReducer.iqsSystemClass,
});

export default connect(mapStateToProps, {
  toggleActionMessage,
  fetchResourceTypesDropdownValues,
  fetchProductHeirarchyDropdownValues,
  saveRequestedEquipments,
})(IqsBooking);
