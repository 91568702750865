import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { images } from 'library/common/commonConstants/ImageConstants';
import strings from 'resources/locales/Translate';
import { NavLink } from 'react-router-dom';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Button from 'library/common/commonComponents/Button/Button';
import { updateUserCheckedDontShowAgain } from '../HomepageActions';
import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { VR_WELCOME_POPUP } from 'library/common/commonConstants/storeConstants';

export const VRWelcomePopup = ({ onClose, user, language }) => {
  const [selected, setSelected] = useState(false);

  const VR_URL = 'https://zeiss.widencollective.com/portals/eqgsvrrl/VirtualReality';
  useEffect(() => {
    $('#vrwelcome-popup').modal('show');

    return () => {
      $('#vrwelcome-popup').modal('hide');
      onClose(VR_WELCOME_POPUP);
    };
  }, []);

  const onCloseClick = () => {
    $(`#${'vrwelcome-popup'}`).modal('hide');
    onClose(VR_WELCOME_POPUP);
    if (selected) {
      updateUserCheckedDontShowAgain(VR_WELCOME_POPUP);
    }
  };

  const handleSelctCheckbox = () => {
    setSelected(!selected);
  };

  return (
<div
className='modal fade modal-container'
id={'vrwelcome-popup'}
tabIndex='-1'
role='dialog'
aria-labelledby={'vrwelcome-popup'}
aria-hidden='true'
data-backdrop='static'
>
<div className='modal-dialog vrwelcome-popup' role='document'>
  <div className='modal-content vrmodel-content'>
    <div className='modal-body p-0'>
    <div className="vr-popup-header">
         <span className='exciting-header'> 🎉 {strings.excitingNews} 🎉 </span><br />
          {strings.VrPopupSeg1}{" "}
        <span className="darkblue-text">{strings.VrPopupSeg2}</span> {strings.VrPopupSeg3}<br/>{strings.VrPopupSeg4}<br/>{strings.VrPopupSeg5}
          <br />
          <br />
          {strings.VrPopupSeg6}{" "}
          <a
            href={VR_URL}
            target="_blank"
            className="lightgreen-text vr-popup-link"
          >
            {strings.VrPopupSeg7}
          </a>{" "}
            <br />
          {strings.VrPopupSeg8}
          <br />
          <br />
          {strings.VrPopupGreet}
        </div>

      <div className='d-flex justify-content-between align-items-center p-4'>
        <Checkbox
          label={strings.dontShowMessage}
          field='canBeShipped'
          isRequired={true}
          selected={selected}
          onChange={handleSelctCheckbox}
          newuiVersionStyle={true}
          dontShowCustomStyle={'dontshowmessage'}
        />
        <Button
          value={strings.ok}
          onClick={() => onCloseClick()}
          bgFill={true}
          styleClass='add-button welcomePopup-close-btn'
        />
      </div>
    </div>
  </div>
</div>
</div>

  );
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
    user: state.authReducer.user,
    homepage: state.homepage,
    appActions: state.appActionsReducer,
  };
};

export default connect(mapStateToProps, {})(VRWelcomePopup);

