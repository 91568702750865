import Validators from "library/utilities/Validators";

export const timeManagementModel = [
  {
    label: 'scheduleType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'type',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'offerConfirmation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'relatedPosition',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    disabled: false,
  },
  {
    label: 'person',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'accountId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'firstName', separator: ',' },
      { key: 'lastName', separator: '' },
    ],
    showClearIcon: false,
  },
  {
    label: 'commentText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'comment',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
  },
  {
    label: 'date',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'eventDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    dateFormat: 'DD.MM.YYYY',
    disabled: false,
    tileDisabled: [],
  },
  {
    label: 'beginStart',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'startTime',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'end',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'endTime',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'break',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'breakTime',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'numberErrorMessage' },
    ],
    required: false,
    styleClass: 'col-3',
    disabled: false,
    inputClassName: 'iqs-request-font-color',
    inputGroupText: 'minutes',
    isAppend: true,
    neweVersionStyle: true,
  },
];