import Validators from 'library/utilities/Validators';

export const FETCH_ALLIQS_REQUEST_DROPDOWN_DATA = 'FETCH_ALLIQS_REQUEST_DROPDOWN_DATA';
export const FETCH_INDUSTRILA_SUB_SEGMENTS = 'FETCH_INDUSTRILA_SUB_SEGMENTS';
export const FETCH_IQS_REQUEST_DETAILES = 'FETCH_IQS_REQUEST_DETAILES';
export const FETCH_IQS_BOOKING_MASTER_DATA = 'FETCH_IQS_BOOKING_MASTER_DATA';
export const FETCH_IQS_REQUESTED_EQUIPMENTS_DATA = 'FETCH_IQS_REQUESTED_EQUIPMENTS_DATA';
export const FTECH_BOOKING_TAB_BOOKINGS = 'FTECH_BOOKING_TAB_BOOKINGS';
export const FETCH_SYSTEMCLASS_DATA = 'FETCH_SYSTEMCLASS_DATA';
export const FETCH_IQS_TIME_MANAGEMENT_LIST = 'FETCH_IQS_TIME_MANAGEMENT_LIST';

export const IqsRequestBreadcrumbData = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'iqsRequests',
  },
  {
    name: 'createOrModifyRequest',
  },
];

export const headerModel = [
  {
    label: 'requestTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'requestTitle',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'requestId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'requestId',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'status',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'requestStatus',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'iqsRequestType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'requestType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'executionLocation',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'executionLocation',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
      label: 'processingLocation',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'processingLocationId',
      validators: [],
      required: false,
      styleClass: 'col-3',
      options: [],
      hasSection: true,
      multiSelect: false,
      filter: false,
      idKey: 'id',
      displayValue: [{ key: 'locationName', separator: '' }],
      optionsArrayKey: 'stockInformation',
      titleDisplay: [
        { key: 'shortName', separator: ' ' },
        { key: 'name', separator: '' },
      ],
    },
  {
    label: 'requestDescription',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'requestDescription',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
];
