import strings from 'resources/locales/Translate';

//GLOBAL ROLES
export const ADMIN = 'Admin';
export const DUST_MANAGER = 'DUST_MANAGER';
export const DUST_USER = 'DUST_USER';

//STOCK ROLES
export const BORROWER = 'Borrower';
export const DISPATCHER = 'Dispatcher';
export const EXTERNAL_BORROWER = 'External Borrower';
export const INBOUND_STAFF = 'Inbound staff';
export const OUTBOUND_STAFF = 'Outbound staff';
export const MANAGER = 'Manager';
export const RECONDITIONING = 'Reconditioning';
export const STOCK_CONTROLLER = 'Stock Controller';
export const READ_ONLY_USER = 'Read Only User';
export const ZMCC_OPERATOR = "Operator"

// LL STOCK ROLES
export const getllStockRoles = () => [{ id: 6, role: strings.stkRequestor, priority: 8 },
{ id: 2, role: strings.llPartner, priority: 4 },
{ id: 1, role: strings.llManager, priority: 3 }
];

// LL STOCK ROLES FOR MESSAGES
export const getllStockRolesForMessages = () => [
  { id: 9, role: 'Admin', priority: 1 },
  { id: 6, role: strings.stkRequestor, priority: 8 },
  { id: 2, role: strings.llPartner, priority: 4 },
  { id: 1, role: strings.llManager, priority: 3 },
];

//Transaction statuses
export const getAlltransactionStatus = () =>[ 
{transactionStatusId: 1, name: strings.transactionRequest, type: "GENERAL"},
{transactionStatusId: 2, name: strings.transactionApproved, type: "GENERAL"},
{transactionStatusId: 3, name: strings.confirmed, type: "GENERAL"},
{transactionStatusId: 5, name: strings.transactionCancelled, type: "GENERAL"},
{transactionStatusId: 6, name: strings.transactionChecked, type: "GENERAL"},
{transactionStatusId: 9, name: strings.transactionDelivered, type: "GENERAL"},]

// RMSZmcc STOCK ROLES
export const getRMSZMCCStockRoles = () =>[
  { id: 6, role: strings.stkRequestor, priority: 8 },
  { id: 2, role: strings.dispatcher, priority: 4 },
  { id: 1, role: strings.zmccManager, priority: 3 },
  { id: 8, role: strings.readOnly, priority: 10 },
  { id: 16, role: strings.operator, priority: 16}
];

// RMSZmcc STOCK ROLES for messages
export const getRMSZMCCStockRolesForMessages = () => [
  { id: 9, role: 'Admin', priority: 1 },
  { id: 6, role: strings.stkRequestor, priority: 8 },
  { id: 2, role: strings.dispatcher, priority: 4 },
  { id: 1, role: strings.zmccManager, priority: 3 },
];

// IQS (SSC De) STOCK ROLES
export const getIQSSscDeRoles = () => [
  { id: 6, role: strings.stkRequestor, priority: 8 },
  { id: 2, role: strings.dispatcher, priority: 4 },
  { id: 1, role: strings.loacationAdmin, priority: 3 },
  { id: 8, role: strings.readOnly, priority: 10 },
  { id: 16, role: strings.operator, priority: 16}
]




