import React, { Component } from 'react';
import { connect } from 'react-redux';
import strings from 'resources/locales/Translate';
import { getTransactionMessages, sendLogisticEmail } from '../../TransactionDetailsActions';
import moment from 'moment';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import MessageDataExpandable from './messageDataExpandableTable';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import toArray from 'lodash/toArray';
import { itemsPerPage } from '../../../../Booking/BookingConstants';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { CrossIcon, NewRefreshIcon, TickIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import Tooltip from 'modules/ZmccTransactions/ZmccTransactionDetails/Tabs/Information/AssignOperator/Tooltip';
import { header, footer } from '../RequestPickupPopup/RequestPickUpHeaderandFooter';
export class Messages extends Component {
  state = {
    loading: true,
    loadMoreComments: false,
    loadMoreDamage: false,
    loadMorePickupRequests: false,
    messageActive: true,
    damageOrLossActive: false,
    pickupRequestActive: false,
    data: {
      page: 1,
      size: 20,
    },
    damageOrLossStateData: {
      page: 1,
      size: 20,
    },
    pickUpRequestStateData: {
      page: 1,
      size: 20,
    },
  };

  componentDidMount() {
    const isZmccTrans = this.props.match && this.props.match.url.includes('isZmcc');
    const zmccTransactionId = this.props.match && this.props.match.params.id;
    this.props.getTransactionMessages({
      page: 1,
      type: 'comment',
      transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
    });
    this.props
    .getTransactionMessages({
      page: 1,
      type: 'pickup-request',
      transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
    });
    this.props
      .getTransactionMessages({
        page: 1,
        type: 'damage-loss-report',
        transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
      })
      .then(res => {
        this.setState({ loading: false });
      });
  }

  dataTableCols = (isZmccTransaction) => {
    const handleRefresh = async (event,item) => {
     event.stopPropagation()
     const messageData = item.text.split('\n');
     const toEmailAddress = messageData[0].split(': ')[1].trim();
     const senderEmailAddress = messageData[1].split(': ')[1].trim();
     const subject = messageData[3].split(': ')[1].trim();
     const emailBody = messageData.slice(5).join('\n').trim();
     const dataToSend = {
      toEmailAddress,
      subject,
      senderEmailAddress,
      emailBody,
      toCcEmailAddress: [],
      emailText: header,
      isZmcc: false
      };
      const transactionID = this.props.transactionsId;
      const response = await this.props.sendLogisticEmail(dataToSend, transactionID);
      if (!response.success) {
        console.error('Failed to resend email');
      }
    };

    const columns = [
      {
        title: strings.date,
        name: 'date',
        isSort: false,
        render: item => {
          return <span>{item.createdAt &&
            moment(item.createdAt).isValid() &&
            moment(item.createdAt).format('DD.MM.YYYY HH:mm') || ''}</span>;
        },
      },
      {
        title: strings.name,
        name: 'name',
        isSort: false,
        render: item => {
          return <span> {item.createdBy ?
            <div>
              {item.createdBy.firstName} {item.createdBy.lastName}
            </div>
            :
            <div>
              {strings.updatedBySystem}
            </div>
          }</span>;
        },
      },
      {
        title: strings.contactNumber,
        name: 'contactNumber',
        isSort: false,
        render: item => {
          return <span>{item?.createdBy?.userAddresses?.length > 0 ? item?.createdBy?.userAddresses[0].phone : ''}</span>;
        },
      },
      {
        title: strings.email,
        name: 'email',
        isSort: false,
        render: item => {
          return <span>{item.createdBy && item.createdBy.email || ''}</span>;
        },
      },
    ];
  
    if (!isZmccTransaction) {
      columns.push({
        title: strings.emailShipmentStatus,
        name: 'emailShipmentStatus',
        isSort: false,
        render: item => {
          return (
            <div className='d-flex align-items-center'>
              {
                item.status == 2 || item.status == 1 || item.status == 0? (
                  <>
                    <Tooltip styles="tooltip-container" content="Pending">
                      <Icon name="clockIcon" width="20" height="20" viewBox="0 0 55 55"></Icon>
                    </Tooltip>
                    <Tooltip styles="tooltip-container" content="Re-Send E-mail">
                      <span onClick={(event) => handleRefresh(event, item)}>
                        <NewRefreshIcon></NewRefreshIcon>
                      </span>
                    </Tooltip>
                  </>
                ) : item.status == 3 ? (
                  <>
                    <Tooltip content="Successfully sent">
                      <TickIcon fill="green"></TickIcon>
                    </Tooltip>
                  </>
                ) : (item.status == 4 || item.status == 5) ? (
                  <>
                    <Tooltip styles="tooltip-container" content="Failed">
                      <CrossIcon width="20" height="20" fill="red"></CrossIcon>
                    </Tooltip>
                    <Tooltip styles="tooltip-container" content="Re-Send E-mail">
                      <span onClick={(event) => handleRefresh(event, item)}>
                        <NewRefreshIcon></NewRefreshIcon>
                      </span>
                    </Tooltip>
                  </>
                ) : null
              }
            </div>
          );
        },
      });
    }
  return columns;

}
  onChangeType = (type) => {
    if (type === 'damageOrloss') {
      this.setState({messageActive: false, damageOrLossActive: true, pickupRequestActive: false,});
    }else if (type === 'pickUpRequest') {
      this.setState({messageActive: false, damageOrLossActive: false, pickupRequestActive: true,})
    }else{
      this.setState({messageActive: true, damageOrLossActive: false, pickupRequestActive: false,})
    }
  }

  handleItemsPerPage =  (size, type) => {
    const {changeLoader} = this.props;
    const isZmccTrans = this.props.match && this.props.match.url.includes('isZmcc');
    const zmccTransactionId = this.props.match && this.props.match.params.id;
    const {messageActive, damageOrLossActive, pickupRequestActive} = this.state
    if (messageActive) {

      this.setState(
        prevState => {
          const newData = { ...prevState.data };

          newData.size = size;
          return { data: newData };
        },
        async () =>{
          changeLoader(true);
         await this.props.getTransactionMessages({
          page: 1,
          type:'comment',
          transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
          size: size
        })
        changeLoader(false);
      }
      );

    }else if (damageOrLossActive) {
      this.setState(
        prevState => {
          const newData = { ...prevState.damageOrLossStateData };

          newData.size = size;
          return { damageOrLossStateData: newData };
        },
        async () =>{
        changeLoader(true);
        await  this.props.getTransactionMessages({
          page: 1,
          type: 'damage-loss-report',
          transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
          size: size
        })
        changeLoader(false);
      }
      );

    } else if (pickupRequestActive) {
      this.setState(
        prevState => {
          const newData = { ...prevState.pickUpRequestStateData };

          newData.size = size;
          return { pickUpRequestStateData: newData };
        },
       async () =>{
        changeLoader(true);
         await this.props.getTransactionMessages({
          page: 1,
          type: 'comment',
          transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
          size: size
        })
        changeLoader(false);
      }
      );
    }

  };

  handlePageChange = page => {
    const isZmccTrans = this.props.match && this.props.match.url.includes('isZmcc');
    const zmccTransactionId = this.props.match && this.props.match.params.id;
    const {messageActive, damageOrLossActive, pickupRequestActive, damageOrLossStateData, data, pickUpRequestStateData} = this.state;
    const {changeLoader} = this.props;
    if (messageActive) {
      this.setState(
        prevState => {
          const newData = { ...prevState.data };

          newData.page = page + 1;
          return { data: newData };
        },
        async () =>{
          changeLoader(true);
         await this.props.getTransactionMessages({
          page: page+1,
          type: 'comment',
          transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
          size: data.size
        })
        changeLoader(false);
      }
      );
    }else if (damageOrLossActive) {
      this.setState(
        prevState => {
          const newData = { ...prevState.damageOrLossStateData };

          newData.page = page + 1;
          return { damageOrLossStateData: newData };
        },
        async () => {
         await changeLoader(true);
          this.props.getTransactionMessages({
          page: page+1,
          type: 'damage-loss-report',
          transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
          size: damageOrLossStateData.size
        })
        changeLoader(false);
      }
      );
    }else if (pickupRequestActive) {
      this.setState(
        prevState => {
          const newData = { ...prevState.pickUpRequestStateData };

          newData.page = page + 1;
          return { pickUpRequestStateData: newData };
        },
        async () => {
          changeLoader(true);
          await this.props.getTransactionMessages({
          page: page+1,
          type: 'pickup-request',
          transactionsId: isZmccTrans ? zmccTransactionId :this.props.transactionsId,
          size: pickUpRequestStateData.size
        })
        changeLoader(false);
      }
      );
    }

  };

  render() {
    const { loading, messageActive, damageOrLossActive, pickupRequestActive, data, damageOrLossStateData, pickUpRequestStateData } = this.state;
    const { commentsData, damageLossReportData, pickupRequestsData, transactionDetails } = this.props;
    const isZmccTransaction = this.props.match && this.props.match.url.includes('isZmcc');
    const showData = ((messageActive && commentsData?.content?.length > 0)  || (damageOrLossActive && damageLossReportData?.content?.length > 0) || (pickupRequestActive && pickupRequestsData?.content?.length > 0));
    return (
      <>
        {this.props.transactionDetails && this.props.transactionDetails.c2cLoan && (
          <div className={'blue-bg-message'}>
            <div className='container-fluid'>{strings.c2cLoanProcessText(this.props.transactionDetails.c2cId)}</div>
          </div>
        )}
        <div className='container-fluid my-3'>
          <div className='message-tab-button-adjust mt-3'>
          <button className={`${messageActive ? 'active-message-button' : 'non-active-message-button'}`} onClick={() =>this.onChangeType('message')}>{strings.messages}</button>
          {!isZmccTransaction && <button className={`${damageOrLossActive ? 'active-message-button' : 'non-active-message-button'}`} onClick={() => this.onChangeType('damageOrloss')}>{strings.damageOrLossReports}</button>}
          {!isZmccTransaction && !transactionDetails?.labAndLocationBooking && <button className={`${pickupRequestActive ? 'active-message-button' : 'non-active-message-button'}`} onClick={() => this.onChangeType('pickUpRequest')}>{strings.pickupRequest}</button>}
          </div>

          <div className='mt-4'>
          { showData ?
           <ExpandableTable
            cols={this.dataTableCols(isZmccTransaction)}
            rows={damageOrLossActive ?damageLossReportData.content : pickupRequestActive ? pickupRequestsData.content : commentsData.content}>
             <MessageDataExpandable pickupRequestActive={pickupRequestActive}/>
          </ExpandableTable > :
          <div className='d-flex  align-items-center justify-content-center mt-4'>{strings.noDataFoundText}</div>
          }
         {showData && <div className='col d-flex justify-content-center mb-3 mt-5'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={this.handleItemsPerPage}
                value={itemsPerPage[damageOrLossActive ? damageOrLossStateData.size : pickupRequestActive ? pickUpRequestStateData.size :data.size]}
              />
              <Pagination
                currentPage={damageOrLossActive ? damageOrLossStateData.page - 1 : pickupRequestActive ? pickUpRequestStateData.page - 1 : data.page - 1}
                totalPages={damageOrLossActive ?damageLossReportData.totalPages === 0 ? damageLossReportData.totalPages+1 : damageLossReportData.totalPages : pickupRequestActive ? pickupRequestsData.totalPages === 0 ? pickupRequestsData.totalPage1 : pickupRequestsData.totalPage : commentsData.totalPages === 0 ? commentsData.totalPages+1 : commentsData.totalPages}
                onPageChange={this.handlePageChange}
              />
          </div>}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ appActionsReducer, authReducer, languageReducer, transactionDetailsReducer }) => ({
  commentsData: transactionDetailsReducer.commentsData,
  damageLossReportData: transactionDetailsReducer.damageLossReportData,
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  transactionsId:
    transactionDetailsReducer.transactionDetails &&
    transactionDetailsReducer.transactionDetails.transactionsId,
  pickupRequestsData: transactionDetailsReducer.pickupRequestsData
});

export default connect(
  mapStateToProps,
  { getTransactionMessages,sendLogisticEmail, changeLoader },
)(Messages);
