import { useState, useEffect } from 'react';
import { companyPopupModel } from './CompanyPopup.constants';
import * as $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { set } from 'lodash';
import { scrollToTop } from 'library/utilities/scrollActions';

export const useCompanyPopup = ({
  id,
  allDropdownData,
  fetchIndustrialSubSegments,
  industrialSubSegments,
  setCompanyList,
  companyList,
  selctedCompannyData,
  setIsCompanyEditing,
  isCompanyEditing,
  setDataForCompany,
  countries,
  toggleActionMessage,
}) => {
  const [companyPopupForm, setCompanyPopupForm] = useState({ formData: {} });

  useEffect(() => {
    if (isCompanyEditing) {
      setCompanyPopupForm({ formData: selctedCompannyData });
    }
  }, [isCompanyEditing]);
  const getcompanyPopupModel = () => {
    const model = [...companyPopupModel];
    model[7].options = countries || [];
    model[11].options = allDropdownData?.IqsIndustrialSegment || [];
    model[13].options = allDropdownData?.IqRequestCompanyNdaType || [];
    model[12].options = allDropdownData?.IqsIndustrialSegment?.find(item => item.staticCode === companyPopupForm.formData?.companyIndustrialSegment)?.additionalProperties?.SubSegments  || [];
    return model;
  };

  const handleForm = ({ values, field, formName }) => {
    if (field === 'companyIndustrialSegment') {
      return setCompanyPopupForm({
        ...companyPopupForm,
        formData: {
          ...companyPopupForm.formData,
          ...values,
          companyIndustrialSubSegment: null,
          companyIndustrialSubSegmentFullValue: null,
        },
      });
    }
    setCompanyPopupForm({
      ...companyPopupForm,
      formData: {
        ...companyPopupForm.formData,
        ...values,
      },
    });
  };

  const onSaveClick = () => {
    const validForm = validateForm({
      form: companyPopupForm,
      model: getcompanyPopupModel(),
    });
    if (!validForm.isFormValid) {
      return setCompanyPopupForm(validForm);
    }

    if (isCompanyEditing) {
      setCompanyList(prevList =>
        prevList.map(company =>
          company.iqsRequestCompanyId === companyPopupForm.formData?.iqsRequestCompanyId
            ? { ...companyPopupForm.formData }
            : company,
        ),
      );
      setDataForCompany({
        ...companyPopupForm.formData,
        customer: companyPopupForm.formData.iqsRequestCompanyId,
        customerFullValue: {
          iqsRequestCompanyId: companyPopupForm.formData.iqsRequestCompanyId,
          companyName: companyPopupForm.formData.companyName,
        },
      });
    } else {
      const prepareData = {
        ...companyPopupForm.formData,
        iqsRequestCompanyId: companyList.length + 1,
        isNewlyAdded: true,
      };
      setCompanyList([...companyList, prepareData]);
    }
    toggleActionMessage(true, 'success', 'companyCreatedSucessMessage');
     scrollToTop(500);
    onCloseClick();
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setCompanyPopupForm({ formData: {} });
    setIsCompanyEditing(false);
  };

  return {
    getcompanyPopupModel,
    handleForm,
    companyPopupForm,
    onCloseClick,
    onSaveClick,
  };
};