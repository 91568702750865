import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';
import { useAddLocationComment } from './AddLocationComment.hook';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { createOrEditIqsComment, fetchIqsLocations } from 'modules/Calendar/CalendarActions';
import { connect } from 'react-redux';
import { deleteLocationComment } from '../../IQSCalendar.actions';

export const AddLocationComment = ({
  id,
  iqsCommentLocations,
  fetchIqsLocations,
  createOrEditIqsComment,
  selectedCommentEvent,
  setSelectedCommentEvent,
  selectedDate,
  deleteLocationComment,
  iqsCommentsLocationsIds,
}) => {
  const {
    addLocationCommentModel,
    handleForm,
    locationCommentFormData,
    onSaveClick,
    onCloseClick,
    error,
    loading,
    onDeleteClick,
    deleteLoading,
  } = useAddLocationComment({
    id,
    iqsCommentLocations,
    fetchIqsLocations,
    createOrEditIqsComment,
    selectedCommentEvent,
    setSelectedCommentEvent,
    selectedDate,
    deleteLocationComment,
    iqsCommentsLocationsIds,
  });
  return (
    <div>
      <Modal
        id={id}
        title={selectedCommentEvent?.commentId ? strings.editLocationComment : strings.createNewLocationComment}
        confirmAction={onSaveClick}
        confirmTitle={strings.confirm}
        loading={loading}
        onCloseClick={onCloseClick}
        className='add-location-comment-popup-width'
        newVersionUi={true}
        titleOfCancel={strings.cancel}
        extraButton={true}
        extraButtonClick={onDeleteClick}
        extraButtonLodaing={deleteLoading}
      >
        <div className='mt-4'>
          <FormComponent
            formName='addLocationCommentForm'
            model={addLocationCommentModel()}
            formValue={locationCommentFormData.formData}
            onChange={handleForm}
          />
        </div>

        {error && <span className='text-danger error-block'>{error}</span>}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, calendar }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  iqsCommentLocations: calendar.iqsCommentLocations,
  iqsCommentsLocationsIds: calendar.iqsCommentsLocationsIds,
});

export default connect(mapStateToProps, { fetchIqsLocations, createOrEditIqsComment, deleteLocationComment })(
  AddLocationComment,
);
