import { connect } from 'react-redux';
import IqsNewRequest from './IqsNewRequest.component';
import {
  fetachIqsBookingMasterData,
  fetchBookingTabBookings,
  fetchDropdownData,
  fetchRequesedEquipmentsData,
  fetchRequestDetlies,
  systemClasForIqs,
} from './IqsNewRequest.actions';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

const mapStateToProps = ({
  authReducer,
  iqsRequestReducer,
  languageReducer,
  masterDataReducer,
  appActionsReducer,
}) => ({
  user: authReducer.user,
  allDropdownData: iqsRequestReducer.allDropdownData,
  language: languageReducer,
  countries: masterDataReducer.countries,
  requestDetailes: iqsRequestReducer.requestDetailes,
  appActions: appActionsReducer,
});

export default connect(mapStateToProps, {
  fetchDropdownData,
  fetchMasterData,
  fetchRequestDetlies,
  fetachIqsBookingMasterData,
  fetchRequesedEquipmentsData,
  fetchBookingTabBookings,
  systemClasForIqs,
})(IqsNewRequest);
