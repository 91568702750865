import React from 'react';
import cn from 'classnames';

import './stylesheet.scss';

export const Checkbox = ({ className, dataTest, disabled, field, label, onChange, selected, style, small, newuiVersionStyle, customeStyledClass, labelTopPosition,  description = '', showInfoIcon = false, dontShowCustomStyle}) => {
  const handleChange = event => {
    const { checked } = event.target;
    onChange(checked, field);
  };

  return (
    <>
      {(showInfoIcon && description) ? (
        <div className='d-flex align-items-center'>
           <label className={`checkbox-container ${className ? className : ''}`} data-test={dataTest} style={style}>
           <p className={`label ${dontShowCustomStyle ? dontShowCustomStyle : ''}`}>{label}</p>
           <input type='checkbox' checked={!!selected} onChange={handleChange} disabled={disabled} />
           <span
             className={cn(
               { checkmark: !small && !newuiVersionStyle, 'small-checkmark': small && !newuiVersionStyle },
               { 'newui-checkmark': newuiVersionStyle && !small, 'newui-small-checkmark': newuiVersionStyle && small },
               `${customeStyledClass ? customeStyledClass : ''}`,
             )}
           >
             {' '}
           </span>
         </label>
          {showInfoIcon && description && (
            <div className='position-relative'>
              <p className='input-icon cursor-pointer mr-1 mb-2'>i</p>
              <p className='info-desc position-absolute p-1'>{description}</p>
            </div>
          )}
        </div>
      ) : (
  <label className={`checkbox-container ${className ? className : ''}`} data-test={dataTest} style={style}>
        <p className={`label ${dontShowCustomStyle ? dontShowCustomStyle : ''}`}>{label}</p>
        <input type='checkbox' checked={!!selected} onChange={handleChange} disabled={disabled} />
        <span
          className={cn(
            { checkmark: !small && !newuiVersionStyle, 'small-checkmark': small && !newuiVersionStyle },
            { 'newui-checkmark': newuiVersionStyle && !small, 'newui-small-checkmark': newuiVersionStyle && small },
            `${customeStyledClass ? customeStyledClass : ''}`,
          )}
        >
          {' '}
        </span>
      </label>
      )}
    </>
  );

};

Checkbox.defaultProps = {
  label: '',
  selected: false,
  field: '',
  disabled: false,
  style: {},
  onChange: () => {},
  newuiVersionStyle: false,
  labelTopPosition: false
};

export default Checkbox;

