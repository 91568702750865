import React from 'react';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import ZmccRadioButton from 'library/common/commonComponents/FormComponent/Optimized/ZMCCRadioButton';
import { useApplicationSampleDetails } from './ApplicationSampleDetails.hook';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import Button from 'library/common/commonComponents/Button/Button';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation.component';
import StatusOfSample from '../../StatusOfSample/StatusOfSample.component';

export const ApplicationSampleDetails = props => {
  const { formValue } = props;
  const { mainApplicationChecklistForm, sampleDeatilsFrom } = formValue;
  const { handleForm, handleNonFormComponent, mainApplicationModel } = useApplicationSampleDetails(props);
  return (
    <>
      <h2 className='mt-4 zmcc-title-heading'>{strings.mainApplicationChecklist}</h2>
      <p className='mt-3 p-2' style={{ whiteSpace: 'break-spaces' }}>
        {strings.subHeading}
      </p>
      <div className='p-2 section-border'>
        <FormComponent
          model={mainApplicationModel()}
          formName='mainApplicationChecklistForm'
          formValue={mainApplicationChecklistForm.formData}
          onChange={handleForm}
        />
      </div>
      <h2 className='zmcc-title-heading mt-3'>{strings.smapleInformation}</h2>
      <div className='p-2 section-border'>
      <div className='col-sm-3 p-0'>
        <InputField
          label={strings.totalNumberOfSamples}
          field='totalNumberOfSamples'
          formName='sampleDeatilsFrom'
          placeholder=''
          isRequired={false}
          type='text'
          validators={[]}
          value={sampleDeatilsFrom.formData.totalNumberOfSamples}
          onChange={e => handleNonFormComponent(e, 'totalNumberOfSamples', 'sampleDeatilsFrom')}
          required={true}
        />
      </div>
      <div className='col-sm-12 p-0 mt-3'>
        <InputField
          label={strings.detailedDescription}
          field='detailedDescription'
          formName='sampleDeatilsFrom'
          placeholder=''
          isRequired={false}
          type='text'
          validators={[]}
          value={sampleDeatilsFrom.formData.detailedDescription}
          onChange={e => handleNonFormComponent(e, 'detailedDescription', 'sampleDeatilsFrom')}
          bigSizeInput={'bigger-input-field'}
        />
      </div>
      <div className='d-flex'>
      <p className='mt-4'>{strings.zmccDocumentLink}</p>
      <Button
        isGhost={true}
        bgFill={false}
        dataTest='transaction-details-show-messages'
        styleClass='show-all-messages-button ml-2'
        iconPosition='left'
        onClick={() => props.downloadZmccDocumentFolder()}
        value={'ZMCC Documents'}
      />
      </div>
      </div>
      
      <div className='col-sm-12 m-0 p-0 '>
        <Attachments
          text='Attach File'
          id='kitAttachmentsId'
          formValue={formValue && formValue}
          hasVideo={true}
          handleForm={props.updateSEMForm}
          actionFrom='zmccAttachement'
          isDownloadable
          downloadStream
          downloadIdField='kitAttachmentsId'
          isUploadAllowed={false}
          newVersionUi={true}
          uploaderStyle={"height-increased-dropzone"}
        />
      </div>
      <div className='row m-0'>
        <div className='col-sm-4 mt-3 p-0'>
          <StatusOfSample {...props} />
        </div>
        <div className='col-sm-2'></div>
        <div className='col-sm-4 mt-3'>
          <ZmccRadioButton
            label={strings.manipulateSamples}
            formName='sampleDeatilsFrom'
            value={sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImaging}
            placeholder=''
            field='manipulateTheSamplesPriorToImaging'
            isRequired={false}
            validators={[]}
            data={[
              {
                id: 'yes',
                value: 'yes',
              },
              {
                id: 'no',
                value: 'no',
              },
            ]}
            onChange={e => handleNonFormComponent(e, 'manipulateTheSamplesPriorToImaging', 'sampleDeatilsFrom')}
          />
        </div>
      </div>

      <div className='col-sm-12 p-0 mt-4'>
        <InputField
          label={strings.samplesCantManipulated}
          field='sampleSpecificComments'
          formName='sampleDeatilsFrom'
          placeholder=''
          isRequired={false}
          type='text'
          validators={[]}
          value={sampleDeatilsFrom.formData.sampleSpecificComments}
          onChange={e => handleNonFormComponent(e, 'sampleSpecificComments', 'sampleDeatilsFrom')}
          bigSizeInput={'bigger-input-field'}
        />
      </div>
    </>
  );
};

export default ApplicationSampleDetails;