import moment from 'moment';

import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import {
  FETCH_FREQUENTLY_BORROWED_LIST,
  FETCH_HOMEPAGE_NEW_KIT_LIST,
  FETCH_LOANS_SUB_MENU,
  IS_OPERATOR,
  MYSCHEDULED_EVENT_LIST,
  SCHEDULED_DETAILS,
  SCHEDULE_TYPES,
} from 'library/common/commonConstants/storeConstants';

import service from "./homepage.services";
import zeissInstance from 'library/utilities/AxiosInstance';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchMasterDataService, fetchMasterDataServicePost, fetchMasterDataServicePut } from 'library/api/master-data.service';

export const fetchKitListForHomepage = (isLoggedIn) => dispatch => {
  let filter = '';
  let graphql = '';

  const date = moment().format('YYYY-MM-DD HH:mm:ss');

  if (isLoggedIn) {
    filter = [[["kitStatus.id:ne:14", "or", "kitStatus.id:ne:15"], "and", "deleted:eq:false", "and", "kitName:nn:", "and", ["availabilityTo:goe:" + date, "or", ["availabilityTo:em:", "or", "availabilityTo:nl:"]], "and", ["kitCondition.id:ne:2", "and", "kitCondition.id:ne:4", "or", "kitCondition.id:nl:0"]], "and", "stock.user.id:eq:0"];
    graphql = "kitInformationId,isLabsLocationPartner,kitName,newTag,kitGenericDevicePicture,kitImages[preview,type,size,file,kitImagesId],stockInformation[id,locationName,locationShortName,businessUnit[name,id],businessUnitId,country[shortName],userRole[accountId,role[role]]],currentPosition,comment";
    dispatch(fetchFrequentlyBorrowedKits());
  } else {
    filter = [[["kitStatus.id:ne:14", "or", "kitStatus.id:ne:15"], "and", "deleted:eq:false", "and", "kitName:nn:", "and", ["availabilityTo:goe:" + date, "or", ["availabilityTo:em:", "or", "availabilityTo:nl:"]], "and", ["kitCondition.id:ne:2", "and", "kitCondition.id:ne:4", "or", "kitCondition.id:nl:0"]]];
    graphql = "kitInformationId,kitName,newTag,kitGenericDevicePicture,kitImages[imageUrlWithoutLogin,type,size,file,kitImagesId],stockInformation[id,locationName,locationShortName,businessUnit[name,id],businessUnitId,country[shortName]],currentPosition,comment";
  }
  const dataToSend = {
    page: 0,
    size: 9,
    filter: filter,
    sort: ['dateCreated', 'desc'],
    graphql: graphql
  };

  dispatch(changeLoader(true));
  service.fetchKitListForHomepageService(dataToSend)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_HOMEPAGE_NEW_KIT_LIST,
          payload: res.data
        });
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      dispatch(changeLoader(false));
      dispatch({
        type: FETCH_HOMEPAGE_NEW_KIT_LIST,
        payload: {content: []}
      });
    });
};

export const fetchFrequentlyBorrowedKits = () => dispatch => {
  const graphql = 'kitInformationId,isLabsLocationPartner,kitName,kitGenericDevicePicture,kitImages[preview,type,size,file,kitImagesId],stockInformation[id,locationName,locationShortName,businessUnit[name,id],businessUnitId,country[shortName],userRole[accountId,role[role]]]';

  const dataToSend = {
    graphql: encodeURIComponent(graphql),
    limit: 9
  };

  dispatch(changeLoader(true));

  service.fetchFrequentlyBorrowedKitsService(dataToSend)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_FREQUENTLY_BORROWED_LIST,
          payload: res.data
        });
      }
      dispatch(changeLoader(false));
    })
    .catch(() => {
      dispatch(changeLoader(false));
      dispatch({
        type: FETCH_FREQUENTLY_BORROWED_LIST,
        payload: []
      });
    })

};

export const fetchSubMenuOfZmccRequest = () => async dispatch => {
  try {
    const res = await zeissInstance.get(URLS.zmccRequestSubmenu);
    saveToStorage(identifiers.zmccSubLoanMenus,res.data);
    dispatch({
      type: FETCH_LOANS_SUB_MENU,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_LOANS_SUB_MENU,
      payload: [],
    });
  }
};

export const getAllScheduledEventList = accountId => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.getAllScheduleList(accountId), {});
    dispatch({
      type: MYSCHEDULED_EVENT_LIST,
      payload: res.data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: MYSCHEDULED_EVENT_LIST,
      payload: [],
    });
    return { success: false };
  }
};

export const getScheludeTypeList = () => async dispatch => {
  try {
    const res = await zeissInstance.get(URLS.getScheludeType);
    dispatch({
      type: SCHEDULE_TYPES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SCHEDULE_TYPES,
      payload: [],
    });
  }
};

export const addSchedule = dataToSend => async dispatch => {
  try {
    const res = await zeissInstance.post(URLS.addSchedule, dataToSend);
    dispatch(getAllScheduledEventList(dataToSend.accountId));
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const checkIsOperator = (accountId) => async dispatch => {
  try {
    const res = await zeissInstance.get(URLS.checIsOperatorUser(accountId));
    dispatch({
      type: IS_OPERATOR,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: IS_OPERATOR,
      payload: [],
    });
  }
};

export const getScheduledDetailes = (scheduledId) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await zeissInstance.get(URLS.getScheduledDetailes(scheduledId));
    dispatch(changeLoader(false));
    dispatch({
      type: SCHEDULED_DETAILS,
      payload: res.data,
    });
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: SCHEDULED_DETAILS,
      payload: [],
    });
    return { success: false };
  }
}

export const editSchedule = (dataToSend) => async dispatch => {
  try {
    const res = await zeissInstance.post(URLS.editMySchedule(dataToSend.scheduledId), dataToSend);
    dispatch(getAllScheduledEventList(dataToSend.accountId));
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
}

export const deleteSchedule = (dataToSend, singleEvent) => async dispatch => {
  try {
    if (singleEvent == true) {
      dispatch(changeLoader(true));
    }
    const res = await zeissInstance.post(URLS.deleteScheduledEvent(dataToSend.scheduledId), dataToSend);
    if (singleEvent) {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'success', 'deleteScheduledEvent'));
    }
    dispatch(getAllScheduledEventList(dataToSend.accountId));
    return { success: true };
  } catch (error) {
    if (singleEvent == true) {
      dispatch(changeLoader(false));
      toggleActionMessage(true, 'error', 'somethingWentWrongMessage');
    }
    return { success: false, error };
  }
}

export const updateUserCheckedDontShowAgain = async (popupName) => {
  try{
    const result = await fetchMasterDataServicePut(`${URLS.getUserCheckedDontShowAgain}?showAgain=false&popupName=${popupName}`)
    return {success: true, data: result.data}
  }catch(err){
    return {success: false, data: false}
  }
}
