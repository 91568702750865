import Validators from 'library/utilities/Validators';

export const companyPopupModel = [
  {
    label: 'sapIdAvailable',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: '',
    field: 'companySAPIDAvailable',
    validators: [],
    required: false,
    styleClass: 'col-6',
    options: [
      {
        id: true,
        value: 'yes',
      },
      {
        id: false,
        value: 'no',
      },
    ],
  },
  {
    label: 'companySapId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companySAPID',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    disabled: false,
  },
  {
    label: 'companyGroup',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyGroup',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'comapnyStreetHouseNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyStreetAndHouseNumber',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyPostalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPostalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyCity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyCity',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyCountry',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'companyPhone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPhone',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyEmail',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.email, message: 'invalidEmailError' },
    ],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyWebsite',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyWebsite',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'industrialSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'companyIndustrialSegment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'industrialSubSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'companyIndustrialSubSegment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'ndaType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'companyNdaType',
    validators: [],
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'ndaValidUntil',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'companyNdaValidUntil',
    validators: [],
    required: false,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
    disabled: false,
    tileDisabled: [],
  },
  {
    label: 'aukomMember',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: '',
    field: 'aukomMember',
    validators: [],
    required: false,
    styleClass: 'col-6',
    options: [
      {
        id: true,
        value: 'yes',
      },
      {
        id: false,
        value: 'no',
      },
    ],
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'companyComment',
    validators: [],
    required: false,
    styleClass: 'col-12',
    disabled: false,
  },
];
