import { useState } from 'react';
import { customerContactPopupModel } from './CustomerContactPopup.constants';
import * as $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { scrollToTop } from 'library/utilities/scrollActions';

export const useCustomerContactPopup = ({
  id,
  allDropdownData,
  setCustomerContactList,
  customerContactList,
  toggleActionMessage,
}) => {
  const [customerContactPopupForm, setCustomerContactPopupForm] = useState({ formData: {} });
  const getCustomerContactPopupModel = () => {
    const model = [...customerContactPopupModel];
    model[9].options = allDropdownData?.IqRequestCustomerContactRole || [];
    return model;
  };

  const handleForm = ({ values, field, formName }) => {
    setCustomerContactPopupForm({
      ...customerContactPopupForm,
      formData: {
        ...customerContactPopupForm.formData,
        ...values,
      },
    });
  };

  const onSaveClick = () => {
    const validForm = validateForm({
      form: customerContactPopupForm,
      model: getCustomerContactPopupModel(),
    });
    if (!validForm.isFormValid) {
      return setCustomerContactPopupForm(validForm);
    }
    const prepareData = {
      ...customerContactPopupForm.formData,
      iqsRequestContactId: customerContactList.length + 1,
      isNewlyAdded: true,
    };
    setCustomerContactList([...customerContactList, prepareData]);
    toggleActionMessage(true, 'success', 'contactCreatedSucessMessage');
    scrollToTop(500);
    onCloseClick();
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setCustomerContactPopupForm({ formData: {} });
  };

  return {
    getCustomerContactPopupModel,
    handleForm,
    customerContactPopupForm,
    onCloseClick,
    onSaveClick,
  };
};
