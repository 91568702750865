import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class ResourceView extends Component {

  static propTypes = {
    schedulerData: PropTypes.object.isRequired,
    contentScrollbarHeight: PropTypes.number.isRequired,
    slotClickedFunc: PropTypes.func,
    slotItemTemplateResolver: PropTypes.func,
  };

  render() {
    const {
      schedulerData,
      contentScrollbarHeight,
      slotClickedFunc,
      slotItemTemplateResolver,
      isZMCCCalendar,
      isLabsLocation,
      isOperatorSchedule
    } = this.props;
    const { renderData } = schedulerData;
    // const changeIntoZmccRoomWise = () => {
    //     let roomNames ={}
    //     renderData.map(data => {
    //       if (!roomNames.hasOwnProperty(data.roomGroupName)){
    //         roomNames[data.roomGroupName] = [];
    //         roomNames[data.roomGroupName].push(data);
    //       }
    //       else{
    //         roomNames[data.roomGroupName].push(data);
    //       }
    //     })
    //     return roomNames;
    // }
    // const renderDataForZMCC = changeIntoZmccRoomWise();
    // const shownRoomNames =[];
    let width = schedulerData.getResourceTableWidth() - 2;
    let paddingBottom = contentScrollbarHeight;
    // let resourceListForZmcc =[];
    // let listData = Object.keys(renderDataForZMCC).forEach(roomName => {
    //   renderDataForZMCC[roomName].map(
    //     item => {
    //       let a =
    //         slotClickedFunc !==  undefined ? (
    //           <span
    //             onClick={() => {
    //               slotClickedFunc(schedulerData, item);
    //             }}
    //           >
    //             <span className='expander-space' />
    //             {item.slotName}
    //           </span>
    //         ) : (
    //           <span>
    //             <span className='expander-space' />
    //             <span>{item.slotName}</span>
    //           </span>
    //         );
    //       let slotItem = (
    //         <div
    //           title={item.slotName}
    //           className='overflow-text header2-text'
    //           style={{ textAlign: 'left' }}
    //         >
    //           {a}
    //         </div>
    //       );
    //       if (!!slotItemTemplateResolver) {
    //         let temp = slotItemTemplateResolver(
    //           schedulerData,
    //           item,
    //           slotClickedFunc,
    //           width,
    //           'overflow-text header2-text',
    //         );
    //         if (!!temp) slotItem = temp;
    //       }

    //       resourceListForZmcc.push(
    //         <>
    //         {/* {
    //           !shownRoomNames.includes(roomName) &&
    //             <tr>  
    //               <td >{roomName.split("_")[0]}</td>                  
    //             </tr>
    //         } */}
    //         <tr key={item.slotId}>
    //           <td data-resource-id={item.slotId} style={{ height: item.rowHeight, ...tdStyle }}>
    //             {slotItem}
    //           </td>
    //         </tr>
    //         </>
    //       );

    //       shownRoomNames.push(roomName);
    //     }
    //   )
    // });
    const isIqsCalendar = window.location.href.includes("iqs-calendar");
    let resourceList = renderData.map(item => {
      let a =
        slotClickedFunc !==  undefined ? (
          <span
            onClick={() => {
              slotClickedFunc(schedulerData, item);
            }}
          >
            <span className='expander-space' />
            {item.slotName}
          </span>
        ) : (
          <span>
            <span className='expander-space' />
            <span>{item.slotName}</span>
          </span>
        );
      let slotItem = (
        <div
          title={item.slotName}
          className='overflow-text header2-text'
          style={{ textAlign: 'left' }}
        >
          {a}
        </div>
      );
      if (!!slotItemTemplateResolver) {
        let temp = slotItemTemplateResolver(
          schedulerData,
          item,
          slotClickedFunc,
          width,
          'overflow-text header2-text',
        );
        if (!!temp) slotItem = temp;
      }

      let tdStyle = {height: item.rowHeight};
      // if(item.groupOnly) {
      //   tdStyle = {
      //     ...tdStyle,
      //     backgroundColor: schedulerData.config.groupOnlySlotColor
      //   };
      // }
      
      return (
        <tr key={item.slotId}>
          <td data-resource-id={item.slotId} style={{ height: item.rowHeight, ...tdStyle, background: (!isLabsLocation && !isZMCCCalendar && !isOperatorSchedule && !isIqsCalendar) && '#0088D0'}}>
            {slotItem}
          </td>
        </tr>
      );
    });

    return (
      <div style={{ paddingBottom: paddingBottom }}>
        <table className='resource-table'>
          <tbody>{resourceList}</tbody>
        </table>
      </div>
    );
  }
}

export default ResourceView;
