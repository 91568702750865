import React, {useState} from 'react'
import './index.styles.scss';


const Tooltip = ({ content, children, styles }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
  
    const handleMouseEnter = () => {
      setTooltipVisible(true);
    };
  
    const handleMouseLeave = () => {
      setTooltipVisible(false);
    };
  
    return (
      <div className="tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isTooltipVisible && <div className={`tooltip-operators ${styles}`}>{content}</div>}
        {children}
      </div>
    );
  };

export default Tooltip