import React from 'react';
import strings from 'resources/locales/Translate';
import './IqsNewRequest.styles.scss';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { headerModel, IqsRequestBreadcrumbData } from './IqsNewRequest.constants';
import { useIqsNewRequest } from './IqsNewRequest.hook';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import ActionMessage from 'library/common/commonComponents/ActionMessage/ActionMessage';

export const IqsNewRequest = props => {
  const { requestDetailes } = props;
  const { displayActionMessage, type, message } = props.appActions;
  const { tabs, getHeaderModel, handleForm, headerForm, setBookingTab, isEdit } = useIqsNewRequest(props);

  return (
    <>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
      <div className='container-fluid'>
        <Breadcrumbs data={IqsRequestBreadcrumbData} />
        <div className='request-title p-2'>
          {requestDetailes?.transactionsInformation?.transactionsId ? strings.editRequest : strings.createRequest}
        </div>
        <div className='col-12 mt-4'>
          <FormComponent
            model={getHeaderModel()}
            formName='headerForm'
            formValue={headerForm.formData}
            onChange={handleForm}
          />
        </div>
        <div className='mt-4'>
          <Tabs tabs={tabs} newUiVersion={true} currentTab={setBookingTab ? 2 : 1} toggleTab={isEdit ? true : false} />
        </div>
      </div>
    </>
  );
};

export default IqsNewRequest;
