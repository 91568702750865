import React, {useState, useEffect} from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import { scrollToTop } from 'library/utilities/scrollActions';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import Button from 'library/common/commonComponents/Button/Button';
import Validators from "library/utilities/Validators";

import {
  newComponentsModel,
} from './NewComponents.constants';
import './NewComponents.style.scss';
import Section from 'library/common/commonComponents/Section/Section';
import { useNewComponents } from './NewComponents.hook';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';

const TAB_ID = 2;

export const NewCreateKitComponents = props => {
  const { formValue, saveKit, cancelCreateKit, handleForm, isOptional, toggleActionMessage, kitDetails, addUpdateNewComponent, newComponentsList, fetchNewComponentsList, updateForm, deleteNewComponent, isEditView, componentsFetchedFromIbase, isInEditOrCreateorCopyMode=true,
    ibaseComponentsList, history
} = props;

  const extractIdFromPathname = (pathname) => {
    const match = pathname.match(/\/(?:edit-kit|edit-zmcc-kit|kit_details)\/(\d+)/);
    return match ? match[1] : null; // Return the ID or null if not found
  };

  const kitId = extractIdFromPathname(history.location.pathname);
  const canEditKit = true; 
  const [visibleComponents, setVisibleComponents] = useState([]);
  const [updatedNewComponentModel,setUpdatedNewComponentModel] = useState([]);

  const { getCols, newComponentsUpdatedList, onAddComponentClick, pageData, loadMoreComponents, loading } = useNewComponents({
    kitId, 
    canEditKit,
    addUpdateNewComponent,
    newComponentsList,
    fetchNewComponentsList,
    formValue,
    updateForm,
    deleteNewComponent,
    isEditView,
    componentsFetchedFromIbase,
    isInEditOrCreateorCopyMode,
    ibaseComponentsList,
    setVisibleComponents
  });
  const newComponentsListLength = newComponentsUpdatedList?.length > 0;

  useEffect(() => {
    setVisibleComponents(newComponentsUpdatedList?.length);
  }, [newComponentsUpdatedList]);

  useEffect(() => {
    const newComponentModelupdate = [...newComponentsModel];
    const formData = formValue?.mainComponentForm?.formData;
    const hasMarketingMaterial = formData?.marketingMaterial;
    const hasSupportingAsset = formData?.supportingAsset;
    if (hasMarketingMaterial || hasSupportingAsset) {
      newComponentModelupdate[2].validators = [
        { check: Validators.materialNumber, message: 'materialNumberFormatErrorOnKitComponent' }
      ];
      newComponentModelupdate[2].required = false;
    } else {
      newComponentModelupdate[2].validators = [
        { check: Validators.required, message: 'requiredErrorMessage' },
        { check: Validators.materialNumber, message: 'materialNumberFormatErrorOnKitComponent' }
      ];
      newComponentModelupdate[2].required = true;
    }
    setUpdatedNewComponentModel(newComponentModelupdate);
  }, [
    formValue?.mainComponentForm?.formData?.marketingMaterial,
    formValue?.mainComponentForm?.formData?.supportingAsset
  ]);

  const handleSaveKit = () => {
    if (newComponentsListLength) {
      saveKit(TAB_ID);
    } else {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'pleaseAddAtlestOneComponents');
    }
  };
  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
          {isInEditOrCreateorCopyMode && (
            <div className='justify-content-between align-items-center bg-filters'>
              <div className='col-sm-10'>
                <FormComponent
                  model={updatedNewComponentModel}
                  formName='newComponentsForm'
                  formValue={formValue.newComponentsForm.formData}
                  onChange={handleForm}
                />
              </div>
              <div className='col-sm-2'>
                <Button
                  bgFill={false}
                  iconName='plus'
                  onClick={() => onAddComponentClick(formValue.newComponentsForm.formData)}
                  value={strings.addComponent}
                  styleClass={
                    formValue.newComponentsForm.isFormValid
                      ? 'w-auto adjust-iqsSsc-add-room-btn'
                      :  (!formValue.newComponentsForm.isFormValid && formValue.newComponentsForm.formData.materialNumberError === 'materialNumberFormatErrorOnKitComponent') ?
                      'w-auto adjust-iqsSsc-add-room-btn mb-5' : 
                      (!formValue.newComponentsForm.isFormValid
                      &&  Object.keys(formValue.newComponentsForm.formData).length >0) &&
                      Object.keys(formValue.newComponentsForm.formData).some(
                        key => key.toLowerCase().includes('error') && formValue.newComponentsForm.formData[key]
                      )
                      ?     
                      'w-auto adjust-iqsSsc-add-room-btn mb-4' :
                      'w-auto adjust-iqsSsc-add-room-btn mb-1' 
                  }
                  newVersionUi={true}
                />
              </div>
            </div>
          )}
        {newComponentsUpdatedList?.length > 0 ? (
          <ExpandableTable cols={getCols()} rows={newComponentsUpdatedList} dontShowExpandArrow={true} />
        ) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}

        {pageData.totalElements > visibleComponents && (
          <div className="load-more-wrapper">
            <div className="col-sm-2 d-flex justify-content-center align-items-center mb-3">
              <Button onClick={loadMoreComponents} value={loading ? '... loading' : strings.loadMore} />
            </div>
          </div>
        )}
        {isInEditOrCreateorCopyMode && (
        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons
              onSave={handleSaveKit}
              onCancel={cancelCreateKit}
              styleClass={'create-kit-button mt-3'}
            />
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default NewCreateKitComponents;
