import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  FETCH_IQS_BOOKING_MASTER_DATA,
  FETCH_ALLIQS_REQUEST_DROPDOWN_DATA,
  FETCH_INDUSTRILA_SUB_SEGMENTS,
  FETCH_IQS_REQUEST_DETAILES,
  FETCH_IQS_REQUESTED_EQUIPMENTS_DATA,
  FTECH_BOOKING_TAB_BOOKINGS,
  FETCH_SYSTEMCLASS_DATA,
  FETCH_IQS_TIME_MANAGEMENT_LIST,
} from './IqsNewRequest.constants';
import { fetchUserList } from 'modules/KitLoan/KitLoanActions';
import React from 'react';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { FETCH_COUNTRIES_WITH_ACTIVE_STOCKS } from 'library/common/commonConstants/storeConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

export const fetchDropdownData = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const response = await fetchMasterDataService(URLS.getAllIqsRequestDropDownData);
    dispatch(changeLoader(false));
    dispatch({
      type: FETCH_ALLIQS_REQUEST_DROPDOWN_DATA,
      payload: response.data,
    });
    dispatch(fetchUserList());
    dispatch(fetchMasterData(URLS.countriesWithActiveStocks, FETCH_COUNTRIES_WITH_ACTIVE_STOCKS));
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: FETCH_ALLIQS_REQUEST_DROPDOWN_DATA,
      payload: {},
    });
    return { success: false };
  }
};

export const fetchIndustrialSubSegments = subSegment => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.getIndustrialSubSegment(subSegment));
    dispatch({
      type: FETCH_INDUSTRILA_SUB_SEGMENTS,
      payload: response.data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: FETCH_INDUSTRILA_SUB_SEGMENTS,
      payload: {},
    });
    return { success: false };
  }
};

export const createRequest = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const response = await fetchMasterDataServicePost(URLS.createRequest, dataToSend);
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'requestCreatedSucessMessage'));
    dispatch(changeLoader(false));
    dispatch(fetchRequestDetlies(response.data?.requestId));
    return { success: true, data: response.data };
  } catch (error) {
    scrollToTop(500);
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    return { success: false };
  }
};

export const fetchRequestDetlies = requestId => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const response = await fetchMasterDataServicePost(URLS.fetchRequest(requestId), {});
    dispatch(changeLoader(false));
    dispatch({
      type: FETCH_IQS_REQUEST_DETAILES,
      payload: response.data,
    });
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: FETCH_IQS_REQUEST_DETAILES,
      payload: {},
    });
    return { success: false };
  }
};

export const fetachIqsBookingMasterData = () => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.iqsBookingMAsterData);
    dispatch({
      type: FETCH_IQS_BOOKING_MASTER_DATA,
      payload: response.data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: FETCH_IQS_BOOKING_MASTER_DATA,
      payload: [],
    });
    return { success: false };
  }
};

export const saveRequestedEquipments = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));
    await fetchMasterDataServicePost(URLS.iqsBookingTabSaveData, dataToSend);
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'requestCreatedSucessMessage'));
    dispatch(changeLoader(false));
    return { success: true };
  } catch (error) {
    scrollToTop(500);
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    return { success: false };
  }
};

export const fetchRequesedEquipmentsData = requestId => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.iqsFetchRequestedEquipments(requestId));
    dispatch({
      type: FETCH_IQS_REQUESTED_EQUIPMENTS_DATA,
      payload: response.data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: FETCH_IQS_REQUESTED_EQUIPMENTS_DATA,
      payload: [],
    });
    return { success: false };
  }
};

export const fetchBookingTabBookings = requestId => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.iqsBookingTabBookings(requestId));
    dispatch({
      type: FTECH_BOOKING_TAB_BOOKINGS,
      payload: response.data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: FTECH_BOOKING_TAB_BOOKINGS,
      payload: [],
    });
    return { success: false };
  }
};

export const systemClasForIqs = buName => async dispatch => {
  try {
    const graphql =
      'systemClassName,systemMainComponent[systemMainComponentId,systemMainComponentName],productHierarchyId,systemClassId';
    const url = `${URLS.categoryList}?graphql=${encodeURIComponent(graphql)}&buName=${buName}`;
    const response = await fetchMasterDataService(url);
    dispatch({
      type: FETCH_SYSTEMCLASS_DATA,
      payload: response.data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: FETCH_SYSTEMCLASS_DATA,
      payload: [],
    });
    return { success: false };
  }
};

export const saveTimeManagement = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));
    await fetchMasterDataServicePost(URLS.saveTimeManagement, dataToSend);
    dispatch(fetchTimeManagementList(dataToSend.requestId));
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'slotCreatedSucessfully'));
    dispatch(changeLoader(false));
    return { success: true };
  } catch (error) {
    scrollToTop(500);
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    return { success: false };
  }
};

export const fetchTimeManagementList = requestId => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.fetchTimeManagementList(requestId));
    dispatch({
      type: FETCH_IQS_TIME_MANAGEMENT_LIST,
      payload: response.data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: FETCH_IQS_TIME_MANAGEMENT_LIST,
      payload: [],
    });
    return { success: false };
  }
}
