// Generate a random alphanumeric string with prefix and timestamp
// Example: "PREFIX-1613596236634-0.563255"
export const generateRandomKey = prefix => {
  let s = `${Date.now()}-${Math.random()}`;
  s = prefix ? `${prefix}-${s}` : s;
  return s;
};

//Generates time slots for every 1 hour from 00:00 to 23:00
export const generateTimeSlots = () => {
  const timeSlots = [];
  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = hour.toString().padStart(2, '0');
    const timeSlot = {
      name: `${formattedHour}:00`,
      staticCode: `${formattedHour}:00`,
    };
    timeSlots.push(timeSlot);
  }
  return timeSlots;
};
