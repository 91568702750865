import React from 'react';
import MasterData from '../MasterData';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import strings from 'resources/locales/Translate';
import AddressConfigration from './Components/AddressConfigration/AddressConfigurationList.component';
import { masterDataBreadcrumbData } from './Components/AddressConfigration/AddressConfigurationList.constants';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { connect } from 'react-redux';
import MeusWarehousAddress from './Components/MedUsWarehouseAddress';
import { checkHasAccessToDlaLocationsWithRoles, checkHasMedUsWarehouseAccess } from 'library/utilities/checkRoles';

export const MasterDataTabs = ({ appActions, user }) => {
  const { displayActionMessage, type, message } = appActions;
  const hasMedUsWarehouseAccess = checkHasMedUsWarehouseAccess(user);
  const hasAccessToDlaLocations = checkHasAccessToDlaLocationsWithRoles(user, ['STOCK_CONTROLLER']);

  let tabs = [];
  if (user && user.admin) {
    tabs.push(
      {
        id: 1,
        tabTitle: 'masterData',
        className: '',
        render: isActive => {
          return <MasterData isActiveTab={isActive} />;
        },
      },
      {
        id: 2,
        tabTitle: 'addressConfig',
        className: '',
        render: isActive => {
          return <AddressConfigration isActiveTab={isActive} />;
        },
      },
      {
        id: 3,
        tabTitle: 'maintainDeliveryAddress',
        className: '',
        render: isActive => {
          return <MeusWarehousAddress isActiveTab={isActive} />;
        },
      }
    );
  }
  if(!user.admin && hasAccessToDlaLocations){
    tabs.push({
      id: tabs.length + 1,
      tabTitle: 'masterData',
      className: '',
      render: isActive => {
        return <MasterData isActiveTab={isActive} hasOnlyAccessToSearchTerm={true} />;
      },
    });
  }
  if (!user.admin && hasMedUsWarehouseAccess) {
    tabs.push({
      id: tabs.length + 1,
      tabTitle: 'maintainDeliveryAddress',
      className: '',
      render: isActive => {
        return <MeusWarehousAddress isActiveTab={isActive} />;
      },
    });
  }

  return (
    (
      <div>
        {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
        <div className='statistics-container'>
          <div className='container-fluid mb-3'>
            <Breadcrumbs data={masterDataBreadcrumbData} />
          </div>
          <Tabs tabs={tabs} />
        </div>
      </div>
    )
  );
};

const mapStateToProps = ({ appActionsReducer, authReducer }) => ({
  appActions: appActionsReducer,
  user: authReducer.user,
});

export default connect(mapStateToProps)(MasterDataTabs);
