import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { sellOffModel, addressModel } from './SellOff.constants';

export const useSellOff = ({
  history,
  saveSellOff,
  stockDetails,
  stockViewDuration,
  fetchMasterData,
  language,
  addressList,
}) => {
  const [sellOffForm, setSellOffForm] = useState({
    formData: {},
  });
  const [shopManagersForm, setShopManagersForm] = useState({
    value: [],
    fullValue: [],
    error: '',
  });
  const [addressForm, setAddressForm] = useState({
    formData: {},
  });

  useEffect(() => {
    fetchMasterData(URLS.stockViewDuration, actionTypes.FETCH_STOCK_VIEW_DURATION);
  }, [language, fetchMasterData]);

  const getSellOffModel = () => {
    const newModel = [...sellOffModel];
    newModel[2].options = stockViewDuration;

    return newModel;
  };


  useEffect(()=>{
    if(stockDetails && !stockDetails.stockSellOffPlatformModel && sellOffForm.formData.sellStatus){
      setAddressForm({
        formData: {
          firstName: addressList[0].firstName,
          lastName: addressList[0].lastName,
          organization: addressList[0].organization,
          department: addressList[0].department,
          street: addressList[0].street,
          number: addressList[0].number,
          postalCode: addressList[0].postalCode,
          stateName: addressList[0].stateName,
          city: addressList[0].city,
          phone: addressList[0].phone,
          email: addressList[0].email,
        },
      })
    }else if(stockDetails && !stockDetails.stockSellOffPlatformModel && !sellOffForm.formData.sellStatus){
      setAddressForm({
        formData: {
          firstName: '',
          lastName: '',
          organization: '',
          department: '',
          street: '',
          number: '',
          postalCode: '',
          stateName: '',
          city: '',
          phone: '',
          email: '',
        },
      })
    }

  },[sellOffForm.formData.sellStatus])

  useEffect(() => {
    if (stockViewDuration && stockViewDuration.length) {
      setSellOffForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          kitViewDuration: prevState.formData.kitViewDuration,
          kitViewDurationFullValue: stockViewDuration.find(item => item.id === prevState.formData.kitViewDuration),
        }})
      );
    }
  }, [stockViewDuration]);

  useEffect(() => {
    if (stockDetails && stockDetails.stockSellOffPlatformModel) {
      setAddressForm({
        formData: {
          firstName: stockDetails.stockSellOffPlatformModel.firstName,
          lastName: stockDetails.stockSellOffPlatformModel.lastName,
          organization: stockDetails.stockSellOffPlatformModel.organization,
          department: stockDetails.stockSellOffPlatformModel.department,
          street: stockDetails.stockSellOffPlatformModel.street,
          number: stockDetails.stockSellOffPlatformModel.number,
          postalCode: stockDetails.stockSellOffPlatformModel.postalCode,
          stateName: stockDetails.stockSellOffPlatformModel.stateName,
          city: stockDetails.stockSellOffPlatformModel.city,
          phone: stockDetails.stockSellOffPlatformModel.phone,
          email: stockDetails.stockSellOffPlatformModel.email,
        },
      });  
      setSellOffForm({
        formData: {
          sellStatus: stockDetails.stockSellOffPlatformModel.sellStatus,
          borrowerViewStatus: stockDetails.stockSellOffPlatformModel.borrowerViewStatus,
          kitViewDuration: stockDetails.stockSellOffPlatformModel.kitViewDuration,
        },
      });
      setShopManagersForm({
        value: stockDetails.stockSellOffPlatformModel.shopManagers.map(manager => manager.accountId),
        fullValue: stockDetails.stockSellOffPlatformModel.shopManagers,
        error: '',
      });
    } else {
      setAddressForm({
        formData: {
          firstName: stockDetails.firstName,
          lastName: stockDetails.lastName,
          organization: stockDetails.organization,
          department: stockDetails.department,
          street: stockDetails.street,
          number: stockDetails.number,
          postalCode: stockDetails.postalCode,
          stateName: stockDetails.stateName || (stockDetails.state && stockDetails.state.stateName) || '',
          city: stockDetails.city,
          phone: stockDetails.phone,
          email: '',
        },
      }); 
    }
  }, [stockDetails]);

  useEffect(() => {
    if (shopManagersForm.fullValue && shopManagersForm.fullValue[0] && !isEqual(shopManagersForm.fullValue[0].email, addressForm.formData.email)) {
      setAddressForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          email: shopManagersForm.fullValue[0].email,
        },
      }));
    }
    if (shopManagersForm.fullValue && !shopManagersForm.fullValue.length) {
      setAddressForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          email: '',
        },
      }));
    }
  }, [shopManagersForm.fullValue]);

  const handleForm = ({ values, field, formName }) => {
    if (formName === 'sellOffForm') {
      setSellOffForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    } else if (formName === 'addressForm') {
      setAddressForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
  }

  const cancelSellOff = () => {
    history.replace('/stocks');
  }

  const handleClickOnSave = async () => {
    const isEdit = stockDetails && stockDetails.stockSellOffPlatformModel && stockDetails.stockSellOffPlatformModel.id;
    const id = isEdit ? stockDetails.stockSellOffPlatformModel.id : stockDetails.id;
    let isValid = true;
    const validSellOffForm = validateForm({
      form: sellOffForm,
      model: sellOffModel || [],
    });
    const validAddressForm = validateForm({
      form: addressForm,
      model: addressModel || [],
    });

    if (!validSellOffForm.isFormValid) {
      isValid = false;
      setSellOffForm(validSellOffForm);
    } 
    if (!validAddressForm.isFormValid) {
      isValid = false;
      setAddressForm(validAddressForm);
    }
    if (!shopManagersForm.value.length) {
      isValid = false;
      setShopManagersForm(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }))
    }

    if (isValid) {
      const dataToSend = {
        sellStatus: !!sellOffForm.formData.sellStatus,
        borrowerViewStatus: !!sellOffForm.formData.borrowerViewStatus,
        kitViewDuration: sellOffForm.formData.kitViewDuration,
        shopManagersIds: shopManagersForm.value,
        firstName: addressForm.formData.firstName,
        lastName: addressForm.formData.lastName,
        organization: addressForm.formData.organization,
        department: addressForm.formData.department,
        street: addressForm.formData.street,
        number: addressForm.formData.number,
        postalCode: addressForm.formData.postalCode,
        state: 0,
        stateName: addressForm.formData.stateName,
        city: addressForm.formData.city,
        phone: addressForm.formData.phone,
        email: addressForm.formData.email,
      };
      await saveSellOff(dataToSend, id, shopManagersForm.fullValue, stockDetails, isEdit);
    }
  }

  const addShopManagers = (value, fullValue) => {
    setShopManagersForm(prevState => ({
      value: [...prevState.value, value],
      fullValue: [...prevState.fullValue, fullValue],
    }));
  }

  const removeShopManagers = accountId => {
    setShopManagersForm(prevState => ({
      value: prevState.value.filter(item => item!== accountId),
      fullValue: prevState.fullValue.filter(item => item.accountId !== accountId),
    }));
  }

  return {
    handleClickOnSave,
    cancelSellOff,
    handleForm,
    addShopManagers,
    removeShopManagers,
    shopManagersForm,
    sellOffForm,
    addressForm,
    getSellOffModel,
  };
};
