import React, { Component } from 'react';
import { connect } from 'react-redux';

import strings from 'resources/locales/Translate';

import './inputFieldStyles.scss';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { NewRefreshIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

export class InputField extends Component {
  state = {
    isInfoOpen: false,
  }

  componentDidMount() {
    this.addTooltipListeners();
  }

  componentDidUpdate() {
    this.addTooltipListeners();
  }

  componentWillUnmount() {
    this.removeTooltipListeners();
  }

  addTooltipListeners = () => {
    const elements = document.querySelectorAll('[data-title]');
    elements.forEach(elem => {
      elem.addEventListener('mouseenter', this.showTooltip);
      elem.addEventListener('mouseleave', this.hideTooltip);
    });
  }

  removeTooltipListeners = () => {
    const elements = document.querySelectorAll('[data-title]');
    elements.forEach(elem => {
      elem.removeEventListener('mouseenter', this.showTooltip);
      elem.removeEventListener('mouseleave', this.hideTooltip);
    });
  }

  showTooltip = (event) => {
    const title = event.target.getAttribute('data-title');
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = title;
    document.body.appendChild(tooltip);
    const rect = event.target.getBoundingClientRect();
    tooltip.style.left = `${rect.left + window.scrollX}px`;
    tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight}px`;
  }

  hideTooltip = () => {
    const tooltip = document.querySelector('.custom-tooltip');
    if (tooltip) {
      tooltip.remove();
    }
  }

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  onChange = event => {
    this.props.onChange(event.target.value, this.props.field);
  };

  render() {
    const {
      dataTest,
      label,
      isRequired,
      type,
      extraProps,
      placeholder,
      icon,
      infoMessage,
      showExtraInfo,
      disabled,
      value,
      error,
      maxLength,
      className,
      bigSizeInput,
      appendIcon,
      adjustIcon,
      newRefreshIcon,
      onClickingIbaseRefreshIcon,
      iconClassName,
      inputClassName,
    } = this.props;

    return (
      <div className={`input-field ${className}`} data-test={dataTest}>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p>
            {showExtraInfo && <p className='input-info cursor-pointer'>i</p>}
            {showExtraInfo && <div className='info-message position-absolute'>{strings[infoMessage]}</div>}
          </div>
        </div>
        {icon && !appendIcon && <Icon name={icon} width={21} height={21} fill='rgba(154, 155, 156, 1)' className='new-version-search-icon' />}
        <div className='d-flex'>
          <input
            type={type}
            value={value || ''}
            className={`form-control rounded-0 ${bigSizeInput !== undefined ?bigSizeInput: ''} position-relative ` + (!!error && 'input-error') + ` ${!appendIcon ? 'input-cursor-adjust' : ''} ${inputClassName}`}
            placeholder={placeholder}
            maxLength={maxLength}
            {...extraProps}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={disabled}
            readOnly={this.props.readOnly}
            onFocus={this.props.onfocus}
          />
          {
            (newRefreshIcon &&  value) &&
            <div className='d-flex justify-content-center align-items-center ml-2' style={{ width: '70px', height: '40px', border: '1px solid #0088d0' }} data-title="Refresh iBase" 
            onClick={() => onClickingIbaseRefreshIcon(value)}
            >
              <NewRefreshIcon />
            </div>
          }
        </div>
        {icon && appendIcon && <span onClick={this.props.onIconClick}><Icon name={icon} width={24} height={24} fill='#9a9b9c' className={`${adjustIcon ? 'new-search-icon-adjust' : 'search-icon'} ${iconClassName}`} /></span>}
        {!!error && <span className='text-danger error-block'>{strings[error] || error}</span>}
      </div>
    );
  }
}

InputField.defaultProps = {
  label: '',
  type: 'text',
  field: '',
  isRequired: false,
  validators: [],
  placeholder: '',
  icon: null,
  showExtraInfo: false,
  infoMessage: '',
  disabled: false,
  onError: () => {},
  onChange: () => {},
  appendIcon: true,
  adjustIcon: false,
  newRefreshIcon: false,
  onClickingIbaseRefreshIcon: () => {},
  readOnly: false,
  onfocus: () => {},
  onBlur: () => {},
  iconClassName: '',
  inputClassName: "",
  onIconClick: () => {},
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(InputField);
