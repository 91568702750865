import * as $ from 'jquery';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { EditOutlineIcon, StockControllerDelete } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import Axios from 'axios';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { saveAs } from 'file-saver';
import strings from 'resources/locales/Translate';
import debounce from 'lodash/debounce';
import moment from 'moment';

export const useIqsAttachments = ({
  getIqsSscAttachmentList,
  stockId,
  deleteIqsSscDeleteAttachment,
  canEditStock,
  changeLoader,
  enableIOOptions,
  getKitAttachmentList,
  kitId,
  deleteKitAttachment,
}) => {
  const [filters, setFilters] = useState({
    attachmentCategory: null,
    attachmentCategoryFullValue: null,
    pageNumber: 0,
    pageSize: 30,
    dateRange: null,
  });

  const [searchText, setSearchText] = useState('');
  const [isEditUpdloadFile, setIsEditUploadFile] = useState(false);
  const [editingData, setEditingData] = useState(null);
  useEffect(() => {
    getIqsSscAttachmentList(stockId);
    if (kitId) {
      getKitAttachmentList(kitId);
    }
  }, [kitId]);

  const onClickUpload = () => {
    $(`#iqs-upload-attachment-modal`).modal('show');
  };

  const downloadFile = item => {
    let url = process.env.REACT_APP_BASE_URL + URLS.iqsSscDownloadAttchment(item.stockImagesId);
    if (kitId) {
      url = process.env.REACT_APP_BASE_URL + URLS.kitDownloadAttchment(item.kitAttachmentsId);
    }
    downloadStream(url, item);
  };

  const downloadStream = (url, file) => {
    changeLoader(true);
    const token = fetchFromStorage(identifiers.token);
    Axios(url, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        token: `${token}`,
      },
    })
      .then(response => {
        const newFile = new Blob([response.data], { type: file.type });
        saveAs(newFile, file.file);
        changeLoader(false);
      })
      .catch(error => {
        changeLoader(false);
        console.log(error);
      });
  };

  const onEditClick = item => {
    setIsEditUploadFile(true);
    $(`#iqs-upload-attachment-modal`).modal('show');
    setEditingData(item);
  };

  const getKitCols = () => [
    {
      title: strings.scheduleInputTitle,
      name: 'scheduleInputTitle',
      isSort: false,
      render: item => {
        return <span>{item.title}</span>;
      },
    },
    {
      title: strings.category,
      name: 'category',
      isSort: false,
      render: item => {
        return <span>{item.attachmentCategory}</span>;
      },
    },
    {
      title: strings.ioOptions,
      name: 'ioOptions',
      isSort: false,
      render: item => {
        return <span>{item.evaluation == true ? strings.io : item.evaluation == false ? strings.nio : ''}</span>;
      },
    },
    {
      title: strings.uploadedDate,
      name: 'uploadedDate',
      isSort: false,
      render: item => {
        return (
          <span>
            {item.uploadedDate && moment(item.uploadedDate).isValid()
              ? moment(item.uploadedDate).format('YYYY-MM-DD')
              : ''}
          </span>
        );
      },
    },
    {
      title: strings.uploadedBy,
      name: 'uploadedBy',
      isSort: false,
      render: item => {
        return <span>{item.uploader}</span>;
      },
    },
    {
      title: strings.comment,
      name: 'comment',
      isSort: false,
      render: item => {
        return <span>{item.comment}</span>;
      },
    },
    {
      title: '',
      name: 'download',
      isSort: false,
      render: item => {
        return (
          <span className='adjust-delete-edit-icon cursor-pointer'>
            <>
              <div onClick={() => onEditClick(item)}>
                <EditOutlineIcon height='16' width='16' />
              </div>
              <div onClick={() => deleteKitAttachment(kitId, item.kitAttachmentsId)}>
                <StockControllerDelete fill='#0088D0' />
              </div>
              <div onClick={() => downloadFile(item)}>
                <Icon name='newVersionDownload' height={17} width={17} viewBox={'0 0 16 13'} fill={'#0088D0'} />
              </div>
            </>
          </span>
        );
      },
    },
  ];

  const getCols = () => [
    {
      title: strings.scheduleInputTitle,
      name: 'scheduleInputTitle',
      isSort: false,
      render: item => {
        return <span>{item.title}</span>;
      },
    },
    {
      title: strings.category,
      name: 'category',
      isSort: false,
      render: item => {
        return <span>{item.attachmentCategory}</span>;
      },
    },
    {
      title: strings.uploadedDate,
      name: 'uploadedDate',
      isSort: false,
      render: item => {
        return (
          <span>
            {item.uploadedDate && moment(item.uploadedDate).isValid()
              ? moment(item.uploadedDate).format('YYYY-MM-DD')
              : ''}
          </span>
        );
      },
    },
    {
      title: strings.uploadedBy,
      name: 'uploadedBy',
      isSort: false,
      render: item => {
        return <span>{item.uploader}</span>;
      },
    },
    {
      title: strings.comment,
      name: 'comment',
      isSort: false,
      render: item => {
        return <span>{item.comment}</span>;
      },
    },
    {
      title: '',
      name: 'download',
      isSort: false,
      render: item => {
        return (
          <span className='adjust-delete-edit-icon cursor-pointer'>
            <>
              <div onClick={() => onEditClick(item)}>
                <EditOutlineIcon height={16} width={16} />
              </div>
              {canEditStock && (
                <div onClick={() => deleteIqsSscDeleteAttachment(stockId, item.stockImagesId)}>
                  <StockControllerDelete fill='#0088D0' />
                </div>
              )}
              <div onClick={() => downloadFile(item)}>
                <Icon name='newVersionDownload' height={17} width={17} viewBox={'0 0 16 13'} fill={'#0088D0'} />
              </div>
            </>
          </span>
        );
      },
    },
  ];

  const handleDateChange = useCallback(
    dateRange => {
      let newDateRange = null;

      if (dateRange) {
        newDateRange = [...dateRange];
        const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
        const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
        newDateRange[0] = dateRangeStart;
        newDateRange[1] = dateRangeEnd;
      }

      setFilters({ ...filters, dateRange });
      getIqsSscAttachmentList(stockId, { ...filters, dateRange });
      if (kitId) {
        getKitAttachmentList(kitId, { ...filters, dateRange });
      }
    },
    [filters, setFilters],
  );

  const onChange = useCallback(
    (id, field, value) => {
      const idValue = id;
      const newFilters = {
        ...filters,
        [field]: idValue,
        [`${field}FullValue`]: value,
      };

      setFilters(newFilters);
      getIqsSscAttachmentList(stockId, newFilters);
      if (kitId) {
        getKitAttachmentList(kitId, newFilters);
      }
    },
    [filters, setFilters],
  );

  const debouncedUpdateData = useCallback(
    debounce(value => {
      getIqsSscAttachmentList(stockId, { ...filters, searchText: value });
      if (kitId) {
        getKitAttachmentList(kitId, { ...filters, searchText: value });
      }
    }, 1000),
    [filters, stockId, kitId],
  );

  const onInputChange = useCallback(
    value => {
      setSearchText(value);
      debouncedUpdateData(value);
    },
    [debouncedUpdateData],
  );

  return {
    onClickUpload,
    getCols,
    onChange,
    filters,
    handleDateChange,
    onInputChange,
    searchText,
    getKitCols,
    isEditUpdloadFile,
    editingData,
    setIsEditUploadFile,
    setEditingData,
  };
};
