import React, { useCallback, useEffect, useState } from 'react';
import { uploadAttachmentModel } from './UploadAttachmentPopup.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import * as $ from 'jquery';
import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';
import { mapFormFieldAndValue } from 'library/utilities/mapFormFieldAndValue';
import moment from 'moment';

export const useUploadAttachmentPopup = ({
  id,
  user,
  attachmentCategory,
  uploadIqsAttachment,
  uploadNewKitAttachment,
  stockId,
  enableIOOptions,
  kitId,
  kitResourceAttachmentCategoriesList,
  getStockUsers,
  stockUsers,
  isEditUpdloadFile,
  editingData,
  setIsEditUploadFile,
  setEditingData,
}) => {
  const [files, setFiles] = useState(null);
  const [uploadFormData, setUploadFormData] = useState({ formData: {} });
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (stockId) {
      getStockUsers(stockId);
    }
  }, [stockId]);

  useEffect(() => {
    if (isEditUpdloadFile && editingData) {
      setEditData();
    } else {
      setPredefinedData();
    }
  }, [isEditUpdloadFile]);

  useEffect(() => {
    if (files?.length > 0) {
      const lastDotIndex = files[0].name.lastIndexOf('.');
      let nameWithoutExtension = files[0].name.substring(0, lastDotIndex);

      setUploadFormData(prevState => ({
        formData: {
          ...prevState.formData,
          fileName: nameWithoutExtension,
        },
      }));
      setFileType(files[0].name.substring(lastDotIndex + 1));
    }
  }, [files]);

  const setPredefinedData = () => {
    if (enableIOOptions) {
      return setUploadFormData({
        formData: {
          uploadedBy: `${user.firstName} ${user.lastName}`,
          uploadatedAt: new Date(),
        },
      });
    }
    setUploadFormData({
      formData: {
        uploadedBy: user.accountId,
        uploadedByFullName: {
          firstName: user.firstName,
          lastName: user.lastName,
          accountId: user.accountId,
        },
        uploadatedAt: new Date(),
      },
    });
  };

  const setEditData = () => {
    if (editingData) {
      const { title, comment, evaluation, uploadedBy, uploadedDate, uploadedAccountId, stockImagesId } = editingData;
      const typeCategoryList = enableIOOptions ? kitResourceAttachmentCategoriesList : attachmentCategory;
      const category = typeCategoryList.find(item => item.name === editingData?.attachmentCategory);
      const uploader = stockUsers?.find(each => each.accountId == uploadedAccountId);

      setUploadFormData({
        formData: {
          fileName: title,
          category: category.staticCode,
          categoryFullValue: category,
          comment: comment,
          uploadedBy: enableIOOptions ? editingData?.uploader : uploader.accountId,
          uploadedByFullName: enableIOOptions ? null : uploader,
          uploadatedAt: new Date(uploadedDate),
          stockImagesId: stockImagesId,
          evaluation: evaluation ? 'YES' : evaluation === false ? 'NO' : '',
        },
      });
    }
  };

  const getModel = () => {
    let model = [...uploadAttachmentModel];
    model[1].options = enableIOOptions ? kitResourceAttachmentCategoriesList : attachmentCategory;

    if (!enableIOOptions) {
      model = mapFormFieldAndValue(model, 'uploadedBy', 'options', stockUsers);
    } else {
      model = mapFormFieldAndValue(model, 'uploadedBy', 'type', 'text');
      model = mapFormFieldAndValue(model, 'uploadedBy', 'placeholder', '');
      model = mapFormFieldAndValue(model, 'uploadatedAt', 'disabled', true);
      model = mapFormFieldAndValue(model, 'uploadedBy', 'disabled', true);
    }

    if (enableIOOptions) {
      model[1].styleClass = 'col-6';
      model.splice(2, 0, {
        label: 'ioOptions',
        value: '',
        type: 'enhanced-circle-RadioButton',
        placeholder: '',
        field: 'evaluation',
        validators: [],
        required: false,
        styleClass: 'col-6',
        options: [
          {
            id: 'YES',
            value: 'io',
          },
          {
            id: 'NO',
            value: 'nio',
          },
        ],
      });
    }

    return model;
  };

  const addFile = file => {
    setFiles(file);
    setError('');
  };

  const handleForm = ({ values, field }) => {
    setUploadFormData({
      ...uploadFormData,
      formData: {
        ...uploadFormData.formData,
        ...values,
      },
    });
    setError('');
  };
  const onFileDelete = () => {
    setFiles(null);
    setUploadFormData(prevState => ({
      formData: {
        ...prevState.formData,
        fileName: '',
      },
    }));
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: uploadFormData,
      model: getModel(),
    });

    if (!validForm.isFormValid) {
      return setUploadFormData(validForm);
    }
    if (files === null && !isEditUpdloadFile) {
      setError('Please upload attachment');
      return;
    }

    const { fileName, category, comment, evaluation, uploadedBy, stockImagesId } = validForm.formData;
    // Shoule be moved to util
    let selectedDate = moment(validForm.formData.uploadatedAt).format('YYYY-MM-DD');
    const now = new Date();
    const currentUTCHours = now.getUTCHours();
    const currentUTCMinutes = now.getUTCMinutes();
    const currentUTCSeconds = now.getUTCSeconds();
    const combinedDateTime = new Date(
      `${selectedDate}T${String(currentUTCHours).padStart(2, '0')}:${String(currentUTCMinutes).padStart(
        2,
        '0',
      )}:${String(currentUTCSeconds).padStart(2, '0')}Z`,
    );
    const formattedDateTime = moment(combinedDateTime).utc().format('YYYY-MM-DD HH:mm:ss');

    const dataToSend = {
      fileName: `${fileName}.${fileType}`,
      category: category,
      comment: comment,
      attachment: files ? files : [],
      stockId: stockId,
      kitId: kitId,
      evaluation: evaluation,
      stockImagesId: stockImagesId || null,
    };
    if (uploadedBy) {
      dataToSend.uploadedBy = uploadedBy;
    }
    if (formattedDateTime) {
      dataToSend.uploadedDate = formattedDateTime;
    }
    if (enableIOOptions) {
      dataToSend.kitAttachmentsId = editingData?.kitAttachmentsId ? editingData.kitAttachmentsId : null;
    }
    setLoading(true);
    const { success, error } =
      enableIOOptions && kitId ? await uploadNewKitAttachment(dataToSend) : await uploadIqsAttachment(dataToSend);
    setLoading(false);
    if (!success) {
      if (error?.response?.data?.status === 422) {
        setError(error.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      onCloseClick();
      setFiles(null);
    }
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setPredefinedData();
    setError('');
    setFiles(null);
    setIsEditUploadFile(false);
    setEditingData(null);
  };

  return {
    addFile,
    getModel,
    uploadFormData,
    handleForm,
    files,
    onFileDelete,
    onSaveClick,
    onCloseClick,
    loading,
    error,
  };
};
