export const en = {
  document: 'Documents and Trainings',
  contact: 'Contact',
  homepage: 'Homepage',
  calendar: 'Calendar',
  loan: 'Loan',
  transaction: 'Requests',
  kits: 'Systems',
  stocks: 'Locations',
  administration: 'Administration',
  de: 'German',
  en: 'English',
  title: 'ZEISS Demo Management App',
  description:
    'The ‘ZEISS Demo Management App’ supports our global salesforce and inventory management teams with the demo logistics process. The app is a one-stop-destination to easily add, manage and sell-off demo inventory for improved cost-efficiency and increased opportunity win rates. In case of comments or questions feel free to contact our support.',
  myTransactions: 'My Transactions',
  kitLists: 'System Lists',
  myAccount: 'My Account',
  loginButton: 'Sign In',
  loginTitle: 'Login',
  loginDescriptionText:
    'If you want to use the ‘ZEISS Demo Management App’ you have to login. If you do not yet have personal access data, you can register now.',
  registerButton: 'Register',
  registerTitle: 'No account yet?',
  registerDescriptionText: 'Register now and get access to our demo locations.',
  yourBenefits: 'Your benefits',
  benefits1: 'Quick and easy demo booking',
  benefits2: 'Order tracking',
  benefits3: 'Uncomplicated handling',
  kitLoan: 'System Loan',
  courseLoan: 'Course Loan',
  singleLoan: 'Single Loan',
  c2cLoan: 'C2C Loan',
  menu: 'Menu',
  back: 'Back',
  myBookings: 'My Current Requests',
  changeProfileData: 'Change profile data',
  requestStockPermission: 'Request location permission',
  logout: 'Sign out',
  optionalField: '(optional)',
  descriptionText: 'Description',
  descriptionTextStep3: 'Order Summary',
  addressText: 'Address',
  pleaseSelect: 'Please select',
  loanProcess: 'Loan Process',
  stock: 'Locations',
  stockMaxThree: 'Locations (max 20.)',
  categorySystem: 'System Class',
  loanFrom: 'Loan from',
  loanTo: 'Loan to',
  showOptions: 'Show options',
  filterData: 'Filter data',
  filterStockPlaceholder: 'Filter Locations',
  filterCategoryPlaceholder: 'Filter System Class',
  pleaseSelectDate: 'Please select date',
  selectDate: 'Select Date',
  requiredErrorMessage: 'Field cannot be empty',
  invalidEmailError: 'E-Mail is not valid',
  invalidNumberError: 'Number is not valid',
  stockController: 'Location Manager',
  stockControllerSingle: 'Location Manager',
  locationName: 'Location Name',
  locationShortName: 'Location Short Name',
  country: 'Country',
  countryLand: 'Country',
  businessUnit: 'Business Unit',
  salutation: 'Salutation',
  firstName: 'First Name',
  lastName: 'Last Name',
  organization: 'Organization',
  department: 'Department',
  street: 'Street',
  number: 'Number',
  postalCode: 'Postal Code',
  city: 'City',
  phone: 'Phone',
  email: 'E-Mail',
  cancel: 'Cancel',
  cancelTransaction: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  kitNumber: 'System Number',
  details: 'Details',
  bookNow: 'Book Now',
  newForLoan: 'New for Loan',
  borrowedFrequently: 'Borrowed frequently',
  stockInformationSavedSuccessfully: 'Location information saved successfully',
  formIsInvalid: 'Please fill all the required fields',
  entitlementIndividuals: 'Entitled Individuals',
  informationTabText: 'Information',
  entitlementTabText: 'Entitlement',
  errorOccuredText: 'An error occurred.',
  navigation: 'Navigation',
  address: 'Address',
  socialMedia: 'Social Media',
  createStock: 'Create Location',
  information: 'Information',
  entitlements: 'Entitlements',
  workflows: 'Workflows',
  createKit: 'Create System',
  storageLocation: 'Storage Location at Location',
  referenceToSapERP: 'Loan Order',
  loanOrder: 'Loan Order',
  systemClassText: 'System Class',
  conditionText: 'Condition',
  commentText: 'Comment',
  deliveryCommentText: 'Delivery Comment',
  days: 'Days',
  delivery: 'Delivery',
  returnDelivery: 'Return Delivery',
  reconditioning: 'Reconditioning',
  systemMainComponent: 'System/Main Component',
  serialNumber: 'Serial Number',
  dateOfManufacture: 'Date of Manufacture (MM/YYYY)',
  countryOfOrigin: 'Country of Origin',
  commodityCode: 'Commodity Code',
  kitStatus: 'System Status',
  assignment: 'Assignment',
  mainComponent: 'Main Component',
  defaultPeriods: 'Default Periods',
  packageDimensions: 'Package Dimensions',
  availability: 'Availability',
  availableFrom: 'Available from',
  availableTo: 'Available to',
  generalInformation: 'General Information',
  kitName: 'System Name',
  ibaseText: 'iBase',
  systemValue: 'Gross List Price',
  marketingMaterialText: 'Marketing Material',
  hideKitText: 'Hide System',
  euroSymbol: '\u20AC',
  addButtonText: 'Add',
  noDataFoundText: 'No Data Available',
  addFileText: 'Add File',
  imagesText: 'Images',
  searchForUsernameText: 'Search by Username',
  filterByRoleText: 'Filter by Role',
  userLabelText: 'User',
  roleLabelText: 'Role',
  specialDeliveryTimes: 'Special Delivery Times',
  targetCountry: 'Target Country',
  deliveryTimes: 'Delivery Times',
  messages: 'Messages',
  components: 'Components',
  componentsWithComment: 'Components (please add material numbers)',
  accessories: 'Accessories',
  salesComponentLabel: 'Sales Components',
  furtherImportantComponentLabel: 'Further important Components',
  freeAccessoriesLabel: 'Free Accessories',
  attachments: 'Attachments',
  searchInput: 'What are you looking for?',
  allCountries: 'All Countries',
  allBusinessUnits: 'All Business Units',
  kitSavedSuccessMessage: 'System saved successfully',
  kitSavedSuccessMessageWithHideKit:
    'System saved successfully. Please mind that the system is not visible in the calendar as long as "Hide System" is marked as true.',
  kitSavedSuccessMessageWithLost:
    'The demo System will not be not displayed in the calendar because it was reported as lost.',
  kitSavedSuccessMessageWithIncomplete:
    'The demo system will not be not displayed in the calendar because it was reported as incomplete.',
  kitSavedSuccessMessageWithDefective:
    'The demo system will not be not displayed in the calendar because it was reported as defective.',
  kitSavedSuccessMessageWithRefurbishment: 'System saved successfully with system reparation details.',
  somethingWentWrongMessage: 'Something went wrong',
  imprintsText: 'Imprints',
  legalNoticesText: 'Legal Notice',
  dataProtectionText: 'Data Protection',
  termsAndConditionText: 'Terms and Condition',
  carlZeissInternational: 'ZEISS International',
  createKitCountryAndCommodityExtraMessage:
    'Needed for custom clearance process, will be printed on delivery documents',
  kitDetailsSavedWithMandatoryFieldsUnfilled:
    'System details saved successfully, but the demo system cannot be booked until all mandatory fields are filled.',
  // kitDetailsSavedWithMandatoryFieldsUnfilled: 'Fields are highlighted in red which are mandorty and should be filled so that kit is avaible for booking',
  requestStockPermissionButtonText: 'Request Permission',
  documentsPageTitleText: 'Documents',
  documentUploadedSuccessfully: 'Document(s) uploaded successfully',
  noFilesUploadedErrorMessage: 'No files added.',
  requestStockPermissionSuccess: 'Request for location permission successfully done.',
  kitImageUploadedSuccessfully: 'System images uploaded successfully.',
  kitAttachmentUploadedSuccessfully: 'System attachments uploaded successfully.',
  fillKitHeaderInformation: 'Please fill system header information',
  stockImages: 'Images',
  stockImageUploadedSuccessfully: 'Location Images uploaded successfully.',
  addAtleastOneStockController: 'Please add atleast one Location Manager.',
  stockDeletedSuccessfully: 'Location deleted successfully.',
  confirmDeleteStock: 'Delete Location?',
  confirmDeleteStockMessage: 'Are you sure you want to delete location?',
  confirm: 'Confirm',
  maintainKits: 'Maintain System',
  copyKit: 'Copy System',
  canNotDeleteNoEmptyStock:
    'There are still demo systems in the location. It is only possible to delete empty location.',
  edit: 'Edit',
  showFilter: 'Show Filters',
  hideFilter: 'Hide Filters',
  itemPerPage: 'Items per page',
  shortName: 'Short Name',
  shortNameCountry: 'Short Name Country',
  exportCurrentList: 'Export Current List',
  exportList: 'Export List',
  dateCreated: 'Date Created',
  status: 'Status',
  currentPosition: 'Current Position',
  showDetails: 'Show Details',
  createServiceTicket: 'Create Service Ticket',
  notAvailable: 'Not available',
  sold: 'Sold',
  toSell: 'To sell',
  inRefurbishment: 'In Reparation',
  ok: 'Ok',
  visible: 'Visible',
  invisible: 'Invisible',
  maintainStocks: 'Maintain Location',
  confirmDeleteKit: 'Delete System?',
  confirmDeleteKitMessage: 'Are you sure you want to delete system?',
  kitDeletedSuccessfully: 'System deleted successfully.',
  available: 'Available',
  readyForLoan: 'Ready for Loan',
  beforeDelivery: 'Before Delivery',
  deliveryCustomer: 'Delivery to the Customer',
  atDemo: 'At demo',
  atDemoUnconfirmed: 'At demo unconfirmed',
  returnToStock: 'Return to location',
  returnToStockUnconfirmed: 'Return to location unconfirmed',
  fileAttached: 'File attached',
  entitlementAtleastOneShouldBeAdded: 'Please select at least one user with role',
  kitAttachmentsDeleted: 'System attachments deleted successfully',
  duplicateStockNameError: 'Location Name already exists. Please enter another location name',
  kitCopySuccessMessage: 'System copied successfully',
  kitCopySuccessMessageWithHideKit:
    'System copied successfully. Please mind that the system is not visible in the calendar as long as "Hide System" is marked as true.',
  kitCopySuccessMessageWithLost:
    'System copied successfully. The demo system will not be not displayed in the calendar because it was reported as lost.',
  kitCopySuccessMessageWithDefective:
    'System copied successfully. The demo system will not be not displayed in the calendar because it was reported as defective.',
  kitCopySuccessMessageWithIncomplete:
    'System copied successfully. The demo system will not be not displayed in the calendar because it was reported as incomplete.',
  kitCopySuccessMessageWithRefurbishment:
    'System copied successfully. The demo system will not be not displayed in the calendar because it was reported as in reparation.',
  kitDetailsCopiedWithMandatoryFieldsUnfilled:
    'System details copied successfully, but the demo system cannot be booked until all mandatory fields are filled',
  addToLoanList: 'Add to Loan List',
  removeFromLoanList: 'Remove from Loan List',
  requestLoan: 'Request Loan',
  requestLoanPopoverMessage:
    'Loan periods you selected is more than 2 weeks, it needs to be requested and then confirmed from the location manager',
  todayText: 'Today',
  allStocksPlaceholder: 'All Locations',
  allCategorySystemsPlaceholder: 'All Systems',
  continueToLoanListText: 'Continue to Loan List',
  displayEquipment: 'Display Equipment',
  markForBookingText: 'Mark for booking/reservation',
  removalText: 'Removal',
  beginningOfBorrowLabel: 'Beginning of borrow',
  endOfBorrowLabel: 'End of borrow',
  yourLoanListHeader: 'Your Loan List',
  loanListLabel: 'Loan List',
  loanListNoticeAddressTextNormal: 'All items will be sent',
  loanListNoticeAddressTextBold: 'to the same address.',
  loanListNoticeAddressWithBookingMessage:
    'If you plan to ship to different addresses, separate bookings must be made.',
  positionText: 'Position',
  createMoreBooking: 'Create more bookings',
  reserveText: 'Reserve',
  reserveKitForSale: 'Reserve',
  continueBooking: 'Continue Booking',
  loanListBottomNoticeText1:
    'If you do not want to complete the booking process yet, you can also reserve the selected positions of you loan list.',
  loanListBottomNoticeText2:
    'Please note that a reservation is only possible within 24 hours and then will be automatically deleted.',
  loanBookingComingSoon: 'This feature is currently under development.',
  conflictBooking: 'Overlapping booking is not allowed',
  reasonForLoan: 'Reason for loan',
  nameShortDescription: 'Name/Short Description',
  customerOrderNumber: 'Customer Order Number',
  contactIdCRM: 'Contact ID CRM',
  opportunityIdCRM: 'Opportunity ID CRM',
  borrower: 'Borrower',
  deliveryAddress: 'Delivery Address',
  unsubscribeText: 'Unsubscribe',
  pastDateBooking: 'You cannot book a system on past dates',
  deliveryAddresses: 'Delivery Addresses',
  zeissIdPortal: 'ZEISS ID Portal',
  createNewDeliveryAddresses: 'Create new delivery address',
  deliveryAddressesSubHeader: 'Create and maintain your delivery addresses used for transactions here.',
  myDeliveryAddresses: 'My delivery addresses',
  createDeliveryAddessFormHeader: 'Create Delivery Address',
  saveAddressButtonText: 'Save Address',
  state: 'State',
  addressTitle: 'Address Title',
  stockNameConflictError: 'Location name already exists. Please choose another location name',
  iBaseConflictError: 'iBase number already exists. Please choose another iBase number',
  additionalSingleLoanRequest: 'Additional Single loan Request',
  deliveryComment: 'Delivery Comment',
  newKitLoan: 'New System Loan',
  customerData: 'Customer data',
  confirmation: 'Confirmation',
  confirmationStep3: 'Confirm Booking',
  additionalLoanRequest: 'Additional loan request',
  position: 'Position ',
  selectedKits: 'Selected Systems',
  addItem: 'Add Item',
  backToLoanList: 'Back to Loan List',
  customerToCustomerLoan: 'Customer to Customer Loan',
  backToAssignment: 'Back to Assignment',
  yourAddress: 'Your Address',
  newSingleLoan: 'New single loan',
  pleaseNote: 'Please note:',
  pleaseNoteText: 'The periods for delivery and reconditioning will be added automatically.',
  reservationFrom: 'Reservation from',
  reservationTo: 'Reservation to',
  reservationPeriod: 'Reservation Period',
  addressSavedSuccessfully: 'Address saved successfully!',
  editAddress: 'Edit address',
  editDeliveryAddessFormHeader: 'Edit Delivery Address',
  kitLoanBookedSuccessfully: 'System loan booked successfully',
  kitReservedSuccessfully: 'System reserved successfully',
  demoPeriodError:
    'Demo period for all the sytems should be same to continue your booking. Or you can make separate bookings for each System',
  selectKitsForBooking: 'Select systems to continue your booking',
  oneOrMoreKitsNotAllowedForReserve:
    'One or more Systems are not available for 24 hour reservation. Please remove those from reserving.',
  createNewSingleLoan: 'Single loan created successfully',
  helpForBuildingAndDismantling: 'Help for building and dismantling required',
  newCourseLoan: 'New Course Loan',
  systemClassSystem: 'System Class/System',
  quantity: 'Quantity',
  addKit: 'Add Device',
  courseLoanBookedSuccessfully: 'Course loan booked successfully',
  singleLoanCanBeBookedOnlySixWeeks: 'Single loan can be booked only 2 weeks in advance',
  pleaseSelectKits: 'Please select the devices for booking',
  executeBooking: 'Execute Booking',
  changeBorrower: 'Change Borrower',
  backToCustomerData: 'Back to Customer Data',
  name: 'Name',
  begin: 'Begin',
  end: 'End',
  approval: 'Approval',
  shipped: 'Shipped',
  receiptAtTheCustomer: 'Receipt At The Customer',
  receiptInStock: 'Receipt In Location',
  checked: 'Checked',
  transactions: 'Transactions',
  changeHistory: 'Change History',
  c2cLoanProcesses: 'C2C Loan Processes',
  createC2CLoan: 'Create C2C Loan',
  maintainC2CLoan: 'Maintain C2C Loan',
  remove: 'Remove',
  saveC2CLoan: 'Save C2C Loan',
  c2CLoansCreated: 'C2C loans successfully created',
  c2CLoansSaved: 'C2C loans successfully saved',
  startDate: 'Start Date',
  endDate: 'End Date',
  changeDates: 'Change Dates',
  rating: 'Rating',
  saveDates: 'Save Dates',
  adjustDates: 'Adjust Dates',
  createMessage: 'Create Message',
  showShippingDocuments: 'Show Shipping Documents',
  showReturnDeliveryNote: 'Show Return Delivery Note',
  showAllMessages: 'Show all messages',
  period: 'Period',
  borrowedKit: 'Borrowed System',
  crmContactId: 'CRM Contact ID',
  crmOpportunityId: 'CRM Opportunity ID',
  crmOpportunityStatus: 'CRM Opportunity Status',
  crmiBase: 'CRM iBase',
  customerOrderId: 'Customer Order ID',
  approveConfirm: 'Request Status',
  location: 'Location',
  loanCreatedBy: 'Loan created by',
  showTransactionDetails: 'Show transaction details',
  returnGoodsBooking: 'Return goods booking',
  returnGoodsBookingMenuText: 'Return goods booking- Single/Course Loan',
  inProcess: 'In-Process',
  entered: 'Entered',
  date: 'Date',
  deliveryNoteNumber: 'Delivery Note Number',
  completeness: 'Completeness',
  condition: 'Condition',
  cancellationRequest: 'Cancellation Request',
  transactionStatus: 'Request status',
  pleaseCall:
    'If you want to change booking details please call [phone] or write an email to <b>[email]</b>. Thank you.',
  pleaseCall: (phone, email) =>
    `<p>If you want to change booking details please call ${phone} or write an email to <b>${email}</b>. Thank you.</p>`,
  dateType: 'Date type',
  dateRange: 'Date Range',
  cancellationRequestSubmitted: 'Your cancellation request is submitted.',
  rejectRequestCancellationRequestSubmitted: 'Your cancellation request has been deferred.',
  chooseCategory: 'Choose Category',
  crmId: 'CRM ID',
  masterDataSavedSuccessfully: 'Master data saved successfully',
  loanPeriodStart: 'Loan Period - from',
  loanPeriodEnd: 'Loan Period - to',
  deliveryFrom: 'Delivery - from',
  deliveryTo: 'Delivery - to',
  returnDeliveryFrom: 'Return Delivery - from',
  returnDeliveryTo: 'Return Delivery - to',
  reconditioningFrom: 'Reconditioning - from',
  reconditioningTo: 'Reconditioning - to',
  returnGoodsBookingFrom: 'Return goods booking - from',
  returnGoodsBookingTo: 'Return goods booking - to',
  loanPeriod: 'Loan period',
  editedBy: 'Edited by',
  deliver: 'Deliver',
  loanApproved: 'Loan Approved',
  target: 'Target',
  viewOnlyPermissions: 'You have permissions only to view content.',
  datesSavedSuccessfully: 'Dates saved successfully.',
  savedSuccessfully: 'Saved successfully',
  c2cLoanProcessText: id => `This loan process is part of a Customer-To-Customer loan. ${id && `Process ID: `}`,
  loanRequestApprovedSuccessfully: ' Loan request approved successfully.',
  requestApprovedSuccessfully: 'Request approved successfully.',
  requestCancelledSuccessfully: 'Request cancelled successfully.',
  transactionUpdatedSuccessfully: 'Transaction updated successfully.',
  errorSavingDates: 'Error saving dates',
  kitsNotSelected: 'Systems are not selected',
  cancelledProcessess: 'Cancelled Requests',
  archiveProcessess: 'Archive Requests',
  requestAccepted: 'Request accepted successfully',
  requestRejected: 'Request rejected successfully',
  incomingDemoKits: 'Incoming Demo Systems',
  outgoingGoods: 'Outgoing Goods',
  cancelledProcesses: 'Cancelled Requests',
  archiveProcesses: 'Archive Requests',
  cancellationApproval: 'Cancellation Requests',
  openProcesses: 'Open Requests',
  damageOrLossReports: 'Damage Or Loss Reports',
  loadMoreMessages: 'Load more messages',
  loadMore: 'Load more',
  noMessageDamage: 'No messages for damage or loss reports.',
  noMessage: 'No messages found.',
  requestAlreadyExists: 'You have already requested for this Location. Please select another location',
  requestCancel: 'Cancel Booking',
  cancelBooking: 'Confirm Cancellation Request',
  rejectCancelRequest: 'Reject Cancellation',
  confirmRejectCancel: 'Are you sure you want to cancel this request?',
  cancelRequestRaised: 'Cancellation request has already been raised for this',
  confirmBookingCancel: 'Are you sure you want to cancel the transaction?',
  reasonCancelBooking:
    'Are you sure you want to cancel your booking? If yes, please mention the reason in the comment box below.',
  courseLoanCanBeBookedOnlySixWeeks: 'Course loan can be booked only 3 weeks in advance',
  loanRequests: 'Demo Request',
  sapOrderCreation: 'SAP Order Creation',
  goodReceipt: 'Incoming Single/Course Loan',
  listReceiving: 'Loans in Demo',
  listReconditioning: 'System Reconditioning',
  noComponentsAvailableMessage: 'No components available',
  noFreeAccessoriesMessage: 'No free accessories available',
  administrationHeading: 'Please select an option to add data to master list',
  systemClassAlreadyExist: 'System class already exists',
  systemComponentAlreadyExist: 'System main component already exists',
  countryAlreadyExist: 'Country already exists',
  selectLoanTypeMessage: 'Please select Demo Process',
  productId: 'Product ID',
  damageLossReport: 'Damage or Loss Report',
  deliveryDocuments: 'Delivery Documents',
  deliveryDocumentsHeading: 'Please configure the footer of your delivery document:',
  management: 'Management',
  law: 'Law',
  companyName: 'Company Name',
  groupName: 'Group Name',
  streetHouseNumber: 'Street & House Number',
  postalCodeCity: 'Postal Code & City',
  webAddress: 'Web Address',
  placeOfJuridiction: 'Place of Jurisdiction',
  vatIdentificationNumber: 'VAT Identification Number',
  taxNumber: 'Tax Number',
  weeeNumber: 'WEEE number',
  name1: 'Name 1',
  name2: 'Name 2',
  name3: 'Name 3',
  name4: 'Name 4',
  deliveryDocumentFooterSavedSuccessfully: 'Footer for delivery documents configured successfully',
  urlErrorMessage: 'URL is incorrect',
  webAddressErrorMessage: 'Please enter correct web address',
  twentyFourHourReservationNotAvailable: 'Not available for 24 hour reservation',
  fileDownloadError: 'An error occurred while downloading document',
  deleteDeliveryAddress: 'Delete Delivery Address',
  deleteTheAddress: 'Are you sure you want to delete the address?',
  addressDeletedSuccessfully: 'Address deleted successfully',
  reservedKitFor24Hours: '24',
  addKitLoan: 'Add Systemi Loan',
  kitLoanId: 'System Loan ID',
  checkBeforeReturn: 'Check Before Return',
  receivedNextCustomer: 'Received next customer',
  atStock: 'At Location',
  markToC2CLoan: 'Mark to C2C Loan',
  currentC2CLoanProcesses: 'Current C2C Loan Processes',
  possibleC2CLoanProcesses: 'Possible C2C Loan Processes',
  wrongCountryIdWhileBooking:
    'Address you have selected seems to be incomplete please select another address or create new',
  selectAll: 'Select All',
  c2cLoans: 'C2C Loans',
  toApprove: 'To Approve',
  approved: 'Approved',
  decline: 'Decline',
  delayReturnTransactionMessage:
    'Delay warning! Please return your demo equipment as soon as possible to the Location! See transaction(s)',
  suggestAlternateMessage:
    'Sorry the given period is not possible! The next possible period for this duration would be: {from} to {to}. If it does not fit to you, please use the calendar to find another slot. Thanks for your understanding!',
  yes: 'Yes',
  no: 'No',
  incomplete: 'Incomplete',
  noKitAddedInCart: 'No Systems Added to Loan List',
  confirmBooking: 'Confirm Booking',
  sameReservationPeriod: 'Reservation period for all systems should be same to continue your booking.',
  orderIsShortlyBeforeDelivery: (phone, email) =>
    `Order is shortly before delivery, please contact location manager by phone ${phone} or email ${email}.`,
  returnGoodsBookingSaved: 'Return goods booking details saved successfully!',
  checkedDate: 'Checked',
  mr: 'Mr.',
  ms: 'Ms.',
  noDefaultAddressAdded: 'No default address added',
  editor: 'Editor',
  addFullDetailsForLoanListItem: 'Please add dates to execute booking.',
  startDateNotSelected: 'Please select beginning of borrow date to check system availability.',
  startDateGreaterThanEndDate: 'Beginning of date cannot be greater than End of borrow date.',
  addedToCart: 'Added to cart',
  notInAvailableSlot: 'Selected system - {kitName} is not in available slot. Please change the dates for availability',
  onTimeDelivery: 'On-Time-Delivery',
  orderCompleteness: 'Order Completeness',
  equipmentFunctionality: 'Equipment Functionality',
  saveRating: 'Save Rating',
  alreadyBookedKitMessage: 'This system is already booked from {from} to {to}',
  alreadyBookedKitBorrower: 'Borrower: {borrower}',
  alreadyBookedKitStockController: 'Location Manager: {stockController}',
  alreadyBookedKitCustomer: 'Customer: {customerName}',
  youHaveToAddTransactionOrderedByDate: 'You have to add transaction ordered by date.',
  deleteC2C: 'Are you sure you to remove transaction from C2C loan proccess?',
  deleteC2CRemove: 'Are you sure you to cancel transaction and remove it from C2C loan proccess?',
  confirmRemoveC2C: 'Remove C2C?',
  transactionWillBeCancelled: transactionsId => `Transaction(${transactionsId}) will be cancelled.`,
  transactionWillBeChanged: ({ transactionsId, firstDate, lastDate }) =>
    `Shipped date for transaction(${transactionsId}) will be changed from ${firstDate} to ${lastDate}.`,
  consignment: 'Confirm Consignment',
  confirmConsignmentMessage: 'Consignment confirmed successfully.',
  availabilityMessage:
    'The selected System is not available for booking for this period. The system is available from {from} and {to}',
  contactUsTitle: 'Contact Us',
  contactUsMessage: 'Message',
  contactUsSubject: 'Subject',
  contactMessageSentSuccessfully: 'Thank you for contacting us.',
  kitNotAvailable: 'This System is not available for booking',
  loginForFilter: 'Please login to view filtered data',
  notAllowedToFilter:
    "You don´t have appropriate rights to filter data. Please click on 'Locations', filter for your Business Unit and Country and request permission for the locations that you need. After the location manager approves your request, you will have access to the location equipment.",
  loanType: 'Loan Type',
  language: 'Language',
  loanTypeAlreadyExist: 'Loan type already exists',
  fullDescription: 'Full Description',
  noKitCondition: 'No Condition',
  dateOfManufactureText: 'Date of Manufacture',
  noCountryAdded: 'Country is not added to selected delivery address. Please add country.',
  selectedKitsNotAvailable: 'Selected system(s) is not available for booking',
  and: 'and',
  availableKits: 'Available Systems',
  reservedKitMessage: 'This system is reserved for 24 hours from {from} to {to}.',
  alreadyAddedKitWithSameDate: 'This system is already selected for booking on same date',
  supportedDocumentsMessage: 'Only PDF, MS WORD, MS EXCEL files are supported',
  fileSizeLimitExceed: '{filename} exceeds limit upload size of 30MB',
  maxFilesErrorMessage: "You can't upload more than {number} files",
  noChangesMade: 'No changes made.',
  pleaseSelectC2c: 'You haven’t added any new transaction to c2c chain.',
  stockDataMandatory: 'Location information is mandatory. Please select location to proceed.',
  kitLoanOrderText: 'Loan Order (Reference to SAP ERP)',
  kitInformationId: 'System Id',
  pleaseClickAddButtonMessage: 'Please click on add button',
  businessUnitMandatory: 'Business Unit is mandatory. Please select business unit to proceed.',
  sapErp: 'SAP ERP',
  maximumNumberOfTransactions: 'There can be maximum of 5 chains in a C2C transaction.',
  twentyFourHourReservation: 'Reserved for 24 Hours',
  newDeliveryAddress: 'New Delivery Address',
  currentPositionDateDisabledMessageFirstPart: requested =>
    `You can't modify the dates for current transaction #${requested} until the previous transaction `,
  currentPositionDateDisabledMessageSecondPart: ' with the same demo System is completed, cancelled or declined.',
  cancellationComment: 'Cancellation Comment',
  linkedLoans: 'Linked Loans',
  emptyDropDownField: key => `${key} field is empty. Please select some other option from dropdown`,
  emptyAccountId: "ZEISS id's for few users are missing. Please check after sometime.",
  loanTypeMandatoryForTransactionFilter: 'Please choose loan type before adding any filters',
  loanTypeNoticeMessage: 'Please choose loan type to enable filters',
  lost: 'Lost',
  hasToBeBefore: (fieldOne, fieldTwo) => `${fieldTwo} date should be greater than ${fieldOne} date`,
  hasToBeBeforeOrEqual: (fieldOne, fieldTwo) => `${fieldTwo} date should be greater than or equal to ${fieldOne} date`,
  nameDepartment: 'Name/Department',
  selectProblem: 'Select a problem',
  providingMoreDetails: 'Would you mind providing some more details, please?',
  suggestionText:
    'Suggestion:\n\u00b7 What did you do so far to solve the issue?\n\u00b7 What did you expect?\n\u00b7 What happened as a result?\n\u00b7 Is there an alternative phone number where we can reach you?',
  ticketUploadDocument:
    'Upload a document, image or video of the issue you are experiencing to help speed our investigation',
  ticketLimitedSize: 'Limited to 5 files | size per file: 100 MB',
  ticketDoNotUpload:
    'Please do not upload sensitive files such as confidential data and/or personal details. Preferably upload generic display of the error message or the faulty behavior of your ZEISS device. Personal or sensitive data should be made unrecognizable.',
  ticketSystemMainComponent: 'System main component',
  deleteAllFilters: 'Delete all filters',
  print: 'Print',
  locationText: 'Location',
  noPackageDimensionsAvailable: 'No package dimensions available',
  yearOfManufacture: 'Year of Manufacture',
  monthOfManufacture: 'Month of Manufacture',
  maxLengthErrorMessage: 'You can choose only 20 Locations',
  serviceTicket: 'Service ticket',
  ticketId: 'Ticket ID',
  problem: 'Problem',
  pleaseLoginAgain: 'Please login again to create ticket',
  createServiceTicketSuccessfully: 'Service ticket created successfully. Your request will appear after some time.',
  noDeliveryAddress: 'No Delivery Address',
  demoKit: 'Demo System',
  availableKitDetails: 'Available',
  allowedFormats: 'Only .PDF, .JPG, .PNG, .DOC, .XLS and .czi files ares allowed',
  maxFileSize: 'File size too big. You can only upload files up to 30 MB.',
  from: 'From',
  to: 'To',
  pleaseSelectPeriodForRefurbishment: 'Please select period for reparation. This period will be blocked in calendar.',
  refurbishmentDateError: 'refurbishmentFrom should be less than refurbishmentTo date',
  formInvalidBookingStep2:
    'Please click on the button "Edit address" and check if all mandatory fields are filled. Tip: You can save frequently used delivery addresses in your account data. This makes the booking process faster.',
  showCustomerInfoPage: 'Show customer information page in return delivery documents',
  doYouWantToBlockSamePeriodForMarkedKits: 'Do you want to block the same period for all marked systems?',
  signature: 'Display signature field on delivery documents',
  legalParagraphEnglish: 'Legal paragraph (English)',
  refresh: 'Refresh',
  scrapped: 'Scrapped',
  dismantled: 'Dismantled',
  incompleteMaintained: 'Incompletely maintained',
  sameStocks: 'Location for all Systems should be same to continue your booking.',
  singleLoanBookedWeeks: days => `Single loan can be booked only ${days} weeks in advance`,
  courseLoanBookedWeeks: days => `Course loan can be booked only ${days} weeks in advance`,
  singleLoanBookedDays: days => `Single loan can be booked only ${days} days in advance`,
  courseLoanBookedDays: days => `Course loan can be booked only ${days} days in advance`,
  statistics: 'Statistics',
  overview: 'Overview',
  utilization: 'Utilization',
  wonOpportunities: 'Won Opportunities',
  reparationTime: 'Reparation Time',
  bookings: 'Bookings',
  timePeriod: 'Time Period',
  discontinued: 'Discontinued',
  refurbishment: 'Refurbishment',
  requested: 'Requested',
  createStockButton: 'Create Location',
  currentTransaction: 'Current Transaction',
  conflictWithTransaction: ids =>
    `There is conflict with transaction ${ids}, do you want to update the remaining transaction(s)?`,
  adoptChangeForSubsequentTransactions: 'Adopt the change for subsequent transactions:',
  conflictingWithOtherTransactions:
    'The dates you have selected are conflicting with other transactions. Please try again.',
  youCanChooseOnly20Stocks: 'You can choose only 20 Warehouses',
  relatedTransactionsConfirm: 'Confirm',
  relatedTransactionsCancel: 'Cancel',
  conflict: 'Conflict',
  start: 'Start',
  returnBookingDate: 'Return booking date',
  conditionReturnGoodBooking: 'Condition',
  commentReturnGoodBooking: 'Comment',
  tracesOfUse: 'Traces of use',
  defective: 'Defective',
  orderDeliveryNumber: 'Order Delivery Number',
  teamBookings: 'Team Bookings',
  users: 'Users',
  members: 'Members',
  subjectTeamBookings:
    'This transaction list is intended to support sales back office. You can monitor loan transactions made by the sales employees you support and make changes if necessary. Please click on "Add" to add their bookings to the list. The employee will then receive an e-mail and will be informed.',
  userRemoveSuccessfully: 'User removed successfully',
  alreadyBookedComment: 'Comment: {comment}',
  activateSellOffFunction: 'Activate sale function',
  externalBorrowerCanSeePlatform: 'External borrower can see platform',
  shopManagers: 'Shop Managers',
  shopManager: 'Shop Manager',
  kitViewDuration: 'For how much weeks can an user reserve a system?',
  currency: 'Currency',
  grossListPrice: 'Gross List Price',
  transferPrice: 'Transfer price',
  salesDate: 'Sales date',
  deliveryTimeInWeeksNumberField: 'Delivery time in weeks (number field)',
  deliveryTimeInWeeks: 'Delivery time in weeks',
  salesComment: 'Sales Comment',
  kitForSale: 'System for sale',
  visibility: 'Visibility',
  salesDateEarlierThanAvailableToDate:
    'You selected a sales date which is earlier than the available to date. Would you like to change that?',
  kitForSaleSavedSuccessfully: 'System for sale saved successfully',
  pleaseSelectUser: 'Please select a user',
  demoSale: 'Marketplace',
  salesOffers: 'Sales Offers',
  singleItemsKit: 'Single items / System',
  demoKits: 'Demo Systems',
  singleItems: 'Single Items',
  transferPriceInEUR: 'Transfer Price in EUR',
  createSingleItem: 'Create Single Item',
  observe: 'Observe',
  order: 'Order',
  orders: 'Orders',
  results: 'Results',
  mySubscriptions: 'My Subscriptions',
  notifyMe: 'Notify Me',
  internalIdentifier: 'Internal identifier',
  contactPerson: 'Contact Person',
  addKitForSale: 'Add System for Sale',
  yearOfManufacturing: 'Year of manufacturing',
  itemDetails: 'Item Details',
  itemName: 'Item Name',
  itemDescription: 'Item Description',
  sellDetails: 'Sell Details',
  notes: 'Notes',
  singleItemName: 'Single Item name',
  contactUsText:
    'Please note that only technical or functional questions regarding the Demo Management App will be answered from our help desk. For status information on your loan transactions, please contact your location Manager. Thank you.',
  kitIsNoLongerAvailable:
    'The system is no longer available from {date}. If you have any questions please contact {emailAddress}.',
  subscribe: 'Subscribe',
  subscribeAndGetNotified: 'Subscribe and get notified when new items appear under the following search parameters.',
  youCanNotHaveMore: 'You cannot have more than 10 subscriptions',
  subscribedOn: 'Subscribed on',
  noSubscriptionsAvailable: 'No subscriptions available',
  pleaseMindThat: 'Please mind that the Opportunity ID needs to have 9 digits and click "+" to confirm the ID.',
  youCanNotEnter: 'You can´t enter the same Opportunity ID in two fields',
  noQuestionsAvailable: 'No questions available',
  questions: 'Questions',
  postYourQuestion: 'Post your question',
  enterYourQuestionHere: 'Enter your question here',
  answerQuestion: 'Answer question',
  postYourAnswer: 'Post your answer',
  enterYourAnswerHere: 'Enter your answer here',
  editAnswer: 'Edit answer',
  showMore: 'Show more',
  notOk: 'Not Ok',
  transport: 'Transport',
  myObservations: 'My Observations',
  stopObservation: 'Stop Observation',
  observations: 'Observations',
  archive: 'Archive',
  cancelReservation: 'Cancel Reservation',
  cancelReservationPopupTitle: 'Are you sure, you want to cancel the reservation?',
  reservation: 'Reservations',
  reservedTill: reservationTo => `Reserved till ${reservationTo} by`,
  youHaveOrderDemoKit:
    'Congratulations! You have ordered a demo system. To verify the order, please enter your CRM Quote ID:',
  enterCRMQuoteID: 'Enter a CRM Quote ID',
  createQuoteFirst: 'Reserve, I have to create the quote first',
  CRMQuoteIDShouldBe10Number: 'CRM Quote ID should be 10 numbers',
  pleaseVerifyPrice: 'Please verify the price',
  price: 'Price',
  kitOrderedSuccessfully: 'System ordered successfully.',
  year: 'Year',
  month: 'Month',
  years: 'Years',
  months: 'Months',
  youCantSelectMoreThanThreeValues: `You can't select more than three values`,
  purchaseRequests: 'Purchase Requests',
  addPurchaseRequest: 'Add a purchase request',
  budget: 'Budget',
  validity: 'Validity',
  whatAreYouLookingFor: 'What are you looking for?',
  haveRequestFor: 'Have a request for',
  purchaseRequestsWasAdded: 'Purchase Requests was added successfully',
  writeComment: 'Write a comment',
  comments: 'Comments',
  daysLeft: 'Days left',
  showDeliveryDocument: 'Show delivery comment on delivery document',
  youCanNotCreateOverlappingBookings: 'You can´t create overlapping bookings. Please change your booking dates.',
  createFolder: 'Create Folder',
  folderName: 'Folder name',
  editFolderName: 'Edit folder name',
  folderDeletedSuccessfully: 'Folder deleted successfully',
  folderCreatedSuccessfully: 'Folder created successfully',
  daysInStock: 'Location Days',
  daysInRefurbishment: 'Refurbishment Days',
  daysInLoan: 'Loan Days',
  daysInTransport: 'Transport Days',
  mandatoryFieldIsMissing: 'This system is not available for booking because the following mandatory field is missing:',
  mandatoryFieldsAreMissing:
    'This system is not available for booking because the following mandatory fields are missing:',
  availabilityPeriodIsExpired: 'This system is not available for booking because the availability period is expired.',
  statusIsNotOk: 'This system is not available for booking because the status is not "Ok" or "System for Sale".',
  hideKitIsActivated: 'This System is not available for booking because "Hide System" is activated.',
  oneMonth: '1 Month',
  pleaseConvertGrossListPrice: 'Please convert gross list price into Euro.',
  forSaleReserved: 'For Sale - Reserved',
  kitIsAlreadyDisplayedInShop: 'System is already displayed in the shop',
  pleaseConvertLocalSalesPrice: 'Please convert local sales price into Euro.',
  purchaseRequestWasUpdated: 'Purchase Request was updated successfully',
  purchaseRequestWasDeleted: 'Purchase Request was deleted successfully',
  deletePurchaseRequest: 'Delete Purchase Request',
  areYouSureYouWantToDeletePurchaseRequest: 'Are you sure you want to delete Purchase Request?',
  salesDetails: 'Sales details',
  internalNotes: 'Internal notes',
  crmQuoteId: 'CRM Quote ID',
  customer: 'Customer',
  purchaseOrder: 'Purchase Order',
  salesOrder: 'Sales Order',
  technicalControl: 'Technical Control',
  internalOrder: 'Internal Order',
  desinfection: 'Desinfection',
  completenessCheck: 'Completeness check',
  productionOrderReparationOrder: 'Production Order/Reparation Order',
  internalStockOrder: 'Internal location order',
  deliveryDate: 'Delivery Date',
  pleaseMindThatFieldNeedsToHave: value => `Please mind that the field needs to have ${value} digits.`,
  exportData: 'Export Data',
  exportTransactionData: 'Export Transaction Data',
  exportKitData: 'Export System Data',
  transactionAttachmentsUploadedSuccessfully: 'Transaction attachments uploaded successfully.',
  transactionAttachmentsDeletedSuccessfully: 'Transaction attachments deleted successfully.',
  taskId: 'Task ID',
  subscriptions: 'Subscriptions',
  subscribeNew: 'Subscribe New',
  commentSubmittedSuccessfully: 'Comment submitted successfully.',
  solvedBy: 'Completed',
  taskCompleted: 'Task completed',
  addComment: 'Add Comment',
  createCustomerCareTicket: 'Create Customer Care Ticket',
  search: 'Search',
  opportunityList: 'Opportunity List',
  opportunityName: 'Opportunity Name',
  customerID: 'Customer ID',
  customerName: 'Customer Name',
  contactAddress: 'Contact address',
  searchWithCustomerName: 'Search with customer name',
  pleaseSubscribeSystemClass:
    'Please subscribe to system class and system main component to get notified via notification bar. In addition, you can subcribe to an email notification.',
  subscribeWasAdded: 'Subscribe was added successfully',
  subscribeWasUpdated: 'Subscribe was updated successfully',
  subscribeWasDeleted: 'Subscribe was deleted successfully',
  masterData: 'Master Data',
  dusdPermissions: 'Demo Unit Status Tool - Permissions',
  serviceManager: 'Service Manager',
  serviceTechnician: 'Service Technician',
  permissionsWereUpdated: 'Permissions were updated successfully',
  transportTime: 'Transport Time',
  dusdTask: 'Demo Unit Status Tool - Tasks',
  addTask: 'Add Task',
  tasks: 'Tasks',
  task: 'Task',
  systemMain: 'System Main Component',
  createdBy: 'Created By',
  createdOn: 'Created On',
  taskWasAdded: 'Task was added successfully',
  taskWasDeleted: 'Task was deleted successfully',
  taskWasUpdated: 'Task was updated successfully',
  deleteTask: 'Delete Task',
  areYouSureYouWantToDeleteTask: 'Are you sure you want to delete Task?',
  new: 'New',
  assigned: 'Assigned',
  completed: 'Completed',
  expired: 'Expired',
  subscribeToDUSD: 'Subscribe to Demo Unit Status Updates',
  demoUnitStatus: 'Demo Unit Status',
  yourName: 'Your Name',
  sistemRestricted: 'System Restricted',
  systemDown: 'System Down',
  noIssues: 'No Issues',
  yesterday: 'Yesterday',
  demoLoanMessages: 'Demo Management Messages',
  demoLoanMessagesTooltip:
    'What messages you receive is defined on location level. If you miss some information or if you get too much information, please discuss that with your location manager. You will find an overview of the location managers under "Locations"',
  demoUnitStatusSubscriptionTooltip:
    'The purpose of this new feature is to document and communicate the status of demo units especially after a market launch. If you want to be informed about the status of a system class, please subscribe to the notifications for it. You will then be informed via the notification bar as soon as colleagues report the status (No Issues, System Restricted, System Down) after a device has been set up for a customer. For more information on the feature, see "Documents and Trainings"',
  productHierarchy: 'Product Hierarchy',
  stockDashboard: 'Location Dashboard',
  businessGroup: 'Business group',
  thisWeek: 'This Week',
  goodsDelivered: 'Goods Delivered',
  demoLoan: 'Demo Loan',
  incomingGoods: 'Incoming Goods',
  goodsConfirmed: 'Goods Confirmed',
  demoActivity: 'Demo Activity',
  delayedTransactions: 'Delayed Transactions',
  kitData: 'System Data',
  fiscalYear: 'Fiscal Year',
  systemClassOptional: 'System Class (Optional)',
  ontimeShippingRate: 'On-time Shipping Rate',
  ontimeRecievedInStock: 'On-time Received in Location',
  totalDelayedKits: 'Total Delayed Systems',
  reconditioningTime: 'Reconditioning Time (Avg.)',
  thisMonth: 'This Month',
  dayOrDayes: dayNumber => (dayNumber === 1 ? 'day' : 'days'),
  minorIssues: 'Minor Issues',
  systemNotWorking: 'System Not Working',
  noTaskAvailable: 'No Task Available',
  noCommentAvailable: 'No Comment Available',
  addDemoUnitStatus: 'Add Demo Unit Status',
  dueToTheMissingIBase:
    'Due to the missing iBase number on this demosystem, a service ticket cannot be created in CRM. Please create a ticket manually if needed.',
  statusSubmittedSuccessfully: 'Status submitted successfully.',
  notificationSettings: 'Notification Settings',
  demoUnitStatusSubscriptions: 'Demo Unit Status Subscriptions',
  notifyMeVia: 'Notify me via',
  webApp: 'Notification bar in Web App',
  mobileApp: 'Mobile App (Notification Tray and Push Notifications)',
  notificationSettingsUpdatedSucessfully: 'Notification Settings updated successfully.',
  deleteSubscription: 'Delete Subscription',
  areYouSureYouWantToDeleteSubscription: 'Are you sure you want to delete Subscription?',
  notifications: 'Notifications',
  settings: 'Settings',
  clearAll: 'Clear All',
  noNotifications: 'No notifications',
  demoUnitStatusTool: 'Demo Unit Status Tool',
  permissions: 'Permissions',
  demoUnitStatusDefective: 'The demo system status was set to "Defective".',
  demoUnitStatusOk: 'The demo system status was set to "Ok".',
  dustManager: 'DUST Manager',
  dustUser: 'DUST User',
  emailNotification: 'E-Mail notification',
  loanReasons: 'Loan Reasons',
  fullScreen: 'Full Screen',
  lastUpdated: min => `Last Updated ${min}m ago`,
  demoPerformed: 'Demos\nperformed',
  incomingDemoBooking: 'Incoming\nDemo Bookings',
  demoWithOppId: 'Demos with\nOpportunity ID',
  oppDemoWinRate: 'Opportunity\nDemos Win-Rate',
  demoReturnedInTime: 'Systems\nreturned in time',
  demoShippedInTime: 'Systems\nshipped in time',
  demoSatisfactionIndex: totalRatedTransactions =>
    `Demo Satisfaction Index (based on ${totalRatedTransactions} ratings)`,
  onTimeDeliveryRate: 'On-time\nDelivery Rate',
  demos: 'Demos',
  performed: 'Performed',
  planned: 'Planned',
  dustMaintainStatus: 'Demo Unit Status Tool - Maintain Status',
  maintainStatus: 'Maintain Status',
  enterSerialNumber: 'Enter serial number',
  noResultFound: 'No Result Found',
  allActiveLateTransactions: 'All Active Late Transactions',
  lateReturns: 'Late Returns',
  activeKitConditionAsOn: date => `Active System Condition As On ${date}`,
  utilizationRate: 'Utilization Rate',
  reparationRate: 'Reparation Rate',
  transportationRate: 'Transportation Rate',
  idleTime: 'Idle Time',
  mostUtilizedKits: 'Most Utilized Systems As on Today',
  leastUtilizedDemo: 'Least Utilized Systems As on Today',
  workingCapitalInStock: 'Working Capital in Location',
  valuesInTEUR: 'Values in TEUR',
  interfaceMonitoring: 'Interface Monitoring',
  endSystem: 'End System',
  statusCode: 'Status Code',
  message: 'Message',
  transactionId: 'Transaction ID',
  kitId: 'System ID',
  transactionName: 'Transaction Name',
  time: 'Time',
  searchForTransactions: 'Search for Transactions',
  userPermissions: 'User Permissions',
  none: 'None',
  deleteAllPermissions: 'Delete All Permissions',
  deleteAllPermissionsForThisUser: 'Delete all permissions for this user',
  permissionForAll: 'Permission for all',
  permission: 'Permission',
  stockPermissions: 'Location Permissions',
  doYouWantToDelete: 'Do you want to delete all the permissions for this user?',
  doYouWantToSetPermission: role =>
    `Do you want to set ‘${role}’ permission for this user for all the filtered location?`,
  setForAllStocks: 'Set for all locations',
  pleaseSaveYourChanges: 'Please save your changes',
  setPermissionForAll: 'Set Permission for All',
  allChangesAreSaved: 'All changes are saved.',
  manageKitPictures: 'Manage System pictures',
  kitPictures: 'System Pictures',
  addImage: 'Add Image',
  systemMainComponentKitPicture: 'System Main Component',
  allowedImgFormats: 'Only .PDF and .JPG files ares allowed',
  imageUploadedSuccessfully: 'Image uploaded successfully',
  errorUploadingImage: 'Error uploading the image. Please try again.',
  imageWasDeletedSuccessfully: 'The image was deleted successfully',
  errorDeletingImage: 'Error deleting the image. Please try again.',
  attachmentsStock: 'Attachments',
  c2cloanTooltip: 'This is a C2C Loan. Click on the reservation bar to see more details.',
  partnerPermissions: 'l@l Permissions',
  actualizePermissions: 'Actualize l@l and ZMCC Permissions',
  LLPermissionsSuccessSaved: 'l@l Permission successfully saved',
  mindToChange:
    'Please mind to change the availability period of the microscopes in your location according to the contract end date so that user can still book it. Thank you.',
  activeUntil: 'Active until:',
  createLLKit: 'Create l@l System',
  partnerSite: 'Partner Site',
  buildingAndRoom: 'Building and Room',
  function: 'Function',
  labsLocation: 'l@l',
  labsLocationPartner: 'labs@location Partner',
  demo: 'Demo',
  typesOfJobs: 'Types of jobs',
  customerInstitute: 'Customer Institute',
  operator: 'Operator',
  customerSite: 'Customer Site',
  stockAddress: 'labs@location Partner Address',
  requestor: 'Requestor',
  pleaseSelectAnOption: 'Please select an option',
  slectedKitsAreNotAvailableForBooking: 'Selected systems are not available for booking',
  maintainLLKit: 'Maintain l@l system',
  copyLLKit: 'Copy l@l system',
  allowedFormatssForDemoKit: 'Only .PDF, .JPG, .PNG, .DOC, .XLS, .CZI, .MKV and .MP4 files ares allowed',
  labsLocationFullName: 'labs@location',
  requestCreatedBy: 'Request created by',
  demoSite: 'Demo site',
  demoTime: 'Time in Hours',
  reserve: 'Reserve',
  approvedByLLManager: 'Approved by l@l Manager',
  confirmedByLLPartner: 'Confirmed by l@l Partner',
  dust: 'DUST',
  kitAttachments: 'System Attachments',
  transactionAttachmentForKit: 'Transaction Attachment for the System',
  copy: 'Copy',
  toBeApprovedByLLManager: 'To be approved by l@l Manager',
  toBeApprovedByLLPartner: 'To be approved by l@l Partner',
  demoConfirmed: 'Demo confirmed',
  cancelled: 'Cancelled',
  labsLocationManagement: 'labs@location Management',
  calculationMethod: 'Calculation method',
  accountStatement: 'Account Statement',
  controllingReport: 'Controlling Report',
  kpis: `KPIs`,
  continent: 'Continent',
  pointsPerHour: 'Points per Hour (Pages per Application Note)',
  pleaseSelectValueFromDropdown: 'Please select a value from dropdown',
  shouldChangesApplyToAllContinents: continent =>
    `Should the changes apply to all continents or just for ${continent}?`,
  applyForAll: 'Apply for all',
  saveOnlyForSelectedContinent: 'Save only for selected continent',
  selectContinent: 'Select Continent',
  valuesSavedSuccessMessage: 'Values saved successfully',
  demoStart: 'Demo Start',
  demoEnd: 'Demo End',
  delayWarning: 'Delay warning! This system-loan was supposed to be completed on {date}.',
  transactionDescription: 'Transaction Description',
  duration: 'Duration',
  earned: 'Earned',
  deducted: 'Deducted',
  sum: 'Sum',
  performance: 'Performance',
  calculationIsNotPossible: 'Calculation is not possible as the operator is not added for the transaction',
  activeSystems: 'Active Systems',
  itIsNotPossibleToAddDemoUnitStatus:
    'It is not possible to add a demo unit status for this demo System. The demo unit status tracking for this system was deactivated from the DUST manager. Please contact demoloanapp@zeiss.com to get more information.',
  areYouSureYouWantToRemoveSystem:
    'Are you sure you want to remove the system, all DUST users will get an email for this change',
  deactivateSystem: 'Deactivate system',
  activateSystem: 'Activate system',
  noActiveSystemsAvailable: 'No active systems available',
  demoUnitStatusCanOnlyBeAddedToSystems:
    'Demo Unit Status can only be added to systems when they are added in this list. When the system is deleted again, it is not possible anymore to add a status. The information which was added before is still documented on the system.',
  activateSystemAndEnableForDUSTTracking: 'Activate system and enable for DUST tracking',
  dateOfActivation: 'Date of activation',
  add: 'Add',
  deactivate: 'Deactivate',
  activate: 'Activate',
  pleaseAddSystemClassAndSystemMainComponent:
    'Please add system class and system main component to allow to add demo unit status to these demo systems.',
  recordAlreadyExists: 'Record already exists',
  notPossibleToAddDemoUnitStatusForThisDemoKit:
    'It is not possible to add a demo unit status for this demo system. The demo unit status tracking for this System was deactivated from the DUST manager. Please contact demoloanapp@zeiss.com to get more information.',
  areYouSureYouWantToActivateSystem:
    'Are you sure you want to activate the system for DUST Status Tracking? All DUST users will get an email for this change.',
  on: 'On',
  off: 'Off',
  deductPoints: 'Deduct Points',
  pointDeduction: 'Point deduction',
  reason: 'Reason',
  salesRepresentative: 'Sales Representative',
  deductionOfPoints: 'Deduction of points',
  numberOfPartners: 'Number of Partners',
  numberOfTransactions: 'Number of Transactions',
  opportunitiesWon: 'Opportunities Won',
  opportunitiesLost: 'Opportunities Lost',
  opportunitiesInProcess: 'Opportunities in Process',
  opportunitiesDiscontinued: 'Opportunities Discontinued',
  opportunityWinRate: 'Opportunity Win-Rate',
  opportunityLostRate: 'Opportunity Lost-Rate',
  mainUtilizedMainComponents: 'Top 10 Utilized Main Components',
  operatorZeiss: 'Operator ZEISS',
  operatorPartner: 'Operator Partner',
  total: 'Total',
  totalAmountOfDemos: 'Total amount of Hours',
  noOfBookings: 'No. of bookings',
  showMap: 'Show Map',
  mapTitle: 'Overview labs@location Partner Locations',
  myLocation: 'My Location',
  selectPartner: 'Select partner site and go back to calendar',
  latitude: 'Latitude',
  longitude: 'Longitude',
  selectLocation: 'Select Location',
  addLocationToMap: 'Add location to map',
  preName: 'Prename',
  surName: 'Surname',
  earnedPoints: 'Earned Points',
  earnedEuros: 'Earned Euros',
  deductedPoints: 'Deducted Points',
  deductedEuros: 'Deducted Euros',
  sumPoints: 'Sum Points',
  sumEuros: 'Sum Euros',
  nameOfOperator: 'Name of Operator',
  loanValue: 'Loan Value in',
  itemNumber: 'Item number',
  division: 'Division',
  systemClassification: 'System Classification',
  kitDetailesSavedWithoutDivisionAndItemNumber:
    'System saved successfully. Warning! ERP Interface can´t be used when the fields "Division" and "Item number" are not filled!',
  returnDeliveryAddress: 'Select return delivery address',
  kitCopiedWithoutmandatoryFiledAndItemNumberDivision:
    'system details copied successfully, but the demo system cannot be booked until all mandatory fields are filled. Warning! ERP Interface can´t be used when the fields "Division" and "Item number" are not filled!',
  kitWithoutmandatoryFiledAndItemNumberDivision:
    'System details saved successfully, but the demo system cannot be booked until all mandatory fields are filled. Warning! ERP Interface can´t be used when the fields "Division" and "Item number" are not filled!',
  divisionOrItemNumberMissing:
    'Please fill Division and Item number for the selected system in order to create Loan order.',
  createLoanOrder: 'Create Loan Order',
  returnLoanOrder: 'Return Loan Order',
  sapERPMappingTable: 'SAP ERP Mapping Table',
  addMapping: 'Add Mapping',
  accountId: 'Account ID',
  accountName: 'Account Name',
  houseNumber: 'House number',
  comment: 'Comment',
  mappingWasAdded: 'Mapping added successfully',
  mappingWasDeleted: 'Mapping deleted successfully',
  mappingWasUpdated: 'Mapping updated successfully',
  confirmDeleteMapping: 'Delete Mapping?',
  confirmDeleteMappingMessage: 'Are you sure you want to delete mapping?',
  accountAlreadyExists: 'Account Id already exists!',
  maintainSystemMainComponentForCalutaion:
    'Points can´t be calculated. Please maintain system main component in system classification table.',
  materialNumber: 'Material Number',
  materialnumberWarning: 'Please enter material number ',
  orderType: 'Order Type',
  distributionChannel: 'Distribution channel',
  billing: 'Billing',
  partner: 'ZI Partner',
  orderData: 'ZA Partner & Order data/Collective number',
  singleLoanDescription: 'Description',
  more: '...more',
  noAccessRights: 'You do not have permission to save ',
  customerIdSap: 'Customer ID SAP',
  goodsRecipientIdSap: 'Goods recipient ID SAP',
  text: 'Text',
  shippingInstruction: 'Shipping instruction',
  submissionData: 'Submission data',
  legalParagraphGerman: 'Legal paragraph (German)',
  costFactorGrossListPricePerLoan: 'Cost factor gross list price per loan',
  costFactorGrossListPricePerDayDelay: 'Cost factor gross list price per day delay',
  costFactorLoanValuePerLoan: 'Cost factor loan value per loan',
  numberOfDaysWhereLoanIsFree: 'Number of Days where Loan is free',
  materialNumberOfTheStand: 'Material number of the stand',
  refurbishmentFees: 'Refurbishment Fees',
  labsAtLocationActivity: 'labs@location activity',
  expectedRefurbishmentFees: 'Expected refurbishment fees',
  penaltyFeesPerDayWhenLoanIsReturnedLate: 'Penalty fees per day when loan is\n returned late',
  theFeesAreSettledInternallyWithTheBorrowingSsc: 'The fees are settled internally with the borrowing SSC.',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  labsAtLocationReservation: 'labs@location Reservation',
  walkOffSalesOrder: 'Walk Off Sales Order',
  requestPickup: 'Request pickup',
  pickUpDate: 'Pick up date',
  pickUpFromWarehouse: 'Pick from location (Delivery to customer)',
  PickUpFromCustomer: 'Pick from customer (Return delivery to location)',
  emailSubject: TransactionNumber =>
    `ZEISS Transportation Request - Transaction ${TransactionNumber} - Delivery to customer`,
  pickFromWarehoueBody: (
    firstName,
    lastName,
    organization,
    phone,
    pickAddressForWhereHouse,
    targetShippingDate,
    receiptAtTheCustomer,
    kitName,
    serialNumber,
    packageDimensions,
    deliveryAdress,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  ) =>
    `Dear Sir or Madam,\n\nPlease organize the following transport:\n**Pick-up address:*\n${pickAddressForWhereHouse}${
      showPicUpComment && pickUpComment ? `\n\n**Pick-up Comment:* ${pickUpComment}` : ''
    }\n**Pick-up date:* ${targetShippingDate ? targetShippingDate : ''}\n\n**Delivery address:*\n${deliveryAdress}${
      deliveryComment ? `\n\n**Delivery Comment*: ${deliveryComment}` : ''
    }\n**Latest delivery date:*${receiptAtTheCustomer}\n**System:* ${kitName}\n**Serial number:* ${serialNumber}${
      packageDimensions ? `\n**Package dimensions:*  ${packageDimensions}` : ''
    }\n**Link To Transaction:* ${linkToTransaction}\n\n\nPlease let me know if it is possible and send me a confirmation.\n\nBest regards,\n${firstName} ${lastName}\n${organization}\n${phone} `,
  pickUp: 'Pick-up',
  pickCustomerBody: (
    firstName,
    lastName,
    organization,
    phone,
    pickAddressForCustomer,
    deliveryAdress,
    targetShippingDate,
    receiptAtTheCustomer,
    kitName,
    serialNumber,
    packageDimensions,
    targetReturnDeliveryDate,
    receiptAtStock,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  ) =>
    `Dear Sir or Madam,\n\nPlease organize the following transport:\n**Pick-up address:*\n${deliveryAdress}\n\n${
      showPicUpComment && pickUpComment ? `**Pick-up Comment:* ${pickUpComment}` : ''
    }\n**Pick-up date:* ${targetReturnDeliveryDate}\n\n**Delivery address:*\n${pickAddressForCustomer}\n\n${
      deliveryComment ? `**Delivery Comment*: ${deliveryComment}` : ''
    }\n**Latest delivery date:*${receiptAtStock}\n**System:* ${kitName}\n**Serial number:* ${serialNumber}${
      packageDimensions ? `\n**$Package dimensions:*  ${packageDimensions}` : ''
    }\n**Link To Transaction:* ${linkToTransaction}\n\n\nPlease let me know if it is possible and send me a confirmation.\n\nBest regards,\n${firstName} ${lastName}\n${organization}\n${phone} `,
  customerEmailSubject: transactionNumber =>
    `ZEISS Transportation Request - Transaction ${transactionNumber} - Delivery to ZEISS`,
  subject: 'Subject',
  emailText: 'Email Text',
  emailSent: 'Email sent sucessfully',
  refurbishmentTime: 'Refurbishment time (Avg.)',
  meantimeToDemo: 'Meantime\nto Demo',
  loanPeriodOverdrawn: 'Loan period overdrawn',
  reparationComment: 'Reparation Comment',
  kitInReparation: 'System in reparation',
  kitInReparationComment: 'Comment: {comment}',
  addRequest: 'Add Request',
  requestDemo: 'Request Demo',
  continueToBooking: 'Continue to Booking',
  demoRequestList: 'Demo Request List',
  yourDemoRequestList: 'Your Demo Request List',
  maintainStock: 'Maintain Location',
  maintainLabsAndLocationPartner: 'Maintain l@l Partner',
  completed: 'Completed',
  customerId: 'Customer ID - Sold-to',
  goodsRecipientId: 'Customer ID - Ship-to',
  deliveryBlock: 'Delivery block',
  formularHeaderText: 'Texts - Form Header (will be printed on commissioning document)',
  shippingInstructions: 'Texts - Shipping instructions (will be printed on commissioning document)',
  searchCustomer: 'Search Customer',
  newReturnDeliveryAddress: 'New return delivery address:',
  stkRequestor: 'Requestor',
  llPartner: 'l@l Partner',
  llManager: 'l@l Manager',
  maxLoanDuration: 'Max. loan duration (in working days)',
  fillDefaultPeriods: 'Please fill proper default period details',
  netValue: 'Net Value',
  labsAndLocationResvationBooking: 'labs@location reservation booking',
  backToCalendar: 'Back to Calendar',
  backToAssignment: 'Back to Assignment',
  labsAndLocationSystem: 'System',
  labsAndLocationReasonForLoan: 'Demo Type',
  availabilityPeriod: 'Availability period',
  customerGroup: 'Customer group 3',
  productIdMissing: 'Loan order cannot be created without the product ID. Please fill the product ID.',
  esbCorrelationId: 'ESB Correlation ID',
  exchangeStockForKitErrorMessage:
    'Please return SAP loan order before changing the location. You can use the button next to the field ‘Loan Order (Reference to SAP ERP)’. If the new location is also externally managed, you can create a new loan order for the new location after you have saved the location change.',
  homeBooking: 'Booking',
  homeKitloanCalendar: 'System-Loan Calendar',
  homeKitloanCalendarDescription: 'Book systems for your customer demo',
  homeLabsAndLocationCalendar: 'labs@location Calendar',
  homeLabsAndLocationCalendarDescription: 'Request demo support from our labs@location Partners',
  homeKitsList: 'System Lists',
  homeKitslistDescription: 'Get an overview about the available demo systems',
  homeMarketplaceDescription: 'Find suitable ex demo devices and single materials for your customers',
  homeDocumentDescription: 'Explore guidelines and training documentation about the DMA',
  homeMyTransaction: 'My Requests',
  homeMyTransactionDescription: 'Get an overview about your bookings',
  warehouseDescription: 'Get an overview about Location and request access permissions',
  homeMyAccountDescription: 'Manage your ZEISS ID account details',
  maintainDemoUnitStatus: 'Maintain Demo Unit Status',
  maintainDusDescription: 'Add DUST status to a system',
  subscribeToSystems: 'Subscribe to Systems',
  subscribeToSystemsDescription: 'For which systems do you want to be notified in case of a new DUST status?',
  activeSystemDescription: 'Find out for which systems DUST tracking is active ',
  taskDescription: 'Get an overview about all DUST Tasks',
  demoRequestDescription: 'Approve or decline demo requests',
  openProcessesDescription: 'Get an overview about your open demo bookings',
  homelabsAndLocationKitListDescription: 'Manage your demo systems',
  homeLabsAndLocationDescription: 'View existing bookings in the calendar',
  homeLabsLocationDcomentDesc: 'Explore training documentation about the DMA',
  homelabslocationMyAccountDesc: 'Manage your ZEISS ID account details',
  homeMyAccount: 'My Account',
  homeNonRmsDecription: 'Find guidelines and training documentation about the DMA',
  deliveryDays: 'Delivery Days',
  returnDeliveryDays: 'Return Delivery Days',
  reconditioningDays: 'Reconditioning Days',
  contactEmail: 'Contact Email',
  accountName: 'Account Name',
  contactFirstName: 'Contact First Name ',
  contactLastName: 'Contact Last Name',
  enableSerchText: 'Please fill minimum one of the fields to search.',
  pickupRequest: 'Pick-up request',
  noPickupRequests: 'No messages for Pick-up request',
  shippingDocumentErrorMessage:
    'It was not possible to generate a shipping document from SAP ERP. Please use the Demo Management App document or check in SAP why the delivery document was not created.',
  updatedBySystem: 'Updated by System',
  confirmed: 'Confirmed',
  delivered: 'Delivered',
  transactionRequest: 'Request',
  transactionApproved: 'Approved',
  transactionCancelled: 'Cancelled',
  transactionChecked: 'Checked (Archived)',
  transactionDelivered: 'Delivered',
  activeLoanOrderMessage1:
    'Warning - A loan order number is existing on System level. Please mind to return the loan order number on System level before you create a new one on transaction level. Click here ',
  activeLoanOrderMessage2: ' to open the System.',
  confirmTheId: () => ' and click "+" to confirm the ID.',
  returned: 'Returned',
  sendMaterialMessage: 'Only marked materials are returned. Please process other materials manually in SAP.',
  createReturnLoanOrder: 'Create Return Loan Order',
  returnLoanOrderDivisonMissing:
    'Please fill Division and Item number for the selected system in order to return Loan order.',
  reasonForLLLoan: 'Reason for request',
  cancelLLTransaction: 'Decline',
  clear: 'Clear Selection',
  operatorChangeWarningMessage: 'When you change the operator the operator name that was logged gets deleted.',
  deliveryNote: 'Delivery Note',
  returnDeliveryNote: 'Return Delivery Note',
  maitenanceCheckList: 'Demo Ready Checklist',
  unpackedFrom: 'Unpacked from:',
  abnormalities: 'Abnormalities:',
  maintenanceChecklist: 'Demo Ready Checklist',
  arrivalAndUnpacking: 'Arrival and unpacking',
  installation: 'Installation',
  setUpBy: 'Set up by:',
  issues: 'Issues',
  temporaryFilesBackup: 'Backup of temporary customer files:',
  systemDemoReady: 'System is demo ready:',
  nps: 'NPS (0-10):',
  yesButRestricted: 'Yes, but restricted',
  demo: 'Demo',
  dismantling: 'Dismantling',
  dismantlingBy: 'Dismantling by:',
  laserSaftey: 'Laser safety:',
  electronicSecurity: 'Electronic security:',
  filesFromPCDeleted: 'Files from PC deleted:',
  maintenanceCompleteness: 'Completeness:',
  maintenanceDemoKit: 'DemoSystem…',
  canBeShipped: 'System can be shipped to next destination',
  mustBeShipped: 'System must be shipped back to warehouse for refurbishment',
  discussionWithProdCenter: 'Discussion with production center, if abnormalities can be solved from service technician',
  maintenanceComment: 'Comment:',
  maintenanceIssues: 'Issues:',
  dateAndNameOfUser: 'Date and name of system user:',
  otherAddress: 'Other Address',
  llPartnerStatus: 'l@l Partner status:',
  addSingleLoan: 'Add Single Loan',
  addSingleItem: 'Add Single Item',
  labsLocationKitBookedSucessfully: 'labs@location request created successfully.',
  customerType: 'Customer type',
  accountIdSap: 'Account ID SAP',
  supportingAsset: 'Supporting Asset',
  addSingleItemInfo: 'Please click “Add +“ to save the material.',
  materialNumberFormatError: 'Please enter Material Number in this 123456-4444-333 format.',
  restrictSpecialCharacters:
    'Please delete the signs “>” and “<“ from your text. Else it will be not possible to create a delivery document for this System.',
  viewDetails: 'View Details',
  waitingForLoanOrder: 'Waiting for loan order number',
  saveToSap: 'Save and send to SAP',
  sapMessage:
    'Please only send this request if the previous attempt failed (see AIF Monitor in SAP). Otherwise the loan order will be created twice in SAP.',
  resendRequest: 'Resend request',
  labslocationMessage:
    'This function will be released soon. Then you will also be able to manage your labs@location requests via the Demo Management App. We will keep you informed.',
  crmErrorMessage: 'Error Message: Will be released soon.',
  sapDetailsChangeMessage: 'If you want to change details you need to go to the location configuration (Workflows).',
  stockWelcomeMessage: (firstName, lastName) => `Dear ${firstName} ${lastName},`,
  stockWelcomeMessageDesc:
    'Welcome to the ZEISS Demo Management App! To be able to work with the app, you first need access rights to the demo locations where you work or to the locations where you want to loan equipment. Please filter in the "Location" section for your business group and your country. You will then be shown the available locations and you can request location permissions by clicking the button "Request Permission". After the location manager has confirmed your request, you will receive an email and you can access the location. Training material for the Demo Management App can be found at the top right under',
  stockPopupDocument: '"About DMA."',
  yourDemoLoanAppTeam: 'Your Demo Management App Team',
  calendarKitNotFoundText: 'Can´t find suitable system? Contact your inventory manager here:',
  nameAndEmail: 'Selected location Name and Email',
  kitDemandSucessMessage: 'Thank you. Your location manager was informed via email.',
  requestDemoKit: 'Request Demo-System',
  stockPopupRequestionPermission: 'Request Permissions',
  Views: 'Views',
  addMaterialNumberInfo: 'Click + to confirm item',
  Age: 'Age',
  firstSalesOrder: 'First Sales Order',
  addSingleLoanMaterial: 'Please click “Add Item” to save the material.',
  inavalidFirstSalesOrderMessage: 'Please enter correct First sales order value.',
  addSingleLoanMaterial: 'Please click “Add Item” to save the material.',
  addressConfig: 'Address Configuration',
  addAddress: 'Add Address',
  setDefault: 'Set default address ',
  c2cFirstTransactionWillBeChanged: (currentTransactionsId, transactionsId) =>
    `Please check the shipping date of transaction ${transactionsId} and correct if needed. Due to the cancellation of transaction ${currentTransactionsId} the time between shipping and receipt at the customer might have changed.`,
  c2cMiddleTransactionWillBeChanged: transactionsId =>
    `The Shipping date and the Receipt at the Customer date of transaction ${transactionsId} was changed in order to close the gap in the C2C chain. Please correct the dates if needed.`,
  addNewAddress: 'Add new address',
  correctOpportunityMessage: 'Please enter a correct Opportunity ID.',
  showStorageLocation: 'Show storage location at the location on the delivery document',
  selectDeliveryAddress: 'Please select Devilvery Address',
  qunatityFieldIsRequired: 'Quantity field is required',
  active: 'Active',
  maintainRMSZMCC: 'Maintain ZEISS Microscopy Customer Center',
  dispatcher: 'Dispatcher',
  zmccManager: 'ZMCC Manager',
  readOnly: 'Read Only User',
  manager: 'Manager',
  stockReconditioning: 'Reconditioning',
  rooms: 'Rooms',
  addRooms: 'Add Room',
  deleteRommWarningMessage: 'Are you sure you want to delete room?',
  deleteRoomTitle: 'Delete Room',
  uniqueRoomNameErrorMessaage: 'The room name already exists.',
  roomDeleteMessage: 'Room Deleted Sucessfully',
  roomAddHintMessage: 'Please click “Add Room” to log the room.',
  zmcc: 'ZMCC',
  inputRoomLabel: 'Room',
  createZMCCKit: 'Create ZMCC System',
  maintainZmccKit: 'Maintain ZMCC System',
  copyZmccKit: 'Copy ZMCC System',
  zmccFullForm: 'ZEISS Microscopy Customer Center',
  room: 'Room',
  owner: 'Owner',
  isMobileOrExternal: 'Is mobile/external',
  roomNotAddedMesage: 'When no room is added to the system, it won’t be visible in the calendar.',
  limitedDemoAbility: 'Limited demo-ability',
  malfunction: 'Malfunction',
  crmAccountId: 'CRM Account ID',
  lateReturnExportError: 'Please select a business group and a country. Afterwards you can export the list.',
  costInformation: 'Cost Information',
  crmAccountIdError: 'Please enter the CRM Account ID.',
  zmccCalendar: 'ZMCC Calendar',
  homeZmccDescription: 'Request demo support from our ZEISS Microscopy Customer Centers',
  zmccRequest: 'ZMCC Request',
  labsLocationRequest: 'labs@location Request',
  calendarBlockingMessage: `Dear User,\n\nfor your location manager and the next borrower it is important that you add the actual data for "receipt at customer" and "return delivery" to the loan. This way we can ensure that all your requirements are met on time and that the systems are delivered to your customers on time. If you are unable to add actual data because the original loan period needs to be extended, please contact your location manager so they can adjust the data.\n\nPlease add actual data to your following transactions before you request the next loan. This would help us a lot. Thank you for your understanding!`,
  additionalSingleLoanCreatedSuccesfully: transactionsId =>
    `Additional single loan was created successfully. Transaction Number ${transactionsId}`,
  PickUpFromLastCustomer: 'Pick from last customer (Customer-to-customer transport)',
  pickCustomerToCustomerBody: (
    firstName,
    lastName,
    organization,
    phone,
    pickupAddressForCustomerToCustomer,
    deliveryAdress,
    targetShippingDate,
    targetReturnDeliveryDate,
    kitName,
    serialNumber,
    packageDimensions,
    receiptAtTheCustomer,
    receiptAtStock,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  ) =>
    `Dear Sir or Madam,\n\nPlease organize the following transport:\n**Pick-up address:*\n${pickupAddressForCustomerToCustomer}\n\n${
      showPicUpComment && pickUpComment ? `**Pick-up Comment:* ${pickUpComment}` : ''
    }\n**Pick-up date:* ${targetShippingDate}\n**Delivery address:*\n${deliveryAdress}\n\n${
      deliveryComment ? `**Delivery Comment*: ${deliveryComment}` : ''
    }\n**Latest delivery date:*${receiptAtTheCustomer}\n**System:* ${kitName}\n**Serial number:* ${serialNumber}${
      packageDimensions ? `\n**$Package dimensions:*  ${packageDimensions}` : ''
    }\n**Link To Transaction:* ${linkToTransaction}\n\n\nPlease let me know if it is possible and send me a confirmation.\n\nBest regards,\n${firstName} ${lastName}\n${organization}\n${phone} `,
  customerToCustomerEmailSubject: TransactionNumber =>
    `ZEISS Transportation Request - Transaction ${TransactionNumber} - Delivery from customer to customer`,
  borrowerInfoMessage:
    'Due to the relocation of the demo location taking place in Jena, there may be extended delivery times in April and May 2023. Please request loans accordingly earlier. Thank you very much.',
  gotIt: 'Got it',
  attention: 'Attention',
  pickupComment: 'Pick-up Comment',
  trackingNumber: 'Tracking number',
  crateId: 'Crate ID',
  alphanumericError: 'Enter only alphanumerical value',
  vrDetails: 'VR Details',
  windowsUserPassword: 'Windows User Password',
  userName: 'User Name',
  password: 'Password',
  linkToClickableURL: 'Link to onboarding material',
  warningForAutomizedOptions: 'Please note that you can only select one automized milestone confirmation option.',
  ageInDays: 'Age in days',
  prevTransactionMessage: 'Jump to previous transaction with this demo system',
  nextTransactionMessage: 'Jump to next transaction with this demo system',
  export: 'Export',
  receiptNextCustomer: 'Receipt next Customer',
  generalInformation: 'General Information',
  customerInformation: 'Customer Information',
  applicationSampleDetails: 'Application & Sample Details',
  demoCenterInformation: 'Demo Center Information',
  additionalInformation: 'Additional Information',
  logisticsCoordination: 'Logistics & Coordination',
  thisFormIsIntendedFor: 'This form is intended for a',
  nameOfRequestor: 'Name of Requestor',
  bestWayToReachMe: 'Best way to reach me',
  mobile: 'Mobile',
  suggestedDate: 'Suggested Date',
  alternateDate: 'Alternate Date',
  primaryInstrument: 'Primary Instrument',
  needTheDataWithin: 'Demo report within:',
  MultiSystemDemo: 'Please explain why you need more than one system',
  opportunityId: 'Opportunity ID:',
  firstDemoForThisProject: 'First demo for this project?',
  customerApplyingForFunding: 'Budgeted or is the customer applying for funding?',
  schedulingInformation: 'SCHEDULING INFORMATION',
  projectDetails: 'PROJECT DETAILS',
  website: 'Website',
  pointOfContact: 'Point of Contact',
  nda: 'Existing NDA',
  designationTitle: 'Designation/Title',
  marketSegment: 'Market Segment',
  marketSubSegment: 'Market Subsegment',
  customerMicroscopeExperience: 'Current customer experience(s) on actual microscopes?',
  zeissCustomer: 'ZEISS Customer',
  relationshipWithZeiss: 'Relationship with ZEISS',
  customerDetails: 'CUSTOMER DETAILS',
  primaryPointOfContact: 'Primary Point of Contact (if different from above)',
  customerBackground: 'CUSTOMER BACKGROUND',
  next: 'Next',
  back: 'Back',
  mainApplicationChecklist: 'MAIN APPLICATION CHECKLIST',
  subHeading: 'What is the primary goal of this demo/sample run?\nPlease be as descriptive as possible.',
  highResolutionImaging: 'High Resolution Imaging',
  lowkvImagingForCharging: 'Low kV imaging for charging/beam sensitive sample',
  materialContrastImaging: 'Material Contrast Imaging',
  vpMode: 'VP mode',
  wetImaging: 'Wet imaging in EP mode with Peltier cooling stage',
  largeAreaMpping: 'Large Area Mpping (Atlas)',
  stemImaging: 'STEM Imaging',
  stageBias: 'Stage Bias',
  eds: 'EDS',
  ebsd: 'EBSD',
  correlativeMicroscopy: 'Correlative Microscopy (add details below)',
  smartPi: 'SmartPI',
  threeView: 'Volutome',
  raman: 'Raman',
  cryo: 'Cryo',
  mineralogic: 'Mineralogic',
  othersSuchAsWds:
    'Others and/or third parties (such as WDS, AFM, metrology with 3DSM or Neo Pixel, please add details below)',
  detailedCompetitionMicroscopeModels: 'Detailed competition Microscope models?',
  doYouHaveAnyDataFromTheCustomer: 'Do you have any data from the customer?(PPT or images from other instruments)',
  smapleInformation: 'SAMPLE INFORMATION',
  totalNumberOfSamples: 'Total number of samples',
  detailedDescription:
    'Please provide detailed descriptions of all samples and attach supporting images and documents Include expected feature sizes, materials and definition of region of interest/s.',
  manipulateSamples: 'Can we manipulate the samples prior to imaging (coat, break, tear, glue etc)?',
  statusSamples: 'Status of samples after demo/sample run activity?',
  samplesCantManipulated: `If the samples can't be manipulated, please state the precautions and Other Sample Specific Comments (handling, storage, sensitivity, priorities etc)`,
  attachFile: 'Attach File',
  systemAccessoriesText: `Please describe the intended content (software/hardware/accessories) to be shown in the demo. What has been offered/discussed with the customer?`,
  hardOrSoftwareAccessories: 'Hardware/Software/Accessories',
  detailedComments: 'Detailed comments',
  shippingSamples: 'SHIPPING SAMPLES',
  demoAttendees: 'DEMO ATTENDEES',
  skillLevel: 'Skill Level',
  descisionMaker: 'Decision Maker',
  toDoList: 'To-Do List',
  alreadySlotReadyToBookOnThatRoom: 'Already slot ready to book on that room',
  removeFromSlotAvailiability: 'Remove from slot Availiability',
  fillWithValidInformation: 'Validations failed please fill it',
  zmcccancelWarningMessage: 'If you cancel all your changes will not be saved. Are you sure you want to continue?',
  teams: 'Teams',
  formIsInvalidWithValidations: 'Please fill all the fields with proper validations',
  commentMsgForZmcc: 'Please explain in comment why more than one demo is needed for this project.',
  suggestedAndALternateCantBeSame: "suggested date and alternate dates can't be same",
  maxc2cLengthIs5: 'Max allowed c2c chain length is 5',
  selectCategory: 'Please select in which product category you want to book a demo:',
  availableToBook: 'Available slot',
  bookedTransaction: 'Booked Transaction',
  clickBackAndFillThem: 'Click back and fill them',
  zmccSuccessCreation: 'Your request was submitted successfully. You will receive an email after confirmation.',
  zmccConfirmationPage: 'All done! If you have made all the entries, you can now submit the request.',
  reparationSlots: 'Reparation slots',
  kitReparationId: 'SystemReparationId',
  kitReparationDeltedSuccessfully: 'System reparation slot was deleted successfully.',
  continue: 'continue',
  cancelWarningMessageForReparation: 'Are you sure you want to delete this reparation slot?',
  continueForm: 'Continue form',
  category: 'Category',
  priority: 'Priority',
  requestDate: 'Request date',
  lastModified: 'Last modified',
  waitingForConfirmation: 'Waiting for confirmation',
  dueDate: 'Due date',
  estimatedDays: 'Estimated duration in Days',
  additionalInstruments: 'Additional Instruments',
  infoMessageForAdditionalInstrument: 'Please provide other needed systems in the text field below',
  zmccStartDate: 'Start Date',
  reserve24: 'Reserve 24h',
  ccEmail: 'CC E-mail',
  clickOnAdd: 'Please click on "+" to add E-mail',
  zmccReservation: 'ZMCC Reservation',
  demoDateAndTime: 'Demo Date and Time',
  zmccSystems: 'System(s)',
  crmDemoRequestID: 'CRM Demo Request ID',
  zmccNameOFRequestor: 'Requestor',
  zmccCustomerInstitute: 'Customer Institute',
  connectedTransaction: 'Connected to transaction',
  subscribers: 'Subscribers',
  samplesAt: 'Samples at',
  isCorrelative: 'Is correlative ',
  zmccDemoSite: 'Demo Site',
  timeTravel: 'Travel time',
  travelLog: 'Travel Log',
  onHold: 'On Hold',
  inProgress: 'In progress',
  waitingForSamples: 'Waiting for Samples',
  waitingForProcessing: 'Waiting for processing',
  doneStatus: 'Done',
  priorityEroor: 'Please select Priority',
  setShippingAddressForZmcc: 'Set as shipping address for samples',
  zmccSystemClass: 'System class',
  maintainDeliveryAddress: 'Maintain delivery addresses',
  deleteAddress: 'Delete Address',
  demoRequestForm: 'Demo Request Form',
  allowedFormatssForZmccTransaction: 'ppt, pptx, .xls, xslx., .docx, doc, pdf, jpeg, jpg, png files ares allowed',
  timeManagement: 'Time management',
  precallWithZmcc: 'Has a precall with ZMCC already been conducted?',
  zmccDocumentLink: 'Please provide the declaration of safety as attachment. Here is the download link:',
  accomodationNeeded: 'Accomodation needed',
  flightNeeded: 'Flight needed (to be booked by Sales)',
  visitMuseum: 'Additional visiting program',
  fileDoesnotExist: 'File does not exist',
  crossSectioning: 'Cross-sectioning',
  temLamellaPrep: 'TEM Lamella Prep',
  fibTomography: 'FIB Tomography',
  nanoPatterning: 'Nano-patterning',
  threeDEds: '3D-EDS',
  threeDEbsd: '3D-EBSD',
  largeAreaMapping: 'Large Area Mapping',
  fsLaser: 'fs laser',
  addDetails: 'Others (Please add details below)',
  tofSims: 'ToF SIMS',
  xbDetailsForCustomer: 'Do we have any data from the customer? (PPT or images from other instruments)',
  xbDetailedDescription:
    'Please provide detailed descriptions of all samples and attach images and documents. Include expected feature sizes, materials, definition of region of interest/s and detailed application on samples.',
  xbStatusSamples: 'Status of samples after demo/sample run activity?',
  xbSamplesCantManipulated:
    'If the samples can´t be manipulated, please state the preauctions and other sample specific comments (handling, storage, sensitivity, priorities etc)',
  demoDetails: 'DEMO DETAILS',
  rankMessage: 'Please provide a rank of each parameter in order of priority. Please do not repeat a value.',
  intendedSystemText: 'Please provide the intended use for the system',
  customerBudget: 'Customer has budget for the following',
  failureAnalysis: 'Failure Analysis',
  researchRandD: 'Research / R&D',
  processDevelopment: 'Process Development',
  production: 'Production',
  qaqc: 'QA/QC',
  flatPanel: 'Flat Panel',
  fourtyX: '40X',
  art: 'ART',
  xrmPrimaryGoals: 'What are the primary goals of this demo/sample run?',
  xrmCustomerCompetition: 'Current customer systems and competition?',
  xrmApplicationInformation: 'APPLICATION INFORMATION',
  xrmApplicationInforDescText: `Please provide detailed description of customer's focus on application/s to better understand the approach of the analysis`,
  xrmPhysicallyManipulateSamples:
    'Can we physically manipulate the samples (glue, epoxy, bondic, trim, break, etc)? *Ideally, customer should be handling all necessary preparation of sample',
  samepleDescription: 'SAMPLE DESCRIPTION',
  xrmOverAllSamples: 'Please describe the overall sample (incuding handling, storage, sensitivity, priorities etc)',
  sampleSpecs: 'SAMPLE SPECS',
  totalSampleSize: 'Total Sample Size',
  xrmFeatureSizes:
    'Feature sizes (μm-mm): e.g. rock pore diameter, wire size, short, via dimension, cartilage thickness Please be as specific as possible, as this will guide the application engineer for optimal imaging',
  xrmFeatureOfRegion:
    'Please define the feature or region of interest: Describe here and attach any soft or hard copies of sketches, diagrams, images or pictures from optical, SEM or equivalent, % composition by vol. if appropriate.',
  xrmExceptedMaterial:
    'What are the expected materials and chemical compositions of the region of interest? e.g. plastic, copper, organic, silicates',
  xrmSubstrate: 'What is the substrate or surrounding material? e.g. silicia, laminate, bone',
  xrmContrastApplied: `Has a contrast agent been applied? If Yes, please include type.`,
  xrmSampleDetails: 'SAMPLE DETAILS',
  bookAccomodation: 'Book accommodation',
  bookflight: 'Book flight',
  todoVisitMuseum: 'Organize visit of museum of optics',
  specifyDetails: 'Please specifiy details',
  lmApplicationAndSamples: 'APPLICATION & SAMPLES',
  primaryApplicationForThisCustomer:
    'What is the primary application for this customer? (Live cell, 3D imaging, High throughput...)',
  sampleGoingToProvideForThisDemo: 'What samples are going to be provided for this demo?',
  learningAboutTheseSamples: 'What do they want to learn about these samples? (expectations)',
  justificationPoints: 'Justification Points (make/break points)',
  aboutCompetitionInThisSale: 'What do we know about the competition in this sale?',
  othersComments: 'Other comments (use space as needed)',
  lmTotalNumberOfSamples: 'Total number of samples',
  lmStatusOFSamples: 'Status of samples after demo/sample run activity',
  lmDoYouhaveCustomerData: 'Do we have any data from the customer? (ppt or images from other instruments)',
  zmccFormName: type => `Demo Request form ${type}`,
  for: 'for',
  requestType: 'Request type',
  zmccTransactionStatusUpdatedMessage: 'Request status updated sucessfully',
  commentPlaceholderForBookings:
    'Please provide information about installation time, return time, reason in details, special delivery requirements or other needed items. Thank you.',
  linkedReservations: 'Linked reservations',
  continueAppMessage: 'Your file is being downloaded, please wait. You can continue to use the application.',
  successMessageForExportListTransaction: 'The transaction data is exported successfully',
  errorMessageForExportListTransaction: 'Something went wrong. The transaction export failed. Please try again.',
  successMessageForExportListKit: 'The System data is exported successfully',
  errorMessageForExportListkit: 'Something went wrong. The System export failed. Please try again.',
  kitLoanOrderTextUpdated: 'Latest loan order (Reference to SAP ERP)',
  alreadyBudgeted: 'Already budgeted',
  applyingForFunding: 'Applying for funding',
  submitMaintenanceChecklist: 'Submit Checklist “Demo Ready”',
  close: 'Close',
  arrivalForm: 'Fill Demo Ready Checklist (Arrival)',
  demoForm: 'Fill Demo Ready Checklist (Demo)',
  dismantlingForm: 'Fill Demo Ready Checklist (Dismantling)',
  systemAvailability: 'System Availability',
  addSlot: 'Add slot',
  recurring: 'Recurring?',
  listLabelRecurring: 'Recurring:',
  deletAvailablitySlotMessage: 'Are you sure you want to delete system availabilty slot?',
  selectKits: 'select Systems',
  deleteSlot: 'Delete slot',
  addNewSlot: 'Add new slot',
  editSlot: 'Edit slot',
  slecteDaysError: 'Please select days',
  slotCreatedSucessfully: 'Data saved successfully.',
  deletdSucessFully: 'Availability slot removed successfully.',
  commentTextForMedTaiwan:
    'Comment (Please provide information about installation time, return time, reason in details, special delivery requirements or other needed items. Thank you.)',
  headerTextArrival: 'Arrival',
  zmccSelectKits: 'Select Systems',
  campaignId: 'Campaign ID',
  campiagnIdCharacter: 'C -',
  campaignIdErrorMessage: 'Campaign ID should have 8 digits',
  campaignShouldBeNumeric: 'Campaign ID should be numeric',
  enterValidCampignId: 'Please enter valid Campaign Id',
  operator: 'Operator',
  operatorHaveActiveTransactions:
    'The operator cannot be removed as it is assigned to a ZMCC request. Please remove the user as an operator from the request and try again.',
  operatorHaveActiveTransactionMessage: message => `${message}`,
  mySchedule: 'My Schedule',
  startTime: 'Start Time',
  endTime: 'End Time',
  allDay: 'All day',
  scheduleInputTitle: 'Title',
  selectType: 'Select Type',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  businessTravel: 'Business travel',
  otherType: 'Other',
  vacation: 'Vacation',
  workFromHome: 'Work from home',
  addSchedule: 'Add Schedule',
  adaptLoanValidationMessage: 'At least one reason for loan is mandatory',
  reasonLoanToOemPartner: 'Loan to OEM Partner',
  reasonInHouseLoan: 'In-house Loan',
  reasonDemoInSscShowroom: 'Demo in SSC Showroom',
  reasonDemoAtTheCustomers: 'Demo at the Customer`s',
  reasonCourse: 'Course',
  reasonLoanBeforeUnitDelivery: 'Loan unit before delivery',
  reasonLoanUnitDurationRepair: 'Loan unit for the duration of repair',
  reasonStrategicLongTermLoan: 'Strategic long term loan',
  reasonTradeFair: 'Trade fair',
  reasonWorkshop: 'Workshop',
  reasonLoanToDealer: 'Loan to Dealer',
  reasonAvailableNoDemo: 'Available/No Demo',
  reasonRMAReturn: 'RMA Return',
  reasonWalkOff: 'Walk Off',
  systemCategory: 'System Category',
  attendeeName: 'Attendee',
  addMoreButtonText: 'Add more',
  editSchedule: 'Edit Schedule',
  assignOperator: 'Assign Operator',
  endTimeShouldBeLessThanStartTime: 'End time should be greater than start time',
  pleaseSelectAtleaseOnOperatorToAssign: 'Please select atleast one operator to assign',
  reassignOperator: 'Reassign',
  viewSchedule: 'View Schedule',
  deleteScheduledEvent: 'Data deleted successfully',
  scheduleType: 'Type',
  thisEvent: 'This event',
  thisAndFollowingEvents: 'This and following events',
  allEvents: 'All events',
  deleteRecurringEvent: 'Delete recurring event',
  UpdateRecurringEvent: 'Update recurring event',
  adoptChangeForSubsequentReservation: 'Adopt the change for subsequent reservations:',
  labsLocationUserWelcomeMessage: (firstName, lastName) =>
    `Dear ${firstName} ${lastName}, welcome to the ZEISS Demo Management App. Please inform your labs@location Manager that you have registered successfully. They will grant you the needed permissions. Afterwards you can start to work. Thank you.`,
  send: 'Send',
  transactionNumber: 'Transaction Number',
  createBooking: 'Create Booking',
  xrmLabel: 'XRM',
  xbLabel: 'XB',
  semLabel: 'SEM',
  lmLabel: 'LM',
  noTypeAdded: 'No type added',
  messageForKitSlotEdit:
    'Warning: Availability of other systems was created along with this system. The changes will apply to all those systems.',
  zmccStatus: 'ZMCC Status',
  demoProcess: 'Demo process',
  demoStart: 'Demo Start',
  demoEnd: 'Demo End',
  warehouses: 'Warehouses',
  lAndlPartner: 'labs@location Partner',
  zmccWarehouses: 'Customer Center',
  selectRequestType: 'Select request type',
  pleaseSelectRequestTypeToContinue: 'Please select request type to continue',
  week: 'Week',
  canEnterMultipleEmails: 'Use comma separated email addresses for multiple recipients.',
  appUrlChangeSubject: 'Renaming of the Website',
  appUrlChangeBodyOne: `We would like to inform you that we will be renaming our app. The previous name "Demo Loan App" will be replaced by "Demo Management App". The reason for this change is that our system is no longer only used for loan processes, but also for the Marketplace, the DUST tool and in the future for other demo processes such as demo management in the customer center and for labs@location requests. We hope that these changes will help to make our website even more user-friendly and comprehensive. If you have saved `,
  appUrlChangeBodyTwo: ` in your browser favorites, please change the link to `,
  thankYou: 'Thank you.',
  dontShowAgain: `Don't show again`,
  leaveWarningMessage: 'Changes you made may not be saved.',
  leave: 'Leave',
  returnDeliveryAddress: 'Return delivery address',
  returnDeliverAddressNotAddedForCountry:
    'For customs reasons, the location manager has maintained a different return delivery address for the country in the delivery address than that of the lending location. If you want to change the country, please check the return delivery address together with your location manager.',
  day: 'Day',
  kitslotIsnotValiable: 'System not available',
  legendConfirm: 'Confirmed demo with all info available',
  legendMissing: 'Resources booked, info missing (sample, NDA, confirmation from customer, hotel booking, etc)',
  legendNonIssue: 'Non-issue related (manual entries, place holders, etc)',
  legendNotAvailable: 'Not available (system in reparation, system down, system not available any more)',
  myQueries: 'My Queries',
  saveQuery: 'Save Query',
  openProcess: 'Open Transaction',
  updateExistingFilters: 'Update existing filters',
  newQuery: 'Create new query',
  queryResult: 'Query Result',
  cancelOperation: 'Cancel the operation',
  aboutDma: 'About DMA',
  customerCenter: 'Customer Center Request',
  showAll: 'Show all',
  updateQueryErrorMessage: 'Please pick an option',
  homeDemoEndDate: 'Demo end',
  newWarehouse: 'Locations',
  sbu: 'SBU',
  atleastOneAttendeeIsMandatory: 'Name, E-mail and Function are required for attendee.',
  mustHaveAttendee: 'Atleast one attendee is required.',
  pleaseFillRequiredFieldsForAttendee: 'Please fill required fields for attendee.',
  dateModified: 'Date modified',
  by: 'by',
  replaceToLocation: 'location',
  emailMessageCategoryHedaReplace: 'Location',
  wareHouseStatus: 'Warehouse Status:',
  locationStatus: 'Location Status:',
  externallyManagedlabel: 'Externally managed location',
  startOfDemo: 'Start of Demo',
  endOfDemo: 'End of Demo',
  labslocationOnCart: 'labs@location',
  hour: 'Hour',
  hours: 'Hours',
  pages: 'Pages',
  contactNumber: 'Contact Number',
  emailColHeader: 'Email',
  action: 'Action',
  availableSystems: 'Available Systems',
  sapcrmnewclientError: 'Opportunity id should start with 250',
  addDescription: 'Add description',
  dmaReporting: 'DMA Reporting',
  getData: 'Get data',
  toptenCountriesUsgae: 'Top 10 Countries DMA Usage',
  requestsCreatedBy: 'Requests Created By',
  requestsShippedBy: 'Requests Shipped By',
  specialProvinceDeliveryTimes: 'Special Province Delivery Times',
  targetProvince: 'Target Province',
  msgForStateOrProvinceSpecialDeliveryTime: (deliveryDays, returnDeliveryDays, reconditioningDays) =>
    `Please take into account that we need ${deliveryDays} days delivery time, ${returnDeliveryDays} days return delivery time and ${reconditioningDays} days reconditioning time when we send the system to this province/state. If the booking can't be executed, please check the calendar or ask your location manager for support.`,
  stateOrProvince: 'State/Province',
  fixedAsset: 'Fixed Asset',
  maxFilesErrorMessageZmcc: "You can't upload more than {number} files",
  stateErrorMesage: 'Please select State/Province value',
  costCenter: 'Cost Center',
  loanEquipment: 'Loan Equipment',
  zeissLocations: 'ZEISS Locations',
  partnerLocations: 'Partner Locations',
  loanEquipmentMessage: 'All equipment that can be shipped to the customer',
  zeissLocationsMessage: 'ZMCC, QEC, Customer Center',
  partnerLocationsMessage: 'labs@location and other',
  createLLStockButton: 'Create L@L Location',
  availableTypesOnlyImagesOnKit: 'Only .jpg, .jpeg, .png, .czi files are allowed.',
  hideDescription: 'Hide Full Description',
  locationCrmId: 'Location CRM ID',
  standardSapPlant: 'Standard SAP Plant',
  standardSapStorageLocation: 'Standard SAP Storage Location',
  locationSecurityEmail: 'Location Security Email',
  locationContactEmail: 'Location Contact Email',
  accountIdSap: 'Account ID (SAP)',
  iqsStock: 'Stock',
  logistics: 'Logistics',
  partName: 'Part Name / ID',
  requestId: 'Request ID',
  partId: 'Part ID',
  partName: 'Part Name',
  tPlanned: '∑t planned',
  tBookedAe: '∑t booked (AE)',
  tBookedMachine: '∑t booked (Machine)',
  tTracked: '∑t tracked',
  searchHere: 'Search Here...',
  externalBorrower: 'External Borrower',
  inBoundStaff: 'Inbound staff',
  outBoundStaff: 'Outbound staff',
  readOnlyUser: 'Read Only User',
  loacationAdmin: 'Location Admin',
  resources: 'Resources',
  SetShippingAddressForCustomerParts: 'Set as shipping address for customer parts',
  resetFilters: 'Reset filters',
  ZMCC_REQUEST: 'Open',
  ZMCC_CHECKED: 'Completed',
  ZMCC_CANCELLED: 'Discontinued',
  ZMCC_WAITING_FOR_SAMPLES: 'Waiting for samples',
  ZMCC_WAITING_FOR_PROCESSING: 'Waiting for alignment',
  ZMCC_IN_PROGRESS: 'In progress',
  ZMCC_WAITING_FOR_REPORT: 'Waiting for report',
  upload: 'Upload',
  uploadedBy: 'Uploaded By',
  uploadData: 'Upload Data',
  uploadedDate: 'Uploaded date',
  dragFile: 'Drag your file(s) or',
  browse: 'browse',
  titleComment: 'Title / Comment',
  iqsAttachmentUploadMessage: 'Location attachment uploaded successfully.',
  deleteAttachmentMessage: 'Attachment deleted successfully',
  selectFile: 'Select File',
  capacity: 'Capacity',
  requestStatus: 'Request Status',
  open: 'Open',
  waitingForAlignment: 'Waiting for alignment',
  waitingForReport: 'Waiting for report',
  addSystem: 'Add System',
  resourceId: 'Resource ID',
  resourceName: 'Resource Name',
  responsiblePerson: 'Responsible Person',
  identifyer: 'Identifier',
  inventoryNumber: 'Inventory number',
  lastMaintenance: 'Last Maintenance',
  maintenanceIntervalInMonths: 'Maintenance interval [Months]',
  purpose: 'Purpose',
  loanInformation: 'Loan Information',
  maxLoanDurationInDays: 'Max. loan duration (in working days) [days]',
  newComponents: 'New Components',
  ioOptions: 'Evaluation',
  io: 'i.O',
  nio: 'n.i.O',
  addComponent: 'Add Component',
  pleaseAddAtlestOneComponents: 'Please Add Atlest One Components',
  createResource: 'Create Resource',
  maintainResource: 'Maintain Resource',
  sapPlant: 'SAP Plant',
  sapStorageLocation: 'SAP Storage Location',
  messageAddedSuccesfully: 'Message added succesfully',
  newComponentDeletedSuccesfully: 'Component deleted succesfully',
  newComponentAddeddSuccesfully:
    'The new component has been successfully saved. Please note that it will not be transferred to the iBase and will not be overwritten by the iBase Sync. It can only be changed manually.',
  iqsSystemClassLabel: 'Class',
  iqsSystemMainComppoenetLabel: 'Component',
  iqsPackageDimensions: 'Package dimensions',
  welcomeHeading: 'Welcome to the new',
  welcomeSubHeadingtext1:
    'We are pleased to inform you that we have carried out a facelift for our ZEISS Demo Management App.',
  welcomeSubHeadingtext2:
    'In the course of this, we have also renamed some keywords to ensure a uniform and standardized mapping of all booking processes.',
  whatHasChanged: 'What has changed?',
  areReferred: 'are now referred to as',
  renamedTo: 'have been renamed',
  welcomeKits: 'Kits',
  welcomeAboutDma:
    'This also allows us to optimally integrate the new RMS booking processes for labs@location and customer center requests (initially a pilot will start with SSC Italy). Training material on the new booking processes can be found under',
  welcomeBUMesaage:
    'In addition, we have individualized the homepage so that it displays specific information for the respective business units. The homepage has also been streamlined and modernized. Our aim is to achieve a uniform standard in all booking processes and to offer you an improved user experience.',
  thankyouMessage: 'Thank you for your cooperation and enjoy the new ZEISS Demo Management App!',
  dontShowMessage: 'Don’t show this message again.',
  welcomeDma: "'About DMA'",
  kitPurchaseOrder: 'Purchase Order',
  bookLoanEquipment: 'Book Loan Equipment',
  bookSingleLoan: 'Book Single Loan',
  bookCourseLoan: 'Book Course Loan',
  llRequest: 'labs@location Request',
  additionalInformation: 'Show additional subject on return delivery document',
  returnDeliveryAddressText: 'Return Delivery Note Text',
  returnDeliveryNoteText:
    'This function enables you to provide the goods receiving department with specific information on the processing of returned demo devices.',
  kitMarketplaceAvaialble: 'Make available on the Marketplace',
  kitFirstSalesOrder: 'First Sales Order',
  kitMarketplacelLoanOrder: 'Loan Order (Reference to SAP ERP)',
  kitMarketplaceSalesComment: 'Sales Comment',
  kitMarketplaceGrossPrice: 'Gross List Price',
  priceType: 'Price Type',
  priceFactor: 'Price Factor',
  kitMarketplaceTransferPrice: 'Transfer Price',
  staticPirce: 'Static Price',
  dynamicPrice: 'Dynamic Price',
  returnDeliveryNoteText:
    'This function enables you to provide the goods receiving department with specific information on the processing of returned demo devices.',
  iqsCalendar: 'IQS Calendar',
  showOnlySelectedResources: 'Show only selected resources',
  errorOnGettingIbaseData: 'Try with valid iBase number',
  marketplaceDeliveryTimes: 'Delivery time [weeks]',
  weeks: 'Weeks',
  iqsTypeLabel: 'Type',
  kitComponenetDescription: 'Description',
  kitComponentID: 'Component ID',
  kitComponentProductID: 'Product ID',
  kitComponentUserStatus: 'User Status',
  materialNumberFormatErrorOnKitComponent: 'Please enter Product ID in this 123456-4444-333 format',
  kitComponentUpdatedSuccesfully:
    'The component has been successfully saved. Please note that it will not be transferred to the iBase and will not be overwritten by the iBase Sync. It can only be changed manually.',
  kitComponentSerialNumber: 'Serial Number',
  returnDeliveryNoteText:
    'This function enables you to provide the goods receiving department with specific information on the processing of returned demo devices.',
  CRMQuoteIDShouldBeMin7Length: 'CRM Quote ID should be at least 7 characters long.',
  CRMQuoteIDHyphenShouldBeAtSecondPosition: 'CRM Quote ID hyphen (-) should be at the second position',
  CRMQuoteIDShouldContainOnlyNumbersOrQUAndHyphen:
    "CRM ID should consist of only Q, U with numbers Or '-' should be only at 2nd Position",
  CRMQuoteIDShouldBeBetween7And10Characters:
    'CRM Quote ID should be at least 7 characters and less than 10 characters.',
  selectPointsOrHours: 'Select Points Or Hours',
  agreedPoints: 'Agreed Points',
  createNewLocationComment: 'Create New Location Comment',
  editLocationComment: 'Edit Location Comment',
  publicHoliday: 'Public holiday',
  locationCommentSuccessMessage: 'Location comment added successfully',
  updateLocationCommentMessage: 'Location comment updated successfully',
  dateErrorMessage: 'End date and time should be greater than start date',
  endOfContract: 'End of Contract',
  returnDeliveryNoteText:
    'This function enables you to provide the goods receiving department with specific information on the processing of returned demo devices.',
  addPoints: 'Add Points',
  pointAddition: 'Earned points',
  AdditionOfPoints: 'Addition of Points',
  endOfContract: 'End of Contract',
  returnDeliveryNoteText:
    'This function enables you to provide the goods receiving department with specific information on the processing of returned demo devices.',
  addPoints: 'Add Points',
  pointAddition: 'Earned points',
  AdditionOfPoints: 'Addition of Points',
  locationStatus: 'Location Status',
  allLocationStatus: 'All Location Statuses',
  bookingType: 'Booking Type',
  bookingCategory: 'Booking Category',
  relatedRequest: 'Related Request',
  relatedPosition: 'Related Position',
  beginStart: 'Start',
  iqsBookingSucessMessage: 'Booking created successfully',
  searchTerms: 'Search Terms',
  addLocation: 'Add location',
  addAnotherLocation: 'Add Another Location',
  addHeader: 'Add Header',
  addAnotherHeader: 'Add Another Header',
  areYouSure: 'Are you sure?',
  newRequest: 'New Request',
  createRequest: 'Create Request',
  requestTitle: 'Request Title',
  iqsRequestType: 'Request Type',
  executionLocation: 'Execution Location',
  processingLocation: 'Processing Location',
  requestDescription: 'Request Description',
  opportunityNumber: 'Opportunity Number',
  salesNumber: 'Sales Number (e.g. Lead)',
  companySapId: 'Company SAP ID (MDG)',
  companyGroup: 'Company Group',
  comapnyStreetHouseNumber: 'Company Street + House Number',
  companyPostalCode: 'Company Postal Code',
  companyCity: 'Company City',
  companyCountry: 'Company Country',
  companyPhone: 'Company Phone',
  companyEmail: 'Company Email',
  companyWebsite: 'Company Website',
  industrialSegment: 'Industrial Segment',
  industrialSubSegment: 'Industrial Sub-Segment',
  ndaType: 'NDA Type',
  ndaValidUntil: 'NDA valid until',
  aukomMember: 'AUKOM Member',
  crmCustomer: 'CRM & Customer',
  editCompany: 'Edit Company',
  createCompany: 'Create Company',
  customerContactCrmId: 'Customer Contact CRM ID',
  customerContactRole: 'Customer Contact Role',
  customerContactFirstName: 'Customer Contact First Name',
  customerContactEmail: 'Customer Contact Email',
  contacts: 'Contacts',
  creator: 'Creator',
  salesRep: 'Sales Rep',
  projectResponsible: 'Project Responsible',
  projectManager: 'Project Manager',
  zeissPerson: 'Person (ZEISS)',
  roleText: 'Role',
  sapIdAvailable: 'SAP ID (MDG) available',
  sapId: 'SAP ID',
  ndaResponsilbe: 'NDA Responsible',
  ndaTitle: 'NDA Title',
  crmIdAvailable: 'CRM ID available',
  foodHabits: 'Food Habits',
  defaultRole: 'Default Role',
  contactCompanySapId: 'Company SAP ID',
  company: 'Company',
  streetAndHouseNumber: 'Street + House Number',
  customerContact: 'Customer Contact',
  custromerCrmId: 'CRM ID',
  addCustomerContact: 'Add Customer Contact',
  createCustomerContact: 'Create Customer Contact',
  iqsRequests: 'IQS Requests',
  createOrModifyRequest: 'Create or modify request',
  addZeissContact: 'Add ZEISS Contact',
  customerContactListError: 'Please add at least one contact',
  zeissPersonListError: 'Please add at least one ZEISS contact',
  requestCreatedSucessMessage: 'Request saved sucessfully',
  companyCreatedSucessMessage: 'Company saved sucessfully',
  contactCreatedSucessMessage: 'Contact saved sucessfully',
  editRequest: 'Edit Request',
  mrOrMrs: 'Mr./Mrs.',
  mrs: 'Mrs.',
  component: 'Component',
  alternativeComponent: 'Alternative Component',
  sensors: 'Sensors',
  rotationTable: 'Rotation Table',
  assets: 'Assets',
  software: 'Software',
  additionalEquipment: 'Additional Equipment',
  addEquipmentRequest: 'Add Equipment Request',
  addBooking: 'Add Booking',
  requestedEquipment: 'Requested Equipment',
  addNewAttendee: 'ADD NEW ATTENDEE',
  requestTypeApplicationSupport: "Application Support",
  requestTypeContractMeasurement: "Contract Measurement",
  requestTypeTraining: "Training",
  requestTypeProductDemonstration: "Product Demonstration",
  requestTypeSoftwareSupport: "Software Support",
  requestTypeITServices: "IT Services",
  requestTypeQualification: "Qualification",
  requestTypeNotAvailable: "Not Available",
  requestTypeOther: "Other",
  executionLocationZeissSiteWithCustomer: "ZEISS Site (with customer)",
  executionLocationZeissSiteWithoutCustomer: "ZEISS Site (without customer)",
  executionLocationCustomerSite: "Customer Site / Fair",
  executionLocationZeissSiteOther: "ZEISS Site (other)",
  executionLocationMobile: "mobile",
  executionLocationTobeDefined: "to be defined",
  requestStatusPreplanning: "pre planning",
  requestStatusExecution: "planning / execution / billing",
  requestStatusCompleted: "Completed",
  bookingStatusRequested: "Requested",
  bookingStatusReservationOrBlocker: "Reservation / Blocker",
  bookingStatusBooked: "Booked",
  requestType: "Request Type",
  requestExecutionLocation: "Request Execution Location",
  requestStatus: "Request Status",
  bookingStatus: "Booking Status",
  otherBooking: 'Other Booking',
  deleteBookingMessage: 'Booking deleted sucessfully',
  editBookingMessage: 'Booking updated sucessfully',
  deleteLocationComment: 'Location comment deleted successfully',
  editBooking: 'Edit Booking',
  showBooking: 'Show Booking',
  excitingNews:'Exciting News! ',
  VrPopupSeg1: "We're thrilled to announce the launch of our ",
  VrPopupSeg2: 'Virtual Reality Demo Experience ',
  VrPopupSeg3: 'warehouse, now ',
  VrPopupSeg4: 'open for bookings! Explore our latest virtual portfolio of systems and elevate your digital ',
  VrPopupSeg5: 'experience. ',
  VrPopupSeg6: 'For more details, including booking instructions, visit our ',
  VrPopupSeg7: 'Virtual Reality Handbook ',
  VrPopupSeg8: 'on Widen. ',
  VrPopupGreet: 'Happy renting! ',
  openCalendar: 'Open Calendar',
  offerConfirmation: 'Offer Confirmation',
  person: 'Person',
  break: 'Break',
  iqsTimeManagement: 'Time Management',
  createTime: 'Create Time',
  saveTime: 'Save Time',
  timeStamps: 'Time Stamps',
  minutes: 'Minutes',
  addPreparationTime: "Add Preparation Time",
  prePreprationPeriod: "Pre-preparation period",
  postPreparationPeriod: "Post-preparation Period",
  editPreparationPeriod: "Edit Preparation Time",
  preparationTime: "Preparation Time",
  iqsLocationName: 'Location Name',
  zmccClassification: 'Classification',
  isAccessory:'Mark as Accessory',
  accessory: 'Is Accessory?',
  emailShipmentStatus: 'E-mail Shipment status',
};
