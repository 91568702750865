import FormComponent from "library/common/commonComponents/FormComponent/Optimized";
import React from "react";
import strings from "resources/locales/Translate";
import Section from 'library/common/commonComponents/Section/Section';
import Button from "library/common/commonComponents/Button/Button";
import CompanyPopup from '../CompanyPopup/CompanyPopup.component';
import CustomerContactPopup from '../CustomerContanctPopup/CustomerContactPopup.component';
import { useInformation } from './Information.hook';
import { connect } from 'react-redux';
import { EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { createRequest } from "modules/IqsNewRequest/IqsNewRequest.actions";
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

export const Information = props => {
  const {
    onClickCompany,
    onClickCustomerContact,
    getContactCustomerModel,
    getAddZeissCompanayModel,
    getContactCreatorModel,
    handleForm,
    crmAndCustomerForm,
    contactCustomerForm,
    contactCreatorForm,
    zeissCompanayForm,
    onClickZeissPerson,
    zeissPersonList,
    handleExistingValueForPerson,
    setCompanyList,
    companyList,
    getcrmAndCustomerModel,
    selctedCompannyData,
    setDataForCompany,
    onEditCompanyCLick,
    setIsCompanyEditing,
    isCompanyEditing,
    setCustomerContactList,
    customerContactList,
    onSaveClick,
    customerContactFromList,
    handleFormCustomeContact,
    onAddContactClick,
  } = useInformation(props);

  return (
    <div className='col-12 mt-4'>
      <Section sectionTitle={strings.crmCustomer}>
        <div className=''>
          <FormComponent
            model={getcrmAndCustomerModel()}
            formName='crmAndCustomerForm'
            formValue={crmAndCustomerForm.formData}
            onChange={handleForm}
          />
        </div>
        <div className='d-flex'>
          <Button
            value={strings.editCompany}
            onClick={onEditCompanyCLick}
            bgFill={false}
            styleClass='add-button adjust-add-location-manager-btn adjust-button-width'
            icon={
              <EditOutlineIcon
                width='14'
                height='14'
                stroke={!selctedCompannyData?.iqsRequestCompanyId ? 'gray' : '#0088D0'}
              />
            }
            newVersionUi={true}
            disabled={!selctedCompannyData?.iqsRequestCompanyId}
          />
          <Button
            value={strings.createCompany}
            onClick={onClickCompany}
            bgFill={false}
            styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
            iconName='plus'
            newVersionUi={true}
          />
        </div>
      </Section>
      <div className='mt-4'>
        <Section sectionTitle={strings.contacts}>
          <div className=''>
            {customerContactFromList &&
              customerContactFromList.map((item, index) => {
                return (
                  <div className=''>
                    <FormComponent
                      model={getContactCustomerModel()}
                      formName='contactCustomerForm'
                      formValue={contactCustomerForm[index]?.formData}
                      onChange={e =>
                        handleFormCustomeContact(e, index, contactCustomerForm[index]?.formData?.iqsRequestContactId)
                      }
                    />
                  </div>
                );
              })}
          </div>
          <div className='d-flex'>
            <Button
              value={strings.addCustomerContact}
              onClick={onAddContactClick}
              bgFill={false}
              styleClass='add-button adjust-add-location-manager-btn adjust-button-width'
              iconName='plus'
              newVersionUi={true}
              disabled={customerContactList?.length <= 1}
            />
            <Button
              value={strings.createCustomerContact}
              onClick={onClickCustomerContact}
              bgFill={false}
              styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
              iconName='plus'
              newVersionUi={true}
            />
          </div>
          {
            <div className='mt-4'>
              <FormComponent
                model={getContactCreatorModel()}
                formName='contactCreatorForm'
                formValue={contactCreatorForm.formData}
                onChange={handleForm}
              />
            </div>
          }
          <div className='mt-3'>
            {zeissPersonList?.length > 0 &&
              zeissPersonList.map((item, index) => (
                <div className='col-6'>
                  <FormComponent
                    model={getAddZeissCompanayModel()}
                    formName='zeissCompanayForm'
                    formValue={item}
                    onChange={e => handleExistingValueForPerson(e, index)}
                  />
                </div>
              ))}
            <div className='col-12 d-flex p-0'>
              <div className='col-6'>
                <FormComponent
                  model={getAddZeissCompanayModel()}
                  formName='zeissCompanayForm'
                  formValue={zeissCompanayForm.formData}
                  onChange={handleForm}
                  disableForm={zeissPersonList?.length >= 5}
                />
              </div>

              <Button
                value={strings.addZeissContact}
                onClick={onClickZeissPerson}
                bgFill={false}
                styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
                iconName='plus'
                newVersionUi={true}
                disabled={zeissPersonList?.length >= 5}
              />
            </div>
          </div>
        </Section>
      </div>
      <div className='d-flex'>
        <Button
          value={strings.save}
          onClick={onSaveClick}
          bgFill={true}
          styleClass='add-button adjust-add-location-manager-btn save-next-pev-button'
          newVersionUi={true}
        />
        <Button
          value={strings.cancel}
          onClick={() => {}}
          bgFill={false}
          styleClass='add-button adjust-add-location-manager-btn save-next-pev-button ml-4'
          newVersionUi={true}
        />
      </div>
      <CompanyPopup
        id='companyPopup'
        setCompanyList={setCompanyList}
        companyList={companyList}
        selctedCompannyData={selctedCompannyData}
        setIsCompanyEditing={setIsCompanyEditing}
        isCompanyEditing={isCompanyEditing}
        setDataForCompany={e => setDataForCompany(e)}
      />
      <CustomerContactPopup
        id='customerContactPopup'
        setCustomerContactList={setCustomerContactList}
        customerContactList={customerContactList}
      />
    </div>
  );
};

const mapStateToProps = ({ authReducer, iqsRequestReducer, masterDataReducer }) => ({
  user: authReducer.user,
  allDropdownData: iqsRequestReducer.allDropdownData,
  usersList: masterDataReducer.usersList,
  requestDetailes: iqsRequestReducer.requestDetailes,
});

export default connect(mapStateToProps, { createRequest, toggleActionMessage })(Information);