import { useEffect, useState } from 'react';
import {
    addSystemAndLocationModel,
  confirmBookingInformationModel,
  confirmBookingOtherModel,
  confirmBookingRequestModel,
} from '../ComfirmBookingPopup/ComfirmBookingPopup.constants';
import * as $ from 'jquery';
import { cloneDeep } from 'lodash';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { IQS_SSC_DE_DESIGNATION } from 'library/utilities/constants';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { validateForm } from 'library/utilities/ValidateForm.util';
import moment from 'moment';
import strings from 'resources/locales/Translate';

export const useEditBookingPopup = ({
  id,
  iqsBookingDropdownData,
  editBookedData,
  getKitsByLocationIds,
  countries,
  editBooking,
  filters,
}) => {
  const [editBookingForm, setEditBookingForm] = useState({ formData: {} });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [systemsList, setSystemsList] = useState([]);
  const [addSystemFrom, setAddSystemForm] = useState({ formData: {} });

  useEffect(() => {
    if (editBookedData?.bookingType) {
      setEditBookingForm({
        formData: {
          bookingType: editBookedData?.bookingType,
          bookingTypeFullValue: {
            name: editBookedData?.bookingTypeTranslated,
            staticCode: editBookedData?.bookingType,
          },
          bookingCategory: editBookedData?.bookingCategory,
          bookingCategoryFullValue: {
            name: editBookedData?.bookingCategoryTranslated,
            staticCode: editBookedData?.bookingCategory,
          },
          bookingFrom: editBookedData?.bookingFrom ? new Date(editBookedData?.bookingFrom) : '',
          bookingTo: editBookedData?.bookingTo ? new Date(editBookedData?.bookingTo) : '',
          otherBookingTitle: editBookedData?.otherBookingTitle,
          otherBookingDescription: editBookedData?.otherBookingDescription,
        },
      });
      setEventsData([...editBookedData?.bookedResources, ...editBookedData?.bookedPersons]);
    }
  }, [editBookedData]);

  useEffect(() => {
    const url = `${URLS.countryList}/business-unit?businessUnitDesignation=${IQS_SSC_DE_DESIGNATION}`;
    fetchMasterData(url, FETCH_COUNTRY_LIST);
  });

  const handleForm = async ({ values, field }) => {
    setError('');
    setEditBookingForm({
      ...editBookingForm,
      formData: {
        ...editBookingForm.formData,
        ...values,
      },
    });
  };

  const handleAddSystemForm = async ({ values, field }) => {
    setError('');
    if (field === 'location') {
      const res = await getKitsByLocationIds({ ids: [values.location] });
      setSystemsList(res?.data);
    }
    setAddSystemForm({
      ...addSystemFrom,
      formData: {
        ...addSystemFrom.formData,
        ...values,
      },
    });
  };

  const informationModel = () => {
    const model = [...confirmBookingInformationModel];
    model[0].options = iqsBookingDropdownData?.iqsRequestBookingTypes || [];
    model[1].options = iqsBookingDropdownData?.iqsRequestCategories || [];
    return model;
  };

  const otherModel = () => {
    const model = [...confirmBookingOtherModel];
    return model;
  };

  const requestModel = () => {
    const model = [...confirmBookingRequestModel];
    return model;
  };

  const getAddLocationSystemModel = () => {
    const model = [...addSystemAndLocationModel];
    model[0].options = countries;
    model[1].options = systemsList;
    return model;
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setEditBookingForm({ formData: {} });
    setSystemsList([]);
    setError('');
    setAddSystemForm({ formData: {} });
  };

  const onDeleteClick = id => {
    const events = cloneDeep(eventsData).filter((_, index) => index !== id);
    setEventsData(events);
  };

  const onAddCLick = () => {
    const validForm = validateForm({
      form: addSystemFrom,
      model: getAddLocationSystemModel(),
    });
    
    if (!validForm.isFormValid) {
      return setAddSystemForm({ formData: validForm.formData });
    }
    const { locationFullValue, systemFullValue } = validForm.formData;
    setEventsData([
      ...eventsData,
      {
        kitInformationId: systemFullValue.kitInformationId,
        kitName: systemFullValue.kitName,
        locationName: locationFullValue.locationName,
        roomName: systemFullValue.roomName,
        bookingId: null,
        resourceType: systemFullValue.resourceType,
        resourceTypeTranslated:
          systemFullValue.resourceType === 'SYSTEM'
            ? 'System'
            : systemFullValue.resourceType === 'EQUIPMENT'
            ? 'Equipment'
            : 'Others',
      },
    ]);

    setAddSystemForm({ formData: {} });
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: editBookingForm,
      model: informationModel(),
    });

    const validOtherForm = validateForm({
      form: editBookingForm,
      model: otherModel(),
    });

    if (!validForm.isFormValid || !validOtherForm.isFormValid) {
      return setEditBookingForm({ formData: { ...validForm.formData, ...validOtherForm.formData } });
    }
    const { bookingFrom, bookingTo, bookingType, bookingCategory, otherBookingTitle, otherBookingDescription } =
      editBookingForm.formData;
    if (moment(bookingTo).isSameOrBefore(moment(bookingFrom))) {
      return setError(strings.dateErrorMessage);
    }

    if (!eventsData.some(item => item.hasOwnProperty('kitInformationId'))) {
      return setError("Please add at least one resource");
    }

    const dataToSend = {
      bookingFrom: moment(bookingFrom).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bookingTo: moment(bookingTo).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bookingType: bookingType,
      bookingCategory: bookingCategory,
      otherBookingTitle: otherBookingTitle,
      otherBookingDescription: otherBookingDescription,
      resources: eventsData?.map(item => item.kitInformationId) || null,
      persons: editBookedData?.bookedPersons?.map(value => value.accountId) || null,
      bookingId: editBookedData.bookingId,
    };
    setLoading(true);
    const res = await editBooking(dataToSend, filters);
    setLoading(false);
    if (!res.success) {
      if (
        res?.error?.response?.status === 422 ||
        res?.error?.response?.status === 403 ||
        res?.error?.response?.status === 404
      ) {
        setError(res.error.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      onCloseClick();
    }
  };

  return {
    informationModel,
    otherModel,
    requestModel,
    handleForm,
    editBookingForm,
    onCloseClick,
    eventsData,
    onDeleteClick,
    getAddLocationSystemModel,
    onAddCLick,
    addSystemFrom,
    handleAddSystemForm,
    onSaveClick,
    loading,
    error,
  };
};
