export const PERSON_TYPE_RESOURCE = {
  staticCode: 'PERSON',
  name: 'Person',
};

export const RESOURCE_TYPES = {
  SYSTEM: 'SYSTEM',
  EQUIPMENT: 'EQUIPMENT',
  OTHER: 'OTHER',
  PERSON: 'PERSON',
};
