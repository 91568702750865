import React from 'react';
import { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ADD_PREPARATION_PERIOD_MODAL_ID } from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.constants';
import $ from 'jquery';
import {
  addPreparationPeriodsForRequest,
  getWholeSemTransaction,
} from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.actions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

const useAddPreparationPeriod = (suggestedDate, preAndPostPreparationPeriod, transactionId) => {
  const [selectedDate, setSelectedDate] = useState(suggestedDate || new Date());
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (suggestedDate) {
      setSelectedDate(
        new Date(moment(suggestedDate).subtract(preAndPostPreparationPeriod.prePreparationPeriod || 0, 'days')),
      );
      const totalStart = moment(suggestedDate)
        .subtract(preAndPostPreparationPeriod.prePreparationPeriod || 0, 'days')
        .startOf('day');
      const totalEnd = moment(suggestedDate)
        .add(preAndPostPreparationPeriod.postPreparationPeriod || 0, 'days')
        .endOf('day');

      setEvent([
        {
          id: 1,
          start: totalStart.format('YYYY-MM-DD HH:mm:ss'),
          end: totalEnd.format('YYYY-MM-DD HH:mm:ss'),
          resourceId: '1',
          title: 'Full Period',
          bgColor: '#0F1F3E',
          showPopover: false,
          customData: {
            preDuration: preAndPostPreparationPeriod.prePreparationPeriod || 0,
            postDuration: preAndPostPreparationPeriod.postPreparationPeriod || 0,
            eventDate: suggestedDate,
          },
        },
      ]);
    }
  }, [
    suggestedDate,
    preAndPostPreparationPeriod.postPreparationPeriod,
    preAndPostPreparationPeriod.prePreparationPeriod,
  ]);

  const headerTemplateResolver = (schedulerData, item) => {
    const isToday = moment(item.time).isSame(moment(), 'day');
    const startOfMonth = moment(item.time).startOf('month');
    const isMonthStart = moment(item.time).isSame(startOfMonth, 'day');
    const startOfWeek = moment(item.time).clone().startOf('isoWeek');
    const isStartOfWeek = moment(item.time).isSame(startOfWeek, 'day');
    const weekNumber = moment(item.time).isoWeek();
    const currentWeekNumber = moment().isoWeek();
    return (
      <>
        <th
          className='header3-text'
          style={{
            width: 60,
            border: 'none',
            borderLeft: isMonthStart || isStartOfWeek ? '1px solid #e0e1dd' : 'none',
          }}
        >
          {(isMonthStart || isStartOfWeek) && (
            <div className={cn('month-week-text-display', { 'header-today-text': weekNumber === currentWeekNumber })}>
              <span>Week {weekNumber}</span>
            </div>
          )}
          <div className={cn('month-slot-header', 'month-view-border-seprator')} style={{ backgroundColor: '#fff' }}>
            <div>{moment(item.time).format('ddd')}</div>
            <div>{moment(item.time).format('DD.MM')}</div>
          </div>
        </th>
      </>
    );
  };

  const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, className, height) => {
    const { preDuration, postDuration, eventDate } = event.customData;
    const pre = Number(preDuration) || 0;
    const post = Number(postDuration) || 0;

    const date = moment(suggestedDate);
    const startOfMonth = date.clone().startOf('month');
    const endOfMonth = date.clone().endOf('month');
    const daysFromStart = date.diff(startOfMonth, 'days');
    const daysToEnd = endOfMonth.diff(date, 'days');
    let maxPre = pre < daysFromStart ? pre : daysFromStart;
    let isCurrentMonth = moment(suggestedDate).month() === moment(schedulerData.startDate).month();

    return (
      <div
        className={className}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: `${pre || post ? '#E0E1DD' : '#fff'}`,
          height: height,
          overflow: 'hidden',
          borderRadius: '5px !important',
        }}
      >
        <div
          style={{
            width: maxPre * 59,
            height: height,
            // backgroundColor: '#E0E1DD',
            flexShrink: 0,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
          }}
        ></div>
        <div
          style={{
            width: 59,
            height: height,
            backgroundColor: isCurrentMonth ? '#0F1F3E' : '#E0E1DD',
            flexShrink: 0,
            borderRadius: 5,
            // marginLeft: `${(pre || post) ? 9: 0}px`,
          }}
        ></div>
        <div
          style={{
            width: post * 59,
            height: height,
            // backgroundColor: '#E0E1DD',
            flexShrink: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
        ></div>
      </div>
    );
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const dataToSend = {
        prePreparationPeriod: preAndPostPreparationPeriod.prePreparationPeriod,
        postPreparationPeriod: preAndPostPreparationPeriod.postPreparationPeriod,
      };
      const response = await addPreparationPeriodsForRequest(dataToSend, transactionId);
      if (response.success) {
        $(`#${ADD_PREPARATION_PERIOD_MODAL_ID}`).modal('hide');
        dispatch(getWholeSemTransaction(`${transactionId}`));
      }
      dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
    } catch (error) {
      $(`#${ADD_PREPARATION_PERIOD_MODAL_ID}`).modal('hide');
      if (error && (error?.response?.status === 422 || error?.response?.status === 403)) {
        dispatch(toggleActionMessage(true, 'error', error?.response?.data?.message || 'somethingWentWrongMessage'));
      }
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    } finally {
      setLoading(false);
    }
  };

  return {
    headerTemplateResolver,
    selectedDate,
    setSelectedDate,
    event,
    eventItemTemplateResolver,
    handleSave,
    loading,
  };
};

export default useAddPreparationPeriod;
