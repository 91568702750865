import { useEffect } from 'react';
import strings from 'resources/locales/Translate';
import {
  astronautColor,
  toreaBayColor,
  ceruleanColor,
  malibuColor,
  limeadeColor,
  blueVioletColor,
  javaColor,
  atlantisColor,
  dairyCreamColor,
  sanMarinoColor,
  osloGrayColor,
  lightBlueSeesionColor,
  blueColor,
  navyBlue,
  blackColor,
  redColor,
} from 'resources/styles/variables.scss';

export const useDmaReporting = ({ dmaReporting, fetchSixMonthTrafficData, sixMonthsTrafficData }) => {
  useEffect(() => {
    fetchSixMonthTrafficData();
  }, []);

  const chartLabels = dmaReporting?.sessionByUserGroupKPI?.map(item => item.name);
  const chartData = dmaReporting?.sessionByUserGroupKPI?.map(item => item.percentage);
  const backgroundColor = [
    astronautColor,
    toreaBayColor,
    ceruleanColor,
    malibuColor,
    limeadeColor,
    blueVioletColor,
    javaColor,
    atlantisColor,
    dairyCreamColor,
    sanMarinoColor,
    osloGrayColor,
    redColor
  ];
  const legends = dmaReporting?.sessionByUserGroupKPI?.map((item, index) => ({
    name: item.name,
    color: backgroundColor[index],
  }));
  const data = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor,
        datalabels: {
          anchor: 'end',
          offset: 11,
        },
      },
    ],
  };

  const topTenSessionData = {
    labels: dmaReporting?.topTenUserCountries?.map(item => item.country),
    datasets: [
      {
        label: strings.users,
        data: dmaReporting?.topTenUserCountries?.map(item => item.userCount),
        backgroundColor: [
          blueColor,
          blueColor,
          blueColor,
          blueColor,
          blueColor,
          blueColor,
          blueColor,
          blueColor,
          blueColor,
          blueColor,
        ],
        borderRadius: 20,
      },
      {
        label: 'Sessions',
        data: dmaReporting?.topTenUserCountries?.map(item => item.sessionCount),
        backgroundColor: [
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
          lightBlueSeesionColor,
        ],
        borderRadius: 20,
      },
    ],
  };

  const defaultOptionsOfTenSessions = {
    scales: {
      yAxes: [
        {
          beginAtZero: false,
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          barPercentage: 0.6,
          categoryPercentage: 0.5,
        },
      ],
    },
    legend: {
      labels: {
        fontSize: 15,
        padding: 25,
        usePointStyle: true,
        boxWidth: 10,
      },
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const trafiicLabel = sixMonthsTrafficData?.map(item => item.label);
  const trafficData = {
    labels: trafiicLabel,
    datasets: [
      {
        data: sixMonthsTrafficData?.map(item => item.traffic),
        backgroundColor: [navyBlue, navyBlue, navyBlue, navyBlue, navyBlue, navyBlue],
      },
    ],
  };
  const maxYvalueForTraffic =
    sixMonthsTrafficData?.length > 0 && Math.max(...sixMonthsTrafficData.map(item => item.traffic));
  
  const defaultOptionsOfTrafficcDats = {
    datasets: {
      bar: {
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: false,
            suggestedMax: maxYvalueForTraffic + 100,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        offset: -25,
        align: 'start',
        font: {
          size: 16,
          weight: 500,
        },
        labels: {
          value: {
            color: blackColor
          }}
      },
    },
    layout: {  
      padding: {
        top: 20,
        bottom: 20,
      },
    },
  };
  return {
    data,
    legends,
    topTenSessionData,
    defaultOptionsOfTenSessions,
    trafficData,
    defaultOptionsOfTrafficcDats,
  };
};