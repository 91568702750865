import React from 'react';

import strings from 'resources/locales/Translate';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField'
import Validators from 'library/utilities/Validators';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import { PERFORMANCE9PRODUCTIONOFSAMPLES, PERFORMANCE12WORKSHOPSCOURSESTRAININGS, TIMEINHOURS, AGREEDPOINTS } from 'library/utilities/constants';

const TypesOfJobsBlock = props => {
  const { typesOfJobs, demoTime, typesOfJobsList, onChangeDemoTime, onChangeTypeOfJobs, handleDropdownChangePointsOrHours, selectedDropdowvalue, TypesOfJobOptionsFor9and12, selectedDropdowvalueErrors } = props;
  
  return (
    <div>
      {typesOfJobsList.map(item => (
        <div className='mb-3' key={item.tyesOfJobsId} title={item.toolTipValue !== null ? item.toolTipValue : ''}>
          <Checkbox
            label={item.name}
            selected={typesOfJobs.includes(item.tyesOfJobsId)}
            field={item.tyesOfJobsId}
            onChange={onChangeTypeOfJobs}
            newuiVersionStyle={true}
          />
          <div className='mt-3'>
            {(typesOfJobs.includes(item.tyesOfJobsId) && (item.staticCode === PERFORMANCE9PRODUCTIONOFSAMPLES || item.staticCode === PERFORMANCE12WORKSHOPSCOURSESTRAININGS )) && 
              <Dropdown
                data={TypesOfJobOptionsFor9and12}
                hasSection={false}
                multiSelect={false}
                filter={false}
                idKey='id'
                field='selectedDropdowvalue'
                displayValue={[{ key: 'value', separator: ' ' }]}
                label={strings.selectPointsOrHours}
                isRequired={true}
                validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                placeholder={strings.pleaseSelect}
                onChange={(value) => handleDropdownChangePointsOrHours(item.tyesOfJobsId, value)}
                value={selectedDropdowvalue[item.tyesOfJobsId]}
                fullValue={TypesOfJobOptionsFor9and12.find(typeofJobPointorHour => typeofJobPointorHour.id === selectedDropdowvalue[item.tyesOfJobsId])}
                error={selectedDropdowvalue[item.tyesOfJobsId] ? '' : selectedDropdowvalueErrors && selectedDropdowvalueErrors[item.tyesOfJobsId]}
            />

            }
          <div className='mt-3'>
            {typesOfJobs.includes(item.tyesOfJobsId) && <InputField
            label={item.tyesOfJobsId === 19 ? strings.pages : 
              selectedDropdowvalue[item.tyesOfJobsId] === TIMEINHOURS ? strings.demoTime : selectedDropdowvalue[item.tyesOfJobsId] === AGREEDPOINTS ? strings.agreedPoints : 
              strings.demoTime}
            field='demoTime'
            placeholder='00.00'
            isRequired={false}
            validators={[]}
            value={demoTime[item.tyesOfJobsId]}
            onChange={value => onChangeDemoTime(item.tyesOfJobsId, value)}
          />}
          </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TypesOfJobsBlock;
