import Validators from 'library/utilities/Validators';

export const addCommentModel = [
  {
    label: 'scheduleInputTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'title',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'start',
    value: '',
    type: 'dateAndTimePicker',
    placeholder: 'pleaseSelect',
    field: 'eventStart',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
  },
  {
    label: 'end',
    value: '',
    type: 'dateAndTimePicker',
    placeholder: 'pleaseSelect',
    field: 'eventEnd',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
  },
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    field: 'stockId',
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'publicHoliday',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: '',
    field: 'publicHoliday',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [
      {
        id: 'YES',
        value: 'yes',
      },
      {
        id: 'NO',
        value: 'no',
      },
    ],
  },
];
