import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { getIconByCondition, getIconByStatus } from 'library/utilities/getStatusAndConditionIcons';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import AddEditSlotPopup from 'modules/Stock/CreateStock/Tabs/AddEditSlotPopup';
import $ from 'jquery';
import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import KeyValueDetails from 'library/common/commonComponents/KeyValueDetails/KeyValueDetails';
import 'library/common/commonComponents/KeyValueDetails/keyValue.styles.scss';

const ZMCCInformation = ({ kitDetail, history, user, availabilitySlotList }) => {
  const [isEditSlot, setIsEditSlot] = useState(false);
  const businessUnit = get(kitDetail, 'businessUnit.name');
  const conditionString = get(kitDetail, 'kitCondition.kitConditionTranslations[0].translatedCondition', '').toLowerCase();

  const checkUserPermission = () => {
    const { stockInformation } = kitDetail;
    let isEditable;
    if (stockInformation && stockInformation.userRole && stockInformation.userRole.length > 0){
      isEditable = stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
      user && user.admin ||
      (stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher');
    }
    return isEditable;
  };


  const isEditable = checkUserPermission();
  const value = fetchFromStorage(identifiers.kitAddSoltPopup);
  const findKitSlot =
    availabilitySlotList &&
    availabilitySlotList.length > 0 &&
    availabilitySlotList.find(item => item.kits.find(each => each.kitInformationId === kitDetail.kitInformationId));

  useEffect(() => {
    if (value !== null && value !== undefined) {
      saveToStorage(identifiers.kitAddSoltPopup, null);
      setTimeout(() => {
        $('#system-availability-slot').modal('show');
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (findKitSlot?.zmccWarehouseSlotId) {
      setIsEditSlot(true);
    }
  }, [availabilitySlotList]);

  const kitData = { kitInformationId: kitDetail?.kitInformationId, kitName: kitDetail?.kitName };

  const onAddOrEditSlot = () => {
    if (findKitSlot?.zmccWarehouseSlotId) {
      setIsEditSlot(true);
    }
    saveToStorage(identifiers.kitAddSoltPopup, true);
    window.open(`#/kit_details/${kitDetail?.kitInformationId}`, '_blank');
  };

  const informationDetails =[
    { key: strings.zmccFullForm, value: (kitDetail.stockInformation && kitDetail.stockInformation.locationName) || '-'},
    { key: strings.businessUnit, value: businessUnit},
    { key: strings.categorySystem, value: (kitDetail.systemClass && kitDetail.systemClass.systemClassName) || '-'},
    { key: strings.systemMainComponent, value: (kitDetail.systemMainComponent && kitDetail.systemMainComponent.systemMainComponentName) || '-'},
    { key: strings.systemCategory, value: (kitDetail.zmccSystemCategory &&  kitDetail.zmccSystemCategory.name) || '-'},
    { key: strings.serialNumber, value: kitDetail.serialNumber || '-'},
    { key: strings.ibaseText, value: kitDetail.ibase || '-'},
    { key: strings.yearOfManufacture, value: kitDetail.dateOfManufacture ? getDateInFormat('YYYY', kitDetail.dateOfManufacture) : '-'},
    { key: strings.monthOfManufacture, value: kitDetail.dateOfManufacture ? getDateInFormat('MMMM', kitDetail.dateOfManufacture) : '-'},
    { key: strings.dateCreated, value: kitDetail.dateCreated ? `${getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated)} ${kitDetail.createdBy ? strings.by +" "+kitDetail.createdBy.firstName + " " + kitDetail.createdBy.lastName  : ""}` : '-'},
    { key: strings.dateModified, value: kitDetail.updatedAt && kitDetail.updatedBy ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${kitDetail.updatedBy ? strings.by + " " + kitDetail.updatedBy.firstName + " " + kitDetail.updatedBy.lastName : ""}` : (kitDetail.updatedAt && (getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt) > getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated))) ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${strings.by} System` : '-' },
    { key: strings.countryLand, value: (kitDetail.stockInformation && kitDetail.stockInformation.country.name) || '-'},
    { key: strings.availability,
      value: kitDetail.availabilityFrom ? getDateInFormat('DD.MM.YYYY', kitDetail.availabilityFrom, kitDetail.availabilityTo) : '-',
      buttonConfig : isEditable ? {
        label : findKitSlot?.zmccWarehouseSlotId ? strings.editSlot : strings.addSlot,
        onClick : onAddOrEditSlot,
        icon : 'plus'
      } : {},

      hasButton: isEditable
    },
    { key: strings.inputRoomLabel, value: kitDetail.warehouseRoom && kitDetail.warehouseRoom.roomName || '-'},
    { key: strings.owner, value: (kitDetail.owner && `${kitDetail.owner.lastName}, ${kitDetail.owner.firstName}`) || '-'},
    { key: strings.isMobileOrExternal, value: kitDetail.isMobileOrExternal ? strings.yes : kitDetail.isMobileOrExternal !== false ? '-' : strings.no},
    { key: strings.isAccessory, value: kitDetail.accessory ? strings.yes : kitDetail.accessory !== false ? '-' : strings.no},
  ]

  return (
    <div className='information-container'>
      <div className='container-fluid'>
      <div className='mt-3'>
        <KeyValueDetails details={[]} sectionTitle={strings.kitStatus}>
          <div className='col-6'>
            <div className='key-header mb-2'>{strings.status}</div>
            <div className='value d-flex mb-3 flex-wrap pt-1'>
              {kitDetail.kitStatuses?.map(
                item =>
                  item.kitStatusesTranslated &&
                  item.kitStatusId !== 15 && (
                    <div key={item.kitStatusId} className='mr-4 mb-2'>
                      {getIconByStatus(item, true, 24)}
                    </div>
                  ),
              )}
              {conditionString && (
                <div key={kitDetail.kitCondition.kitConditionId} className='mr-4 mb-2'>
                  {getIconByCondition(kitDetail.kitCondition, true, 24)}
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='key-header'>{strings.function}</div>
            <div className='description-container word-wrap mb-3 value pt-1'>
              {kitDetail.comment || strings.noCommentAvailable}
            </div>
          </div>
        </KeyValueDetails>
        <KeyValueDetails details={informationDetails} sectionTitle={strings.information} styleColClass='col-3' >
        </KeyValueDetails>
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: `/edit-zmcc-kit/${kitDetail.kitInformationId}`,
                state: { edit: true, tab: 'information' },
              })
            }
          />
        )}
      </div>
      <AddEditSlotPopup
        id='system-availability-slot'
        isPopupFromKit={true}
        kitData={kitData}
        warehouseId={kitDetail.stockInformation.id}
        editingAvailabilitySlotData={findKitSlot}
        isEditMode={isEditSlot}
        onEditClose={e => setIsEditSlot(e)}
      />
    </div>
  );
};

export default ZMCCInformation;
