import React from 'react';
import strings from 'resources/locales/Translate';

const Legend = ({ title = '', color = '', borderColor = '', stripColor = '', borderStyle }) => {
  if (!title) return;
  const style = {};
  if (color) {
    style.backgroundColor = color;
  }
  if (borderColor) {
    style.border = `3px solid ${borderColor}`;
  }
  if(borderStyle){
    style.border = borderStyle
  }
  if (stripColor) {
    style['background'] = `repeating-linear-gradient(-45deg, ${stripColor}, ${stripColor} 2px, #fff 2px,  #fff 5px)`;
  }

  return (
    <div className='d-flex align-items-center mt-3'>
      <div className='legend-box' style={{ ...style }}></div>
      <span className='legend-title'>{title}</span>
    </div>
  );
};

function CalendarLegends() {
  return (
    <div className='calendar-legends-container'>
      <div>
        <p className='container-title align-self-start'>{strings.requestType}</p>
        <Legend title={strings.requestTypeApplicationSupport} color='#DA9694' />
        <Legend title={strings.requestTypeContractMeasurement} color='#F4B084' />
        <Legend title={strings.requestTypeTraining} color='#00B0F0' />
        <Legend title={strings.requestTypeProductDemonstration} color='#FFFF99' />
        <Legend title={strings.requestTypeSoftwareSupport} color='#FFC000' />
        <Legend title={strings.requestTypeITServices} color='#A7FFEE' />
        <Legend title={strings.requestTypeQualification} color='#F2BDFF' />
        <Legend title={strings.requestTypeNotAvailable} color='#616161' />
        <Legend title={strings.requestTypeOther} color='#000000' />
      </div>
      <div>
        <p className='container-title'>{strings.requestExecutionLocation}</p>
        <Legend title={strings.executionLocationZeissSiteWithCustomer} borderColor='#FF0000' />
        <Legend title={strings.executionLocationZeissSiteWithoutCustomer} borderColor='#00B050' />
        <Legend title={strings.executionLocationCustomerSite} borderColor='#FFC000' />
        <Legend title={strings.executionLocationZeissSiteOther} borderColor='#0070C0' />
        <Legend title={strings.executionLocationMobile} borderColor='#7030A0' />
        <Legend title={strings.executionLocationTobeDefined} borderColor='#000000' />
        <p className='container-title mt-3'>{strings.requestStatus}</p>
        <Legend title={strings.requestStatusPreplanning} borderColor='#000000' stripColor='#C1C1C1' />
        <Legend title={strings.requestStatusExecution} borderColor='#000000' color='#C4C4C4' />
        <Legend title={strings.requestStatusCompleted} borderColor='#000000' color='#C9FFE1' />
        <p className='container-title mt-3'>{strings.bookingStatus}</p>
        <Legend title={strings.bookingStatusRequested} borderColor='#000000' color='#fff' borderStyle={"4px dashed black"}/>
        <Legend title={strings.bookingStatusReservationOrBlocker} borderColor='#000000' color='#fff' borderStyle={"3px dashed black"} />
        <Legend title={strings.bookingStatusBooked} borderColor='#000000' color='#fff' />
      </div>
    </div>
  );
}

export default CalendarLegends;
