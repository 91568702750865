import { useCallback, useEffect, useReducer, useState } from 'react';
import useTitle from 'library/common/commonHooks/useTitle.hook';
import strings from 'resources/locales/Translate';
import { fetchSearchTermConfigEnabledStocks, transformSearchTermResponse } from '../../MasterData.actions';
import { useDispatch, useSelector } from 'react-redux';
import { saveOrEditSearchTermsForStock, getAllSearchTerms } from '../../MasterData.actions';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';
import { generateRandomKey } from 'library/utilities/commonUtils';

const ACTIONS = {
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  CHANGE_NEW_HEADER: 'CHANGE_NEW_HEADER',
  ADD_HEADER: 'ADD_HEADER',
  CHANGE_TERM: 'CHANGE_TERM',
  ADD_TERM: 'ADD_TERM',
  MARK_TERM_DELETED: 'MARK_TERM_DELETED',
  MARK_HEADER_ACTION: 'MARK_HEADER_ACTION',
  ADD_NEW_STOCK: 'ADD_NEW_STOCK',
  ADD_LOCATION: 'ADD_LOCATION',
  REPLACE_LOCATION: 'REPLACE_LOCATION',
};

const createAction = (type, payload) => ({ type, payload });

const locationReducer = (state, { locationIndex, value, field, fullValue, error }) => {
  const updatedState = [...state];
  const location = updatedState[locationIndex];
  location['locationValue'] = value;
  location.locationFullValue = fullValue;
  location.locationError = error;
  return updatedState;
};

const headerReducer = (state, { locationIndex, value, error }) => {
  const updatedState = [...state];
  const location = updatedState[locationIndex];
  location.headerValue = value;
  location.headerError = error;

  return updatedState;
};

const addHeaderReducer = (state, { locationIndex }) => {
  const updatedState = [...state];
  const location = updatedState[locationIndex];

  if (!location.headerValue) {
    location.headerError = 'requiredErrorMessage';
    return updatedState;
  }

  location.headerError = null;
  const newHeader = {
    headerId: generateRandomKey('header'),
    headerValue: location.headerValue,
    terms: [],
    editing: true,
    created: false,
  };

  location.searchTerms = [...(location.searchTerms || []), newHeader];
  location.headerValue = '';

  return updatedState;
};

const termReducer = (state, { locationIndex, headerIndex, value, error }) => {
  const updatedState = [...state];
  const location = updatedState[locationIndex];
  const header = location?.searchTerms[headerIndex];

  header.termValue = value;
  header.termError = error;

  return updatedState;
};

const addTermReducer = (state, { locationIndex, headerIndex }) => {
  const updatedState = [...state];
  const location = updatedState[locationIndex];
  const header = location.searchTerms[headerIndex];

  if (!header.termValue) {
    header.termError = 'requiredErrorMessage';
    return updatedState;
  }

  header.termError = null;
  const newTerm = {
    termId: generateRandomKey('term'),
    termValue: header.termValue,
  };

  header.terms = [...(header.terms || []), newTerm];
  header.termValue = '';
  return updatedState;
};

const markTermWithDeleted = (state, { locationIndex, headerIndex, termIndex }) => {
  const updatedState = [...state];
  const location = updatedState[locationIndex];
  const header = location.searchTerms[headerIndex];
  const term = header.terms[termIndex];
  term.isDeleted = true;

  return updatedState;
};

const markHeaderWithAction = (state, { locationIndex, headerIndex, action }) => {
  const updatedState = [...state];
  const location = updatedState[locationIndex];
  const header = location.searchTerms[headerIndex];
  if (action === 'edit') header['editing'] = !header['editing'];
  if (action === 'delete') header.isDeleted = true;
  return updatedState;
};

const replaceLocation = (state, { locationIndex, locationInfo }) => {
  const updatedState = [...state];
  updatedState[locationIndex] = locationInfo;
  return updatedState;
};

const searchTermsReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_LOCATION:
      return locationReducer(state, action.payload);
    case ACTIONS.CHANGE_NEW_HEADER:
      return headerReducer(state, action.payload);
    case ACTIONS.ADD_HEADER:
      return addHeaderReducer(state, action.payload);
    case ACTIONS.CHANGE_TERM:
      return termReducer(state, action.payload);
    case ACTIONS.ADD_TERM:
      return addTermReducer(state, action.payload);
    case ACTIONS.MARK_TERM_DELETED:
      return markTermWithDeleted(state, action.payload);
    case ACTIONS.MARK_HEADER_ACTION:
      return markHeaderWithAction(state, action.payload);
    case ACTIONS.ADD_NEW_STOCK:
      return [
        ...state,
        {
          locationName: '',
          locationId: 1,
          locationFullValue: {},
        },
      ];
    case ACTIONS.ADD_LOCATION:
      return [...action.payload];
    case ACTIONS.REPLACE_LOCATION:
      return replaceLocation(state, action.payload);
    default:
      return state;
  }
};

const useSearchTerms = () => {
  useTitle(strings.searchTerms);
  const [searchTerms, dispatch] = useReducer(searchTermsReducer, []);
  const storeDispatch = useDispatch();
  const countries = useSelector(state => state.masterDataReducer.searchTermsTemplate);
  const [selectedStocks, setSelectedStocks] = useState(new Set());
  const [countriesDropdown, setCountriesDropdown] = useState(countries || []);

  useEffect(() => {
    storeDispatch(fetchSearchTermConfigEnabledStocks());
    fetchSearchTerms();
  }, []);

  useEffect(() => {
    setCountriesDropdown(prev => {
      const alreadyUsedStocks =
        searchTerms?.filter(item => item?.locationValue > 0)?.map(item => item.locationValue) || [];
      return (
        countries?.map(country => ({
          ...country,
          stockInformation: country?.stockInformation?.filter(stock => !alreadyUsedStocks.includes(stock?.id)) || [],
        })) || []
      );
    });
  }, [searchTerms, countries]);

  const fetchSearchTerms = async () => {
    storeDispatch(changeLoader(true));
    try {
      const response = await getAllSearchTerms();
      dispatch({
        type: ACTIONS.ADD_LOCATION,
        payload: response,
      });
    } catch (err) {
      console.log('Failed to fetch search terms', err);
    } finally {
      storeDispatch(changeLoader(false));
    }
  };

  const onChangeLocation = useCallback(
    locationIndex => (value, field, fullValue) => {
      dispatch(
        createAction(ACTIONS.CHANGE_LOCATION, {
          locationIndex,
          value,
          field,
          fullValue,
        }),
      );
    },
    [],
  );

  const onChangeNewHeader = useCallback(
    locationIndex => value => {
      dispatch(
        createAction(ACTIONS.CHANGE_NEW_HEADER, {
          locationIndex,
          value,
        }),
      );
    },
    [],
  );

  const onAddHeader = useCallback(
    locationIndex => () => {
      dispatch(createAction(ACTIONS.ADD_HEADER, { locationIndex }));
    },
    [],
  );

  const onTermChange = useCallback(
    (locationIndex, headerIndex) => value => {
      dispatch(
        createAction(ACTIONS.CHANGE_TERM, {
          locationIndex,
          headerIndex,
          value,
        }),
      );
    },
    [],
  );

  const onAddTerm = useCallback(
    (locationIndex, headerIndex) => () => {
      dispatch(
        createAction(ACTIONS.ADD_TERM, {
          locationIndex,
          headerIndex,
        }),
      );
    },
    [],
  );

  const onDeleteTerm = useCallback(
    (locationIndex, headerIndex) => termIndex => {
      dispatch(
        createAction(ACTIONS.MARK_TERM_DELETED, {
          locationIndex,
          headerIndex,
          termIndex,
        }),
      );
    },
    [],
  );

  const onHeaderAction = useCallback(
    (locationIndex, headerIndex) => action => {
      console.log(locationIndex, headerIndex, action);
      //   dispatch(
      //     createAction(ACTIONS.MARK_HEADER_ACTION, {
      //       locationIndex,
      //       headerIndex,
      //       action,
      //     }),
      //   );
    },
    [],
  );

  const onAddStock = useCallback(() => {
    dispatch(createAction(ACTIONS.ADD_NEW_STOCK, {}));
  }, []);

  const validateSaving = (locIndex, locationSearchTerms) => {
    let exitSaving = false;
    if (!locationSearchTerms?.locationValue || locationSearchTerms?.locationValue <= 0) {
      dispatch({
        type: ACTIONS.CHANGE_LOCATION,
        payload: {
          locationIndex: locIndex,
          value: searchTerms.locationValue,
          fullValue: {},
          error: 'requiredErrorMessage',
        },
      });
      exitSaving = true;
    }
    if (!locationSearchTerms?.searchTerms?.length) {
      dispatch({
        type: ACTIONS.CHANGE_NEW_HEADER,
        payload: {
          locationIndex: locIndex,
          value: '',
          error: 'requiredErrorMessage',
        },
      });
      exitSaving = true;
    }
    // locationSearchTerms?.searchTerms?.find(item => !item?.terms?.length > 0)

    let emptyTermsHeader = locationSearchTerms?.searchTerms?.findIndex(item => !item?.terms?.length > 0 && !item?.isDeleted) 
    if(emptyTermsHeader !== null && emptyTermsHeader !== undefined && emptyTermsHeader !== -1){
      dispatch({
        type: ACTIONS.CHANGE_TERM,
        payload: {
          locationIndex: locIndex,
          headerIndex: emptyTermsHeader,
          value: '',
          error: 'requiredErrorMessage',
        },
      });
      exitSaving = true;
    }
    // Check if the headers have atleast on term which are not deleted
    emptyTermsHeader = locationSearchTerms.searchTerms?.findIndex(item => {
      if(item?.isDeleted){
        return false;
      }
      const counter = item?.terms?.reduce((counter, term) => (!term.isDeleted ? counter + 1 : counter), 0);
      return counter <= 0; 
    });
    if(emptyTermsHeader !== null && emptyTermsHeader !== undefined && emptyTermsHeader !== -1){
      dispatch({
        type: ACTIONS.CHANGE_TERM,
        payload: {
          locationIndex: locIndex,
          headerIndex: emptyTermsHeader,
          value: '',
          error: 'requiredErrorMessage',
        },
      });
      exitSaving = true;
    }
    return exitSaving;
  };

  /**
   * Key decisions:
   * - Every new header or search term is assigned an alphanumeric string ID using the `generateRandomKey` method.
   *   - If the ID can be converted to a number, it indicates that the item is already saved and sent by the backend.
   *   - Otherwise, it is considered a newly created header or search term.
   * - To avoid rendering issues with lists, items are not removed directly from the array during rendering.
   *   - Instead, the `isDeleted` flag is used to exclude them from being displayed.
   *   - Before sending data to the backend, items marked as `isDeleted` are filtered out.
   */
  const onSaveSearchTerms = async locIndex => {
    let locationSearchTerms = searchTerms[locIndex];
    if (validateSaving(locIndex, locationSearchTerms)) return;

    locationSearchTerms = {
      ...locationSearchTerms,
      searchTerms: locationSearchTerms?.searchTerms?.filter(header => {
        if (isNaN(parseInt(header?.headerId)) && header?.isDeleted) {
          return false;
        }
        return true;
      }),
    };

    const payload = {
      stockInformationId: locationSearchTerms?.locationValue ?? null,
      headerItems:
        locationSearchTerms?.searchTerms?.map(header => {
          const headerItem = {
            headerItemId: isNaN(parseInt(header?.headerId)) ? 0 : header.headerId,
            headerItemName: header?.headerValue ?? '',
            searchTerms: header.isDeleted
              ? []
              : header?.terms
                  ?.filter(term => isNaN(parseInt(term?.termId)) && !term?.isDeleted)
                  ?.map(val => val?.termValue) ?? [],
            deleteSearchTermIds: header.isDeleted
              ? header?.terms?.map(term => term?.termId) ?? []
              : header?.terms
                  ?.filter(term => !isNaN(parseInt(term?.termId)) && term?.isDeleted)
                  ?.map(val => val?.termId) ?? [],
          };
          return headerItem;
        }) ?? [],
    };
    try {
      storeDispatch(changeLoader(true));
      const response = await storeDispatch(saveOrEditSearchTermsForStock([payload]));
      storeDispatch(changeLoader(false));
      if (response?.data) {
        let data = transformSearchTermResponse(response);
        dispatch({
          type: ACTIONS.REPLACE_LOCATION,
          payload: {
            locationIndex: locIndex,
            locationInfo: data[0] || {},
          },
        });
      }
    } catch (err) {
      console.log('Failed to save search terms', err);
    }
  };

  const onHeaderOptionsClick = useCallback(
    (locIndex, headerIndex) => actionType => {
      dispatch(
        createAction(ACTIONS.MARK_HEADER_ACTION, {
          locationIndex: locIndex,
          headerIndex,
          action: actionType,
        }),
      );
    },
    [],
  );

  return {
    searchTerms,
    onAddHeader,
    onChangeNewHeader,
    onChangeLocation,
    onTermChange,
    onAddTerm,
    onDeleteTerm,
    onHeaderAction,
    countries,
    onAddStock,
    countriesDropdown,
    onSaveSearchTerms,
    onHeaderOptionsClick,
  };
};

export default useSearchTerms;
