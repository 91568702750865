import { scrollToTop } from 'library/utilities/scrollActions';
import { useEffect, useState } from 'react';
import strings from 'resources/locales/Translate';

const createInitialEquipmentRequest = data => {
  return {
    resourceType: data?.type || '',
    resourceTypeFullValue: data?.typeTranslated || null,
    resourceTypeError: '',
    productHierarchyId: data?.productHierarchy?.id || '',
    productHierarchyIdFullValue: data?.productHierarchy || null,
    productHierarchyIdError: '',
    systemClassId: data?.systemClass?.systemClassId || '',
    systemClassIdFullValue: data?.systemClass || null,
    systemMainComponentId: data?.systemMainComponent?.map(comp => comp.systemMainComponentId) || [],
    systemMainComponentIdFullValue: data?.systemMainComponent || null,
    systemMainComponentIdError: '',
    alternativeSystemMainComponentIds: data?.alternateMainComponents?.map(comp => comp.systemMainComponentId) || [],
    alternativeSystemMainComponentIdsFullValue: data?.alternateMainComponents || null,
    alternativeSystemMainComponentIdsError: '',
    additionalEquipment: data?.additionalEquipment || '',
    additionalEquipmentError: '',
    component: data?.component || '',
    componentError: '',
    sensors: data?.sensors?.map(item => item.staticCode) || [],
    sensorsFullValue: data?.sensors || null,
    sensorsError: '',
    rotationTables: data?.rotationTables?.map(item => item.staticCode) || [],
    rotationTablesFullValue: data?.rotationTables || null,
    rotationTablesError: '',
    assets: data?.assets?.map(item => item.staticCode) || [],
    assetsFullValue: data?.assets || null,
    assetsError: '',
    software: data?.softwareTools?.map(item => item.staticCode) || [],
    softwareFullValue: data?.softwareTools || null,
    softwareError: '',
    id: data?.id || null,
  };
};

export const useIqsBooking = ({
  fetchResourceTypesDropdownValues,
  fetchProductHeirarchyDropdownValues,
  requestId,
  saveRequestedEquipments,
  iqsrequestedEquipmentsData,
  history,
  toggleActionMessage
}) => {
  const [equipmentRequestList, setEquipmentRequestList] = useState([]);

  useEffect(() => {
    fetchResourceTypesDropdownValues();
    fetchProductHeirarchyDropdownValues('IQS_SSC_DE');
  }, []);

  useEffect(() => {
    if (iqsrequestedEquipmentsData?.length > 0) {
      const initialRequests = iqsrequestedEquipmentsData.map(item => createInitialEquipmentRequest(item));
      setEquipmentRequestList(initialRequests);
    } else {
      setEquipmentRequestList([createInitialEquipmentRequest()]);
    }
  }, [iqsrequestedEquipmentsData]);

  const addEquipmentRequest = () => {
    setEquipmentRequestList(prevState => [...prevState, createInitialEquipmentRequest()]);
  };

  const handleChangeValues = (value, field, fullValue, index) => {
    if (field === 'productHierarchyId') {
      return setEquipmentRequestList(prevState => {
        const updatedList = [...prevState];
        updatedList[index] = {
          ...updatedList[index],
          [field]: value,
          [`${field}FullValue`]: fullValue,
          [`${field}Error`]: '',
          systemClassId: '',
          systemClassIdFullValue: null,
          systemMainComponentId: [],
          systemMainComponentIdFullValue: null,
          alternativeSystemMainComponentIds: [],
          alternativeSystemMainComponentIdsFullValue: null,
        };
        return updatedList;
      });
    }

    if (field === 'systemClassId') {
      return setEquipmentRequestList(prevState => {
        const updatedList = [...prevState];
        updatedList[index] = {
          ...updatedList[index],
          [field]: value,
          [`${field}FullValue`]: fullValue,
          [`${field}Error`]: '',
          systemMainComponentId: [],
          systemMainComponentIdFullValue: null,
          alternativeSystemMainComponentIds: [],
          alternativeSystemMainComponentIdsFullValue: null,
        };
        return updatedList;
      });
    }
    setEquipmentRequestList(prevState => {
      const updatedList = [...prevState];
      updatedList[index] = {
        ...updatedList[index],
        [field]: value,
        [`${field}FullValue`]: fullValue,
        [`${field}Error`]: '',
      };
      return updatedList;
    });
  };

  const checkValidations = () => {
    let hasError = false;
    const checkError = equipmentRequestList.map(item => {
      const errors = {};
      if (!item.resourceType) {
        errors['resourceTypeError'] = 'requiredErrorMessage';
      }
      if (item.resourceType === 'SYSTEM') {
        if (!item.productHierarchyId) {
          errors['productHierarchyIdError'] = 'requiredErrorMessage';
        }
        if (!item.systemClassId) {
          errors['systemClassIdError'] = 'requiredErrorMessage';
        }
        if (item.systemMainComponentId.length === 0) {
          errors['systemMainComponentIdError'] = 'requiredErrorMessage';
        }
        if (item.alternativeSystemMainComponentIds.length === 0) {
          errors['alternativeSystemMainComponentIdsError'] = 'requiredErrorMessage';
        }
      } else {
        if (!item.component) {
          errors['componentError'] = strings.requiredErrorMessage;
        }
      }
      if (Object.keys(errors).length > 0 && hasError !== true) {
        hasError = true;
      }
      return { ...item, ...errors };
    });

    if (hasError) {
      setEquipmentRequestList(checkError);
    }
    return hasError;
  };

  const onSaveClick = () => {
    if (checkValidations()) {
      toggleActionMessage(true, 'error', 'formIsInvalid');
      scrollToTop(500);
      return;
    }

    const requestedEquipments = equipmentRequestList.map(equipment => ({
      id: equipment?.id ? equipment?.id : null,
      resourceType: equipment.resourceType,
      productHierarchyId: equipment.productHierarchyId ? equipment.productHierarchyId : null,
      systemClassId: equipment.systemClassId,
      systemMainComponentId: equipment.systemMainComponentId ? equipment.systemMainComponentId : [],
      alternativeSystemMainComponentIds: equipment.alternativeSystemMainComponentIds ? equipment.alternativeSystemMainComponentIds : [],
      additionalEquipment: equipment.additionalEquipment,
      sensors: equipment.sensors ? equipment.sensors : [],
      rotationTables: equipment.rotationTables ? equipment.rotationTables : [],
      assets: equipment.assets ? equipment.assets : [],
      software: equipment.software ? equipment.software : [],
      component: equipment.component,
    }));

    const dataToSend = {
      requestedEquipments: requestedEquipments,
      requestId: requestId,
    };
    saveRequestedEquipments(dataToSend);
  };

  const onAddBookingClick = () => {
    history.push(`/iqs-calendar?requestId=${requestId}`);
  };

  return {
    equipmentRequestList,
    addEquipmentRequest,
    handleChangeValues,
    onSaveClick,
    onAddBookingClick,
  };
};
