import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Uploader from 'library/common/commonComponents/Uploader/Uploader';
import { useUploadAttachmentPopup } from './UploadAttachmentPopup.hook';
import { connect } from 'react-redux';
import { StockControllerDelete } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { getStockUsers, uploadIqsAttachment } from 'modules/Stock/CreateStock/CreateStock.actions';
import { uploadNewKitAttachment } from 'modules/Kits/CreateZmccKit/CreateZmccKit.actions';
import { IQS_SSC_DC_STOCK_ALLOWED_FILE_TYPES } from 'library/utilities/constants';

export const UploadAttachmentPopup = ({
  id,
  user,
  attachmentCategory,
  uploadIqsAttachment,
  uploadNewKitAttachment,
  stockId,
  enableIOOptions,
  kitId,
  kitResourceAttachmentCategoriesList,
  getStockUsers,
  stockUsers,
  isEditUpdloadFile,
  editingData,
  setIsEditUploadFile,
  setEditingData,
}) => {
  const {
    addFile,
    getModel,
    uploadFormData,
    handleForm,
    files,
    onFileDelete,
    onSaveClick,
    onCloseClick,
    loading,
    error,
  } = useUploadAttachmentPopup({
    id,
    user,
    attachmentCategory,
    uploadIqsAttachment,
    stockId,
    enableIOOptions,
    uploadNewKitAttachment,
    kitId,
    kitResourceAttachmentCategoriesList,
    getStockUsers,
    stockUsers,
    isEditUpdloadFile,
    editingData,
    setIsEditUploadFile,
    setEditingData,
  });
  return (
    <Modal
      id={id}
      title={strings.uploadData}
      confirmAction={onSaveClick}
      confirmTitle={strings.confirm}
      loading={loading}
      onCloseClick={onCloseClick}
      className='upload-attachment-popup-width'
      newVersionUi={true}
      titleOfCancel={strings.cancel}
    >
      {!isEditUpdloadFile && (
        <>
          <p className='uploader-label-adjust mb-2'>{strings.selectFile}</p>
          <Uploader
            allFiles={[]}
            // mimeType={IQS_SSC_DC_STOCK_ALLOWED_FILE_TYPES}
            maxFiles={1}
            onFileAdd={addFile}
            text={strings.addImage}
            iconStyle='icon'
            uploaderStyle='adjust-uploader'
            isMultiple={false}
            isIqsAttachment={true}
            disabled={files?.length > 0 ? true : false}
          />
        </>
      )}
      {files?.length > 0 && (
        <div className='d-flex justify-content-between align-items-center display-fileName mt-2'>
          {files[0].name}
          <button onClick={onFileDelete} className='delete-button'>
            <StockControllerDelete />
          </button>
        </div>
      )}
      <div className='mt-4'>
        <FormComponent
          formName='UploadAttachmentForm'
          model={getModel()}
          formValue={uploadFormData.formData}
          onChange={handleForm}
        />
      </div>

      {error && <span className='text-danger error-block'>{error}</span>}
    </Modal>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, createStockReducer }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  attachmentCategory: createStockReducer.attachmentCategory,
  stockId: createStockReducer.stockDetails.id,
  stockUsers: createStockReducer.stockUsers,
});

export default connect(mapStateToProps, { uploadIqsAttachment, uploadNewKitAttachment, getStockUsers })(
  UploadAttachmentPopup,
);
