import React from 'react';
import { get } from 'lodash';
import cn from 'classnames';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { Link } from 'react-router-dom';
import { getResourceType } from '../IQSCalendar.actions';
import { RESOURCE_TYPES } from 'library/common/commonConstants/kitConstants';
import moment from 'moment';
import Popover from 'library/common/commonComponents/CustomPopover/Popover';

let groupOnlySlots = ['comments', 'systems', 'equipment', 'other', 'person'];
const COLLAPSABLE_PREFIX = 'collapsable__';

function ResourceItem({
  schedulerData,
  slot,
  hiddenResourceGroups,
  toggleExpandFunc,
  onChangeCheckbox,
  selectedSystem,
}) {
  const isGrouping = groupOnlySlots.includes(slot.slotId) || slot?.slotId?.includes(COLLAPSABLE_PREFIX);
  const isLocationComments = slot?.slotId?.includes('__comments');
  const { resources } = schedulerData;
  const actualSlot = resources.find(resource => resource.id === slot.slotId);
  let type = getResourceType(actualSlot?.type);
  let component = actualSlot.systemMainComponent;
  let serialNumber = actualSlot.serialNumber;
  let inventoryNumber = actualSlot.inventoryNumber;
  let dateOfManufacture = actualSlot.dateOfManufacture;
  let lasteMaintananceDate = actualSlot.lastMaintenance;
  let category = actualSlot.category;
  const locationGrouping = slot?.slotId?.includes(COLLAPSABLE_PREFIX);
  let isKit = actualSlot?.kitId > 0;
  let isOperator = actualSlot?.accountId > 0;

  const renderKitToolTip = () => {
    return (
      <>
        <div class='details'>
          <p className='heading'>
            <b>{slot.slotName}</b>
          </p>
          <table className='table table-borderless'>
            <tbody>
              <tr>
                <td>Type:</td>
                <td>{type}</td>
              </tr>
              {actualSlot?.type === RESOURCE_TYPES.SYSTEM && (
                <tr>
                  <td>Component:</td>
                  <td>{component}</td>
                </tr>
              )}
              {actualSlot?.type !== RESOURCE_TYPES.SYSTEM && (
                <tr>
                  <td>Component:</td>
                  <td>{category}</td>
                </tr>
              )}
              {serialNumber && (
                <tr>
                  <td>Serial Number:</td>
                  <td>{serialNumber}</td>
                </tr>
              )}
              {inventoryNumber && (
                <tr>
                  <td>Inventory Number:</td>
                  <td>{inventoryNumber}</td>
                </tr>
              )}
              {dateOfManufacture && (
                <tr>
                  <td>Date of Manufacture:</td>
                  <td>{moment(dateOfManufacture).format('DD-MM-YYYY')}</td>
                </tr>
              )}
              {lasteMaintananceDate && (
                <tr>
                  <td>Last Maintenance:</td>
                  <td>{moment(lasteMaintananceDate).format('DD-MM-YYYY')}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='d-flex justify-content-end'>
          <Link to={`/kit_details/${slot.slotId}`} target='_blank'>
            <button class='view-resource'>View Resource</button>
          </Link>
        </div>
      </>
    );
  };

  const renderPersonPopover = () => {
    const personName = `${actualSlot?.firstName} ${actualSlot?.lastName}`;
    const department = actualSlot?.department;
    const jobTitle = actualSlot?.jobTitle;
    const email = actualSlot?.email;
    const mobile = actualSlot?.mobile;
    return (
      <>
        <div class='details text-left'>
          <p className='heading'>
            <b>{personName}</b>
          </p>
          {department && <p className='person-detail'>{department}</p>}
          {jobTitle && <p className='person-detail'>{jobTitle}</p>}
          {email && (
            <p className='person-detail' style={{ textDecoration: 'underline' }}>
              <a href={`mailto:${email}`} style={{ color: '#000' }}>
                {email}
              </a>
            </p>
          )}
          {mobile && <p className='person-detail'>{mobile}</p>}
        </div>
      </>
    );
  };

  return (
    <div className='d-flex align-items-center w-100 text-left'>
      {isGrouping ? (
        <span className='mr-3 cusor-pointer' onClick={() => toggleExpandFunc(schedulerData, [slot.slotId])}>
          <Icon
            name={'arrow'}
            width={20}
            height={20}
            fill={'#000'}
            className={cn({
              'chevron-down': !hiddenResourceGroups.includes(slot.slotId),
              'chevron-up': hiddenResourceGroups.includes(slot.slotId),
            })}
          />
        </span>
      ) : null}
      <div className='d-flex justify-content-between align-items-center w-100 cursor-pointer'>
        <div className='d-flex'>
          {!isGrouping && !isLocationComments && (
            <div
              className={`resource-checkbox ${
                selectedSystem?.includes(slot.slotId) ? 'selected-resource-checkbox' : ''
              }`}
              onClick={() => onChangeCheckbox(slot.slotId, schedulerData)}
            ></div>
          )}
          <div
            style={{
              fontSize: isGrouping ? 14 : 12,
              fontWeight: isGrouping && !locationGrouping ? 'bold' : 'normal',
            }}
            className=''
          >
            {locationGrouping ? (
              <div className='d-flex align-items-center'>
                <span>{actualSlot?.locationName}</span>
                {actualSlot?.roomName && (
                  <>
                    <div className='bullet'></div>
                    <span className='font-weight-bold'>{actualSlot?.roomName}</span>
                  </>
                )}
              </div>
            ) : (
              slot.slotName
            )}
          </div>
        </div>

        {!isGrouping && (
          <div className={cn('cursor-pointer mr-3', { 'info-icon': !slot.slotId?.includes('comment') })}>
            <Popover
              content={isOperator ? renderPersonPopover() : renderKitToolTip()}
              popoverInnerClassName='resources-tooltip-card'
            >
              {!slot.slotId?.includes('comment') && <span>i</span>}
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResourceItem;
