import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import CreateKit from './CreateKit.component';
import { fetchKitDetail, updateFormCreateKit, resetCreateKit, saveCreateKit } from './CreateKit.actions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { deletereparationSlotForKit } from '../KitDetails/KitDetailsAction';
import { addUpdateNewComponent, fetchNewComponentsList, deleteNewComponent, updateForm } from '../CreateZmccKit/CreateZmccKit.actions';

const mapStateToProps = ({ appActionsReducer, authReducer, createKitReducer, languageReducer, masterDataReducer, createZmccKitReducer }) => ({
  appActions: appActionsReducer,
  formValue: {
    generalInformationForm: createKitReducer.generalInformationForm,
    generalInformationKitForSaleForm: createKitReducer.generalInformationKitForSaleForm,
    furtherImportantComponentForm: createKitReducer.furtherImportantComponentForm,
    salesComponentForm: createKitReducer.salesComponentForm,
    freeAccessoriesForm: createKitReducer.freeAccessoriesForm,
    kitImages: createKitReducer.kitImages,
    kitImagesToDelete: createKitReducer.kitImagesToDelete,
    kitAttachments: createKitReducer.kitAttachments,
    kitAttachmentsToDelete: createKitReducer.kitAttachmentsToDelete,
    vrDetalisForm: createKitReducer.vrDetalisForm,
  },
  kitDetails: createKitReducer.kitDetails,
  sellOffCurrency: masterDataReducer.sellOffCurrency,
  kitImages: createKitReducer.kitImages,
  kitInformationId: createKitReducer.kitInformationId,
  language: languageReducer,
  user: authReducer.user,
  newComponentsForm: createZmccKitReducer.newComponentsForm,
  mainComponentForm: createKitReducer.mainComponentForm,
  newComponentsList: createZmccKitReducer.newComponentsList,
  componentsFetchedFromIbase: createZmccKitReducer.componentsFetchedFromIbase,
  newComponentsList: createZmccKitReducer.newComponentsList,
  ibaseComponentsList: createZmccKitReducer.ibaseComponentsList
});

export default connect(mapStateToProps, {
  fetchMasterData,
  fetchKitDetail,
  updateFormCreateKit,
  saveCreateKit,
  resetCreateKit,
  toggleActionMessage,
  deletereparationSlotForKit,
  addUpdateNewComponent, 
  fetchNewComponentsList, 
  deleteNewComponent ,
  updateForm
})(CreateKit);
