import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useCompanyPopup } from './CompanyPopup.hook';
import { connect } from 'react-redux';
import { fetchIndustrialSubSegments } from 'modules/IqsNewRequest/IqsNewRequest.actions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

export const CompanyPopup = ({
  id,
  allDropdownData,
  fetchIndustrialSubSegments,
  industrialSubSegments,
  setCompanyList,
  companyList,
  selctedCompannyData,
  setIsCompanyEditing,
  isCompanyEditing,
  setDataForCompany,
  countries,
  toggleActionMessage,
}) => {
  const { getcompanyPopupModel, handleForm, companyPopupForm, onCloseClick, onSaveClick } = useCompanyPopup({
    id,
    allDropdownData,
    fetchIndustrialSubSegments,
    industrialSubSegments,
    setCompanyList,
    companyList,
    selctedCompannyData,
    setIsCompanyEditing,
    isCompanyEditing,
    setDataForCompany,
    countries,
    toggleActionMessage,
  });
  return (
    <div>
      <div>
        <Modal
          id={id}
          title={strings.company}
          confirmAction={onSaveClick}
          confirmTitle={strings.confirm}
          //   loading={loading}
          onCloseClick={onCloseClick}
          className='add-location-comment-popup-width'
          newVersionUi={true}
          titleOfCancel={strings.cancel}
        >
          <div className='mt-4'>
            <FormComponent
              formName='companyPopupForm'
              model={getcompanyPopupModel()}
              formValue={companyPopupForm.formData}
              onChange={handleForm}
            />
          </div>

          {/* {error && <span className='text-danger error-block'>{error}</span>} */}
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, iqsRequestReducer, stocksListReducer }) => ({
  user: authReducer.user,
  allDropdownData: iqsRequestReducer.allDropdownData,
  industrialSubSegments: iqsRequestReducer.industrialSubSegments,
  countries: stocksListReducer.countriesWithActiveStocks,
});

export default connect(mapStateToProps, { fetchIndustrialSubSegments, toggleActionMessage })(CompanyPopup);
