import React from 'react';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';

function AddSearchTermHeader({ onChange = () => {}, onAdd = () => {}, value = '', error = null, disabled = false }) {
  return (
    <div className='d-flex'>
      <InputField
        value={value}
        label='Header'
        className='term-input-container mr-3'
        onChange={onChange}
        error={error}
        disabled={disabled}
        isRequired={true}
      />
      <Button
        value={strings.addHeader}
        iconName='plus'
        bgFill={false}
        newVersionUi={true}
        styleClass={`add-btn-plus add-header  ${error ? 'align-self-center' : 'mt-3'}`}
        onClick={onAdd}
        disabled={disabled}
      />
    </div>
  );
}

export default AddSearchTermHeader;
