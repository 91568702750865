import React from 'react';
import moment from 'moment';
import { ViewTypes } from 'library/common/commonComponents/ReactBigScheduler';
import Popover from 'library/common/commonComponents/CustomPopover/Popover';
import strings from 'resources/locales/Translate';

function ZmccRequestSlot({
  event,
  schedulerData,
  height,
  className,
  zmccCurrentViewType,
  slotBackgroundColor = '',
  mustBeWidth,
  mainSlotPopoverContent,
}) {
  const { postPreparationPeriod, prePreparationPeriod, suggestedDateFrom } = event;
  const pre = Number(prePreparationPeriod) || 0;
  const post = Number(postPreparationPeriod) || 0;

  const suggestedDate = moment(suggestedDateFrom);
  const schedulerStartDate = moment(schedulerData.startDate);
  const schedulerEndDate = moment(schedulerData.endDate);

  const prePreparationStartDate = moment.max(suggestedDate.clone().subtract(pre, 'days'), schedulerStartDate);
  const prePreparationEndDate = moment.min(suggestedDate.clone().subtract(pre > 0 ? 1 : 0, 'days'), schedulerEndDate);

  let preSlotWidth = prePreparationEndDate.diff(prePreparationStartDate, 'days');
  preSlotWidth = (preSlotWidth >= 0) ? preSlotWidth + 1 : 0;

  let isCurrent = schedulerStartDate.month() === suggestedDate.month();
  if (zmccCurrentViewType === ViewTypes.WeekDay) {
    isCurrent = schedulerStartDate.week() === suggestedDate.week();
  } else if (zmccCurrentViewType === ViewTypes.Day) {
    isCurrent = schedulerStartDate.isSame(suggestedDate, 'day');
    preSlotWidth = isCurrent ? 0 : ((pre > 0 && prePreparationStartDate.isBefore(suggestedDate)) ? 1 : 0);
  }

  const cellWidth = zmccCurrentViewType === ViewTypes.Month ? schedulerData.getContentCellWidth() : schedulerData.getContentCellWidth() * 2 * 11;
  const postSlotTotalWidth = mustBeWidth - (((pre > 0 ? 1 : 0) * preSlotWidth * cellWidth) + (isCurrent ? cellWidth : 0)) || 0;

  const renderPreparationPopoverContent = () => {
    return (
      <>
        <p>
          {strings.prePreprationPeriod}: {pre} day(s)
        </p>
        <p>Request: #{event.transactionsId}</p>
      </>
    );
  };

  const renderPostPreparationPopoverContent = () => {
    return (
      <>
        <p>
          {strings.postPreparationPeriod}: {post} day(s)
        </p>
        <p>Request: #{event.transactionsId}</p>
      </>
    );
  };

  return (
    <div
      className={className}
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: `${pre || post ? 'rgba(154, 155, 156, 0.7)' : '#fff'}`,
        height: height,
        overflow: 'hidden',
        borderRadius: zmccCurrentViewType === ViewTypes.Month ? 25 : 0,
      }}
    >
      {pre ? (
        <div style={{ width: preSlotWidth * cellWidth, height: height, flexShrink: 0 }}>
          <Popover content={renderPreparationPopoverContent()} popoverInnerClassName='zmcc-popover-inner'>
            <div style={{ width: '100%', height: height }}></div>
          </Popover>
        </div>
      ): null}
      <Popover content={mainSlotPopoverContent} popoverInnerClassName='zmcc-popover-inner'>
        <div
          style={{
            width: cellWidth * (isCurrent ? 1 : 0),
            height: height,
            backgroundColor: isCurrent ? slotBackgroundColor : 'transparent',
            flexShrink: 0,
            borderRadius: zmccCurrentViewType === ViewTypes.Month ? 25 : 0,
            position: 'relative',
            textAlign: 'center',
          }}
        >
          {(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) && isCurrent && (
            <p className='display-value-for-booked-kit' style={{ lineHeight: `${height}px` }}>
              {event.title}
            </p>
          )}
        </div>
      </Popover>
      {post ? (
        <div style={{ width: postSlotTotalWidth, height: height, flexShrink: 0 }}>
          <Popover content={renderPostPreparationPopoverContent()} popoverInnerClassName='zmcc-popover-inner'>
            <div style={{ width: postSlotTotalWidth, height: height }}></div>
          </Popover>
        </div>
      ): null}
    </div>
  );
}

export default ZmccRequestSlot;
