import { useEffect, useState } from 'react';
import { addCommentModel } from './AddLocationComment.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import moment from 'moment';
import * as $ from 'jquery';
import strings from 'resources/locales/Translate';
import { DATE_FORMAT } from 'library/common/commonComponents/ReactBigScheduler';

export const useAddLocationComment = ({
  id,
  iqsCommentLocations,
  fetchIqsLocations,
  createOrEditIqsComment,
  selectedCommentEvent,
  setSelectedCommentEvent,
  selectedDate,
  deleteLocationComment,
  iqsCommentsLocationsIds,
}) => {
  const [locationCommentFormData, setLocationCommentFormData] = useState({ formData: {} });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchIqsLocations();
  }, [fetchIqsLocations]);

  useEffect(() => {
    if (selectedCommentEvent?.commentId) {
      setDataForEdit();
    }
  }, [selectedCommentEvent]);

  const setDataForEdit = () => {
    const filterLocation = iqsCommentLocations.filter(
      item => item.stockInformation && item.stockInformation.length > 0,
    );
    let locationCommentStock = null;

    for (const location of filterLocation) {
      locationCommentStock = location.stockInformation.find(stock => stock.id === selectedCommentEvent.stockId);
      if (locationCommentStock) {
        break;
      }
    }

    setLocationCommentFormData({
      formData: {
        title: selectedCommentEvent.title,
        description: selectedCommentEvent.description,
        eventStart: selectedCommentEvent.start,
        eventEnd: selectedCommentEvent.end,
        stockId: selectedCommentEvent.stockId,
        stockIdFullValue: locationCommentStock,
        publicHoliday: selectedCommentEvent.publicHoliday ? 'YES' : 'NO',
      },
    });
  };

  const handleForm = ({ values, field }) => {
    setError('');
    setLocationCommentFormData({
      ...locationCommentFormData,
      formData: {
        ...locationCommentFormData.formData,
        ...values,
      },
    });
  };

  const addLocationCommentModel = () => {
    let model = [...addCommentModel];
    model[4].options = iqsCommentLocations;
    return model;
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: locationCommentFormData,
      model: addLocationCommentModel(),
    });

    if (!validForm.isFormValid) {
      return setLocationCommentFormData(validForm);
    }
    if (moment(validForm.formData.eventEnd).isSameOrBefore(moment(validForm.formData.eventStart))) {
      return setError(strings.dateErrorMessage);
    }
    const { title, description, eventStart, eventEnd, stockId, publicHoliday } = validForm.formData;
    const data = {
      commentId: selectedCommentEvent?.commentId ? selectedCommentEvent?.commentId : null,
      title: title,
      description: description,
      eventStart: moment(eventStart).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      eventEnd: moment(eventEnd).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      stockId: stockId,
      publicHoliday: publicHoliday === 'YES' ? true : false,
    };
    setLoading(true);
    const res = await createOrEditIqsComment(data);
    setLoading(false);
    if (!res.success) {
      if (res?.error?.response?.status === 422 || res?.error?.response?.status === 403) {
        setError(res.error.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      onCloseClick();
    }
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setLocationCommentFormData({ formData: {} });
    setSelectedCommentEvent(null);
    setError('');
  };

  const onDeleteClick = async () => {
    const locationIds = { ids: iqsCommentsLocationsIds.comments.map(comment => comment.stockId) };
    const dispalyStartDate = moment(selectedDate).startOf('month').format(DATE_FORMAT);
    const dispalyEndDate = moment(selectedDate).endOf('month').format(DATE_FORMAT);
    setDeleteLoading(true);
    const res = await deleteLocationComment(
      selectedCommentEvent?.commentId,
      locationIds,
      dispalyStartDate,
      dispalyEndDate,
    );
    setDeleteLoading(false);
    if (!res.success) {
      if (
        res?.error?.response?.status === 422 ||
        res?.error?.response?.status === 403 ||
        res?.error?.response?.status === 404
      ) {
        setError(res.error.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      onCloseClick();
    }
  };
  return {
    addLocationCommentModel,
    handleForm,
    locationCommentFormData,
    onSaveClick,
    onCloseClick,
    error,
    loading,
    onDeleteClick,
    deleteLoading,
  };
};
