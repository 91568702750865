import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import Information from './Information.component';
import {
  fetchSystemClassData,
  fetchSystemMainComponentData,
  fetchUniqueIBaseValue,
  saveCreateKit,
  updateFormCreateKit,
  saveSaleKit,
  fetchActiveUnitDate,
  fetchdivisionList,
  checkErpStatus,
  refurbishmentFessStatus,
  getKitInfoByIbase,
  getStockSearchTerms,
} from '../../CreateKit.actions';

const mapStateToProps = ({ createKitReducer, masterDataReducer, languageReducer, authReducer, kitDetailsReducer }) => ({
  formValue: {
    assignmentForm: createKitReducer.assignmentForm,
    mainComponentForm: createKitReducer.mainComponentForm,
    availabilityForm: createKitReducer.availabilityForm,
    defaultPeriodsForm: createKitReducer.defaultPeriodsForm,
    packageDimensionsForm: createKitReducer.packageDimensionsForm,
    kitStatusForm: createKitReducer.kitStatusForm,
  },
  kitInformationId: createKitReducer.kitInformationId,
  kitDetails: createKitReducer.kitDetails,
  language: languageReducer.language,
  masterData: masterDataReducer,
  user: authReducer.user,
  divisionsList: createKitReducer.divisionsList,
  erpStatus: createKitReducer.erpStatus,
  refurbishmentStatus: createKitReducer.refurbishmentStatus,
  activeTransactionLoanOrderData: kitDetailsReducer.activeTransactionLoanOrderData,
  stockSearchTerms: createKitReducer.stockSearchTerms,
});

export default connect(mapStateToProps, {
  fetchSystemClassData,
  fetchSystemMainComponentData,
  fetchUniqueIBaseValue,
  saveCreateKit,
  updateFormCreateKit,
  fetchMasterData,
  saveSaleKit,
  toggleActionMessage,
  fetchActiveUnitDate,
  fetchdivisionList,
  checkErpStatus,
  refurbishmentFessStatus,
  getKitInfoByIbase,
  getStockSearchTerms,
})(Information);
