import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { useEditBookingPopup } from './EditBookingPopup.hook';
import Section from 'library/common/commonComponents/Section/Section';
import { connect } from 'react-redux';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { editBooking, getKitsByLocationIds } from '../../IQSCalendar.actions';
import Icon from 'library/common/commonComponents/Icon/Icon';

export const EditBookingPopup = ({
  id,
  iqsBookingDropdownData,
  editBookedData,
  getKitsByLocationIds,
  countries,
  editBooking,
  filters,
}) => {
  const {
    informationModel,
    otherModel,
    requestModel,
    handleForm,
    editBookingForm,
    error,
    onCloseClick,
    eventsData,
    onDeleteClick,
    getAddLocationSystemModel,
    onAddCLick,
    addSystemFrom,
    handleAddSystemForm,
    onSaveClick,
    loading,
  } = useEditBookingPopup({
    id,
    iqsBookingDropdownData,
    editBookedData,
    getKitsByLocationIds,
    countries,
    editBooking,
    filters,
  });

  return (
    <Modal
      id={id}
      title={strings.editBooking}
      confirmAction={onSaveClick}
      confirmTitle={strings.save}
      loading={loading}
      onCloseClick={onCloseClick}
      className='add-location-comment-popup-width'
      newVersionUi={true}
      titleOfCancel={strings.close}
      extraButton={false}
    >
      <div className='col-12 p-0'>
        <Section sectionTitle={strings.information}>
          <div className='mt-3'>
            <FormComponent
              model={informationModel()}
              formName='informationForm'
              formValue={editBookingForm.formData}
              onChange={handleForm}
            />
          </div>
        </Section>
      </div>
      <div className='col-12 mt-4 p-0'>
        <Section sectionTitle={strings.information}>
          <div className='mt-3'>
            <FormComponent
              model={requestModel()}
              formName='requestForm'
              formValue={editBookingForm.formData}
              onChange={handleForm}
            />
          </div>
        </Section>
      </div>
      <div lassName='col-12 mt-4 p-0'>
        <Section sectionTitle={strings.resources}>
          <div className='mt-3'>
            {eventsData?.map((item, index) => {
              return (
                <div key={index} style={{ marginBottom: '20px' }} className='d-flex justify-content-between'>
                  <div>
                    <div className='selected-resource' style={{ fontWeight: '700' }}>
                      {item.kitName || `${item.firstName} ${item.lastName}`}
                    </div>

                    {!item?.accountId && (
                      <div className='selected-resource' style={{ fontWeight: '400' }}>
                        {`${item.resourceTypeTranslated}, ${item.kitName}`}
                      </div>
                    )}
                    {!item?.accountId && (
                      <div className='selected-resource' style={{ fontWeight: '400' }}>
                        {item.locationName}, {item.roomName}
                      </div>
                    )}
                    {item?.accountId && (
                      <div className='selected-resource' style={{ fontWeight: '400' }}>
                        {'Person'}, {`${item.firstName} ${item.lastName}`}
                      </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='popover-icons-display mr-3'
                      onClick={() => onDeleteClick(index)}
                      style={{ backgroundColor: '#fff' }}
                    >
                      <Icon name='deleteOutline' height={14} width={14} viewBox='0 0 14 14' fill='#0088d0' />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='mt-3 d-flex'>
            <div className='col-11 pl-0'>
              <FormComponent
                model={getAddLocationSystemModel()}
                formName='addLocationSystemForm'
                formValue={addSystemFrom.formData}
                onChange={handleAddSystemForm}
              />
            </div>

            <div className='col-2 p-0'>
              {/* <button onClick={onAddCLick}>{strings.add}</button> */}
              <button className='edit-booked-event-button' onClick={onAddCLick}>
                <Icon name='plus' height={20} width={20} fill='#0088d0' />
              </button>
            </div>
          </div>
        </Section>
      </div>
      <div className='col-12 mt-4 p-0'>
        <Section sectionTitle={strings.information}>
          <div className='mt-3'>
            <FormComponent
              model={otherModel()}
              formName='otherForm'
              formValue={editBookingForm.formData}
              onChange={handleForm}
            />
          </div>
        </Section>
      </div>

      {error && <span className='text-danger error-block'>{error}</span>}
    </Modal>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, calendar, masterDataReducer }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  editBookedData: calendar.editBookedData,
  iqsBookingDropdownData: calendar.iqsBookingDropdownData,
  countries: masterDataReducer.countries,
});

export default connect(mapStateToProps, { getKitsByLocationIds, editBooking })(EditBookingPopup);
