import { withRouter } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

import AppRoutes from 'main/RouteContainer';
import ScrollToTop from 'library/common/commonComponents/ScrollToTop/ScrollToTop';
import Header from 'library/common/commonComponents/Header/Header';
import Footer from 'library/common/commonComponents/Footer';
import NotificationBar from 'library/common/commonComponents/NotificationBar';
import Tooltip from 'library/common/commonComponents/Tooltip';
import "react-big-calendar/lib/css/react-big-calendar.css"

import ErrorBoundary from './ErrorBoundaries';

const App = ({ history, loader, location, isLoggedIn, user }) => (

  <div className='App'>
    <Header history={history} isLoggedIn ={isLoggedIn} user={user} />
    <ScrollToTop />
    <ErrorBoundary history={history} location={location}>
      <AppRoutes/>
    </ErrorBoundary>
    <Footer />
    <Tooltip />
    {isLoggedIn && <NotificationBar />}
    {loader.isLoading && <div className='loader' />}
  </div>
);

const mapStateToProps = state => {
  return {
    loader: state.appActionsReducer,
    user: state.authReducer.user,
    isLoggedIn: state.authReducer.isLoggedIn,
  };
};

export default withRouter(connect(mapStateToProps)(App));
