import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import {isEqual} from 'lodash';

import strings from 'resources/locales/Translate';

import InputField from '../InputField/InputField';
import Dropdown from '../Dropdown/Dropdown';
import RadioButton from '../RadioButton/RadioButton';
import TextArea from '../TextArea/TextArea';
import InputGroup from '../InputGroup/InputGroup';
import Checkbox from '../Checkbox/Checkbox';
import DateInput from '../DateInput/DateInput';

export class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.dropdown = {};
    this.dates = {};
    this.textarea = {};
    this.state = {
      formData: {},
      isFormValid: false,
      updated: false,
    };
  }

  componentDidMount() {
    this.createFormData();
  }

  componentDidUpdate(prevProps) {
    const { clear, value, language } = this.props;

    if (language !== prevProps.language) {
      this.createFormData();
    }

    if (clear && clear !== prevProps.clear) {
      if (this.props.clear) {
        this.setState({formData: {}, isFormValid: false}, () => this.createFormData());
      }
    }

    if (value && !isEqual(value, prevProps.value)) {
      this.createFormData();
    }
  }

  getFormData = () => {
    const {model} = this.props;
    let isValid = true;
    model.forEach(element => {
      if (element.required) {
        isValid = isValid && !!this.state.formData[element.field] && !this.state.formData[element.field + 'Error'];
      }
    });
    this.setState({isFormValid: isValid}, () => {
      return {formData: this.state.formData, isValid, isFormValid: isValid};
    });
    return {formData: this.state.formData, isValid, isFormValid: isValid};
  }

  resetFormData = () => {
    const {model, value} = this.props;
    let formData = {};
    model.forEach(item => {
      if (item.type === 'checkbox') {
        formData[item.field] = value && value[item.field] ? value[item.field] : false;
      } else if (item.initialValue && item.initialValue[item.idKey]) {
        formData[item.field] = item.initialValue[item.idKey];
      } else if (item.initialValue) {
        formData[item.field] = item.initialValue;
      } else {
        formData[item.field] = value && value[item.field] ? value[item.field] : '';
      }
      formData[item.field + 'Error'] = value && value[item.field] ? false : !!item.required;
    });
    this.validateFormAndSendValue(formData, null, !this.props.onBlurUpdate);
    if (Object.keys(this.textarea).length) {
      Object.keys(this.textarea).forEach(key => {
        this.textarea[key].setState(state => ({
          error: false,
          errorMessage: null,
          isInfoOpen: false,
          value: '',
        }));
      });
    }
    if (Object.keys(this.dropdown).length) {
      const {model} = this.props;
      Object.keys(this.dropdown).forEach(key => {
        if (model[key].type === 'dropdown' && !model[key].multiSelect && model[key].initialValue) {
          this.dropdown[key].setState(state => ({
            ...state,
            selectedSingleValue: model[key].initialValue,
          }));
        }
      })
    }
  }

  createFormData() {
    const {model, value} = this.props;
    let objectOfFields = {};
    model.forEach(item => {
      if (item.type === 'checkbox') {
        objectOfFields[item.field] = value && value[item.field] ? value[item.field] : false;
      } else {
        if (value) {
          objectOfFields[item.field] = value[item.field] === 0 ? value[item.field] : value && value[item.field] ? value[item.field] : '';
        }
        else {
          objectOfFields[item.field] = value && value[item.field] ? value[item.field] : '';
        }

      }
      if (value) {
        objectOfFields[item.field + 'Error'] = value[item.field] === 0 ? false : value && value[item.field] ? false : !!item.required;
      }
      else {
        objectOfFields[item.field + 'Error'] = value && value[item.field] ? false : !!item.required
      }
    });
    // This will be used in future
    // this.validateFormAndSendValue(objectOfFields, null, !this.props.onBlurUpdate);

    this.validateFormAndSendValue(objectOfFields, null);
  }

  handleInputValueChange = (onBlurUpdate) => (value, field, type = '') => {
    let formData = {...this.state.formData};
    if (type === 'date') {
      if (value === '') formData[field] = '';
      else formData[field] = moment(value).format('YYYY-MM-DD HH:mm:ss');
    } else {
      formData[field] = value;
    }
    this.validateFormAndSendValue(formData, field, onBlurUpdate);
  };

  handleInputFieldError = (error, field) => {
    let formData = this.state.formData;
    formData[field + 'Error'] = error.error;
    this.validateFormAndSendValue(formData, field, true);
  };

  validateFormAndSendValue(formData, lastEditedField, onBlurUpdate) {
    const {model} = this.props;
    let isValid = true;

    model.forEach(element => {
      isValid = isValid && !formData[element.field + 'Error'];
    });

    this.setState({formData, isFormValid: isValid}, () => {
      // This will be used in future
      // if (this.props.onChange && !onBlurUpdate) {
      //  const dataToSend = {
      //     formData,
      //     isFormValid: this.state.isFormValid,
      //   };
      //   this.props.onChange(dataToSend, lastEditedField);
      // }

      const dataToSend = {
        formData,
        isFormValid: this.state.isFormValid,
      };
      this.props.onChange(dataToSend, lastEditedField);
    });
  }

  renderFormField() {
    const {model, value, clear, language, doValidate, resetValidation, disableForm} = this.props;
    let arrayOfFields = [];
    model.forEach((item, key) => {
      switch (item.type) {
        case 'text':
        case 'email':
        case 'number':
          arrayOfFields.push(
            <div key={key} className={`form-group ${item.styleClass}`}>
              <InputField
                dataTest={item.dataTest}
                label={strings[item.label]}
                type={item.type}
                field={item.field}
                validators={item.validators}
                isRequired={item.required}
                extraProps={item.extraProps}
                value={value && value[item.field]}
                placeholder={strings[item.placeholder]}
                showExtraInfo={item.showExtraInfo}
                infoMessage={item.infoMessage}
                doValidateForm={doValidate}
                resetValidation={resetValidation}
                icon={item.icon}
                clear={clear}
                disabled={disableForm ? disableForm : item.disabled}
                onError={this.handleInputFieldError}
                onChange={this.handleInputValueChange(this.props.onBlurUpdate)}
                onBlur={this.handleInputValueChange(!this.props.onBlurUpdate)}
              />
            </div>,
          );
          break;
        case 'inputgroup':
          arrayOfFields.push(
            <div key={key} className={`form-group ${item.styleClass}`}>
              <InputGroup
                dataTest={item.dataTest}
                label={strings[item.label]}
                type={item.type}
                field={item.field}
                validators={item.validators}
                isRequired={item.required}
                extraProps={item.extraProps}
                value={value && value[item.field]}
                placeholder={strings[item.placeholder]}
                inputGroupText={item.isAppend ? strings[item.inputGroupText] : item.inputGroupText}
                showExtraInfo={item.showExtraInfo}
                infoMessage={item.infoMessage}
                icon={item.icon}
                doValidateForm={doValidate}
                resetValidation={resetValidation}
                clear={clear}
                disabled={disableForm ? disableForm : item.disabled}
                onError={this.handleInputFieldError}
                onChange={this.handleInputValueChange(this.props.onBlurUpdate)}
                onBlur={this.handleInputValueChange(!this.props.onBlurUpdate)}
                isAppend={item.isAppend}
              />
            </div>,
          );
          break;
        case 'textarea':
          arrayOfFields.push(
            <div key={key} className={`form-group ${item.styleClass}`}>
              <TextArea
                ref={ref => {
                  this.textarea[key] = ref;
                }}
                dataTest={item.dataTest}
                label={strings[item.label]}
                type={item.type}
                field={item.field}
                clear={clear}
                validators={item.validators}
                isRequired={item.required}
                extraProps={item.extraProps}
                doValidateForm={doValidate}
                resetValidation={resetValidation}
                value={
                  value && value[item.field] ? value[item.field] : this.state.formData[item.field]
                }
                placeholder={strings[item.placeholder]}
                showExtraInfo={item.showExtraInfo}
                infoMessage={item.infoMessage}
                onError={this.handleInputFieldError}
                onChange={this.handleInputValueChange(this.props.onBlurUpdate)}
                onBlur={this.handleInputValueChange(!this.props.onBlurUpdate)}
              />
            </div>,
          );
          break;
        case 'dropdown':
          arrayOfFields.push(
            <div key={key} className={`form-group ${item.styleClass}`}>
              <Dropdown
                key={key}
                ref={ref => {
                  this.dropdown[key] = ref;
                }}
                dataTest={item.dataTest}
                data={item.options}
                hasSection={item.hasSection}
                multiSelect={item.multiSelect}
                filter={item.filter}
                fetchValueFrom={item.fetchValueFrom}
                isRequired={item.required}
                placeholder={strings[item.placeholder]}
                label={strings[item.label]}
                field={item.field}
                idKey={item.idKey}
                displayValue={item.displayValue}
                validators={item.validators}
                value={value && value[item.field]}
                clearOption={item.clearOption}
                disabled={disableForm ? disableForm : item.disabled}
                excludeData={item.excludeData}
                optionsArrayKey={item.optionsArrayKey}
                titleDisplay={item.titleDisplay}
                showExtraInfo={item.showExtraInfo}
                infoMessage={item.infoMessage}
                doValidateForm={doValidate}
                resetValidation={resetValidation}
                selectAllPlaceholder={item.selectAllPlaceholder}
                clear={clear}
                onChangeValue={this.handleInputValueChange(false)}
                onError={this.handleInputFieldError}
              />
            </div>,
          );
          break;
        case 'radio-button':
          arrayOfFields.push(
            <div key={key} className={`form-group ${item.styleClass}`}>
              <RadioButton
                dataTest={item.dataTest}
                data={item.options}
                field={item.field}
                label={strings[item.label]}
                validators={item.validators}
                isRequired={item.required}
                value={value && value[item.field]}
                showExtraInfo={item.showExtraInfo}
                infoMessage={item.infoMessage}
                doValidateForm={doValidate}
                resetValidation={resetValidation}
                clear={clear}
                disabled={disableForm ? disableForm : item.disabled}
                onError={this.handleInputFieldError}
                onChange={this.handleInputValueChange(false)}
                showClearIcon={item.showClearIcon}
              />
            </div>,
          );
          break;
        case 'checkbox':
          arrayOfFields.push(
            <div key={key} className={`form-group ${item.styleClass}`}>
              <Checkbox
                dataTest={item.dataTest}
                field={item.field}
                label={strings[item.label]}
                selected={value && value[item.field]}
                disabled={disableForm ? disableForm : item.disabled}
                onClick={this.handleInputValueChange(false)}
              />
            </div>,
          );
          break;
        case 'date':
          arrayOfFields.push(
            <div key={key} className={`form-group ${item.styleClass}`}>
              <DateInput
                ref={ref => {
                  this.dates[item.field] = ref;
                }}
                dataTest={item.dataTest}
                disabled={disableForm ? disableForm : !!item.disabled}
                field={item.field}
                placeholder={strings[item.placeholder]}
                label={strings[item.label] || item.label}
                additionalLabel={item.additionalLabel}
                footerLabel={item.footerLabel}
                maxDate={item.maxDate}
                minDate={item.minDate}
                mainContainerStyle={item.mainContainerStyle}
                locale={language.locale}
                selectedDate={value && value[item.field]}
                validators={item.validators}
                isRequired={item.required}
                hideOptionalText={item.hideOptionalText}
                showExtraInfo={item.showExtraInfo}
                infoMessage={item.infoMessage}
                dateFormat={item.dateFormat}
                minDetail={item.minDetail}
                maxDetail={item.maxDetail}
                showClearIcon={item.showClearIcon}
                doValidateForm={doValidate}
                resetValidation={resetValidation}
                isClearable={item.isClearable}
                {...item.extraProps}
                onChangeDate={date => this.handleInputValueChange(false)(date, item.field, item.type)}
                onError={this.handleInputFieldError}
                activeStartDate={item.activeStartDate}
                isOpenDateModal={item.isOpenDateModal}
                onClick={(e) => this.props.onClick(e, item)}
              />
            </div>,
          );
          break;
        default:
          break;
      }
    });
    return arrayOfFields;
  }

  render() {
    return (
      <div
        className={'form-component-container ' + this.props.className}
        data-test={this.props.dataTest || 'form-component-container'}
      >
        <div className={'row ' + this.props.rowStyle}>{this.renderFormField()}</div>
      </div>
    );
  }
}

FormComponent.defaultProps = {
  value: '',
  doValidate: false,
  activeStartDate: null,
  onClick: () => {},
  onChange: () => {},
  onBlurUpdate: false,
  disableForm: false
};

const mapStateToProps = state => ({
  language: state.languageReducer,
});

export default connect(
  mapStateToProps,
  null,
  null,
  {forwardRef: true},
)(FormComponent);
