import {
  ADD_BOOKING,
  FETCH_AVAILABLE_KIT_LIST,
  FETCH_OPERATORS_LIST,
  FETCH_STOCKS_FOR_USERS,
  UPDATE_FILTERS,
  UPDATE_LABS_LOCATION_FILTERS,
  UPDATE_ZMCC_FILTERS,
  UPDATE_ZMCC_DATE_FILTERS,
  CLEAR_ZMCC_EXISTING_DATA,
  SYSTEM_MAIN_COMPONENTS_BY_SYSTEM_CLASSES,
  IQS_CALENDAR_RESOURCES,
  FETCH_IQS_COMMENT_LOCATIONS,
  IQS_ADDED_COMMENT_LIST,
  IQS_LOCATIONS_IDS,
  FETCH_IQS_BOOKING_DROPDOWN_DATA,
  FETCH_IQS_REQUESTS_EVENTS,
  FETCH_IQS_BOOKING_EDIT_DATA,
  FETCH_KITS_BY_LOCATIONID
} from './calendarConstants';

export const initialState = {
  kits: null,
  bookings: [],
  stocks: [],
  alreadyBooked: [],
  filters: {
    loanType: 1,
    category: null,
    categoryFullValue: null,
    stock: null,
    stockFullValue: null,
  },
  labsLocationFilters: {
    loanType: 1,
    category: null,
    categoryFullValue: null,
    stock: null,
    stockFullValue: null,
  },
  zmccFilters: {
    loanType: 1,
    category: null,
    categoryFullValue: null,
    stock: null,
    stockFullValue: null,
    systemCategory: null,
    systemCategoryFullValue: null,
  },
  operatorsList: [],
  zmccExistingMonths: new Set(),
  iqsCalendarResources: [],
  iqsCommentLocations: [],
  iqsAddedCommentList: [],
  iqsCommentsLocationsIds: [],
  iqsBookingDropdownData: {},
  iqsRequestEvents: [],
  editBookedData: {},
  kitByLocation: [],
};

const CalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOKING:
      return {
        ...state,
        bookings: action.payload
      };
    case FETCH_AVAILABLE_KIT_LIST:
      if(action.payload?.isZmccCalendar){
        return {
          ...state,
          kits: {content: [...(state.kits?.content || []), ...(action.payload?.kits?.content || [])], ...action.payload.kits},
          alreadyBooked: [...state.alreadyBooked, ...action.payload.alreadyBooked]
        };
      }
      return {
        ...state,
        kits: action.payload.kits,
        alreadyBooked: action.payload.alreadyBooked
      };
    case FETCH_STOCKS_FOR_USERS:
      return {
        ...state,
        stocks: action.payload
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case UPDATE_LABS_LOCATION_FILTERS:
      return {
        ...state,
        labsLocationFilters: action.payload
      };
    case UPDATE_ZMCC_FILTERS:
      return {
        ...state,
        zmccFilters: action.payload
      };
    case FETCH_OPERATORS_LIST:
      return {
        ...state,
        operatorsList: action.payload
      };
    case UPDATE_ZMCC_DATE_FILTERS:
      return {...state, zmccExistingMonths: new Set([...state.zmccExistingMonths, ...action.payload])}
    case CLEAR_ZMCC_EXISTING_DATA:
      return {
        ...state,
        kits: null,
        alreadyBooked: [],
        zmccExistingMonths: new Set()
      }
    case SYSTEM_MAIN_COMPONENTS_BY_SYSTEM_CLASSES:
      return {...state, systemMainComponentValues: action.payload}
    case IQS_CALENDAR_RESOURCES:
      return {...state, iqsCalendarResources: action.payload}
    case FETCH_IQS_COMMENT_LOCATIONS:
      return {...state, iqsCommentLocations: action.payload}
    case IQS_ADDED_COMMENT_LIST:
    return {...state, iqsAddedCommentList: action.payload}
    case IQS_LOCATIONS_IDS:
      return {...state, iqsCommentsLocationsIds: action.payload}
    case FETCH_IQS_BOOKING_DROPDOWN_DATA:
      return {...state, iqsBookingDropdownData: action.payload}
    case FETCH_IQS_REQUESTS_EVENTS:
      return {...state, iqsRequestEvents: action.payload}
      case FETCH_IQS_BOOKING_EDIT_DATA:
        return {...state, editBookedData: action.payload}
    default:
      return state;
  }
};

export default CalendarReducer;
