import { useState, useCallback, useEffect } from 'react';
import { regex } from 'library/common/commonConstants/RegexConstants';

import cloneDeep from 'lodash/cloneDeep';
import * as $ from 'jquery';
import { containsIgnoreCase } from 'library/utilities/stringUtils';
import Validators from 'library/utilities/Validators';
import { checkLogisticCoordinationForm, validateNameEmailAndFunctionInAttendees } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';
export const useLogisticAndCordination = (props) => {
  const { formValue, user, deleteAttendee, saveZmccSemData, currentTab, isZmcc, zmccXrmBooking, zmccLmBooking, zmccXbBooking, zmccSemBooking, updateZmccTransaction, getWholeSemTransaction  } = props;
  let { logisticsAndCoordinationForm, todosListForm, customerDetilsForm, primaryPointOfContactForm, generalInformationForm } = formValue;
  const customerFormData =  customerDetilsForm.formData;
  const pocFormData = primaryPointOfContactForm.formData

  const [attendeeName, setAttendeeName] = useState('');
  const [attendeeEmail, setAttendeeEmail] = useState('');
  const [functionData, setFunctionData] = useState('');
  const [descisionMaker, setDescisionMaker] = useState('');
  const [accomodationNeeded, setAccomodationNeeded] = useState('');
  const [accomodationNeededData, setAccomodationNeededData] = useState('');
  const [flightNeeded, setFlightNeeded] = useState('');
  const [flightNeededData, setFlightNeededData] = useState('');
  const [visitMuseumOfOptics, setVisitMuseumOfOptics] = useState('');
  const [visitMuseumOfOpticsData, setVisitMuseumOfOpticsData] = useState('');
  const [isEditng, setIsEditng] = useState(false);
  const [skillLevel, setSkillLevel] = useState({
    value: null,
    fullValue: null,
  });
  const [showCheckboxes, setShowCheckBoxes] = useState(false)

  const [attendeesList, setAttendeesListList] = useState([]);
  const [editingFiledId, setEditingFiledId] = useState('');

  const customerAttendeeAvailable = logisticsAndCoordinationForm.formData.zmccAttendeesList && logisticsAndCoordinationForm.formData.zmccAttendeesList.find(item => item.isCustomerAttendee);
  const pocAvailable = logisticsAndCoordinationForm.formData.zmccAttendeesList && logisticsAndCoordinationForm.formData.zmccAttendeesList.find(item => item.isPOCtAttendee);

  const isCustomerInformationAvailable = (customerFormData?.customerName?.trim() || customerFormData?.customerEmail?.trim() || customerFormData?.customerFunction?.trim()) ? true : false;

  useEffect(() => {
    if (isCustomerInformationAvailable || (pocFormData?.pocName !== null && pocFormData?.pocEmail !== null)) {
      if (
        ((customerAttendeeAvailable === undefined && isCustomerInformationAvailable) ||
          (pocAvailable === undefined &&
            customerDetilsForm.formData.customerPointOfContact === 'no' &&
            pocFormData.pocName !== undefined &&
            pocFormData.pocName !== '' &&
            pocFormData.pocEmail !== undefined &&
            pocFormData.pocEmail !== '')) &&
        currentTab === 6
      ) {
        if (!isZmcc) {
          saveZmccSemData('save', 6);
        }
      }
    }
  }, [currentTab]);

  useEffect(() => {
    if(generalInformationForm?.formData?.stockInformationIdFullValue?.locationName){
      let stockName = generalInformationForm?.formData?.stockInformationIdFullValue?.locationName
      if(containsIgnoreCase(stockName, "ZMCC Europe")){
        setShowCheckBoxes(true)
      }else{
        setShowCheckBoxes(false)
      }
    }else{
      setShowCheckBoxes(false)
    }
  }, [generalInformationForm])

  useEffect(() => {
    setAttendeeName('');
    setDescisionMaker('');
    setAttendeeEmail('');
    setFunctionData('');
    setSkillLevel({ value: null, fullValue: null });
    setAccomodationNeededData('');
    setAccomodationNeeded('');
    setFlightNeeded('');
    setFlightNeededData('')
    setVisitMuseumOfOptics('');
    setVisitMuseumOfOpticsData('');
  },[logisticsAndCoordinationForm.formData.zmccAttendeesList]);

  const addIntoAttendeesListList = (
    name,
    email,
    functionData,
    decisionMaker,
    skillId,
    accomodationNeededData,
    flightNeededData,
    visitMuseumOfOpticsData,
  ) => {
    let result = regex.email.test(email);

    if (attendeeEmail !== '' && !result){
      props.updateSEMForm({ logisticsAndCoordinationForm: { formData: { ...logisticsAndCoordinationForm.formData, attendeeEmailError: 'invalidEmailError'}}});
      return
    }
    if (!handleValidation()) {
      return;
    }
    let lenghtofAttendenteList = logisticsAndCoordinationForm.formData.zmccAttendeesList !== undefined ?logisticsAndCoordinationForm.formData.zmccAttendeesList.length: '0';
    setAttendeesListList(
      logisticsAndCoordinationForm.formData.zmccAttendeesList
        ? [
            ...logisticsAndCoordinationForm.formData.zmccAttendeesList,
            {
              zmccAttendeesId: null,
              attendeeName: name,
              attendeeEmail: email,
              functionData: functionData,
              decisionMaker: decisionMaker == 'yes' ? true : false,
              skillLevelId: skillId,
              accomodationNeededData: accomodationNeededData,
              flightNeededData: flightNeededData,
              visitMuseumOfOpticsData: visitMuseumOfOpticsData,
              accomodationNeeded: accomodationNeeded,
              flightNeeded: flightNeeded,
              visitMuseumOfOptics: visitMuseumOfOptics,
              isCustomerAttendee: false,
              isPOCtAttendee: false,
            },
          ]
        : [
            {
              zmccAttendeesId: null,
              attendeeName: name,
              attendeeEmail: email,
              functionData: functionData,
              decisionMaker: decisionMaker == 'yes' ? true : false,
              skillLevelId: skillId,
              accomodationNeededData: accomodationNeededData,
              flightNeededData: flightNeededData,
              visitMuseumOfOpticsData: visitMuseumOfOpticsData,
              accomodationNeeded: accomodationNeeded,
              flightNeeded: flightNeeded,
              visitMuseumOfOptics: visitMuseumOfOptics,
              isCustomerAttendee: false,
              isPOCtAttendee: false,
            },
          ],
    );
    let tempAttendeList = [];
    if (accomodationNeededData !== '') {
      tempAttendeList.push({
        zmccTodosId: null,
        zmccTodosText: accomodationNeededData,
        createdBy: user.accountId,
        checkedBy: null,
        isChecked: false,
        title: 'accomodationNeeded',
        attendeAccId: lenghtofAttendenteList+'0'
      });}

      if (flightNeededData !== '') {
        tempAttendeList.push({
          zmccTodosId: null,
          zmccTodosText: flightNeededData,
          createdBy: user.accountId,
          checkedBy: null,
          isChecked: false,
          title: 'flightNeeded',
          attendeAccId: lenghtofAttendenteList+'1',
        });
      }

      if (visitMuseumOfOpticsData !== '') {
        tempAttendeList.push({
          zmccTodosId: null,
          zmccTodosText: visitMuseumOfOpticsData,
          createdBy: user.accountId,
          checkedBy: null,
          isChecked: false,
          title: 'visitMuseumOfOptics',
          attendeAccId: lenghtofAttendenteList+'2',
        });
      }
    const updateAttendeList = logisticsAndCoordinationForm.formData.zmccAttendeesList;
    if (logisticsAndCoordinationForm.formData.zmccAttendeesList && logisticsAndCoordinationForm.formData.zmccAttendeesList.length > 0) {
      updateAttendeList.forEach(item => {
        item['skillLevelId'] = item.skillLevel ? item.skillLevel.skillLevelId : null
      });
    }

    isZmcc ? updateZmccTransaction(zmccLmBooking ? 'LM' : zmccXbBooking ? 'XB' : zmccXrmBooking ? 'XRM' : 'SEM') : saveZmccSemData('save', 6)
    setAttendeeName('');
    setDescisionMaker('');
    setAttendeeEmail('');
    setFunctionData('');
    setSkillLevel({ value: null, fullValue: null });
    setAccomodationNeededData('');
    setAccomodationNeeded('');
    setFlightNeeded('');
    setFlightNeededData('')
    setVisitMuseumOfOptics('');
    setVisitMuseumOfOpticsData('');
  };

  const handleNonFormComponent = (values, field, formName ) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null,
          [`${field}DataError`]: null,
        },
      },
    });
    props.setUnsavedChanges(true);
  };

  const handleEditNonFormComponent = (values, formName ) => {
    const selectedAttendee = logisticsAndCoordinationForm.formData.zmccAttendeesList.filter((item, index) => index === editingFiledId);
    let filteredList = logisticsAndCoordinationForm.formData.zmccAttendeesList.filter((item, index) => index !== editingFiledId);
    filteredList.splice(editingFiledId,0,values);
    filteredList.forEach(item => {
      item['skillLevelId'] = item.skillLevel ? item.skillLevel.skillLevelId : item.skillLevelIdFullValue ? item.skillLevelIdFullValue.id : null
    });
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          zmccAttendeesList:[
            ...(filteredList || {}),
          ]
        },
      },
    });

  };
  const handleEditNonFormComponentForTodoList = (values, formName ) => {
    // const selectedAttendee = logisticsAndCoordinationForm.formData.zmccAttendeesList.filter((item, index) => index === editingFiledId);
    // let filteredList
    // // = todosListForm.formData.zmccTodosListDataList.filter((item, index) =>  !== item.attendeAccId);
    // filteredList.splice(editingFiledId,0,values);
    // props.updateSEMForm({
    //   [formName]: {
    //     ...props.formValue[formName],
    //     formData: {
    //       zmccAttendeesList:[
    //         ...(filteredList || {}),
    //       ]
    //     },
    //   },
    // });

  };

 const onChangeExistingValue = useCallback((value, field, index) => {
    const newListOfChangedValues = cloneDeep(logisticsAndCoordinationForm.formData.zmccAttendeesList);
    let tempvalues = [...newListOfChangedValues];
    if (field === 'attendeeName') {
      tempvalues[index].attendeeName = value;
      // handleEditNonFormComponent(value, 'attendeeName', 'logisticsAndCoordinationForm', index);
      //  return setAttendeeName(value);
      handleNonFormComponent([...tempvalues], 'attendeeName', 'logisticsAndCoordinationForm')
    }

  },[logisticsAndCoordinationForm.formData])



  const onChangeInput = (value, field, fullValue) => {
    if (field === 'attendeeName') {

      handleNonFormComponent(value, 'attendeeName', 'logisticsAndCoordinationForm');
      return setAttendeeName(value);
    }
    if (field === 'attendeeEmail') {
      handleNonFormComponent(value, 'attendeeEmail', 'logisticsAndCoordinationForm');
      return setAttendeeEmail(value);
    }
    if (field === 'function') {
      handleNonFormComponent(value, 'function', 'logisticsAndCoordinationForm');
      return setFunctionData(value);
    }
    if (field == 'descisionMaker') {
      handleNonFormComponent(value, 'descisionMaker', 'logisticsAndCoordinationForm');
      return setDescisionMaker(value);
    }
    if (field == 'skillLevelId') {
      handleNonFormComponent(value, 'skillLevelId', 'logisticsAndCoordinationForm');
      return setSkillLevel({ value, fullValue });
    }
    if (field === 'accomodationNeededData') {
      handleNonFormComponent(value, 'accomodationNeededData', 'logisticsAndCoordinationForm');
      return setAccomodationNeededData(value);
    }
    if (field === 'flightNeededData') {
      handleNonFormComponent(value, 'flightNeededData', 'logisticsAndCoordinationForm');
      return setFlightNeededData(value);
    }
    if (field === 'visitMuseumOfOpticsData') {
      handleNonFormComponent(value, 'visitMuseumOfOpticsData', 'logisticsAndCoordinationForm');
      return setVisitMuseumOfOpticsData(value);
    }

    props.setUnsavedChanges(true);
  };

  const onChangeCheckbox = (value, field) => {

    if (field === 'accomodationNeeded') {
      handleNonFormComponent(value, 'accomodationNeeded', 'logisticsAndCoordinationForm');
      return setAccomodationNeeded(value);
    }

    if (field === 'flightNeeded') {
      handleNonFormComponent(value, 'flightNeeded', 'logisticsAndCoordinationForm');
      return setFlightNeeded(value);
    }

    if (field === 'visitMuseumOfOptics') {
      handleNonFormComponent(value, 'visitMuseumOfOptics', 'logisticsAndCoordinationForm');
      return setVisitMuseumOfOptics(value);
    }
    props.setUnsavedChanges(true);
  };

  const handleValidation = () =>{
    let isValid = true;

    isValid = validateNameEmailAndFunctionInAttendees(logisticsAndCoordinationForm).isValid
    if(!isValid){
      props.updateSEMForm({ logisticsAndCoordinationForm: { formData: { ...logisticsAndCoordinationForm.formData, ...validateNameEmailAndFunctionInAttendees(logisticsAndCoordinationForm).nameEmailAndFunctionErrors}}});
    }

    if (accomodationNeeded && accomodationNeededData === '') {
      isValid = false;
      props.updateSEMForm({ logisticsAndCoordinationForm: { formData: { ...logisticsAndCoordinationForm.formData, accomodationNeededDataError: 'requiredErrorMessage'}}});
    }

    if (flightNeeded && flightNeededData === '') {
      isValid = false;
      props.updateSEMForm({ logisticsAndCoordinationForm: { formData: { ...logisticsAndCoordinationForm.formData, flightNeededDataError: 'requiredErrorMessage'}}});
    }

    if (visitMuseumOfOptics && visitMuseumOfOpticsData === '') {
      isValid = false;
      props.updateSEMForm({ logisticsAndCoordinationForm: { formData: { ...logisticsAndCoordinationForm.formData, visitMuseumOfOpticsDataError: 'requiredErrorMessage'}}});
    }

    return isValid
  }

  const onEditClick = (index) => {
    $('#edit-Attendee-modal').modal('show');
    setEditingFiledId(index);
  };

  const saveEditAttendee = data => {

  };

  const deleteAttendeById = (item) =>{
    const transactionsId = props.match.params && props.match.params.id;
    deleteAttendee(item.zmccAttendeesId, props.user, props.marketSegments, transactionsId, isZmcc, getWholeSemTransaction);
  }

  return {
    attendeeName,
    attendeeEmail,
    attendeesList,
    functionData,
    descisionMaker,
    skillLevel,
    addIntoAttendeesListList,
    handleNonFormComponent,
    onChangeInput,
    onChangeCheckbox,
    accomodationNeeded,
    flightNeeded,
    visitMuseumOfOptics,
    accomodationNeededData,
    flightNeededData,
    visitMuseumOfOpticsData,
    onEditClick,
    saveEditAttendee,
    isEditng,
    onChangeExistingValue,
    editingFiledId,
    handleEditNonFormComponent,
    handleEditNonFormComponentForTodoList,
    deleteAttendeById,
    showCheckboxes
  };

};
