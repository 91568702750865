import React from 'react';
import { ViewTypes } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import withDndContext from 'library/utilities/withDndContext';
import './iqsCalendar.styles.scss';
import SlotItem from './components/SlotItem';
import { useAppSchedular } from './IQSAppSchedular.hook';
import CalendarHeader from './components/CalendarHeader';
import Scheduler from 'library/common/commonComponents/ReactBigScheduler';
import CalendarLegends from './components/CalendarLegends';
import { connect } from 'react-redux';
import { fetchIqsCalendarResources, fetchSingleBookingData } from './IQSCalendar.actions';
import AddLocationComment from './components/AddLocationComment/AddLocationComment.component';
import { fetchIqsCommentsList } from '../CalendarActions';
// import Scheduler from 'library/common/commonComponents/ReactBigScheduler/Scheduler';
import ComfirmBookingPopup from './components/ComfirmBookingPopup/ComfirmBookingPopup.component';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import BookedEventDetailsPopup from './components/BookedEventDetailsPopup/BookedEventDetailsPopup.component';
import EditBookingPopup from './components/EditBookingPopup/EditBookingPopup.component';
import Button from 'library/common/commonComponents/Button/Button';
import Popover from 'library/common/commonComponents/CustomPopover/Popover';
import AddLocationCommentButton from './components/AddLocationCommentButton';

const IQSCalendar = props => {
  const {
    calendarConfig,
    prevClick,
    nextClick,
    onSelectDate,
    onViewChange,
    eventClicked,
    renderDayHeader,
    selectedViewType,
    slotTempletResolver,
    toggleExpandFunc,
    schedulerRef,
    todayClick,
    selectedDate,
    slotItemPopover,
    iqsCalendarResources,
    filter,
    onAddCommnetClick,
    selectedCommentEvent,
    setSelectedCommentEvent,
    newEvent,
    updateEventStart,
    updateEventEnd,
    moveEvent,
    onRemoveEvent,
    selectedSystem,
    eventSelectedPopupOpen,
    setEventSelectedPopupOpen,
    conflictOccurred,
    setSelectedSystem,
    user,
    singleEventClickedId,
  } = useAppSchedular(props);
  // Custom Event Template
  const customEventTemplateResolver = (_, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => (
    <SlotItem
      user={user}
      mustAddCssClass={mustAddCssClass}
      mustBeHeight={mustBeHeight}
      event={event}
      selectedViewType={selectedViewType}
      calendarConfig={calendarConfig}
      handleRemoveEvent={(calendarConfig, event) => onRemoveEvent(calendarConfig, event)}
    />
  );

  const resourceNameTemplateResolver = title => {
    return (
      <Popover content={<CalendarLegends />} popoverBoxClassName='iqs-calendar-popover'>
        <div className='d-flex justify-content-center'>
          <Button value='Legends' styleClass={'text-white w-50'} />
        </div>
      </Popover>
    );
  };

  return (
    <>
      <CalendarHeader
        onViewChange={onViewChange}
        selectedViewType={selectedViewType}
        prevClick={prevClick}
        nextClick={nextClick}
        todayClick={todayClick}
        onSelectDate={onSelectDate}
        selectedDate={selectedDate}
      />
      {calendarConfig && iqsCalendarResources && iqsCalendarResources.length > 0 && (
        <div
          className={`iqs-calendar-container ${
            selectedViewType == ViewTypes.Custom ? 'hide-border-day-week-view' : ''
          }`}
          ref={schedulerRef}
        >
          <Scheduler
            schedulerData={calendarConfig}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}
            onViewChange={onViewChange}
            eventItemClick={eventClicked}
            eventItemTemplateResolver={customEventTemplateResolver}
            nonAgendaCellHeaderTemplateResolver={renderDayHeader}
            slotItemTemplateResolver={slotTempletResolver}
            toggleExpandFunc={toggleExpandFunc}
            eventItemPopoverTemplateResolver={slotItemPopover}
            newEvent={newEvent}
            updateEventStart={updateEventStart}
            updateEventEnd={updateEventEnd}
            moveEvent={moveEvent}
            conflictOccurred={conflictOccurred}
            useCustomEvents={true}
            resourceNameTemplateResolver={resourceNameTemplateResolver}
          />
        </div>
      )}
      <AddLocationCommentButton onAddCommnetClick={onAddCommnetClick} />
      <AddLocationComment
        id={'add-location-comment-modal'}
        selectedCommentEvent={selectedCommentEvent}
        setSelectedCommentEvent={setSelectedCommentEvent}
        selectedDate={selectedDate} 
      />
      <ComfirmBookingPopup
        id={'confirm-booking-modal'}
        calendarConfig={calendarConfig}
        selectedSystem={selectedSystem}
        eventSelectedPopupOpen={eventSelectedPopupOpen}
        setEventSelectedPopupOpen={setEventSelectedPopupOpen}
        setSelectedSystem={setSelectedSystem}
        singleEventClickedId={singleEventClickedId}
        history={props.history}
      />

      <BookedEventDetailsPopup id={'view-details-modal'} filters={props?.filters?.filter}/>
      <EditBookingPopup id={'edit-booking-modal'} filters={props?.filters?.filter} />
    </>
  );
};

export default withDndContext(
  connect(null, {
    fetchIqsCalendarResources,
    fetchIqsCommentsList,
    toggleActionMessage,
    toggleActionMessage,
    fetchSingleBookingData
  })(IQSCalendar),
);
