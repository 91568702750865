import React, { PureComponent } from 'react';
import { get } from 'lodash';
import strings from 'resources/locales/Translate';

import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Tabs from 'library/common/commonComponents/Tabs/container/TabsContainer';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import { createStockBreadcrumbData, createStockTabsConstant ,labsAndLocationBreadcrumbData,createStockTabsConstantForLabsAndLocation, createStockTabsConstantForRMSZmcc,zmccBreadcrumbData, createTabsForIqsDe} from './CreateStock.constants';

export default class CreateStock extends PureComponent {
  constructor(props) {
    super(props);
    const stockId = get(this.props.match, 'params.id');
    document.title = stockId ? strings.maintainStock : strings.createStock;
    let currentTab = 1;
    if (props.history.location.state && props.history.location.state.tab) {
      if (props.history.location.state.tab === 'entitlements') {
        currentTab = 2;
      }
    }
    this.state = {
      stockId,
      isEditView: !!stockId,
      currentTab,
    };
  }

  componentDidMount() {
    this.props.fetchMasterData(URLS.userList, actionTypes.FETCH_USERS_LIST);
    this.props.fetchMasterData(URLS.rolesList, actionTypes.FETCH_ROLES_LIST);
    this.props.fetchMasterData(URLS.businessUnitList, actionTypes.FETCH_BUSINESS_UNIT_LIST);
    this.props.fetchMasterData(URLS.countryList, actionTypes.FETCH_COUNTRY_LIST);
    this.props.getAttachmentCategory();
    if (this.props.match.params.id && this.props.match.params.id > 0) {
      this.props.setStockId(this.props.match.params.id);
      this.props.fetchStockDetails(
        URLS.stockDetails,
        actionTypes.FETCH_STOCK_DETAILS,
        this.props.history,
        this.state.stockId,
      );
      this.props.getPendingKitsListByWarehouseWhereSlotIsNotAvailable(this.props.match.params.id);
    } else {
      this.props.cancelStockCreation();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language.language !== this.props.language.language) {
      const stockId = get(this.props.match, 'params.id');
      document.title = stockId ? strings.maintainStock : strings.createStock;
      this.props.fetchMasterData(URLS.countryList, actionTypes.FETCH_COUNTRY_LIST);
      this.props.getAttachmentCategory();
    }
  }

  componentWillUnmount() {
    this.props.cancelStockCreation();
  }

  render() {
    const { stockDetails } = this.props.createStockReducer;
    const { stockId, currentTab } = this.state;
    const { displayActionMessage, type, message, isLoading, showAsIs } = this.props.appActions;
    const { isEditView } = this.state;
    const isLabsLocationBusinessUnit = stockDetails && stockDetails.businessUnitFullValue &&stockDetails.businessUnitFullValue.designation === 'RMS_L_L'
    const isRmsZmcc = stockDetails && stockDetails.businessUnitFullValue &&stockDetails.businessUnitFullValue.designation === 'RMS_ZMCC';
    const isIqsSscDe = stockDetails?.businessUnitFullValue?.designation === 'IQS_SSC_DE';
    return (
      <div className='create-stock-container'>
        {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : strings[message]} />}
        <div className='container-fluid'>
          <Breadcrumbs data={isLabsLocationBusinessUnit ? labsAndLocationBreadcrumbData : isRmsZmcc ? zmccBreadcrumbData : createStockBreadcrumbData} />
          {(!isLabsLocationBusinessUnit && !isRmsZmcc) && (
            <h1 className='page-title'>{isEditView ? strings.maintainStocks : strings.createStock}</h1>
          )}
          {isLabsLocationBusinessUnit && (
            <h1 className='page-title'>{isEditView ? strings.maintainLabsAndLocationPartner : strings.createStock}</h1>
          )}
          {isRmsZmcc && (
            <h1 className='page-title'>{isEditView ? strings.maintainRMSZMCC : strings.createStock}</h1>
          )}
        </div>

        {(!stockId || (stockDetails && stockDetails.id)) && !isLabsLocationBusinessUnit && !isRmsZmcc && !isIqsSscDe && (
          <Tabs
            tabs={createStockTabsConstant(this.props.history, stockId, stockDetails)}
            toggleTab={!!stockDetails.id}
            currentTab={currentTab}
            newUiVersion={true}
          />
        )}
        {(!stockId || (stockDetails && stockDetails.id)) && isLabsLocationBusinessUnit && !isRmsZmcc && !isIqsSscDe &&(
          <Tabs
            tabs={createStockTabsConstantForLabsAndLocation(this.props.history, stockId, stockDetails)}
            toggleTab={!!stockDetails.id}
            currentTab={currentTab}
            newUiVersion={true}
          />
        )}
        {(!stockId || (stockDetails && stockDetails.id)) && isRmsZmcc && !isLabsLocationBusinessUnit && !isIqsSscDe &&(
          <Tabs
            tabs={createStockTabsConstantForRMSZmcc(this.props.history, stockId, stockDetails)}
            toggleTab={!!stockDetails.id}
            currentTab={currentTab}
            newUiVersion={true}
          />
        )}
        {(!stockId || (stockDetails && stockDetails.id)) && isIqsSscDe && !isRmsZmcc && !isLabsLocationBusinessUnit &&(
          <Tabs
            tabs={createTabsForIqsDe(this.props.history, stockId, stockDetails, isIqsSscDe)}
            toggleTab={!!stockDetails.id}
            currentTab={currentTab}
            newUiVersion={true}
          />
        )}
        {isLoading && <div className='loader' />}
      </div>
    );
  }
}
