import { useState, useEffect } from 'react';
import {
  addZeissCompanayModel,
  contactCreatorModel,
  crmAndCustomerModel,
  customerContactModel,
} from './Information.contants';
import * as $ from 'jquery';
import { cloneDeep, set } from 'lodash';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { headerModel } from 'modules/IqsNewRequest/IqsNewRequest.constants';
import { scrollToTop } from 'library/utilities/scrollActions';

export const useInformation = ({
  id,
  allDropdownData,
  usersList,
  headerFormData,
  setHeaderForm,
  createRequest,
  history,
  isEdit,
  requestDetailes,
  toggleActionMessage,
  match,
}) => {
  const [crmAndCustomerForm, setcrmAndCustomerForm] = useState({ formData: {} });
  const [contactCustomerForm, setContactCustomerForm] = useState([{ formData: {} }]);
  const [contactCreatorForm, setcontactCreatorForm] = useState({ formData: {} });
  const [zeissCompanayForm, setzeissCompanayForm] = useState({ formData: {} });
  const [zeissPersonList, setZeissPersonList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selctedCompannyData, setSelctedCompannyData] = useState({});
  const [isCompanyEditing, setIsCompanyEditing] = useState(false);
  const [customerContactList, setCustomerContactList] = useState([]);
  const [selctedCustomerContactData, setSelctedCustomerContactData] = useState([]);
  const [customerContactFromList, setCustomerContactFromList] = useState([[...customerContactModel]]);

  //SetData for edit mode
  useEffect(() => {
    if (isEdit && requestDetailes) {
      const activeCompany = requestDetailes?.iqsBookingCompanyList?.find(item => item.isActive);
      setCompanyList(requestDetailes?.iqsBookingCompanyList);
      const formData = {
        opportunityNumber: requestDetailes?.opportunityNumber,
        salesNumber: requestDetailes?.salesNumber,
        companySAPID: activeCompany?.companySAPID,
        customer: activeCompany?.iqsRequestCompanyId,
        customerFullVlaue: activeCompany,
        companyName: activeCompany?.companyName,
        companyGroup: activeCompany?.companyGroup,
        companyStreetAndHouseNumber: activeCompany?.companyStreetAndHouseNumber,
        companyPostalCode: activeCompany?.companyPostalCode,
        companyCity: activeCompany?.companyCity,
        countryId: activeCompany?.country?.id,
        countryIdFullValue: activeCompany?.country,
        companyPhone: activeCompany?.companyPhone,
        companyEmail: activeCompany?.companyEmail,
        companyWebsite: activeCompany?.companyWebsite,
        companyIndustrialSegment: activeCompany?.companyIndustrialSegment,
        companyIndustrialSegmentFullValue: activeCompany?.companyIndustrialSegmentTranslated,
        companyIndustrialSubSegment: activeCompany?.companyIndustrialSubSegment,
        companyIndustrialSubSegmentFullValue: activeCompany?.companyIndustrialSubSegmentTranslated,
        companyNdaType: activeCompany?.companyNdaType,
        companyNdaTypeFullValue: activeCompany?.companyNdaTypeTranslated,
        companyNdaValidUntil: activeCompany?.companyNdaValidUntil ? new Date(activeCompany?.companyNdaValidUntil) : '',
        aukomMember: activeCompany?.aukomMember,
        iqsRequestCompanyId: activeCompany?.iqsRequestCompanyId,
        companySAPIDAvailable: activeCompany?.companySAPIDAvailable,
      };
      setcrmAndCustomerForm({ formData: formData });
      setDataForCompany(formData);
      setZeissPersonList(
        requestDetailes?.iqsRequestZeissPersons?.map(item => ({
          personId: item.person.accountId,
          personIdFullValue: item.person,
          role: item.role,
          roleFullValue: item.roleTranslated,
          zeissPersonId: item.zeissPersonId,
        })) || [],
      );

      setcontactCreatorForm({
        formData: {
          creatorId: requestDetailes?.creator?.accountId,
          creatorIdFullValue: requestDetailes?.creator,
          salesRepId: requestDetailes?.salesRep?.accountId,
          salesRepIdFullValue: requestDetailes?.salesRep,
          projectResponsibleId: requestDetailes?.projectResponsible?.accountId,
          projectResponsibleIdFullValue: requestDetailes?.projectResponsible,
          projectManagerId: requestDetailes?.projectManager?.accountId,
          projectManagerIdFullValue: requestDetailes?.projectManager,
        },
      });

      setCustomerContactList(requestDetailes?.iqsBookingContactsList || []);
      const filterActiveEvents = requestDetailes?.iqsBookingContactsList?.filter(item => item.isActive);
      const prepareContactForm = filterActiveEvents?.map(item => ({
        formData: {
          iqsRequestContactId: item.iqsRequestContactId,
          contactCrmIdAvailable: item.contactCrmIdAvailable,
          customerContact: item.iqsRequestContactId,
          customerContactFullValue: item,
          contactFirstName: item.contactFirstName,
          contactEmail: item.contactEmail,
          iqsRequestCustomerContactRole: item.iqsRequestCustomerContactRole,
          iqsRequestCustomerContactRoleFullValue: item.iqsRequestCustomerContactRoleTranslated,
          isActive: item.isActive,
          contactCRMID: item.contactCRMID
        },
      }));
      setContactCustomerForm(prepareContactForm || [{ formData: {} }]);
      if (filterActiveEvents?.length > 0) {
        const newCustomerContactFormList = Array(filterActiveEvents?.length).fill(customerContactModel);
        setCustomerContactFromList(newCustomerContactFormList);
      }
    }
  }, [requestDetailes]);

  const onClickCompany = () => {
    $(`#companyPopup`).modal('show');
  };
  const onClickCustomerContact = () => {
    $(`#customerContactPopup`).modal('show');
  };

  const getcrmAndCustomerModel = () => {
    const model = [...crmAndCustomerModel];
    model[3].options = companyList;
    return model;
  };

  const getContactCustomerModel = () => {
    const model = [...customerContactModel];
    let contactIds = new Set(customerContactList.map(item => item.iqsRequestContactId));
    let formDataIds = new Set(
      contactCustomerForm.length > 0 ? contactCustomerForm?.map(item => item.formData.iqsRequestContactId) : [],
    );
    let filterIds = new Set([...contactIds].filter(id => !formDataIds.has(id)));
    model[1].options = customerContactList.filter(item => filterIds.has(item.iqsRequestContactId));
    model[4].options = allDropdownData?.IqRequestCustomerContactRole || [];
    return model;
  };

  const getAddZeissCompanayModel = () => {
    const model = [...addZeissCompanayModel];
    model[0].options = usersList;
    model[1].options = allDropdownData?.IqRequestContactsRole || [];
    return model;
  };

  const getContactCreatorModel = () => {
    const model = [...contactCreatorModel];
    model[0].options = usersList;
    model[1].options = usersList;
    model[2].options = usersList;
    model[3].options = usersList;

    return model;
  };

  const setDataForCompany = e => {
    setSelctedCompannyData(e);
    setcrmAndCustomerForm({
      ...crmAndCustomerForm,
      formData: {
        ...crmAndCustomerForm.formData,
        ...e,
      },
    });
  };

  const handleForm = ({ values, field, formName }) => {
    if (field === 'customer') {
      const selectedCompany = companyList.find(company => company.iqsRequestCompanyId === values.customer);
      setDataForCompany({
        ...selectedCompany,
        companyIndustrialSegmentFullValue: selectedCompany?.companyIndustrialSegmentTranslated
          ? selectedCompany?.companyIndustrialSegmentTranslated
          : selectedCompany?.companyIndustrialSegmentFullValue,
        companyIndustrialSubSegmentFullValue: selectedCompany?.companyIndustrialSubSegmentTranslated
          ? selectedCompany?.companyIndustrialSubSegmentTranslated
          : selectedCompany?.companyIndustrialSubSegmentFullValue,
        companyNdaTypeFullValue: selectedCompany?.companyNdaTypeTranslated
          ? selectedCompany?.companyNdaTypeTranslated
          : selectedCompany?.companyNdaTypeFullValue,
        countryIdFullValue: selectedCompany?.country ? selectedCompany?.country : selectedCompany?.countryIdFullValue,
      });
    }
    const formStateMap = {
      crmAndCustomerForm: setcrmAndCustomerForm,
      contactCreatorForm: setcontactCreatorForm,
      zeissCompanayForm: setzeissCompanayForm,
    };

    const setFormState = formStateMap[formName];

    if (setFormState) {
      setFormState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
  };

  const onClickZeissPerson = () => {
    setZeissPersonList([...zeissPersonList, zeissCompanayForm.formData]);
    setzeissCompanayForm({ formData: {} });
  };

  const handleExistingValueForPerson = (e, index) => {
    const existingData = cloneDeep(zeissPersonList);
    existingData[index] = {
      ...existingData[index],
      ...e.values,
    };
    setZeissPersonList(existingData);
  };

  const onEditCompanyCLick = () => {
    setIsCompanyEditing(true);
    $(`#companyPopup`).modal('show');
  };

  const handleFormCustomeContact = (e, index, iqsRequestContactId) => {
    const existingData = cloneDeep(contactCustomerForm);
    let selectedCustomerContact = {};
    const indexOfList = customerContactList.findIndex(
      contact => contact.iqsRequestContactId === (e.values.customerContact || iqsRequestContactId),
    );
    if (e.field === 'customerContact') {
      if (indexOfList !== -1) {
        selectedCustomerContact = customerContactList[indexOfList];
      }
      existingData[index].formData = {
        ...existingData[index]?.formData,
        ...selectedCustomerContact,
        ...e.values,
      };
    } else {
      existingData[index].formData = {
        ...(existingData[index]?.formData || {}),
        ...e.values,
      };
    }

    setCustomerContactList(prevState => {
      if (indexOfList === -1) {
        return prevState;
      }
      let newData = prevState[indexOfList] || {};
      newData = { ...newData, ...e.values };
      prevState[indexOfList] = newData;
      return [...prevState];
    });

    setContactCustomerForm(existingData);
  };

  const onAddContactClick = () => {
    setCustomerContactFromList(prevSate => {
      return [...prevSate, [...customerContactModel]];
    });
    setContactCustomerForm(prevState => {
      return [...prevState, { formData: {} }];
    });
  };

  const onSaveClick = async () => {
    const header = validateForm({
      form: headerFormData,
      model: headerModel,
    });
    const companyForm = validateForm({
      form: crmAndCustomerForm,
      model: crmAndCustomerModel,
    });
    const creatorForm = validateForm({
      form: contactCreatorForm,
      model: contactCreatorModel,
    });

    let isErrorinCustomerContact = false;
   
   if (!contactCustomerForm[0]?.formData?.customerContact) {
    isErrorinCustomerContact = true
      setContactCustomerForm([{formData: {
        ...contactCustomerForm[0]?.formData,
        customerContactError: 'requiredErrorMessage'
      }}]);
    
   }
   
    if (!header.isFormValid || !companyForm.isFormValid || !creatorForm.isFormValid || isErrorinCustomerContact) {
      setHeaderForm(header);
      setcrmAndCustomerForm(companyForm);
      setcontactCreatorForm(creatorForm);
      toggleActionMessage(true, 'error', 'formIsInvalid');
      scrollToTop(500);
      return;
    }

    let formValues = {
      ...headerFormData.formData,
      ...contactCreatorForm.formData,
    };

    Object.keys(formValues).forEach(key => {
      if (key.includes('Error') || key.includes('FullValue')) {
        delete formValues[key];
      }
      if (key === 'requestStatus') {
        formValues[key] = formValues[key] ? formValues[key] : null;
      }
      if(key === 'executionLocation'){
        formValues[key] = formValues[key] ? formValues[key] : null;
      }
    });

    const iqsRequestCompanyDtoSet = companyList.map(item => {
      let updatedItem = item;
      if (updatedItem.isNewlyAdded) {
        updatedItem = {
          ...updatedItem,
          iqsRequestCompanyId: null,
        };
      }
      if(!updatedItem?.companyNdaType){
        updatedItem.companyNdaType = null;
      }
      if (selctedCompannyData.iqsRequestCompanyId === item.iqsRequestCompanyId) {
        updatedItem.isActive = true;
      } else {
        updatedItem.isActive = false;
      }
      return updatedItem;
    });

    const personsList = zeissPersonList.map(item => {
      const cleanedItem = {};
      Object.keys(item).forEach(key => {
        if (!key.includes('Error') && !key.includes('FullValue')) {
          cleanedItem[key] = item[key];
        }
      });
      return cleanedItem;
    });

    const iqsRequestCustomerContactDto = customerContactList.map(item => {
      let cleanedItem = {};
      Object.keys(item).forEach(key => {
        if (!key.includes('Error') && !key.includes('FullValue')) {
          cleanedItem[key] = item[key];
        }
      });
      if (cleanedItem.isNewlyAdded) {
        cleanedItem = {
          ...cleanedItem,
          iqsRequestContactId: null,
        };
      }
      contactCustomerForm.forEach(form => {
        if (form.formData.iqsRequestContactId === item.iqsRequestContactId) {
          cleanedItem.isActive = true;
        }
      });
      return cleanedItem;
    });

    const dataToSend = {
      ...formValues,
      iqsRequestCompanyDtoSet: iqsRequestCompanyDtoSet,
      iqsRequestCustomerContactDto: iqsRequestCustomerContactDto,
      iqsRequestZeissPersonDto: personsList,
      opportunityNumber: crmAndCustomerForm?.formData?.opportunityNumber,
      salesNumber: crmAndCustomerForm?.formData?.salesNumber,
      companySAPID: crmAndCustomerForm?.formData?.companySAPID,
    };
    const res = await createRequest(dataToSend);
    if (res.success && !isEdit) {
      history.replace(`/edit-iqs-request/${res.data.requestId}`);
    }
  };

  return {
    onClickCompany,
    onClickCustomerContact,
    getContactCustomerModel,
    getAddZeissCompanayModel,
    getContactCreatorModel,
    handleForm,
    crmAndCustomerForm,
    contactCustomerForm,
    contactCreatorForm,
    zeissCompanayForm,
    onClickZeissPerson,
    zeissPersonList,
    handleExistingValueForPerson,
    setCompanyList,
    companyList,
    getcrmAndCustomerModel,
    selctedCompannyData,
    setDataForCompany,
    onEditCompanyCLick,
    setIsCompanyEditing,
    isCompanyEditing,
    setCustomerContactList,
    customerContactList,
    onSaveClick,
    customerContactFromList,
    handleFormCustomeContact,
    onAddContactClick,
  };
};
