import React from 'react';
import './searchTerms.styles.scss';
import useSearchTerms from './SearchTerms.hook';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import strings from 'resources/locales/Translate';
import AddSearchTermHeader from './components/AddHeader.component';
import AddTerms from './components/AddTerms.component';
import Button from 'library/common/commonComponents/Button/Button';
import { MAX_HEADERS_ALLOWED_FOR_LOCATION, MAX_SEARCH_TERMS_ALLOWED } from '../../MasterData.constants';

function SearchTerms() {
  const {
    searchTerms,
    onAddHeader,
    onChangeLocation,
    onChangeNewHeader,
    onTermChange,
    onAddTerm,
    onDeleteTerm,
    onAddStock,
    countriesDropdown,
    onSaveSearchTerms,
    onHeaderOptionsClick,
  } = useSearchTerms();
  return (
    <>
      {searchTerms.map((item, index) => {
        return (
          <div className='d-flex mt-3 w-100' key={`item.locationId-${index}`}>
            <div className='col-sm-6 col-md-5 col-lg-4 search-container search-container-left'>
              <div className='d-flex flex-column justify-content-between'>
                <Dropdown
                  data={countriesDropdown}
                  hasSection={true}
                  // multiSelect={true}
                  filter={true}
                  clearMultiOption={true}
                  idKey='id'
                  displayValue={[{ key: 'locationName', separator: '' }]}
                  optionsArrayKey='stockInformation'
                  titleDisplay={[
                    { key: 'shortName', separator: ' ' },
                    { key: 'name', separator: '' },
                  ]}
                  isRequired={true}
                  placeholder={strings.pleaseSelect + '...'}
                  label={strings.location}
                  field='stock'
                  onChange={onChangeLocation(index)}
                  value={item.locationValue}
                  fullValue={item.locationFullValue}
                  selectAllOption
                  selectAllPlaceholder={strings.selectAll}
                  newDropdownStyle={true}
                  error={item.locationError}
                  
                />
                <Button value='Save' onClick={() => onSaveSearchTerms(index)} styleClass='mt-3 save-btn-search-term' />
              </div>
            </div>
            <div className='col-sm-6 col-md-7 col-lg-8 search-container search-container-right'>
              <AddSearchTermHeader
                onAdd={onAddHeader(index)}
                onChange={onChangeNewHeader(index)}
                value={item.headerValue}
                error={item.headerError}
                disabled={
                  item?.searchTerms?.filter(item => !item?.isDeleted)?.length >= MAX_HEADERS_ALLOWED_FOR_LOCATION
                }
              />
              {item?.searchTerms?.length
                ? item.searchTerms.map((eachSeacrhGroup, headerIndex) => {
                    return (
                      <AddTerms
                        key={eachSeacrhGroup.headerId || `header-${headerIndex}`}
                        terms={eachSeacrhGroup.terms}
                        headerValue={eachSeacrhGroup.headerValue}
                        editing={eachSeacrhGroup.editing}
                        onChange={onTermChange(index, headerIndex)}
                        value={eachSeacrhGroup.termValue}
                        onAdd={onAddTerm(index, headerIndex)}
                        error={eachSeacrhGroup.termError}
                        onDeleteTerm={onDeleteTerm(index, headerIndex)}
                        onHeaderAction={onHeaderOptionsClick(index, headerIndex)}
                        created={eachSeacrhGroup.created}
                        onSave={() => onSaveSearchTerms(index)}
                        disabled={
                          eachSeacrhGroup?.terms?.filter(item => !item?.isDeleted)?.length >= MAX_SEARCH_TERMS_ALLOWED
                        }
                        deleted={eachSeacrhGroup.isDeleted}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        );
      })}
      <Button
        value={searchTerms?.length > 0 ? strings.addAnotherLocation : strings.addLocation}
        onClick={onAddStock}
        iconName='plus'
        newVersionUi={true}
        styleClass={`mt-3 mb-3 my-inline-btn`}
        bgFill={false}
      />
    </>
  );
}

export default SearchTerms;
