import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import React, { useEffect, useCallback, useState } from 'react';
import strings from 'resources/locales/Translate';
import {
    CheckMark,
    CrossIcon,
    EditOutlineIcon,
    StockControllerDelete,
  } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { newComponentsModel } from './NewComponents.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { UPDATE_ZMCC_KIT_FORM } from '../../CreateZmccKit.constants';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Validators from 'library/utilities/Validators';
import { kitCompoennetUseStatusOptions } from 'library/utilities/constants';

export const useNewComponents = ({
    kitId,
    addUpdateNewComponent,
    fetchNewComponentsList,
    deleteNewComponent,
    newComponentsList,
    canEditKit,
    formValue,
    updateForm,
    isEditView,
    componentsFetchedFromIbase,
    isInEditOrCreateorCopyMode,
    ibaseComponentsList,
    setVisibleComponents
  }) => {
    const [newComponentsUpdatedList, setNewComponentsUpdatedList] = useState([]);
    const [componentsFetchedFromIbaseState, setComponentsFetchedFromIbaseState] = useState(true);
    const [searchData, setSearchData] = useState({ searchText: '' });
    const [editingFileds, setEditingFields] = useState([-1]);
    const [loadMoreClicked, setLoadMoreClicked] = useState(false);
    const [editingFieldsBackup, setEditingFieldsBackup] = useState([]);
    const [loading, setLoading] = useState(false);

    const [pageData, setPageData] = useState({
      page: 0,
      size: 20,
      totalPages: undefined,
      totalElements: undefined
    });


  useEffect(() => {
    const updateFullValue = (list) => {
      return list?.map(item => ({
        ...item,
        userStatusFullValue: kitCompoennetUseStatusOptions.find(option => option.value === item.userStatus)
      }));
    };

    if (ibaseComponentsList?.length && componentsFetchedFromIbaseState) {
      setNewComponentsUpdatedList(ibaseComponentsList);
      setVisibleComponents(ibaseComponentsList.length);
      setPageData(prev => ({ ...prev, totalPages: Math.ceil(ibaseComponentsList.length / 20), totalElements: ibaseComponentsList.length }));
      updateForm({
        type: UPDATE_ZMCC_KIT_FORM,
        newComponentsForm: { formData: {}, isFormValid: false },
        newComponentsList: ibaseComponentsList,
      });
      setComponentsFetchedFromIbaseState(false);
    } else if (!loadMoreClicked && kitId && newComponentsUpdatedList?.length === 0 && isEditView) {
      if (newComponentsList?.content?.length){
        const updatedList = updateFullValue(newComponentsList.content);
        setNewComponentsUpdatedList(updatedList);
        setVisibleComponents(newComponentsList?.content?.length);
        setPageData(prev => ({ ...prev, totalPages: newComponentsList?.totalPages, totalElements: newComponentsList?.totalElements }));
      }
    } else if (!isEditView && !loadMoreClicked && !componentsFetchedFromIbase){
      const updatedList = updateFullValue(newComponentsList.content);
      setNewComponentsUpdatedList(updatedList);
      setVisibleComponents(newComponentsList?.content?.length);
      setPageData(prev => ({ ...prev, totalPages: newComponentsList?.totalPages, totalElements: newComponentsList?.totalElements }));
    } else if (!componentsFetchedFromIbase && kitId && newComponentsList.length != newComponentsUpdatedList?.length && !loadMoreClicked ){
      const updatedList = updateFullValue(newComponentsList.content);
      setNewComponentsUpdatedList(updatedList);
      setVisibleComponents(newComponentsList?.content?.length);
      setPageData(prev => ({ ...prev, totalPages: newComponentsList?.totalPages, totalElements: newComponentsList?.totalElements }));
    }
  }, [ibaseComponentsList, newComponentsList, componentsFetchedFromIbase]);

  
   const loadMoreComponents = () => {
    setLoading(true);
    setLoadMoreClicked(true);
     const nextPage = pageData.page + 1;
     if (kitId){
       fetchNewComponentsList(kitId, nextPage, pageData.size).then(response => {
         if (response.success) {
           const updatedList = [...newComponentsUpdatedList, ...response.response.content];
           setNewComponentsUpdatedList(updatedList);
           setVisibleComponents(updatedList.length);
           setPageData(prev => ({
              ...prev,
              page: nextPage,
              totalPages: response.response.totalPages,
              totalElements: response.response.totalElements
            }));
          }
          setLoading(false);
       });
     }
   };

    const addNewComponentToForm = async () => {
      const validForm = validateForm({ form: formValue.newComponentsForm, model: newComponentsModel });
      
      if (!validForm.isFormValid) {
        return updateForm({ type: UPDATE_ZMCC_KIT_FORM, newComponentsForm: validForm });
      } else {
        if (kitId && !componentsFetchedFromIbase) {
          const { success } = await addUpdateNewComponent({ ...formValue.newComponentsForm.formData, id: null, "kitInformationId": kitId , isMainComponent: false}, true);
          if (success){
            setLoadMoreClicked(false);
            setPageData(prev => ({
              ...prev, page : 0
            }));
            fetchNewComponentsList(kitId);
          }
          updateForm({ type: UPDATE_ZMCC_KIT_FORM, newComponentsForm: { formData: {
              "userStatus": "ACTV",
              "userStatusError": null,
              "userStatusFullValue": {
                  "id": "Active",
                  "value": "ACTV"
              }
          }, isFormValid: false } });
        } else {

          const formData = { ...formValue.newComponentsForm.formData };

          const newComponent = { ...formData, id: (newComponentsUpdatedList?.length || 0) + 1, "kitInformationId": kitId, newStateComponent: true, isMainComponent: false };
          const updatedList = [newComponent, ...(newComponentsUpdatedList || [])];

          setNewComponentsUpdatedList(updatedList);

          updateForm({
            type: UPDATE_ZMCC_KIT_FORM,
            newComponentsForm: { formData: {
                "userStatus": "ACTV",
                "userStatusError": null,
                "userStatusFullValue": {
                    "id": "Active",
                    "value": "ACTV"
                },
              },
            isFormValid: false },
            newComponentsList: updatedList
          });
          setVisibleComponents(updatedList.length);
          setPageData(prev => ({
             ...prev,
             totalPages: Math.ceil(updatedList.length/20),
             totalElements: updatedList.length
           }));
        }
      }
    };

    const onChange = (e, id, field) => {
      const changedValues = newComponentsUpdatedList.map(item => {
        if (item.id === id) {
          delete [`${field}Error`];
          return { ...item, [field]: e };
        } else {
          return item;
        }
      });

      setNewComponentsUpdatedList(changedValues);
    };

    const onEditClick = (item,id) => {
      setEditingFields(prevSate => [...prevSate, id]);
      setEditingFieldsBackup(prevState => [...prevState, item]);
    };

    const onDeleteClick  = async (id, kitId) =>{
      if (kitId && !componentsFetchedFromIbase) {
        const {success} = await deleteNewComponent(id,kitId);
        if (success){
          setLoadMoreClicked(false);
          setPageData(prev => ({
            ...prev, page : 0
          }));
          fetchNewComponentsList(kitId);
        }
      } else{
        const filterData = newComponentsUpdatedList.filter(item => item.id !== id);
        setNewComponentsUpdatedList(filterData);
        setVisibleComponents(filterData.length);
        setPageData(prev => ({
          ...prev,
          totalElements: prev.totalElements - 1,
          totalPages: Math.ceil((prev.totalElements - 1) / prev.size)
        }));
        updateForm({ type: UPDATE_ZMCC_KIT_FORM, newComponentsList: filterData });
      }
    };

    const checkValidation = (id, filterData) => {    
      let isValid = true;
      let validObj = {};
      if (filterData[0].componentName === '') {
        validObj = { ...validObj, componentNumberError: 'requiredErrorMessage' };
        isValid = false;
      } else {
        validObj = { ...validObj, componentNumberError: '' };
      }
    
      if (Validators.number(filterData[0].componentNumber, 'invalidNumberError')) {
        validObj = { ...validObj, componentNumberError: 'invalidNumberError' };
        isValid = false;
      } else {
        validObj = { ...validObj, componentNumberError: '' };
      }
    
      if (filterData[0].materialNumber === '' || filterData[0].materialNumber === null || filterData[0].materialNumber === undefined) {
        validObj = { ...validObj, materialNumberError: 'requiredErrorMessage' };
        isValid = false;
      } else if (Validators.materialNumber(filterData[0].materialNumber, 'materialNumberFormatErrorOnKitComponent')) {
        validObj = { ...validObj, materialNumberError: 'materialNumberFormatErrorOnKitComponent' };
        isValid = false;
      } else {
        validObj = { ...validObj, materialNumberError: '' };
      }
    
      if (!filterData[0].userStatus) {
        validObj = { ...validObj, userStatusError: 'requiredErrorMessage' };
        isValid = false;
      } else {
        validObj = { ...validObj, userStatusError: '' };
      }
        
      const changedValues = newComponentsUpdatedList.map(item => {
        if (item.id === id) {
          return { ...item, ...validObj };
        }
        return item;
      });
      setNewComponentsUpdatedList(changedValues);
      return isValid;
    };

    const onSaveEditData = async id => {       
      const filterData = newComponentsUpdatedList.filter(item => item.id === id);

      const item = filterData[0];      
    
      if (!checkValidation(id, filterData)) {        
        return;
      }

      const dataToSend = {
        componentNumber: item.componentNumber,
        componentName: item.componentName,
        materialNumber: item.materialNumber,
        serialNumber: item.serialNumber,
        userStatus: item.userStatus,
        kitInformationId: kitId,
        id: item.newStateComponent ? null : item.id,
        isMainComponent: item?.isMainComponent ? item?.isMainComponent : false,
        quantity : item.quantity
      };      
    
      if (kitId && !componentsFetchedFromIbase) {
        const { success } = await addUpdateNewComponent(dataToSend, false);
        if (success) {
          fetchNewComponentsList(kitId, pageData.page, pageData.size);
        }
      } else {
        const updatedList = newComponentsUpdatedList.map(item => {
          if (item.id === id) {
            return dataToSend;
          }
          return item;
        });
        setNewComponentsUpdatedList(updatedList);
        updateForm({ type: UPDATE_ZMCC_KIT_FORM, newComponentsList: updatedList });
      }
      setEditingFields(editingFileds.filter(item => item !== id));
    };

    const onAddComponentClick = () => {
      addNewComponentToForm();
    }

    
    const handleDropdownChange = (value, id, field) => {
      const updatedList = newComponentsUpdatedList.map(item => {
        if (item.id === id) {
          const fullValueHere = kitCompoennetUseStatusOptions.find(option => option.value === value);
          return { ...item, [field]: value, [`${field}FullValue`]: fullValueHere };
        }
        return item;
      });
      setNewComponentsUpdatedList(updatedList);
    };

    const onCancelClick = id => {
      let originalItem;
      if (componentsFetchedFromIbase){
        originalItem = ibaseComponentsList.find(item => item.id === id);
      } else if (!originalItem) {
        originalItem = newComponentsList?.content?.find(item => item.id === id);
      }       
      if (!originalItem) {
        originalItem = editingFieldsBackup.find(item => item.id === id);
      }
      const updatedList = newComponentsUpdatedList.map(item => {
        if (item.id === id) {
          return originalItem;
        }
        return item;
      });
      setNewComponentsUpdatedList(updatedList);
      setEditingFields(editingFileds.filter(item => item !== id));
    };

    const getCols = () => [
        {
          title: strings.kitComponenetDescription,
          name: 'componentName',
          isSort: false,
          render: item => {
            return (
              <span>
                {editingFileds.includes(item.id) ? (
                  <InputField
                    label={''}
                    field='componentName'
                    placeholder={''}
                    isRequired={true}
                    type='text'
                    validators={[]}
                    value={item.componentName}
                    error={item.componentNameError}
                    onChange={e => onChange(e, item.id, 'componentName')}
                  />
                ) : (
                  item.componentName
                )}
              </span>
            );
          },
        },
          {
            title: strings.kitComponentID,
            name: 'componentNumber',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <InputField
                      label={''}
                      field='componentNumber'
                      placeholder={''}
                      isRequired={true}
                      type='text'
                      validators={[]}
                      value={item.componentNumber}
                      error={item.componentNumberError}
                      onChange={e => onChange(e, item.id, 'componentNumber')}
                    />
                  ) : (
                    item.componentNumber
                  )}
                </span>
              );
            },
          },
          {
            title: strings.kitComponentProductID,
            name: 'materialNumber',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <InputField
                      label={''}
                      field='materialNumber'
                      placeholder={''}
                      isRequired={true}
                      type='text'
                      validators={[]}
                      value={item.materialNumber}
                      error={item.materialNumberError}
                      onChange={e => onChange(e, item.id, 'materialNumber')}
                    />
                  ) : (
                    item.materialNumber
                  )}
                </span>
              );
            },
          },
          {
            title: strings.serialNumber,
            name: 'serialNumber',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <InputField
                      label={''}
                      field='serialNumber'
                      placeholder={''}
                      isRequired={true}
                      type='text'
                      validators={[]}
                      value={item.serialNumber}
                      error={item.serialNumberError}
                      onChange={e => onChange(e, item.id, 'serialNumber')}
                    />
                  ) : (
                    item.serialNumber
                  )}
                </span>
              );
            },
          },
          {
            title: strings.quantity,
            name: 'quantity',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <InputField
                      label={''}
                      field='quantity'
                      placeholder={''}
                      isRequired={true}
                      type='text'
                      validators={[]}
                      value={item.quantity}
                      error={item.quantityError}
                      onChange={e => onChange(e, item.id, 'quantity')}
                    />
                  ) : (
                    item.quantity ?  item.quantity : '-'
                  )}
                </span>
              );
            },
          },
          {
            title: strings.kitComponentUserStatus,
            name: 'userStatus',
            isSort: false,
            render: item => {
              return (
                <span>
                  {editingFileds.includes(item.id) ? (
                    <Dropdown
                      data={kitCompoennetUseStatusOptions}
                      hasSection={false}
                      multiSelect={false}
                      filter={false}
                      idKey='value'
                      field='userStatus'
                      displayValue={[{ key: 'id', separator: ' ' }]}
                      isRequired
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      placeholder={strings.pleaseSelect}
                      onChange={(value) => handleDropdownChange(value, item.id, 'userStatus')}
                      value={item.userStatus}
                      fullValue={item.userStatusFullValue || kitCompoennetUseStatusOptions.find(option => option.value === item.UserStatus)}
                      error={item.userStatusError}
                  />
                  ) : (
                    item.userStatus === 'ACTV' ? 'Active' : (item.userStatus === 'DACT'  || item.userStatus ===  'INACTV') ? 'Inactive' : '-'
                  )}
                </span>
              );
            },
          },
          {
            title: '',
            name: 'deleteOrEdit',
            isSort: false,
            render: item => {
              return (
                <span>
                  {!editingFileds.includes(item.id) && canEditKit && isInEditOrCreateorCopyMode && (
                    <div className='adjust-delete-edit-icon cursor-pointer'>
                      <div onClick={() => onEditClick(item, item.id)}>
                        <EditOutlineIcon width={16} height={16} />
                      </div>
                      <div onClick={() => onDeleteClick(item.id, kitId)}>
                        <StockControllerDelete fill='#0088D0' />
                      </div>
                    </div>
                  )}

                  {editingFileds.includes(item.id) && item.id !== -1 && canEditKit && isInEditOrCreateorCopyMode && (
                    <div className='adjust-delete-edit-icon mt-3 cursor-pointer'>
                      <div onClick={() => onSaveEditData(item.id)}>
                        <CheckMark />
                      </div>
                      <div onClick={() => onCancelClick(item.id)}>
                        <CrossIcon />
                      </div>
                    </div>
                  )}
                </span>
              );
            },
          },
      ];

    
      return {
        getCols,
        newComponentsUpdatedList,
        onAddComponentClick,
        loadMoreComponents,
        pageData,
        loading
      };
}