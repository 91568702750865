import React, { useEffect, useState } from 'react';
import Information from './Tabs/Information/Information.component';
import { headerModel } from './IqsNewRequest.constants';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { IQS_SSC_DE_DESIGNATION } from 'library/utilities/constants';
import IqsBooking from './Tabs/IqsBooking/IqsBooking.component';
import useTitle from 'library/common/commonHooks/useTitle.hook';
import strings from 'resources/locales/Translate';
import TimeManagement from './Tabs/TimeManagement/TimeManagement.component';

export const useIqsNewRequest = props => {
  const {
    fetchDropdownData,
    allDropdownData,
    language,
    fetchMasterData,
    countries,
    location,
    match,
    fetchRequestDetlies,
    requestDetailes,
    history,
    fetachIqsBookingMasterData,
    fetchRequesedEquipmentsData,
    fetchBookingTabBookings,
    systemClasForIqs,
  } = props;

  const [headerForm, setHeaderForm] = useState({ formData: {} });
  const [requestId, setRequestId] = useState('');
  const isEdit = location.pathname.includes('edit');
  const setBookingTab = location?.state?.includes('booking-tab');
  useTitle(isEdit ? strings.editRequest + ` - #${requestId}` : strings.createRequest);

  useEffect(() => {
    fetchDropdownData();
    fetachIqsBookingMasterData();
  }, [language]);

  useEffect(() => {
    const url = `${URLS.countryList}/business-unit?businessUnitDesignation=${IQS_SSC_DE_DESIGNATION}`;
    fetchMasterData(url, FETCH_COUNTRY_LIST);
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchRequestDetlies(match.params.id);
      fetchRequesedEquipmentsData(match.params.id);
      setRequestId(match.params.id);
      fetchBookingTabBookings(match.params.id);
      systemClasForIqs('IQS (SSC DE)');
    }
  }, [match.params.id]);

  useEffect(() => {
    if (isEdit) {
      setDeatilesData();
    }
  }, [requestDetailes]);

  const setDeatilesData = () => {
    setHeaderForm({
      formData: {
        requestTitle: requestDetailes?.transactionsInformation?.name,
        requestId: requestDetailes?.transactionsInformation?.transactionsId,
        requestStatus: requestDetailes?.iqsStatus,
        requestStatusFullValue: requestDetailes?.iqsStatusTranslated,
        requestType: requestDetailes?.requestType,
        requestTypeFullValue: requestDetailes?.requestTypeTranslated,
        executionLocation: requestDetailes?.executionLocation,
        executionLocationFullValue: requestDetailes?.executionLocationTranslated,
        processingLocationId: requestDetailes?.stockInformation?.id,
        processingLocationIdFullValue: requestDetailes?.stockInformation,
        requestDescription: requestDetailes?.requestDescription,
        comment: requestDetailes?.comment,
      },
    });
  };
  const getHeaderModel = () => {
    const model = [...headerModel];
    model[2].options = allDropdownData?.IqRequestStatus || [];
    model[3].options = allDropdownData?.IqRequestType || [];
    model[4].options = allDropdownData?.IqRequestExecutionLocation || [];
    model[5].options = countries || [];
    return model;
  };

  const handleForm = ({ values, field }) => {
    setHeaderForm({
      ...headerForm,
      formData: {
        ...headerForm.formData,
        ...values,
      },
    });
  };
  const tabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: (isActive, props) => {
        return (
          <Information
            isActive={isActive}
            {...props}
            headerFormData={headerForm}
            setHeaderForm={setHeaderForm}
            getHeaderModel={getHeaderModel}
            isEdit={isEdit}
            history={history}
            match={match}
          />
        );
      },
    },

    {
      id: 2,
      tabTitle: 'homeBooking',
      className: '',
      render: isActive => {
        return (
          <IqsBooking
            isActive={isActive}
            requestId={requestId}
            history={history}
            // headerFormData={headerForm}
            // setHeaderForm={setHeaderForm}
            // getHeaderModel={getHeaderModel}
            // isEdit={isEdit}
            // history={history}
            // match={match}
          />
        );
      },
    },
    {
      id: 3,
      tabTitle: 'iqsTimeManagement',
      className: '',
      render: isActive => {
        return (
          <TimeManagement
            isActive={isActive}
            requestId={requestId}
            history={history}
            // headerFormData={headerForm}
            // setHeaderForm={setHeaderForm}
            // getHeaderModel={getHeaderModel}
            // isEdit={isEdit}
            // history={history}
            // match={match}
          />
        );
      },
    },
  ];

  return {
    tabs,
    getHeaderModel,
    handleForm,
    headerForm,
    setBookingTab,
    isEdit,
  };
};
