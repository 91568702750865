import { NavLink } from 'react-router-dom';
import React, { PureComponent } from 'react';

import strings from 'resources/locales/Translate';
import pdfEn from 'resources/pdf/Guidance_Accounting_Treatment_Final_15.07.2020.pdf';
import pdfGe from 'resources/pdf/Demo_Inventory_Guidelines_dt_Version 1.0_Juni 2020.pdf';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { scrollToTop } from 'library/utilities/scrollActions';
import { images } from 'library/common/commonConstants/ImageConstants';

import { footerLinks, footerModel } from './footerModel';
import Icon from '../Icon/Icon';
import './footer.styles.scss';

class Footer extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", () => this.updateDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateDimensions());
  }

  updateDimensions = () => {
    if (window.innerWidth < 500) {
      this.setState({width: 500, height: 102});
    } else {
      let update_width = window.innerWidth;
      let update_height = Math.round(update_width / 4.4);
      this.setState({width: update_width, height: update_height});
    }
  };

  render() {
    const {width} = this.state;
    const {language} = this.props.language;
    const pdf = language === 'en' ? pdfEn : pdfGe;
    const currentDate = new Date();
    const dateFrom = '2006';
    const dateTo = getDateInFormat('YYYY', currentDate);

    return (
      <div className='main-container position-relative'>
        <div className='container-fluid'>
          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className="order-2 order-md-1 mt-4 mt-md-2">
              <p className='bottom-text'>© {`${dateFrom}–${dateTo}`} ZEISS</p>
            </div>
            <div className='d-flex flex-wrap order-1 order-md-2 mt-2'>
              <a href={footerLinks.imprints[language]} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.imprintsText}</p>
              </a>
              <a href={footerLinks.legalNotice} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.legalNoticesText}</p>
              </a>
              <a href={footerLinks.dataProtection} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.dataProtectionText}</p>
              </a>
              <a href={pdf} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.termsAndConditionText}</p>
              </a>
              <a href={footerLinks.carlZeissInternational} target='_blank' rel="noopener noreferrer">
                <p className='bottom-text'>{strings.carlZeissInternational}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
