import React from 'react';
import { DeleteIcon, EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import strings from 'resources/locales/Translate';
import { CrossIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import { TickIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

function AddTerms({
  terms = [],
  headerValue = '',
  editing = false,
  onChange = () => {},
  value = '',
  onAdd = () => {},
  error = '',
  onDeleteTerm = () => {},
  onHeaderAction = () => {},
  disabled = false,
  deleted = false,
}) {
  if (deleted) return null;
  return (
    <div className='add-edit-term-container w-100'>
      <div className='header'>
        <p>{headerValue}</p>
        <div className='d-flex'>
          <div className='d-flex mr-2' onClick={() => onHeaderAction('edit')} style={{ cursor: 'pointer' }}>
            <EditOutlineIcon />
            <span className='ml-2 edit-icon'>{strings.edit}</span>
          </div>
          <span
            onClick={() => {
              onHeaderAction('delete');
            }}
            style={{ cursor: 'pointer' }}
          >
            <DeleteIcon fill='#5D6676' />
          </span>
        </div>
      </div>
      {editing && (
        <div className='input-container'>
          <InputField
            value={value || ''}
            label={'Add Search Terms'}
            className='term-input-container mr-3'
            onChange={onChange}
            disabled={disabled}
            error={error}
            isRequired={true}
          />
          <button className={`ok-button ${error ? 'align-self-center' : 'mt-3'}`} onClick={onAdd} disabled={disabled}>
            <TickIcon />
          </button>
          <button className={`cancel-button ${error ? 'align-self-center' : 'mt-3'}`} onClick={() => onChange('')} disabled={disabled}>
            <CrossIcon />
          </button>
        </div>
      )}

      <div className='terms-container'>
        {terms?.map((term, index) => (
          <React.Fragment key={term.termId}>
            {term?.isDeleted ? null : (
              <div key={index} className='d-flex align-items-center term '>
                <span>{term.termValue}</span>
                {editing && (
                  <span className='ml-1 mb-1' style={{ cursor: 'pointer' }} onClick={() => onDeleteTerm(index)}>
                    <CrossIcon width='15' height='16' viewBox='0 0 16 19' />
                  </span>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {/* {created && editing && (
        <Button value={strings.save} styleClass=' save-btn-search-term save-btn-search-header' onClick={onSave} />
      )} */}
    </div>
  );
}

export default AddTerms;
