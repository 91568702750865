import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'library/common/commonComponents/Modal';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Button from 'library/common/commonComponents/Button/Button';
import { updateUserCheckedDontShowAgain } from '../HomepageActions';
import { APP_URL_CHANGE_POPUP } from 'library/common/commonConstants/storeConstants';

const MODAL_ID = 'app-url-change-notify';

function AppUrlChangePopup(props) {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleOnChange = (checked, field) => {
    setDontShowAgain(checked);
  };

  // useEffect(() => {
  //   $(`#${MODAL_ID}`).modal('hide');
  //   handleShowUrlChangePopup();
  // }, [props?.authentication?.isLoggedIn, props?.authentication?.isAuthLoading]);

  const handleOnSubmit = async () => {
    $(`#${MODAL_ID}`).modal('hide');
    if (dontShowAgain) {
      await updateUserCheckedDontShowAgain(APP_URL_CHANGE_POPUP);
    }
    props.onClose(APP_URL_CHANGE_POPUP)
  };

  const handleOnClose = () => {
    setShowPopup(false)
    $(`#${MODAL_ID}`).modal('hide');
    props.onClose(APP_URL_CHANGE_POPUP)
  }
  const oldDomainJsx = <a href={`${process.env.REACT_APP_OLD_URL}`}>{process.env.REACT_APP_OLD_URL}</a>;
  const newDomainJsx = <a href={`${process.env.REACT_APP_BASE_URL}`}>{process.env.REACT_APP_BASE_URL}</a>;

  return (
    <Modal
      id={MODAL_ID}
      onCloseClick={handleOnClose}
      titleOfCancel='Cancel'
      confirmTitle='Okay'
      confirmAction={() => console.log('Cliked okay')}
      title={strings.appUrlChangeSubject}
      className='modal-lg'
      buttonStyles={'d-none'}
    >
      <p>
        {strings.appUrlChangeBodyOne} {oldDomainJsx} {strings.appUrlChangeBodyTwo} {newDomainJsx}.{' '}
        {strings.thankYou}
      </p>
      <div className='d-flex justify-content-between mt-3'>
        <Checkbox
          field={'dontShowAgain'}
          label={strings.dontShowAgain}
          onChange={handleOnChange}
          selected={dontShowAgain}
        />
      </div>
      <div className='d-flex justify-content-center mt-2'>
        <Button value={'Okay'} styleClass={'w-25'} onClick={handleOnSubmit} />
      </div>
    </Modal>
  );
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    authentication: state.authReducer,
    homepage: state.homepage,
    appActions: state.appActionsReducer,
  };
};

export default connect(mapStateToProps, {})(AppUrlChangePopup);
