import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import './Popover.styles.scss';

const PopoverComponent = ({
  children,
  content,
  position = 'bottom',
  offset = 8,
  xOffset = 0,
  yOffset = 0,
  popoverInnerClassName = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popoverStyle, setPopoverStyle] = useState({});
  const [arrowStyle, setArrowStyle] = useState({});
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);
  const timeoutRef = useRef(null);

  const calculatePosition = () => {
    if (!isOpen || !popoverRef.current || !buttonRef.current) return;

    const popover = popoverRef.current.getBoundingClientRect();
    const button = buttonRef.current.getBoundingClientRect();
    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight,
      scrollX: window.scrollX,
      scrollY: window.scrollY,
    };

    let newPosition = position;
    let coords = { top: 0, left: 0 };
    let arrow = { top: '50%', left: '50%' };

    const space = {
      top: button.top - popover.height - offset,
      bottom: viewport.height - (button.bottom + popover.height + offset),
      left: button.left - popover.width - (offset + xOffset),
      right: viewport.width - (button.right + popover.width + offset),
    };

    const bestPosition = Object.entries(space).reduce((a, b) => (space[a[0]] > space[b[0]] ? a : b))[0];
    newPosition = position === 'auto' ? bestPosition : position;

    switch (newPosition) {
      case 'top':
        coords = {
          left: button.left + (button.width - popover.width) / 2,
          top: button.top - popover.height - offset,
        };
        arrow = { bottom: '-4px', left: '50%' };
        break;
      case 'bottom':
        coords = {
          left: button.left + (button.width - popover.width) / 2,
          top: button.bottom + offset,
        };
        arrow = { top: '-4px', left: '50%' };
        break;
      case 'left':
        coords = {
          left: button.left - popover.width - offset,
          top: button.top + (button.height - popover.height) / 2,
        };
        arrow = { right: '-4px', top: '50%' };
        break;
      case 'right':
        coords = {
          left: button.right + offset,
          top: button.top + (button.height - popover.height) / 2,
        };
        arrow = { left: '-4px', top: '50%' };
        break;
    }

    coords.left = Math.min(Math.max(offset, coords.left), viewport.width - popover.width - offset);
    coords.top = Math.min(Math.max(offset, coords.top), viewport.height - popover.height - offset);

    setPopoverStyle({
      position: 'fixed',
      top: `${coords.top}px`,
      left: `${coords.left}px`,
      transform: 'none',
      zIndex: 99999,
    });

    setArrowStyle({
      ...arrow,
      transform: 'translate(-50%, -50%)',
    });
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  useEffect(() => {
    if (isOpen) {
      calculatePosition();
      window.addEventListener('scroll', calculatePosition);
      window.addEventListener('resize', calculatePosition);
    }

    return () => {
      window.removeEventListener('scroll', calculatePosition);
      window.removeEventListener('resize', calculatePosition);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpen, content]);

  return (
    <div className='popover-wrapper w-100'>
      <div
        ref={buttonRef}
        className='popover-trigger'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setIsOpen(!isOpen)}
      >
        {children}
      </div>
      {isOpen && (
        <div
          ref={popoverRef}
          className='popover-content'
          style={popoverStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className='popover-arrow' style={arrowStyle} />
          <div className={cn('popover-inner', popoverInnerClassName)}>{content}</div>
        </div>
      )}
    </div>
  );
};

export default PopoverComponent;
