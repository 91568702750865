import React, { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';
import moment from 'moment';

import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

import './dateInput.styles.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { getLocale } from 'library/utilities/getLocale';

export const DateInput = props => {
  const {
    clearIcon,
    datePickerFieldStyle,
    placeholder,
    dateFormat,
    dataTest,
    label,
    additionalLabel,
    footerLabel,
    datePickerLabelStyle,
    mainContainerStyle,
    isRequired,
    hideOptionalText,
    minDate,
    maxDate,
    isRange,
    disabled,
    selectedDate,
    error,
    onBlur,
    isOpenDateModal,
    momentDateFormat,
    showClearIcon,
  } = props;

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const language = useSelector(state => state.languageReducer);

  const onChange = date => {
    props.onChange(date ? date : '', props.field);
  };

  const onDateReset = e => {
    e.preventDefault();
    props.onChange(null, props.field);
  };

  const toggleCalendar = () => {
    if (isOpenDateModal) {
      setIsCalendarVisible(!isCalendarVisible);
    }
  };

  const handleClick = e => {
    if (!isOpenDateModal) {
      props.onClick(e);
    }
  };

  const getDateValue = () => {
    if (selectedDate && selectedDate.length === 2) {
      return [new Date(selectedDate[0]), new Date(selectedDate[1])];
    }

    // Added new key because of date is not setting proper in differebt time zones
    if (momentDateFormat === true && selectedDate) {
      return new Date(selectedDate);
    }else{
      return selectedDate;
    }
    
  };

  const handleClear = (e) => {
    e.stopPropagation();
    const { onChange, field } = props;
    if (onChange) {
      onChange("", field);
    }
  };
  
  const displayDate = selectedDate ? moment(selectedDate).format(dateFormat) : placeholder;
  let displayDateRange = selectedDate && selectedDate.length === 1 && moment(selectedDate[0]).format(dateFormat);
  if (selectedDate && selectedDate.length > 1) {
    displayDateRange = `${moment(selectedDate[0]).format(dateFormat)} - ${moment(selectedDate[1]).format(dateFormat)}`;
  }
  const datePlaceholder = isRange && Array.isArray(selectedDate) ? displayDateRange : displayDate;
  return (
    <div className={`date-input-container ${mainContainerStyle}`} data-test={dataTest} onClick={handleClick}>
      
        <p className={'date-picker-label ' + datePickerLabelStyle}>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p> 
      {!!additionalLabel && <p className='date-picker-additional-label'>{additionalLabel}</p>}
      <div className={`app-datepicker cursor-pointer ${datePickerFieldStyle} ${error && 'input-error'}`}>
        <div
          className={
            'd-flex datepicker-view justify-content-between align-items-center text-overflow-ellipsis ' +
            (isCalendarVisible && 'calendar-shadow') +
            (disabled && ' disabled')
          }
          onClick={!disabled ? toggleCalendar : null}
        >
          <span>{datePlaceholder}</span>
          <div>
            {clearIcon && selectedDate ? (
              <span className='mr-1' onClick={onDateReset}>
                &times;
              </span>
            ) : null}
            {selectedDate && showClearIcon === true && (
              <span className="dropdown-clear-icon mr-3" onClick={handleClear}>
                <Icon name="clear" width="13" height="13" viewBox="0 0 48 48" fill={'#737678'} />
              </span>
            )}
            <Icon name='calendar' width={24} height={24} fill={'rgba(0,0,0,0.5)'} />
          </div>
        </div>
        <DatePicker
          data-test={dataTest}
          {...props}
          minDetail={'decade'}
          className={'datepicker-component-container'}
          calendarClassName={'datepicker-calendar-container calendar-shadow'}
          calendarIcon={<Icon name='calendar' width={24} height={24} />}
          clearIcon={selectedDate ? <span>&times;</span> : null}
          showLeadingZeros={true}
          isOpen={isCalendarVisible}
          onChange={onChange}
          onBlur={onBlur}
          value={getDateValue()}
          minDate={minDate && moment(minDate).isValid() ? new Date(minDate) : null}
          maxDate={maxDate && moment(maxDate).isValid() ? new Date(maxDate) : null}
          selectRange={isRange ? true : false}
          tileDisabled={({date}) => 
            props.disabledDates && props.disabledDates.length > 0 && !props.disabledDates.includes(`${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`)
          }
          locale={getLocale(language.locale)}
        />
      </div>
      {!!footerLabel && <p className='date-picker-footer-label'>{footerLabel}</p>}
      {error && <span className='text-danger error-block'>{strings[error] || error}</span>}
    </div>
  );
};

DateInput.propTypes = {
  onChange: PropTypes.func,
  datePickerFieldStyle: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  datePickerLabelStyle: PropTypes.string,
  mainContainerStyle: PropTypes.string,
  selectedDate: PropTypes.any,
  isRange: PropTypes.bool,
};

DateInput.defaultProps = {
  placeholder: strings.pleaseSelectDate + ' ...',
  onChange: () => {},
  datePickerFieldStyle: '',
  minDate: null,
  dateFormat: 'DD.MM.YYYY',
  label: strings.selectDate,
  mainContainerStyle: '',
  isRequired: false,
  selectedDate: null,
  validators: [],
  onError: () => {},
  doValidateForm: false,
  isRange: false,
  isOpenDateModal: true,
  onClick: () => {},
};

export default DateInput;
