import React, { useState } from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { connect } from 'react-redux';
import Section from 'library/common/commonComponents/Section/Section';
import moment from 'moment';
import * as $ from 'jquery';
import { deleteBooking } from '../../IQSCalendar.actions';

export const BookedEventDetailsPopup = ({ id, editBookedData, deleteBooking, filters }) => {
  const [error, setError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const onEditClick = () => {
    onCloseClick();
    setTimeout(() => {
      $(`#edit-booking-modal`).modal('show');
    }, 500);
  };
  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
  };
  const onDeleteClick = async () => {
    setDeleteLoading(true);
    const res = await deleteBooking(editBookedData?.bookingId, filters);
    setDeleteLoading(false);
    if (!res.success) {
      if (
        res?.error?.response?.status === 422 ||
        res?.error?.response?.status === 403 ||
        res?.error?.response?.status === 404
      ) {
        setError(res.error.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      onCloseClick();
    }
  };
  return (
    <Modal
      id={id}
      title={strings.showBooking}
      confirmAction={onEditClick}
      confirmTitle={strings.edit}
      //   loading={loading}
      onCloseClick={onCloseClick}
      className='add-location-comment-popup-width'
      newVersionUi={true}
      titleOfCancel={strings.close}
      extraButton={true}
      extraButtonClick={onDeleteClick}
      extraButtonLodaing={deleteLoading}
    >
      <div className='col-12 p-0'>
        <Section sectionTitle={strings.information}>
          <div className='mt-3 col-12 row p-0'>
            <div className='col-4'>{strings.bookingCategory}</div>
            <div className='col-6'>{editBookedData.bookingTypeTranslated}</div>
            <div className='col-4'>{strings.bookingType}</div>
            <div className='col-6'>{editBookedData.bookingCategoryTranslated}</div>
            <div className='col-4'>{strings.duration}</div>
            {editBookedData?.bookingFrom && (
              <div className='col-6'>
                {moment.utc(editBookedData?.bookingFrom).local().format('YYYY-MM-DD HH:mm:ss')}
                <br></br>
                {moment.utc(editBookedData?.bookingTo).local().format('YYYY-MM-DD HH:mm:ss')}
              </div>
            )}
          </div>
        </Section>
      </div>
      <div className='col-12 p-0 mt-3'>
        <Section sectionTitle={strings.otherBooking}>
          <div className='mt-3 col-12 row p-0'>
            <div className='col-4'>{strings.scheduleInputTitle}</div>
            <div className='col-6'>{editBookedData.otherBookingTitle}</div>
            <div className='col-4'>{strings.descriptionText}</div>
            <div className='col-6'>{editBookedData.otherBookingDescription}</div>
          </div>
        </Section>
      </div>
      <div className='col-12 p-0 mt-3'>
        <Section sectionTitle={strings.resources}>
          {editBookedData?.bookedResources?.map((resource, index) => {
            return (
              <div key={index} style={{ marginBottom: '20px' }}>
                <div className='selected-resource' style={{ fontWeight: '700' }}>
                  {resource.kitName}
                </div>
                <div className='selected-resource' style={{ fontWeight: '400' }}>
                  {`${resource.resourceTypeTranslated}, ${resource.kitName}`}
                </div>
                <div className='selected-resource' style={{ fontWeight: '400' }}>
                  {resource.locationName}, {resource.roomName}
                </div>
              </div>
            );
          })}
          {editBookedData?.bookedPersons?.map((person, index) => {
            return (
              <div key={index} style={{ marginBottom: '20px' }}>
                <div className='selected-resource' style={{ fontWeight: '700' }}>
                  {`${person.firstName} ${person.lastName}`}
                </div>
                <div className='selected-resource' style={{ fontWeight: '400' }}>
                  {'Person'}, {`${person.firstName} ${person.lastName}`}
                </div>
              </div>
            );
          })}
        </Section>
      </div>
      {error && <span className='text-danger error-block'>{error}</span>}
    </Modal>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, calendar }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  editBookedData: calendar.editBookedData,
});

export default connect(mapStateToProps, { deleteBooking })(BookedEventDetailsPopup);
