import {
  FETCH_IQS_BOOKING_MASTER_DATA,
  FETCH_ALLIQS_REQUEST_DROPDOWN_DATA,
  FETCH_INDUSTRILA_SUB_SEGMENTS,
  FETCH_IQS_REQUEST_DETAILES,
  FETCH_IQS_REQUESTED_EQUIPMENTS_DATA,
  FTECH_BOOKING_TAB_BOOKINGS,
  FETCH_SYSTEMCLASS_DATA,
  FETCH_IQS_TIME_MANAGEMENT_LIST,
} from './IqsNewRequest.constants';

export const initialState = {
  allDropdownData: {},
  industrialSubSegments: [],
  requestDetailes: [],
  iqsBookingMasterData: [],
  iqsrequestedEquipmentsData: [],
  iqsBookingTabsBookngs: [],
  iqsSystemClass: [],
  timeManagementList: [],
};
const IqsRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALLIQS_REQUEST_DROPDOWN_DATA:
      return {
        ...state,
        allDropdownData: action.payload,
      };
    case FETCH_INDUSTRILA_SUB_SEGMENTS:
      return {
        ...state,
        industrialSubSegments: action.payload,
      };
    case FETCH_IQS_REQUEST_DETAILES:
      return {
        ...state,
        requestDetailes: action.payload,
      };
    case FETCH_IQS_BOOKING_MASTER_DATA:
      return {
        ...state,
        iqsBookingMasterData: action.payload,
      };
    case FETCH_IQS_REQUESTED_EQUIPMENTS_DATA:
      return {
        ...state,
        iqsrequestedEquipmentsData: action.payload,
      };
    case FTECH_BOOKING_TAB_BOOKINGS:
      return {
        ...state,
        iqsBookingTabsBookngs: action.payload,
      };
    case FETCH_SYSTEMCLASS_DATA:
      return {
        ...state,
        iqsSystemClass: action.payload,
      };
      case FETCH_IQS_TIME_MANAGEMENT_LIST:
      return {
        ...state,
        timeManagementList: action.payload,
      };
    default:
      return state;
  }
};

export default IqsRequestReducer;
