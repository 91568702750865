import React, { useState, useEffect } from 'react';
import Icon from "../../../library/common/commonComponents/Icon/Icon";
import strings from 'resources/locales/Translate';
import { object } from 'prop-types';
import { validateNameEmailAndFunctionInAttendees } from './SemBooking.actions';
import { regex } from 'library/common/commonConstants/RegexConstants';
import { ZMCC_CREATED } from 'library/common/commonConstants/storeConstants';


const ZMCCTabs = (props) => {
  const {
    saveCurrentTab,
    toggleTab = true,
    tabs,
    currentTab,
    getTabId,
    isXbBooking,
    isSemBooking,
    isXRMBooking,
    isLMBooking,
    zmccXrmBooking,
    zmccLmBooking,
    zmccXbBooking,
    zmccSemBooking,
  } = props;
  const [activeTab, setActiveTab] = useState(currentTab || 1);
  const [tabCompletionStatus, setTabCompletionStatus] = useState({});
  const {formValue} = props;;
  const {
    generalInformationForm, schedulingInformationForm, projectDetailsForm,
    customerDetilsForm, primaryPointOfContactForm, customerBackgroundForm,
    mainApplicationChecklistForm, sampleDeatilsFrom, 
    demoCenterInformationForm, additionalInformationForm,
    logisticsAndCoordinationForm, 
    todosListForm,
    transactionsId,
    xbMainApplicationChecklistForm,
    xbSampleDeatilsFrom,
    xrmDemoDetailsForm, 
    xrmApplicationInformationForm, 
    xrmSamplesDetailsForm, 
    xrmSampleDescriptionForm, 
    xrmSampleSpecsForm,
    lmApplicationAndSamplesForm
  } = formValue;
  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab])

  useEffect(() => {
    const updatedCompletionStatus = { ...tabCompletionStatus };
    updatedCompletionStatus[activeTab] = checkTabCompletion(activeTab);
    setTabCompletionStatus(updatedCompletionStatus);
  }, [activeTab, formValue]);

  const toggleTabFunc = (id) => () => {
    if (toggleTab && activeTab !== id) {
      setActiveTab(id);
      getTabId(id)
      saveCurrentTab && saveCurrentTab(id);
    }
  }
  const generalInformationMandatoryData = ['stockInformationId','requesterId'];
  const schedulingInformationMandatoryData = ['suggestedDate','kitInformationId'];
  const projectDetailsMandatoryData = [];
  const intendedIdsForOpportunityMandatory =[1,4,5,6,14];
  if ( generalInformationForm.formData.intendedForId && intendedIdsForOpportunityMandatory.includes(generalInformationForm.formData.intendedForId)){
    projectDetailsMandatoryData.push('opportunityId');
  }
  if (schedulingInformationForm.formData.additionalInstrument && schedulingInformationForm.formData.additionalInstrument.includes(0)) {
    schedulingInformationMandatoryData.push('corrMicDetails');
  }
  
  const checkGeneralInformationMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let keys = [...Object.keys(generalInformationForm.formData), ...Object.keys(schedulingInformationForm.formData),  ...Object.keys(projectDetailsForm.formData)];
    generalInformationMandatoryData.map(key => {
        if (!keys.includes(key)){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        } else if(!generalInformationForm.formData[key]){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        }
    });

    schedulingInformationMandatoryData.map(key => {
      if (!keys.includes(key)){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }else if(!schedulingInformationForm.formData[key]){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    projectDetailsMandatoryData.map(key => {
      if (!keys.includes(key)){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if (key === 'opportunityId' && (projectDetailsForm.formData.opportunityIdError === "invalidNumberError" || projectDetailsForm.formData.opportunityIdError === 'correctOpportunityMessage' ) ) {
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
      else if(!projectDetailsForm.formData[key]){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    return isMandatoryFilled;
  }

  const generalInformationTabError = checkGeneralInformationMandatoryFilled();

  let customerDetilsMandatoryData = ['customerName', 'customerOrganization'];
  customerDetilsForm?.formData?.customerDescisionMaker ? customerDetilsMandatoryData = ['customerName', 'customerOrganization', 'customerSkillLevelId'] :  customerDetilsMandatoryData = ['customerName', 'customerOrganization'] ;
  const customerBackgroundMandatoryData =[];
  let primaryPointOfContactMandatory = [];
  if (customerDetilsForm.formData.customerPointOfContact === 'no') {
    primaryPointOfContactMandatory.push('pocName', 'pocEmail');
  }
  const checkCustomerInformationMandatoryFilled  = () =>{
    let isMandatoryFilled = true;
    let keys = [...Object.keys(customerDetilsForm.formData),  ...Object.keys(customerBackgroundForm.formData), ...Object.keys(primaryPointOfContactForm.formData)]
    customerDetilsMandatoryData.map(key => {
        if (!keys.includes(key) || customerDetilsForm.formData[key] === null ||  customerDetilsForm.formData[key] === ""){
          isMandatoryFilled = false;
          return isMandatoryFilled;
        }
    });

    primaryPointOfContactMandatory.map(key => {
      if (!keys.includes(key) || primaryPointOfContactForm.formData[key] == null ||  primaryPointOfContactForm.formData[key] == ""){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if (key === 'pocEmail' && (primaryPointOfContactForm.formData.pocEmailError === "invalidEmailError")) {
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
      else if(primaryPointOfContactForm.formData[key] && !primaryPointOfContactForm.formData[key].length >0 ){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    customerBackgroundMandatoryData.map(key => {
      if (!keys.includes(key) || customerBackgroundForm.formData[key] == null || customerBackgroundForm.formData[key] == ""){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      } else if(!customerBackgroundForm.formData[key] && !customerBackgroundForm.formData[key].length >0){
        isMandatoryFilled = false;
        return isMandatoryFilled;
      }
    });

    return isMandatoryFilled;
  }

  const customerInformationTabError = checkCustomerInformationMandatoryFilled();

  const mainApplicationMandatoryData = [];
  const sampleDeatilsMandatoryData = [];
  const xbMainApplicationMandatoryData = [];
  const xbSampleDeatilsMandatoryData = [];
  const xrmDemoDetailsMandatoryData = [];
  const xrmApplicationInfoMandatoryData = [];
  const xrmSamplesDetailsMandatoryData = [];
  const xrmSampleDescriptionMadatoryData = [];
  const xrmSampleSpecMandatoryData = [];
  const lmApplicationSamplesMandatoryData = [];

  if (sampleDeatilsFrom.formData.statusOfSampleSolved && !sampleDeatilsFrom.formData?.completedByName ) {    
    sampleDeatilsMandatoryData.push('completedByName');
  }
  if (xbSampleDeatilsFrom.formData.statusOfSampleSolvedXb && xbSampleDeatilsFrom.formData?.completedByNameXb === '') {    
    xbSampleDeatilsMandatoryData.push('completedByNameXb');
  }
  
  // const checkApplicationAndSamplesMandatoryFilled  = () =>{
  //   let isMandatoryFilled = true;
  //   let keys = [...Object.keys(mainApplicationChecklistForm.formData), ...Object.keys(sampleDeatilsFrom.formData)]
  //   mainApplicationMandatoryData.map(key => {
  //       if (!keys.includes(key)){
  //         isMandatoryFilled = false;
  //         return isMandatoryFilled;
  //       } else if(!mainApplicationChecklistForm.formData[key]){
  //         isMandatoryFilled = false;
  //         return isMandatoryFilled;
  //       }
  //   });
    
  //   if(sampleDeatilsFrom.formData?.totalNumberOfSamples && !regex.number.test(sampleDeatilsFrom.formData?.totalNumberOfSamples)){
  //     isMandatoryFilled = false;
  //     return isMandatoryFilled;
  //   }

  //   sampleDeatilsMandatoryData.map(key => {
  //     if (!keys.includes(key)){
  //       isMandatoryFilled = false;
  //       return isMandatoryFilled;
  //     } else if(!sampleDeatilsFrom.formData[key]){
  //       isMandatoryFilled = false;
  //       return isMandatoryFilled;
  //     }
  //   });

  //   return isMandatoryFilled;
  // }


//   const checkXbApplicationAndSamplesMandatoryFilled  = () =>{
//     let isMandatoryFilled = true;
//     let keys = [...Object.keys(xbMainApplicationChecklistForm.formData), ...Object.keys(xbSampleDeatilsFrom.formData)]
//     xbMainApplicationMandatoryData.map(key => {
//         if (!keys.includes(key)){
//           isMandatoryFilled = false;
//           return isMandatoryFilled;
//         } else if(!xbMainApplicationChecklistForm.formData[key]){
//           isMandatoryFilled = false;
//           return isMandatoryFilled;
//         }
//     });

//   xbSampleDeatilsMandatoryData.map(key => {
//     if (!keys.includes(key)){
//       isMandatoryFilled = false;
//       return isMandatoryFilled;
//     } else if(!xbSampleDeatilsFrom.formData[key]){
//       isMandatoryFilled = false;
//       return isMandatoryFilled;
//     }
//   });

//   return isMandatoryFilled;
// }

// const checkLmApplicationAndSamplesMandatoryFilled  = () =>{
//   let isMandatoryFilled = true;
//   let keys = [...Object.keys(lmApplicationAndSamplesForm.formData)]
//   lmApplicationSamplesMandatoryData.map(key => {
//       if (!keys.includes(key)){
//         isMandatoryFilled = false;
//         return isMandatoryFilled;
//       } else if(!lmApplicationAndSamplesForm.formData[key]){
//         isMandatoryFilled = false;
//         return isMandatoryFilled;
//       }
//   });

//   if(!sampleDeatilsFrom.formData.statusOfSampleId){
//     return false;
//   }
//   if (sampleDeatilsFrom.formData.statusOfSampleSolved && !sampleDeatilsFrom.formData?.completedByName) {    
//     return false;
//   }

// return isMandatoryFilled;
// }

// const checkXrmApplicationAndSamplesMandatoryFilled  = () =>{
//   let isMandatoryFilled = true;
//   let keys = [...Object.keys(xrmDemoDetailsForm.formData), ...Object.keys(xrmApplicationInformationForm.formData), ...Object.keys(xrmSamplesDetailsForm.formData), ...Object.keys(xrmSampleDescriptionForm.formData), ...Object.keys(xrmSampleSpecsForm.formData)]
//   xrmDemoDetailsMandatoryData.map(key => {
//       if (!keys.includes(key)){
//         isMandatoryFilled = false;
//         return isMandatoryFilled;
//       } else if(!xrmDemoDetailsForm.formData[key]){
//         isMandatoryFilled = false;
//         return isMandatoryFilled;
//       }
//   });

// xrmApplicationInfoMandatoryData.map(key => {
//   if (!keys.includes(key)){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   } else if(!xrmApplicationInformationForm.formData[key]){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   }
// });

// xrmSamplesDetailsMandatoryData.map(key => {
//   if (!keys.includes(key)){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   } else if(!xrmSamplesDetailsForm.formData[key]){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   }
// });

// xrmSampleDescriptionMadatoryData.map(key => {
//   if (!keys.includes(key)){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   } else if(!xrmSampleDescriptionForm.formData[key]){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   }
// });

// xrmSampleSpecMandatoryData.map(key => {
//   if (!keys.includes(key)){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   } else if(!xrmSampleSpecsForm.formData[key]){
//     isMandatoryFilled = false;
//     return isMandatoryFilled;
//   }
// });

//   if(!sampleDeatilsFrom.formData.statusOfSampleId){
//     return false;
//   }
//   if (sampleDeatilsFrom.formData.statusOfSampleSolved && !sampleDeatilsFrom.formData?.completedByName) {    
//     return false;
//   }

// return isMandatoryFilled;
// }

  // const applicationAndSamplesTabError = checkApplicationAndSamplesMandatoryFilled();
  // const xbApplicationAndSamplesTabError = checkXbApplicationAndSamplesMandatoryFilled();
  // const xrmApplicationAndSamplesTabError = checkXrmApplicationAndSamplesMandatoryFilled();
  // const lmApplicationAndSamplesTabError = checkLmApplicationAndSamplesMandatoryFilled();

  // const checkDemoCenterInformationMandatoryFilled  = () =>{
  //   let isMandatoryFilled = true;
  //   let data = demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList;
  //   if (data && !data.length >0){
  //     isMandatoryFilled = false;
  //     return isMandatoryFilled;
  //   } else if(!demoCenterInformationForm.formData.hasOwnProperty('zmccHardwareSoftwareAccessoriesList')){
  //     isMandatoryFilled = false;
  //     return isMandatoryFilled;
  //   }

  //   return isMandatoryFilled;
  // }

  // const demoCenterInformationTabError = checkDemoCenterInformationMandatoryFilled();

  // const additionalInformationMandatoryData =['detailedCommentsAdditionalInformation'];

  // const checkAdditionalInformationMandatoryFilled  = () =>{
  //   let isMandatoryFilled = true;
  //   let keys = [...Object.keys(additionalInformationForm.formData)]
  //   additionalInformationMandatoryData.map(key => {
  //       if (keys.includes(key) && (additionalInformationForm.formData[key] == null || additionalInformationForm.formData[key] == '')){
  //         isMandatoryFilled = false;
  //       }
  //       else if (!keys.includes(key)){
  //         isMandatoryFilled = false;
  //       }
  //   });

  //   return isMandatoryFilled;
  // }

  // const additionalInformationMandatoryFilledTabError = checkAdditionalInformationMandatoryFilled();

  // const checkLogisticsAndCoordination = () => {
  //   let isMandatoryFilled = true;
  //   let data = logisticsAndCoordinationForm.formData.zmccAttendeesList;
  //   if (data && !data.length >0){
  //     isMandatoryFilled = false;
  //     return isMandatoryFilled;
  //   } else if(!logisticsAndCoordinationForm.formData.hasOwnProperty('zmccAttendeesList')){
  //     isMandatoryFilled = false;
  //     return isMandatoryFilled;
  //   }

  //   return isMandatoryFilled;
  // }


  // const checkTodoListData = () => {
  //   let isMandatoryFilled = true;
  //   let data = todosListForm.formData.zmccTodosListDataList;
  //   if (data && !data.length >0){
  //     isMandatoryFilled = false;
  //     return isMandatoryFilled;
  //   } else if(!todosListForm.formData.hasOwnProperty('zmccTodosListDataList')){
  //     isMandatoryFilled = false;
  //     return isMandatoryFilled;
  //   }

  //   return isMandatoryFilled;
  // }

  // const todosListTab = checkTodoListData();
  // const zmccTabShowBlueTic = () =>{
  //   const data = logisticsAndCoordinationForm.formData;
  //   let isMandatoryFilledFilled  = false;
  //   if (data.accomodationNeeded === true &&  (data.accomodationNeededData === undefined || data.accomodationNeededData === '' || data.accomodationNeededData === null)) {
  //     return isMandatoryFilledFilled = false;
  //   }
  //   if (data.flightNeeded === true && (data.flightNeededData === undefined || data.flightNeededData === "" || data.flightNeededData === null)) {
  //     return isMandatoryFilledFilled = false;
    
  //   }
  //   if (data.visitMuseumOfOptics === true  && (data.visitMuseumOfOpticsData === undefined ||data.visitMuseumOfOpticsData === "" || data.visitMuseumOfOpticsData === null)) {
  //     return isMandatoryFilledFilled = false;
  //   }
  //   if(((data.attendeeName && data.attendeeName !== "" && data.attendeeName !== null) || (data.attendeeEmail && data.attendeeEmail !== "" && data.attendeeEmail !== null ) || (data.function && data.function !== "" && data.function !== null) || (data.skillLevelId && data.skillLevelId !== "" && data.skillLevelId !== null)  || (data.descisionMaker && data.descisionMaker !=="" && data.descisionMaker !== null))){
  //     isMandatoryFilledFilled = true;
  //   }
      
  //     return isMandatoryFilledFilled;
  // }
  
  // const thirdTabMandatoryDataCheck = () => {
  //   if ((isSemBooking || zmccSemBooking) && applicationAndSamplesTabError) {
  //     return true;
  //   }else if ((isXbBooking || zmccXbBooking) && xbApplicationAndSamplesTabError) {
  //     return true;
  //   }else if ((isXRMBooking || zmccXrmBooking) && xrmApplicationAndSamplesTabError) {
  //     return true
  //   }else if ((isLMBooking || zmccLmBooking) && lmApplicationAndSamplesTabError) {
  //     return true
  //   }else{
  //     return false;
  //   }
  // }

  const checkTabCompletion = (tabId) => {
    if (tabId == 1){
      return checkGeneralInformationMandatoryFilled();
    } else if(tabId == 2){
      return checkCustomerInformationMandatoryFilled()
    } else if(tabId == 3){
      if ((isSemBooking || zmccSemBooking)) {
        return isAllMandatoryFilled;
        // return checkApplicationAndSamplesMandatoryFilled();
        return isAllMandatoryFilled;
      }else if ((isXbBooking || zmccXbBooking)) {
        return isAllMandatoryFilled;
        // return checkXbApplicationAndSamplesMandatoryFilled();
      }else if ((isXRMBooking || zmccXrmBooking)) {
        return isAllMandatoryFilled;
        // return checkXrmApplicationAndSamplesMandatoryFilled();
      }else if ((isLMBooking || zmccLmBooking)) {
        return isAllMandatoryFilled;
        // return checkLmApplicationAndSamplesMandatoryFilled();
      }else{
        return false;
      }
    } else if(tabId == 4){
      return isAllMandatoryFilled;
      // return checkDemoCenterInformationMandatoryFilled();
    } else if(tabId == 5){
      return isAllMandatoryFilled;
      // return checkAdditionalInformationMandatoryFilled();
    } else if(tabId == 6){
      return isAllMandatoryFilled;
      // return isLogisticTabValid();
    } else if(tabId == 7){
      return isAllMandatoryFilled;
    //   return checkTodoListData();
    }
  }

  // const isLogisticTabValid = () => {
  //   let valiations = validateNameEmailAndFunctionInAttendees(logisticsAndCoordinationForm);
  //   const data = logisticsAndCoordinationForm.formData;
  //   if (data.accomodationNeeded === true &&  (data.accomodationNeededData === undefined || data.accomodationNeededData === '' || data.accomodationNeededData === null)) {
  //     return false;
  //   }
  //   if (data.flightNeeded === true && (data.flightNeededData === undefined || data.flightNeededData === "" || data.flightNeededData === null)) {
  //     return false;
    
  //   }
  //   if (data.visitMuseumOfOptics === true  && (data.visitMuseumOfOpticsData === undefined ||data.visitMuseumOfOpticsData === "" || data.visitMuseumOfOpticsData === null)) {
  //     return false;
  //   }

  //   if(valiations.allEmpty && logisticsAndCoordinationForm.formData?.zmccAttendeesList?.length){
  //     return true
  //   }
  //   if(!valiations.allEmpty && valiations.isValid){
  //     return true
  //   }
  //   if(valiations.allEmpty && !logisticsAndCoordinationForm.formData?.zmccAttendeesList?.length){
  //     return false;
  //   }
  //   return false;
  // }

  // const logisticsAndCoordinationTabError = isLogisticTabValid();
 
  // const showDefaultBlueTick = generalInformationTabError && customerInformationTabError && thirdTabMandatoryDataCheck() && (demoCenterInformationTabError || demoCenterInformationForm.formData?.hardOrSoftwareAccessories?.length > 0) && logisticsAndCoordinationTabError;


  const { zmccTransactionDetails } = props;
  const isBookingCompleted = zmccTransactionDetails && zmccTransactionDetails.zmccSavedStatus===ZMCC_CREATED ? true : false;
  const isAllMandatoryFilled = generalInformationTabError && customerInformationTabError && (activeTab>6 || isBookingCompleted);


  return (
    <div className='app-tabs'>
      <div className={`Zmcc-tab-header`}>
        <div className='tabs-button-alignment'>
          {tabs.map((item, index, key) => (
            <div
              className={`tab cursor-pointer ${activeTab === item.id ? 'active active-tab-color' : ''} ${
                item.className
              }`}
              onClick={toggleTabFunc(item.id)}
            >
              <div className='tab-icon-and-name'>
                {item.tabTitle == 'generalInformation' &&
                  (generalInformationTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!generalInformationTabError  && transactionsId != null) || (!tabCompletionStatus[1] && !generalInformationTabError && activeTab !==1 )) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!generalInformationTabError && index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'customerInformation' &&
                  (customerInformationTabError ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : ((!customerInformationTabError && transactionsId != null) || (tabCompletionStatus.hasOwnProperty(2) && !tabCompletionStatus[2] && activeTab !==2 && !customerInformationTabError )) ? (
                    <Icon name='info' width={24} height={24} fill='red' className='action-icon' />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {!customerInformationTabError && index + 1}
                    </div>
                  ))}

                {(isSemBooking || zmccSemBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}
                {(isXbBooking || zmccXbBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}
                {(isXRMBooking || zmccXrmBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                {(isLMBooking || zmccLmBooking) &&
                  item.tabTitle == 'applicationSampleDetails' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'demoCenterInformation' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'additionalInformation' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'logisticsCoordination' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                {item.tabTitle == 'toDoList' &&
                  (isAllMandatoryFilled ? (
                    <Icon
                      name='blueTickMark'
                      viewBox='0 0 20 20'
                      width={24}
                      height={24}
                      fill='#0088D0'
                      className='action-icon'
                    />
                  ) : (
                    <div className={`${activeTab === item.id ? 'active-tab-icon-color' : 'non-active-icon-color'}`}>
                      {index + 1}
                    </div>
                  ))}

                <div>
                  {strings[item.tabTitle]} {!!item.count && `(${item.count})`}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='tab-content' id='nav-tabContent'>
        {tabs.map((item, key) => (
          <div
            className={`tab-pane fade ${activeTab === item.id ? 'show active' : ''}`}
            id='list-home'
            role='tabpanel'
            aria-labelledby='list-home-list'
            key={key}
          >
            {item.render(activeTab === item.id, { ...props })}
          </div>
        ))}
      </div>
    </div>
  );
}

ZMCCTabs.defaultProps = {
  toggleTab: true,
};

export default ZMCCTabs;
