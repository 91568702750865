import { batchActions } from 'redux-batched-actions';

import { logNetworkError } from 'library/utilities/logError';
import store from 'main/store/configureStore';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import * as masterServices from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  CAN_EDIT_STOCK,
  ADDRESS_LIST,
  CAN_ADD_MULTIPLE_ADDRESS,
  ROOMS_LIST,
  INTERNAL_CRM_ACCOUNT_ID,
  KITS_LIST_BY_WAREHOUSE_ID,
  ATTACHMENTS_CATEGORY,
  IQS_SSS_ATTACHMENT_LIST,
  IQS_SSS_ROOMS_LIST,
  STOCK_USERS
} from 'modules/Stock/CreateStock/CreateStock.constants';

import * as services from './CreateStock.services';
import { fetchStockEntitlements } from './Tabs/Entitlements/Entitlements.actions';
import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
  deleteMasterDataService,
} from 'library/api/master-data.service';
import { scrollToTop } from 'library/utilities/scrollActions';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import Axios from 'axios';
import { saveAs } from 'file-saver';

/**
 * This function will save stock information to server
 * @param data
 * @returns {Function}
 */
export const saveStockInformation = (data,history) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(changeLoader(true));
    const dataToSend = {};
    for (let key in data) {
      if (!key.includes('Error') && !key.includes('FullValue')) {
        dataToSend[key] = data[key];
      }
    }
    if (!dataToSend.hasOwnProperty('stateName')) {
      dataToSend['stateName'] = '';
    }
    services
      .createStockService(dataToSend)
      .then(res => {
        if (res.status === 200) {
          dispatch(
            batchActions([
              dispatch({
                type: actionTypes.STOCK_CREATED,
                payload: res.data.id,
              }),
              dispatch(fetchStockDetails(URLS.stockDetails, actionTypes.FETCH_STOCK_DETAILS)),
              dispatch(toggleActionMessage(true, 'success', 'stockInformationSavedSuccessfully')),
            ]),
          );
        }
        dispatch(changeLoader(false));
        resolve(res.data);
        history.push(`edit-stock/${res.data.id}`)
        window.location.reload();
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(changeLoader(false));
        if (err && err.hasOwnProperty('response') && (err.response.status === 409 || err.response.status === 404)) {
          dispatch(toggleActionMessage(true, 'error', 'stockNameConflictError'));
        } else {
          dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
        }
        reject(err);
      });
  });
};

export const fetchStockDetails = (url, actionType, history, stockId) => dispatch => {
  const stockReducer = store.getState().createStockReducer;
  const masterDataReducer = store.getState().masterDataReducer;
  const user = store.getState().authReducer.user;
  if (stockId || stockReducer.stockId) {
    dispatch(changeLoader(true));
    return masterServices.fetchMasterDataService(url + (stockId || stockReducer.stockId)).then(res => {
      dispatch(changeLoader(false));
      if (res.status === 200) {
        dispatch(fetchStockAddress(stockReducer.stockId));
        dispatch(checkCanAddMultipleAddress(stockReducer.stockId));
        dispatch(fetchRoomsList(stockReducer.stockId));
        dispatch(getAllActiveProvinceCountries());
        if (res.data !== null) {
          const data = res.data;
          data.businessUnitFullValue = data.businessUnit;
          data.businessUnit = data.businessUnit.id;
          data.countryFullValue = data.country;
          data.country = data.country.id;
          const { usersList } = masterDataReducer;
          const stockController = data.stockUserRole.filter(user => user.role.id === 1);
          stockController.forEach(user => {
            user.stockId = user.id;
            const userItem = usersList.find(item => item.accountId === user.accountId);
            if (userItem) {
              user.firstName = userItem.firstName;
              user.lastName = userItem.lastName;
              user.email = userItem.email;
            }
          });
          const canEditStock =
            user.admin || stockController.findIndex(stock => stock.accountId === user.accountId) !== -1;
          data.stockUserRole = Object.assign([], stockController);
          dispatch(
            batchActions([
              dispatch(fetchStockEntitlements(stockId || stockReducer.stockId, false)),
              dispatch({
                type: actionType,
                payload: data,
              }),
              dispatch({
                type: CAN_EDIT_STOCK,
                payload: canEditStock,
              }),
            ]),
          );
        } else {
          history.replace('/stocks');
        }
      }
    });
  }
};

/**
 * This function will update stock informiton to server
 * @param data
 * @returns {Function}
 */
export const updateStockInformation = data => dispatch => {
  return new Promise((resolve, reject) => {
    const stockReducer = store.getState().createStockReducer;
    dispatch(changeLoader(true));
    let dataToSend = {};
    for (let key in data) {
      if (!key.includes('Error')) {
        dataToSend[key] = data[key];
      }
    }
    services
      .updateStockService(dataToSend, stockReducer.stockId)
      .then(res => {
        if (res.status === 200) {
          dispatch(
            batchActions([
              dispatch(fetchStockDetails(URLS.stockDetails, actionTypes.FETCH_STOCK_DETAILS)),
              dispatch(toggleActionMessage(true, 'success', 'stockInformationSavedSuccessfully')),
            ]),
          );
        }
        dispatch(changeLoader(false));
        resolve(res);
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(changeLoader(false));
        if (err && err.response && err.response.status && err.response.status === 404) {
          dispatch(toggleActionMessage(true, 'error', 'stockNameConflictError'));
        } else if (err && err.response && err.response.status && err.response.status === 409) {
          dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
        } else if (err && err.response && err.response.data && err.response.data.message) {
          let message = err.response.data.message;
          let showAsIs = true;
          if (message === '"Record already exist') {
            message = 'stockNameConflictError';
            showAsIs = false;
          }
          dispatch(toggleActionMessage(true, 'error', message, showAsIs));
        } else {
          dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
        }
        reject(err);
      });
  });
};

export const setStockId = id => dispatch => {
  dispatch({
    type: actionTypes.STOCK_CREATED,
    payload: id,
  });
};

export const cancelStockCreation = () => {
  return {
    type: actionTypes.RESET_CREATE_STOCK,
  };
};

export const getStateInput = country => async dispatch => {
  // const masterDataReducer = store.getState().masterDataReducer;

  if (country) {
    const countryName = country.name.toLowerCase();
    if (
      countryName === 'australia' ||
      countryName === 'united states' ||
      countryName === 'canada' ||
      countryName === 'mexico' || 
      countryName === 'china' 
      // || 
      // masterDataReducer?.activeProvinceCountries.includes(country.id)
    ) {
      dispatch(changeLoader(true));
      return services
        .getStates(country.id)
        .then(({ data, status }) => {
          dispatch(changeLoader(false));
          if (status === 200 && data && data.length) {
            return data;
          }
          return null;
        })
        .catch(err => {
          logNetworkError(err);
          batchActions([
            dispatch(changeLoader(false)),
            dispatch(toggleActionMessage(true, 'error', 'errorOccuredText')),
          ]);
          return null;
        });
    }
  }
  return null;
};

export const fetchStockAddress = stockId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.stockAddressList(stockId));

    dispatch({
      type: ADDRESS_LIST,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const addNewAddress = (dataToSend, stockId) => async dispatch => {
  try {
    const { status } = await fetchMasterDataServicePost(URLS.addStockAddress(stockId), dataToSend);
    if (status === 200) {
      dispatch(fetchStockAddress(stockId));
      return { success: true };
    }
    return { success: false };
  } catch (err) {
    return { success: false, err };
  }
};

export const editAddress = (dataToSend, id, stockId) => async dispatch => {
  try {
    const { status } = await fetchMasterDataServicePut(URLS.editStockAddress(id), dataToSend);
    if (status === 200) {
      dispatch(fetchStockAddress(stockId));
      return { success: true };
    }
    return { success: false };
  } catch (err) {
    return { success: false, err };
  }
};

export const deleteAddress = (id, stockId) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { status } = await deleteMasterDataService(URLS.deleteAddress(id));
    dispatch(changeLoader(false));
    if (status === 200) {
      dispatch(fetchStockAddress(stockId));
      return { success: true };
    }
    return { success: false };
  } catch (err) {
    dispatch(changeLoader(false));
    return { success: false };
  }
};

export const checkCanAddMultipleAddress = stockId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.showAddButton(stockId));
    dispatch({
      type: CAN_ADD_MULTIPLE_ADDRESS,
      payload: data,
    });
    return { success: true };
  } catch (err) {}
};

export const fetchRoomsList = stockId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.roomsList(stockId));
    dispatch({
      type: ROOMS_LIST,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const addNewRoom = (dataToSend, stockId, isIqsssc = false) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { status } = await fetchMasterDataServicePost(URLS.addNewRoom(stockId), dataToSend);
    dispatch(changeLoader(false));
    if (status === 200) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
      if (isIqsssc) {
        dispatch(getIqsRoomsList(stockId))
      }else{
        dispatch(fetchRoomsList(stockId));
      }
      
      return { success: true };
    }
    return { success: false };
  } catch (err) {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, "error", "somethingWentWrongMessage"));
    return { success: false, err };
  }
};

export const editRooms = (dataToSend, id, stockId, isIqsssc = false) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { status } = await fetchMasterDataServicePut(URLS.editRoom(id), dataToSend);
    dispatch(changeLoader(false));
    if (status === 200) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
      if (isIqsssc) {
        dispatch(getIqsRoomsList(stockId))
      }else{
        dispatch(fetchRoomsList(stockId));
      }
    }
    return { success: true };
  } catch (err) {
    dispatch(toggleActionMessage(true, "error", "somethingWentWrongMessage"));
    dispatch(changeLoader(false));
    return { success: false, err };
  }
};

export const deleteRoom = (dataToSend, id, stockId, isIqsssc = false) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { status } = await deleteMasterDataService(URLS.delteRoom(id), dataToSend);
    dispatch(changeLoader(false));
    if (status === 200) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'roomDeleteMessage'));
      if (isIqsssc) {
        dispatch(getIqsRoomsList(stockId))
      }else{
        dispatch(fetchRoomsList(stockId));
      }
    }
    return { success: true };
  } catch (error) {
    dispatch(toggleActionMessage(true, "error", "somethingWentWrongMessage"));
    dispatch(changeLoader(false));
    return { success: false, error };
  }
};

export const updateCrmAccountId = payload => ({
  type: INTERNAL_CRM_ACCOUNT_ID,
  payload,
});

export const getPendingKitsListByWarehouseWhereSlotIsNotAvailable = (warehouseId) => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.getfilteredKitByWarehouse(warehouseId));
    dispatch({
      type: KITS_LIST_BY_WAREHOUSE_ID,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: KITS_LIST_BY_WAREHOUSE_ID,
      payload: [],
    });
    return { success: false };
  }}

  export const getAllKitsListByWarehouse = (warehouseId) => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.getAllKitsByWarehouse(warehouseId));
      dispatch({
        type: KITS_LIST_BY_WAREHOUSE_ID,
        payload: data,
      });
      return { success: true };
    } catch (error) {
      dispatch({
        type: KITS_LIST_BY_WAREHOUSE_ID,
        payload: [],
      });
      return { success: false };
    }
}



export const getAllActiveProvinceCountries = () => async dispatch => {
  try {
    const {data, status} = await fetchMasterDataService(URLS.getAllActiveProvinceCountries);
    if (status == 200){
      dispatch({
        type: actionTypes.ACTIVE_PROVINCE_COUNTRIES,
        payload: data,
      });
      return { success: true , data : data};
    }
  } catch (error) {
    dispatch({
      type: actionTypes.ACTIVE_PROVINCE_COUNTRIES,
      payload: [],
    });
    return { success: false };
  }
}

export const getAttachmentCategory = () => async dispatch => {
  try { 
    const { data } = await fetchMasterDataService(URLS.attachmentCategories);
    dispatch({ type: ATTACHMENTS_CATEGORY, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: ATTACHMENTS_CATEGORY, payload: [] });
    return { success: false };
  }
};

export const uploadIqsAttachment = (dataToSend) => async dispatch => {
  try {
    const formData = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    dataToSend.attachment.forEach((file) => 
    formData.append('attachment', file)
  );
  formData.append('fileName', dataToSend.fileName);
    formData.append('category', dataToSend.category);
    formData.append('uploadedBy', dataToSend.uploadedBy);
    formData.append('uploadedDate', dataToSend.uploadedDate);
    if(dataToSend.comment){
      formData.append('comment', dataToSend.comment);
    }
    if (dataToSend.stockImagesId) {
      formData.append('stockImageId', dataToSend.stockImagesId);
    }
    
    await fetchMasterDataServicePost(URLS.uploadAttachment(dataToSend.stockId), formData, config);
    dispatch(toggleActionMessage(true, 'success',`${dataToSend.stockImagesId ? 'slotCreatedSucessfully' : 'iqsAttachmentUploadMessage'}`));
    dispatch(getIqsSscAttachmentList(dataToSend.stockId));
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    return { success: false, error };
  }
}

export const getIqsSscAttachmentList = (stockId, dataToSend) => async dispatch => {
  try {
    const sendData = handleDataToSend(dataToSend)
    dispatch(changeLoader(true));
    const {data, status} = await fetchMasterDataServicePost(URLS.iqsAttachmentList(stockId), sendData);
    dispatch(changeLoader(false));
    if (status == 200){
      dispatch({
        type: IQS_SSS_ATTACHMENT_LIST,
        payload: data,
      });
      return { success: true };
    }
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: IQS_SSS_ATTACHMENT_LIST,
      payload: [],
    });
    return { success: false };
  }
}

const handleDataToSend = (data) => {  
  let sendData = {
    fromDate: data?.dateRange ? data.dateRange[0] : null,
    toDate: data?.dateRange ? data.dateRange[1] : null,
    pageSize: 30,
    pageNumber: 0,
    searchText: data?.searchText ? data?.searchText : '',
    attachmentCategory: data?.attachmentCategory ? data.attachmentCategory : null
  }

  return sendData;
}

export const deleteIqsSscDeleteAttachment = (stockId, id) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    await deleteMasterDataService(URLS.deleteIqsAttchment(stockId, id));
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'success','deleteAttachmentMessage'));
    dispatch(getIqsSscAttachmentList(stockId))
    return { success: true };

  } catch (error) {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'success','somethingWentWrongMessage'))
    return { success: false };
  }
}

export const getIqsRoomsList = (stockId, dataToSend) => async dispatch => {
  try {
    const sendData = handleDataToSendRooms(dataToSend);
    dispatch(changeLoader(true));
    const {data, status} = await fetchMasterDataServicePost(URLS.iqsRoomsList(stockId), sendData);
    dispatch(changeLoader(false));
    if (status == 200){
      dispatch({
        type: IQS_SSS_ROOMS_LIST,
        payload: data,
      });
      return { success: true };
    }
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: IQS_SSS_ROOMS_LIST,
      payload: [],
    });
    return { success: false };
  }
}

const handleDataToSendRooms = (data) => {
  let sendData = {
    size: 30,
    page: 0,
    searchText: data?.searchText ? data?.searchText : '',
}
 return sendData;
};

export const getStockUsers = (stockId) => async dispatch => {
  try{
    dispatch(changeLoader(true));
    const {data, status} = await fetchMasterDataService(URLS.getStockUsers(stockId));
    dispatch({type: STOCK_USERS, payload: data})
  }catch(err){
    dispatch({type: STOCK_USERS, payload: []})
    console.log(err);
  }finally{
    changeLoader(false);
  }
}