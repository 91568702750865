import {
    UPDATE_ZMCC_KIT_FORM,
    FETCH_WAREHOUSES_FOR_ZMCC_BU,
    FETCH_SYSTEM_CLASSES_ZMCC_BU,
    FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
    FETCH_WAREHOUSE_ROOMS_ZMCC_BU,
    FETCH_WAREHOUSE_USERS_ZMCC_BU,
    FETCH_ZMCC_BU_CONDITIONS,
    FETCH_ZMCC_KIT_DETAILS,
    RESET_ZMCC_BU_KIT_FORM,
    FETCH_RESOURCE_FUNCTION_VALUES,
    FETCH_RESOURSE_TYPE_VALUES,
    FETCH_PRODUCT_HEIRARCHY_VALUES,
    FETCH_RESOURCE_STATUS_VALUES,
    FETCH_NEW_COMPONENTS_LIST,
    FETCH_KIT_MESSAGES_LIST,
    FETCH_KIT_RESOURCE_ATTACHEMNT_CATEGORIES,
    KIT_NEW_ATTACHMENT_LIST,
    MARKETPLACE_ACTIVE_IN_LOCATION,
    IBASE_NEW_COMPONENTS_LIST
  } from './CreateZmccKit.constants';
  
  const EMPTY_FORM = {
    formData: {},
    isFormValid: false,
  };
  
  export const INITIAL_STATE = {
    kitInformationId: null,
    kitDetails: null,
    generalInformationForm: EMPTY_FORM,
    informationForm: {
      formData: {},
      isFormValid: false,
    },
    iqsIdentifierForm: {...EMPTY_FORM},
    iqsStatusForm: {...EMPTY_FORM},
    iqsLoanInformationForm: {...EMPTY_FORM},
    kitStatusForm: {
      formData: {
        kitCondition: 6,
        kitConditionFullValue: { kitStatusId: 6, translatedStatus: 'Ok' },
      },
      isFormValid: false,
    },
    furtherImportantComponentForm: EMPTY_FORM,
    salesComponentForm: EMPTY_FORM,
    freeAccessoriesForm: EMPTY_FORM,
    kitImages: [],
    kitAttachments: [],
    kitImagesToDelete: [],
    kitAttachmentsToDelete: [],
    stocks: [],
    rooms: [],
    zmccUsers: [],
    systemClasses: [],
    systemMainComponent: [],
    conditions: [],
    activeUntil: null,
    vrDetalisForm: EMPTY_FORM,
    resourceFunctionValues: [],
    resourceTypeValues: [],
    productHierarchyValues :[],
    resourceStatusValues : [],
    newComponentsForm: {
      "formData": {
          "userStatus": "ACTV",
          "userStatusError": null,
          "userStatusFullValue": {
              "id": "Active",
              "value": "ACTV"
          }
      },
      "isFormValid": false
    },
    newComponentsList : [],
    componentsFetchedFromIbase: false,
    kitMessagesList: [],
    kitResourceAttachmentCategoriesList :[],
    kitNewAttachments : [],
    marketplaceInformation: EMPTY_FORM,
    marketplacePrice: EMPTY_FORM,
    isMarketplaceActiveInLocation: false,
    ibaseComponentsList : [],
  };
  
  export const CreateZMCCKitReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case UPDATE_ZMCC_KIT_FORM:
        return { ...state, ...action.payload };
      case FETCH_WAREHOUSES_FOR_ZMCC_BU:
        return { ...state, stocks: action.payload };
      case FETCH_SYSTEM_CLASSES_ZMCC_BU:
        return { ...state, systemClasses: action.payload };
      case FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU:
        return { ...state, systemMainComponent: action.payload };
      case FETCH_WAREHOUSE_ROOMS_ZMCC_BU:
          return { ...state, rooms: action.payload };
      case FETCH_WAREHOUSE_USERS_ZMCC_BU:
        return { ...state, zmccUsers: action.payload };
      case FETCH_ZMCC_BU_CONDITIONS:
        return { ...state, conditions: action.payload };
      case FETCH_ZMCC_KIT_DETAILS:
        return { ...state, ...action.payload, kitInformationId: action.payload.kitDetails.kitInformationId };
      case RESET_ZMCC_BU_KIT_FORM:
        return { ...INITIAL_STATE };
      case FETCH_RESOURCE_FUNCTION_VALUES:
        return { ...state, resourceFunctionValues: action.payload  };
      case FETCH_RESOURSE_TYPE_VALUES:
        return { ...state, resourceTypeValues: action.payload  };
      case FETCH_PRODUCT_HEIRARCHY_VALUES:
        return { ...state, productHierarchyValues: action.payload  };
      case FETCH_RESOURCE_STATUS_VALUES:
        return { ...state, resourceStatusValues: action.payload  };
      case FETCH_NEW_COMPONENTS_LIST:
        return { ...state, newComponentsList: action?.payload?.content, componentsFetchedFromIbase :  action?.payload?.ibaseComponents };
      case IBASE_NEW_COMPONENTS_LIST:
        return { ...state, ibaseComponentsList: action?.payload?.content, componentsFetchedFromIbase :  action?.payload?.ibaseComponents };
      case FETCH_KIT_MESSAGES_LIST:
        return { ...state, kitMessagesList: action.payload  };
      case FETCH_KIT_RESOURCE_ATTACHEMNT_CATEGORIES:
        return { ...state, kitResourceAttachmentCategoriesList: action.payload  };
      case KIT_NEW_ATTACHMENT_LIST:
        return { ...state, kitNewAttachments : action.payload  };
      case MARKETPLACE_ACTIVE_IN_LOCATION:
        return { ...state, isMarketplaceActiveInLocation: action.payload };
      default:
        return state;
    }
  };
  
  export default CreateZMCCKitReducer;
  