import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

import { countries_En } from './country-list';

export const FETCH_SYSTEM_CLASSES = '@@ADMINISTRATION/FETCH_SYSTEM_CLASSES';
export const MAX_SEARCH_TERMS_ALLOWED = 50;
export const MAX_HEADERS_ALLOWED_FOR_LOCATION = 20;

export const administrationBreadcrumbs = [
  {
    name: 'homepage',
    url: '/home'
  },
  {
    name: 'administration',
  },
];

export const SystemClassModel = [
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'id',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      { key: 'name', separator: '' }
    ],
    excludeData: []
  },
  {
    label: 'productHierarchy',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'productHierarchy',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      { key: 'name', separator: '' }
    ],
    excludeData: []
  },
  {
    label: 'systemClassText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'systemClass',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3'
  },
  {
    label: 'crmId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'crmId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3'
  }
];

export const SystemMainComponentModel = [
  {
    label: 'systemClassText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
  },
  {
    label: 'systemMainComponent',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'systemMainComponent',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
  },
  {
    label: 'crmId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'crmId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
  },
];

export const CountryModel = [{
  label: 'country',
  value: '',
  type: 'dropdown',
  placeholder: 'pleaseSelect',
  field: 'shortName',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-sm-3 mt-3',
  options: countries_En,
  hasSection: false,
  multiSelect: false,
  filter: true,
  idKey: 'shortName',
  displayValue: [
    { key: 'country', separator: '' }
  ],
  excludeData: []
}];

export const categoryList = [
  { name: strings.categorySystem, id: 'systemClass' },
  { name: strings.systemMainComponent, id: 'systemMainComponent' },
  { name: strings.countryLand, id: 'countryOfOrigin' },
];
