import Button from 'library/common/commonComponents/Button/Button';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchFromStorage } from 'library/utilities/storage';
import React from 'react';

function AddLocationCommentButton({ onAddCommnetClick, renderOnlyLengends = false }) {
  const user = fetchFromStorage(identifiers.user);
  const checkStockRoles = () => {
    if (user.admin) {
      return true;
    }
    if (user.stockRoles.length > 0) {
      return user.stockRoles.some(
        role =>
          role.businessUnitName === 'IQS (SSC DE)' &&
          (role.roleKey === 'STOCK_CONTROLLER' || role.roleKey === 'DISPATCHER'),
      );
    }
  };
  return (
    <div className='calendar-legends-container justify-content-end mt-5 w-100'>
      {checkStockRoles() && !renderOnlyLengends && (
        <div className='align-self-start d-flex flex-column'>
          <Button
            value='Add Location Comment'
            bgFill={false}
            iconName='plus'
            newVersionUi
            styleClass='mt-3'
            onClick={onAddCommnetClick}
          />
        </div>
      )}
    </div>
  );
}

export default AddLocationCommentButton;
