import React from 'react';
import Section from 'library/common/commonComponents/Section/Section';
import { useTimeManagement } from './TimeManagement.hook';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import strings from 'resources/locales/Translate';
import { fetchIqsBookingDropdownData } from 'modules/Calendar/IQSCalendar/IQSCalendar.actions';
import { connect } from 'react-redux';
import Button from 'library/common/commonComponents/Button/Button';
import { SaveIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { fetchTimeManagementList, saveTimeManagement } from 'modules/IqsNewRequest/IqsNewRequest.actions';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

export const TimeManagement = ({
  fetchIqsBookingDropdownData,
  iqsBookingDropdownData,
  user,
  usersList,
  requestId,
  saveTimeManagement,
  fetchTimeManagementList,
  timeManagementList,
  language,
  toggleActionMessage,
}) => {
  const { getTimeManagementModel, handleForm, timeManagementForm, onSaveTimeClick, cols } = useTimeManagement({
    fetchIqsBookingDropdownData,
    iqsBookingDropdownData,
    user,
    usersList,
    requestId,
    saveTimeManagement,
    fetchTimeManagementList,
    language,
    toggleActionMessage,
  });
  return (
    <div>
      <Section sectionTitle={strings.createTime}>
        <div className='col-12'>
          <FormComponent
            model={getTimeManagementModel()}
            formName='timeManagementForm'
            formValue={timeManagementForm.formData}
            onChange={handleForm}
          />
        </div>

        <Button
          value={strings.saveTime}
          onClick={onSaveTimeClick}
          bgFill={false}
          styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
          icon={<SaveIcon width='27' height='17' viewBox='0 0 14 15' />}
          newVersionUi={true}
          className={'iqs-request-font-color'}
        />
      </Section>

      <div className='mt-4'>
        <Section sectionTitle={strings.timeStamps}>
          {timeManagementList?.length > 0 && (
            <ExpandableTable cols={cols} rows={timeManagementList} dontShowExpandArrow={true} />
          )}
        </Section>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, iqsRequestReducer, masterDataReducer, calendar, languageReducer }) => ({
  user: authReducer.user,
  timeManagementList: iqsRequestReducer.timeManagementList,
  usersList: masterDataReducer.usersList,
  iqsBookingDropdownData: calendar.iqsBookingDropdownData,
  language: languageReducer.language,
});

export default connect(mapStateToProps, {
  fetchIqsBookingDropdownData,
  saveTimeManagement,
  fetchTimeManagementList,
  toggleActionMessage,
})(TimeManagement);
