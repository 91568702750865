import Validators from "library/utilities/Validators";

export const crmAndCustomerModel = [
  {
    label: 'opportunityNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'opportunityNumber',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
    newRefreshIcon : true,
    inputClassName: 'iqs-request-font-color'
  },
  {
    label: 'salesNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'salesNumber',
    validators: [],
    required: false,
    styleClass: 'col-3 iqs-request-font-color',
    disabled: false,
    inputClassName: 'iqs-request-font-color'
  },
  {
    label: 'companySapId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companySAPID',
    validators: [],
    required: false,
    styleClass: 'col-3 iqs-request-font-color',
    disabled: false,
    newRefreshIcon : true,
    inputClassName: 'iqs-request-font-color'
  },
  {
    label: 'customer',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'customer',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'iqsRequestCompanyId',
    displayValue: [{ key: 'companyName', separator: ' ' }],
    showClearIcon: false,
    inputClassName: 'iqs-request-font-color'
  },
  {
    label: 'companyName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyName',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color'
  },
  {
    label: 'companyGroup',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyGroup',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color'
  },
  {
    label: 'comapnyStreetHouseNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyStreetAndHouseNumber',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'companyPostalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPostalCode',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'companyCity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyCity',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
      label: 'companyCountry',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'countryId',
      validators: [],
      required: false,
      styleClass: 'col-3',
      options: [],
      hasSection: false,
      multiSelect: false,
      filter: true,
      idKey: 'id',
      displayValue: [{ key: 'name', separator: ' ' }],
      showClearIcon: false,
      disabled: true,
    },
  {
    label: 'companyPhone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPhone',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'companyEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyEmail',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'companyWebsite',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyWebsite',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'industrialSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'companyIndustrialSegment',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'industrialSubSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'companyIndustrialSubSegment',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'ndaType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'companyNdaType',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'ndaValidUntil',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'companyNdaValidUntil',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    dateFormat: 'DD.MM.YYYY',
    disabled: true,
    tileDisabled: [],
    inputClassName: 'iqs-request-font-color',
  },
  {
    label: 'aukomMember',
    selected: false,
    type: 'enhanced-checkbox',
    field: 'aukomMember',
    styleClass: 'col-sm-3',
    newuiVersionStyle: true,
    labelTopPosition: true,
    disabled: true,
    inputClassName: 'iqs-request-font-color',
  },
];

export const customerContactModel = [
  {
    label: 'customerContactCrmId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactCRMID',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
    newRefreshIcon: true,
  },
  {
    label: 'customerContact',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'customerContact',
    validators: [],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'iqsRequestContactId',
    displayValue: [{ key: 'contactFirstName', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'customerContactFirstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactFirstName',
    validators: [],
    required: true,
    styleClass: 'col-3',
    disabled: false,
  },
  {
    label: 'customerContactEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactEmail',
    validators: [],
    required: true,
    styleClass: 'col-3',
    disabled: false,
  },
  {
    label: 'customerContactRole',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'iqsRequestCustomerContactRole',
    validators: [],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
];

export const contactCreatorModel = [
  {
    label: 'creator',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'creatorId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
  {
    label: 'salesRep',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'salesRepId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
  {
    label: 'projectResponsible',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'projectResponsibleId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
  {
    label: 'projectManager',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'projectManagerId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
];
  
  export const addZeissCompanayModel = [
    {
      label: 'zeissPerson',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'personId',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage'}],
      required: false,
      styleClass: 'col-6 pl-0',
      options: [],
      hasSection: false,
      multiSelect: false,
      filter: true,
      idKey: 'accountId',
      displayValue: [
        { key: 'lastName', separator: ' ' },
        { key: 'firstName', separator: '' },
      ],
      showClearIcon: false,
    },
    {
      label: 'roleText',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'role',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage'}],
      required: false,
      styleClass: 'col-6',
      options: [],
      hasSection: false,
      multiSelect: false,
      filter: false,
      idKey: 'staticCode',
      displayValue: [{ key: 'name', separator: ' ' }],
      showClearIcon: false,
    },
  ];
  