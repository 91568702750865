import React, { Component } from 'react';
import { debounce, isEqual, toArray } from 'lodash';
import $, { data } from 'jquery';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';

import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { scrollToTop } from 'library/utilities/scrollActions';
import { getStockFullValueByIds } from 'library/utilities/getFullValueByIds';
import { getAllStockIds } from 'library/utilities/getAllStockIds';
import { convertParamsToObject } from 'library/utilities/convertParamsToObject';
import { checkUserRoles, checkZmccUserRoles } from 'library/utilities/checkRoles';
import { permissions } from 'library/common/commonConstants/permissionsConstants';

import { kitsListBreadcrumbData, itemsPerPage, INITIAL_FILTER } from './KitsList.constants';
import Filter from './Components/Filter/Filter';
import { dataTable } from './dataTable';
import ExportFilterKits from './Components/ExportFilterKits';
import ExpandableRowOfKitListTable from './Components/ExpandableRowOfKitListTable';
import ContinueUseApplicationPopup from 'modules/Booking/Components/ContinueApplicationPopup/ContinueUseApplicationPopup.component';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import Icon from 'library/common/commonComponents/Icon/Icon';

const checkFiltersLanguage = (filter, data) => {
  let statusFullValue;
  let conditionFullValue;
  const { conditions, kitStatus } = data;
  if (filter.status && filter.status.length > 0) {
    statusFullValue = filter.status
      .map(item => kitStatus.filter(status => status.kitStatusId === item)[0])
      .filter(item => item);
  }
  if (filter.condition && filter.condition > 0) {
    conditionFullValue = filter.condition.map(
      item => conditions.filter(condition => condition.kitStatusId === item)[0],
    );
  }
  return {
    ...filter,
    statusFullValue,
    conditionFullValue,
  };
};
class KitsList extends Component {
  constructor(props) {
    super(props);
    document.title = 'Kits';
    let filter = { ...INITIAL_FILTER, status: [8, 13] };
    const storage = fetchFromStorage(identifiers.dla);

    if (props.history.location.state && props.history.location.state.systemMainComponent) {
      const stock = getAllStockIds(props.countries);
      filter = {
        status: [8],
        stock,
        stockFullValue: getStockFullValueByIds(stock, props.countries),
        category: [props.history.location.state.systemMainComponent],
        categoryFullValue: [
          {
            systemMainComponentId: props.history.location.state.systemMainComponent,
            systemMainComponentName: props.history.location.state.systemMainComponentName,
          },
        ],
      };
    } else if (props.location && props.location.search) {
      const params = convertParamsToObject(props.location.search);
      const conditionParam = Number(params.condition);
      const stockParam = params.stock ? params.stock.split(',').map(item => Number(item)) : null;
      const stockFullValueParam = params.stockFullValue
        ? params.stockFullValue.split(',').map((item, index) => ({ locationName: item, id: stockParam[index] }))
        : null;

      filter = {
        condition: [conditionParam],
        stock: stockParam,
        stockFullValue: stockFullValueParam,
      };
    } else if (storage) {
      filter = {
        ...storage,
        searchTerm: storage.searchTerm ? storage.searchTerm : '',
      };
    }
    const hasAccessToZmcc =  this.props.user &&
    this.props.user.stockRoles.find(
      item => (item.businessUnitName === 'RMS (ZMCC)' && item.roleName !== 'Borrower'),
    )
    const hasAccessToLL = this.props.user &&
    this.props.user.stockRoles.find(item => item.businessUnitName === 'RMS labs@location Partner');
    this.state = {
      countries: [],
      isShowFilter: true,
      isLabsLocationType: false,
      isZmccType: false,
      isDlaType: false,
      hasLLPermission: hasAccessToLL,
      hasNormalPermission:
        this.props.user &&
        this.props.user.stockRoles.find(item => item.businessUnitName !== 'RMS labs@location Partner' && item.businessUnitName !== 'RMS (ZMCC)'),
      hasZmccPermission: hasAccessToZmcc,
      data: {
        page: 1,
        size: 10,
        sort: {
          kitName: null,
          serialNumber: null,
          dateCreated: 'desc',
          systemValue: null,
          stockName: null,
          currentPosition: null,
          country: null,
        },
        filter : (hasAccessToZmcc || hasAccessToLL) ? INITIAL_FILTER : filter,
      },
    };
  }

  componentDidMount() {
    const roles = ['Stock Controller', 'Operator', 'Dispatcher', 'Reconditioning']; 
    const hasZmccRolesAccess = checkZmccUserRoles(roles, this.props.user);
    const lastSlectedCheckBoxInKit = fetchFromStorage(identifiers.lastSlectedCheckboxInKits);
    if (lastSlectedCheckBoxInKit !== null && lastSlectedCheckBoxInKit !== undefined) {
      if (lastSlectedCheckBoxInKit === 'zmcc') {
        this.setState(prevState => ({ isLabsLocationType: false, isDlaType: false, isZmccType: true }));
      this.props.fetchKitsListData(
        `${URLS.kitsList}?businessUnitType=RMS_ZMCC`,
        actionTypes.FETCH_KITS_LIST,
        this.getSavedFilters(identifiers.zmcc),
        // this.state.data,
      );
      }else if (lastSlectedCheckBoxInKit === 'l@l') {
        this.setState(prevState => ({ isLabsLocationType: true, isDlaType: false, isZmccType: false }));
      this.props.fetchKitsListData(
        `${URLS.kitsList}?businessUnitType=SPECIAL`,
        actionTypes.FETCH_KITS_LIST,
        this.getSavedFilters(identifiers.labsAtLocation),//this.state.data,
        this.state.isLabsLocationType,
      );
      this.props.fetchMasterData(URLS.systemclassesLabsLocation, actionTypes.FETCH_LABS_LOCATION_SYSTEM_CLASSES);
      this.props.fetchMasterData(URLS.userCountriesLabLocation, actionTypes.FETCH_LABS_LOCATION_COUNTRIES);
      }else {
        this.setState(prevState => ({ isLabsLocationType: false, isDlaType: true, isZmccType: false }));
      this.props.fetchKitsListData(
        URLS.kitsList + '?businessUnitType=GENERAL',
        actionTypes.FETCH_KITS_LIST,
        this.getSavedFilters(identifiers.dla) //this.state.data,
      );
      }
    }else{
      if (hasZmccRolesAccess) {
        this.setState(prevState => ({ isLabsLocationType: false, isDlaType: false, isZmccType: true }));
        this.props.fetchKitsListData(
          `${URLS.kitsList}?businessUnitType=RMS_ZMCC`,
          actionTypes.FETCH_KITS_LIST,
          this.getSavedFilters(identifiers.zmcc),//this.state.data,
        );
        this.props.fetchMasterData(`${URLS.zmccCountryAndStocksList}?excludeRequesterRole=true`, actionTypes.FETCH_RMS_ZMCC_COUNTRIES);
      }
      else if ((this.state.hasLLPermission && this.state.hasNormalPermission) || (this.props.user && this.props.user.admin)) {
        this.setState(prevState => ({ isLabsLocationType: false, isDlaType: true }));
        this.props.fetchKitsListData(
          `${URLS.kitsList}?businessUnitType=GENERAL`,
          actionTypes.FETCH_KITS_LIST,
          this.getSavedFilters(identifiers.dla)//this.state.data,
        );
        this.props.fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
      } else if (this.state.hasNormalPermission && !(this.props.user && this.props.user.admin)) {
        this.setState(prevState => ({ isLabsLocationType: false, isDlaType: true }));
        this.props.fetchKitsListData(
          URLS.kitsList + '?businessUnitType=GENERAL',
          actionTypes.FETCH_KITS_LIST,
          this.getSavedFilters(identifiers.dla)//this.state.data,
        );
        this.props.fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
      } else if ((this.state.hasLLPermission || this.props.user.labLocationPartner) && !(this.props.user && this.props.user.admin)) {
        this.setState(prevState => ({ isLabsLocationType: true, isDlaType: false }));
        this.props.fetchKitsListData(
          `${URLS.kitsList}?businessUnitType=SPECIAL`,
          actionTypes.FETCH_KITS_LIST,
          this.getSavedFilters(identifiers.labsAtLocation), //this.state.data,
          this.state.isLabsLocationType,
        );
        this.props.fetchMasterData(URLS.userCountriesLabLocation, actionTypes.FETCH_LABS_LOCATION_COUNTRIES);
        this.props.fetchMasterData(URLS.systemclassesLabsLocation, actionTypes.FETCH_LABS_LOCATION_SYSTEM_CLASSES);
      }
    }
    
    this.props.fetchCartDataFromLocalStorage();
    this.props.fetchProblemsList();
    this.loadInitialData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.language !== prevProps.language) {
      this.fetchDataOnLanguageChange();
    }
  }
  componentWillUnmount() {
    this.debounceUpdateData.cancel();
  }

  getSavedFilters = (identifier) => {
    let storage = fetchFromStorage(identifier);
    if(!storage){
      storage = {};
    }
    let newData = this.state.data;
    newData = {
      ...newData,
      filter: {
        ...newData.filter,
        ...storage,
      }
    }
    this.setState(prevState => ({...prevState, data: newData }))
    return newData;
  }

  loadInitialData = async () => {
    const { fetchMasterData } = this.props;
    const lastSlectedCheckBoxInKit = fetchFromStorage(identifiers.lastSlectedCheckboxInKits);
    if (this.state.isLabsLocationType || lastSlectedCheckBoxInKit === 'l@l' || this.props.user?.labLocationPartner) {
      fetchMasterData(URLS.userCountriesLabLocation, actionTypes.FETCH_LABS_LOCATION_COUNTRIES);
    } else if (this.state.isZmccType || lastSlectedCheckBoxInKit === 'zmcc') {
      await this.props.fetchMasterData(`${URLS.zmccCountryAndStocksList}?excludeRequesterRole=true`, actionTypes.FETCH_RMS_ZMCC_COUNTRIES);
    } else if (this.state.isDlaType || lastSlectedCheckBoxInKit === 'dla')  {
      await fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
    }
    await fetchMasterData(URLS.categoryList, actionTypes.FETCH_CATEGORY_LIST);
    await fetchMasterData(URLS.kitCondition, actionTypes.FETCH_CONDITIONS);
    await fetchMasterData(URLS.kitStatus, actionTypes.FETCH_KIT_STATUS);
    await fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
    this.checkFiltersLanguage();
  };

  fetchDataOnLanguageChange = async () => {
    const { data } = this.state;
    const { fetchMasterData, fetchKitsListData } = this.props;
    await fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
    await fetchMasterData(URLS.kitStatus, actionTypes.FETCH_KIT_STATUS);
    await fetchMasterData(URLS.kitCondition, actionTypes.FETCH_CONDITIONS);
    this.checkFiltersLanguage();
  };

  checkFiltersLanguage = () => {
    const newFilter = checkFiltersLanguage(this.state.data.filter, this.props);
    if (newFilter.statusFullValue || newFilter.conditionFullValue) {
      this.setState(
        prevState => ({
          data: {
            ...prevState.data,
            filter: newFilter,
          },
        }),
        () => {
          this.saveFilterToStorage(identifiers.filtersKit, this.state.data.filter);
        },
      );
    }
  };

  handleApiCallToKits(state) {
    if (state.isDlaType) {
      this.props.fetchKitsListData(
        URLS.kitsList + '?businessUnitType=GENERAL',
        actionTypes.FETCH_KITS_LIST,
        state.data,
      );
    } else if (state.isZmccType){
      this.props.fetchKitsListData(
        URLS.kitsList + '?businessUnitType=RMS_ZMCC',
        actionTypes.FETCH_KITS_LIST,
        state.data,
      );
    }
    else {
      this.props.fetchKitsListData(
        URLS.kitsList + '?businessUnitType=SPECIAL',
        actionTypes.FETCH_KITS_LIST,
        state.data,
        this.state.isLabsLocationType,
      );
    }
  }

  handleFilterChange = (key, value, fullValue) => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.filter[key] = value;
        newData.filter[`${key}FullValue`] = fullValue;
        return {
          data: newData,
        };
      },
      () => {
        this.saveFilterToStorage(identifiers.filtersKit, this.state.data.filter);
        this.handleApiCallToKits(this.state);
      },
    );
  };

  saveFilterToStorage = (key, value) => {
    if (this.state.isDlaType) {
      saveToStorage(identifiers.dla, value);
    } else if (this.state.isZmccType) {
      saveToStorage(identifiers.zmcc, value);
    }else {
      saveToStorage(identifiers.labsAtLocation, value);
    }
  };

  resetFilters = () => {
    this.setState(
      prevState => ({
        data: { ...prevState.data, filter: { ...INITIAL_FILTER, searchTerm: prevState.data.searchTerm } },
      }),
      () => {
        this.saveFilterToStorage(identifiers.filtersKit, this.state.data.filter);
        this.handleApiCallToKits(this.state);
      },
    );
  };

  showOrHideFilter = () => {
    this.setState(prevState => ({ isShowFilter: !prevState.isShowFilter }));
  };

  handleSearchChange = e => {
    const { value } = e.target;
    this.debounceUpdateData({ value });
  };

  debounceUpdateData = debounce(({ value }) => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.page = 1;
        newData.filter.searchTerm = value;
        return { data: newData };
      },
      () => {
        this.saveFilterToStorage(identifiers.filtersKit, this.state.data.filter);
        this.handleApiCallToKits(this.state);
      },
    );
  }, 1000);

  handlePageChange = page => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };

        newData.page = page + 1;
        return { data: newData };
      },
      () => this.handleApiCallToKits(this.state),
    );
  };

  handleItemsPerPage = size => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };

        newData.size = size;
        return { data: newData };
      },
      () => this.handleApiCallToKits(this.state),
    );
  };

  handleSort = name => {
    this.setState(
      prevState => {
        const newData = JSON.parse(JSON.stringify(prevState.data));
        Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
        newData.sort[name] = prevState.data.sort[name] ? (prevState.data.sort[name] === 'asc' ? 'desc' : null) : 'asc';
        return { data: newData };
      },
      () => this.handleApiCallToKits(this.state),
    );
  };

  goToCreateKits = () => {
    this.props.resetCreateKit();
    this.props.history.push('/create-kit');
  };

  goGetDemoRequestForm = () => {
    this.props.fetchDemoRequestForm();
  };

  goToCreateLLKits = () => {
    this.props.history.push('/create-llkit');
  };

  goToCreateZMCCKits = () => {
    this.props.history.push('/create-zmcc-kit');
  };

  handleDelete = async id => {
    const { data } = this.state;
    const { deleteKit } = this.props;
    await deleteKit(id);
    this.handleApiCallToKits(this.state);
    scrollToTop(500);
  };

  downloadKitList = () => {
    $('#export-filter-kits').modal('show');
  };

  toggleDisplayOfCreateKitButton() {
    const { user } = this.props;
    const isStockController =
      user &&
      user.stockRoles.some(
        item => item.businessUnitName !== 'RMS labs@location Partner' && item.roleName === 'Stock Controller',
      );

    return isStockController || this.state.isDlaType || (user && user.admin);
  }

  createDataForSystemClassDropdown() {
    const { stock } = this.state.data.filter;
    const { categoriesAndSystems } = this.props;
    let arrayOfSystemClass = [];

    if (stock && stock.length > 0) {
      const { stockSystemClasses, systemClasses } = categoriesAndSystems;
      if(stockSystemClasses){
      stock.forEach(stock => {
        const data = stockSystemClasses && stockSystemClasses[stock];
        if (data) {
          data.forEach(item => {
            arrayOfSystemClass.push(systemClasses.find(systemClass => item === systemClass.systemClassId));
          });
        }
      });
      }else{
        arrayOfSystemClass = categoriesAndSystems && categoriesAndSystems.systemClasses;
      }
    } else {
      arrayOfSystemClass = categoriesAndSystems && categoriesAndSystems.systemClasses;
    }

    return Array.from(new Set(arrayOfSystemClass)).sort((a, b) => {
      const systemClassA = a.systemClassName.toUpperCase();
      const systemClassB = b.systemClassName.toUpperCase();

      return systemClassA > systemClassB ? 1 : -1;
    });
  }

  fetchKitsList = () => {
    const { data } = this.state;
    const { fetchKitsListData } = this.props;
    fetchKitsListData(URLS.kitsList, actionTypes.FETCH_KITS_LIST, data);
  };

  getCols = () => {
    const { user } = this.props;
    const hasPermissionsForDustStatus = checkUserRoles(permissions.dustStatus, user);
    const cols = dataTable();
    cols[2].isHidden = hasPermissionsForDustStatus ? false : true;
    if (this.state.isDlaType) {
      cols[8].title = strings.location;
      cols[5].isHidden = this.state.isDlaType;
    } else if (this.state.isZmccType) {
      cols[8].title = strings.location;
      cols[2].isHidden = this.state.isZmccType;
      cols[7].isHidden = this.state.isZmccType;
      cols[10].isHidden = this.state.isZmccType;
    } 
    else {
      cols[8].title = strings.partnerSite;
      cols[2].isHidden = this.state.isLabsLocationType;
      cols[7].isHidden = this.state.isLabsLocationType;
      cols[10].isHidden = this.state.isLabsLocationType;
      cols[5].isHidden = this.state.isLabsLocationType;
    }
    return cols;
  };

  handleCheckbox = (_, type) => {
    saveToStorage(identifiers.lastSlectedCheckboxInKits, type)
    if (type === 'dla') {
      let storage = fetchFromStorage(identifiers.dla);
      let tempFilter = {
        ...storage,
      };
      if (storage) {
        tempFilter.searchTerm = storage.searchTerm ? storage.searchTerm : '';
      }
      if (storage === null || storage === undefined) {
        tempFilter.status = [8,13]
      }
      this.setState(
        prevState => ({
          isLabsLocationType: false,
          isDlaType: true,
          isZmccType: false,
          data: {
            ...prevState.data,
            filter: tempFilter,
            page: 1,
          },
        }),
        () => {
          this.props.fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
          this.props.fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
          this.props.fetchKitsListData(
            URLS.kitsList + '?businessUnitType=GENERAL',
            actionTypes.FETCH_KITS_LIST,
            this.state.data,
          );
        },
      );
    } else if (type === 'zmcc') {
      let storage = fetchFromStorage(identifiers.zmcc);

      let tempFilter = {
        ...storage,
      };
      if (storage) {
        tempFilter.searchTerm = storage.searchTerm ? storage.searchTerm : '';
      }
      this.setState(
        prevState => ({
          isLabsLocationType: false,
          isDlaType: false,
          isZmccType: true,
          data: {
            ...prevState.data,
            filter: tempFilter,
            page: 1,
          },
        }),
        () => {
          this.props.fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
          this.props.fetchMasterData(`${URLS.zmccCountryAndStocksList}?excludeRequesterRole=true`, actionTypes.FETCH_RMS_ZMCC_COUNTRIES);
          this.props.fetchKitsListData(
            URLS.kitsList + '?businessUnitType=RMS_ZMCC',
            actionTypes.FETCH_KITS_LIST,
            this.state.data,
          );
        },
      );
    } else {
      let storage = fetchFromStorage(identifiers.labsAtLocation);
      let tempFilter = {
        ...storage,
      };
      if (storage) {
        tempFilter.searchTerm = storage.searchTerm ? storage.searchTerm : '';
      }
      this.setState(
        prevState => ({
          isLabsLocationType: true,
          isDlaType: false,
          isZmccType: false,
          data: {
            ...prevState.data,
            filter: tempFilter,
            page: 1,
          },
        }),
        () => {
          this.props.fetchMasterData(URLS.userCountriesLabLocation, actionTypes.FETCH_LABS_LOCATION_COUNTRIES);
          this.props.fetchKitsListData(
            URLS.kitsList + '?businessUnitType=SPECIAL',
            actionTypes.FETCH_KITS_LIST,
            this.state.data,
            this.state.isLabsLocationType,
          );
          this.props.fetchMasterData(URLS.systemclassesLabsLocation, actionTypes.FETCH_LABS_LOCATION_SYSTEM_CLASSES);
        },
      );
    }
  };

  render() {
    const kitStatusIds = [13, 8, 9, 14, 10];
    const conditionIds = [10, 13, 14];
    const labsAtLocationCaonditions = [1, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14];
    const rmsZMCCConditions = [ 10, 11, 12, 1, 3, 7, 9];
    const { isLoading, displayActionMessage, type, message } = this.props.appActions;
    const { data, isShowFilter, isDlaType, isLabsLocationType, isZmccType } = this.state;
    const { kitsList, countries, kitStatus, conditions, user } = this.props;
    const filteringKitStatus = kitStatus.filter(status => kitStatusIds.includes(status.kitStatusId));
    const filteringConditions = conditions.filter(condition => !conditionIds.includes(condition.kitStatusId));
    const checkKitsList = kitsList && kitsList.content && kitsList.content.length;
    const categoriesAndSystems = this.createDataForSystemClassDropdown();
    const hasLLPermission = (user && user.admin) || this.state.hasLLPermission;
    const hasNonRmsPermission = (user && user.admin) || this.state.hasNormalPermission;
    const hasZmccPermission = (user && user.admin) || this.state.hasZmccPermission;
    const cols = this.getCols();
    const isLLEnabled = process.env.REACT_APP_IS_LABSLOCATION_ENABLED === 'true';
    const isZMCCEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';
    const checkStockPermission =
      this.props.user &&
      this.props.user.stockRoles.find(
        item => item.roleName.toLowerCase() === 'dispatcher' || item.roleName.toLowerCase() === 'stock controller',
      );
      const checkZmccStockPermission =
      this.props.user &&
      this.props.user.stockRoles.find(
        item => (item.businessUnitName === 'RMS (ZMCC)' && item.roleName.toLowerCase() === 'stock controller'),
      );

    const showCreateKitButton = (user && user.admin) || checkStockPermission;
    const filteringConditionsForLabsAtLocation = conditions.filter(
      condition => !labsAtLocationCaonditions.includes(condition.kitStatusId),
    );
    const filteringConditionsForZmcc = conditions.filter(
      condition => !rmsZMCCConditions.includes(condition.kitStatusId),
    );
    const labsAtLocationStorage = fetchFromStorage(identifiers.labsAtLocation);
    const labsLocationSearch = labsAtLocationStorage && labsAtLocationStorage.searchTerm;
    const zmccStorage = fetchFromStorage(identifiers.zmcc);
    const zmccSearch = zmccStorage && zmccStorage.searchTerm;
    const showZmccCreateKit = (user && user.admin) || checkZmccStockPermission;

    const isOnlyZMCCUserOrAdmin =  ((this.props.user.admin) || (this.props.user &&
      this.props.user.stockRoles.find(
        item => (item.businessUnitName === 'RMS (ZMCC)'))));

    const zmccClassifications = 
    [
      {"zmccClassificationId": 1, "zmccClassification": "System"},
      { "zmccClassificationId": 2, "zmccClassification": "Accessory"}
    ];

    let isRmsStockController = false;
    const allRoles =
      this.props.user &&
      this.props.user.stockRoles &&
      this.props.user.stockRoles.find(
        item => item.businessUnitName === 'RMS' && item.roleName.toLowerCase() === 'stock controller',
      );

    if (allRoles) {
      isRmsStockController = true;
    }
    isRmsStockController = (user && user.admin) || isRmsStockController;
    return (
      <div>
        {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
        <div className='container-fluid mb-3'>
          <Breadcrumbs data={kitsListBreadcrumbData} />
          <div className='row no-gutters align-items-center'>
            <div className='col-lg col-12 mb-lg-0 mb-3'>
              <div className='d-flex align-items-center'>
                <h1 className='kit-heading-title mr-3'>{strings.kits}</h1>
              </div>
            </div>
            <div className='col-lg-auto col mr-3 d-flex'>
              <div className='input-wrapper'>
                {isDlaType && <SearchField onChange={this.handleSearchChange} initialValue={data.filter.searchTerm} />}
                {isLabsLocationType && (
                  <SearchField onChange={this.handleSearchChange} initialValue={labsLocationSearch} />
                )}
                {isZmccType && (
                  <SearchField onChange={this.handleSearchChange} initialValue={zmccSearch} />
                )}
              </div>
            </div>
            </div>
            <div className='mt-4 adjust-hideFilter-and-type'>
            <div className='adjust-trans-type-button'>
              {((hasLLPermission && hasNonRmsPermission) || (hasNonRmsPermission && hasZmccPermission)) &&  (
                <button
                  className={`${isDlaType ? 'active-transaction-type' : 'type-button'}`}
                  onClick={() => this.handleCheckbox('', 'dla')}
                >
                  {strings.loanEquipment} <div className="icon-container"><Icon name='infoRounded' fill={"rgba(151, 151, 151, 1)"} width='15' height='15' viewBox='0 0 15 14' className='info-button cursor-pointer' /> <div className="tooltip">{strings.loanEquipmentMessage}</div></div>
                </button>
              )}
              {isZMCCEnabled && ((hasZmccPermission && hasNonRmsPermission) || (hasZmccPermission && hasLLPermission)) && (
                  <button
                    className={`${isZmccType ? 'active-transaction-type' : 'type-button'}`}
                    onClick={() => this.handleCheckbox('', 'zmcc')}
                  >
                    {strings.zeissLocations} <div className="icon-container"><Icon name='infoRounded' fill={"rgba(151, 151, 151, 1)"} width='15' height='15' viewBox='0 0 15 14' className='info-button cursor-pointer' /><div className="tooltip">{strings.zeissLocationsMessage}</div></div>
                  </button>
                )}
              {((hasLLPermission && hasNonRmsPermission) ||( hasZmccPermission && hasLLPermission)) && (
                <button
                  className={`${isLabsLocationType ? 'active-transaction-type' : 'type-button'}`}
                  onClick={() => this.handleCheckbox('', 'l@l')}
                >
                  {strings.partnerLocations} <div className="icon-container"><Icon name='infoRounded' fill={"rgba(151, 151, 151, 1)"} width='15' height='15' viewBox='0 0 15 14' className='info-button cursor-pointer' /><div className="tooltip">{strings.partnerLocationsMessage}</div></div>
                </button>
              )}
            </div>
            
            <div onClick={this.showOrHideFilter}>
              {isShowFilter && (
                <button className='type-button mr-2'>
                  <Icon name='hideFilter' width='20' height='20' viewBox='0 0 20 20' fill={'#0088D0'} />{' '}
                  <span className='ml-1 hideFilter-button'>{strings.hideFilter}</span>
                </button>
              )}
              {!isShowFilter && <button className='type-button hideFilter-button mr-2'>{strings.showAll}</button>}
            </div>
          </div>
          <hr style={{marginTop:'3px'}}></hr>
        </div>
        {this.state.isDlaType && (
          <Filter
            isShowFilter={isShowFilter}
            isLabsLocationType={false}
            countries={countries}
            categoriesAndSystems={categoriesAndSystems}
            kitStatus={filteringKitStatus}
            conditions={filteringConditions}
            handleFilterChange={this.handleFilterChange}
            selected={data.filter}
            resetFilters={this.resetFilters}
          />
        )}

        {this.state.isLabsLocationType && (
          <Filter
            isShowFilter={isShowFilter}
            isLabsLocationType={true}
            countries={countries}
            categoriesAndSystems={this.props.labsLocationSystemClasses}
            kitStatus={filteringKitStatus}
            conditions={filteringConditionsForLabsAtLocation}
            handleFilterChange={this.handleFilterChange}
            selected={data.filter}
            resetFilters={this.resetFilters}
          />
        )}
        {this.state.isZmccType && 
          <Filter 
            isShowFilter={isShowFilter} 
            isLabsLocationType={false} 
            isZmccType={true} 
            countries={countries}
            categoriesAndSystems={categoriesAndSystems}
            kitStatus={filteringKitStatus}
            conditions={filteringConditionsForZmcc}
            zmccClassifications={zmccClassifications}
            handleFilterChange={this.handleFilterChange}
            selected={data.filter}
            resetFilters={this.resetFilters}
            isZmccUser={isOnlyZMCCUserOrAdmin}
          />
        }
        <div className='kit-table'>
          {checkKitsList ? (
            <div className='container-fluid mb-3'>
              <ExpandableTable
                user={user}
                cols={cols}
                rows={kitsList.content}
                sortFields={data.sort}
                handleSort={this.handleSort}
              >
                <ExpandableRowOfKitListTable fetchKitsList={this.fetchKitsList} handleDelete={this.handleDelete} />
              </ExpandableTable>
            </div>
          ) : (
            <div className='d-flex justify-content-center my-5'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
        <div className='container-fluid'>
          <div className='row no-gutters'>
            <div className='col-md-auto mb-3'>
              {this.state.isDlaType && showCreateKitButton && (
                <Button bgFill={true} iconName='plus' value={strings.createKit} onClick={this.goToCreateKits} newVersionUi={true}
                 iconWidth={20}
                iconHeight={20}
                 styleClass='mt-0 add-padding-create-kit-button'/>
              )}
            </div>
            {this.state.isDlaType && isRmsStockController && (
              <div className='col-md-auto pl-md-3 mb-3'>
                <Button
                  bgFill={false}
                  iconName='plus'
                  value={strings.requestDemoKit}
                  onClick={this.goGetDemoRequestForm}
                  newVersionUi={true}
                  iconWidth={20}
                  iconHeight={20}
                   styleClass='mt-0 add-padding-for-demo-request-button'
                />
              </div>
            )}
            {this.state.isDlaType && (
              <div className='col-md-auto pl-md-3 mb-3'>
                <Button
                  bgFill={false}
                  iconName='newVersionDownload'
                  value={strings.exportList}
                  styleClass='mt-0 adjust-padding-button'
                  onClick={this.downloadKitList}
                  newVersionUi={true}
                  iconWidth={15}
                  iconHeight={20}
                  viewBox={"0 0 16 16"}
                />
              </div>
            )}
            {this.state.isLabsLocationType && showCreateKitButton && (
              <div className='col-md-auto mb-3'>
                <Button
                  bgFill={true}
                  iconName='plus'
                  value={strings.createLLKit}
                  styleClass='mt-0 add-padding-create-kit-button'
                  onClick={this.goToCreateLLKits}
                  newVersionUi={true}
                  iconWidth={20}
                  iconHeight={20}
                />
              </div>
            )}
            {this.state.isZmccType && showZmccCreateKit && (
              <div className='col-md-auto md-3 mb-3'>
                <Button
                  bgFill={true}
                  iconName='plus'
                  value={strings.addSystem}
                  styleClass='mt-0 add-padding-create-kit-button'
                  onClick={this.goToCreateZMCCKits}
                  newVersionUi={true}
                  iconWidth={20}
                  iconHeight={20}
                />
              </div>
            )}
          </div>
        </div>
        <div className=''>
        {checkKitsList ? (
          <div className='col d-flex justify-content-center mb-3 align-items-center'>
            <ItemsPerPage
              itemsPerPage={toArray(itemsPerPage)}
              handleItemsPerPage={this.handleItemsPerPage}
              value={itemsPerPage[data.size]}
            />
            <Pagination
              currentPage={data.page - 1}
              totalPages={kitsList.totalPages}
              onPageChange={this.handlePageChange}
            />
          </div>
        ) : null}
        </div>
        {isLoading && <div className='loader' />}
        <ExportFilterKits
          id='export-filter-kits'
          categoriesAndSystems={categoriesAndSystems}
          conditions={filteringConditions}
          countries={countries}
          kitStatus={filteringKitStatus}
          filters={data.filter}
          user={user}
          changeLoader={this.props.changeLoader}
          getSavedFilters = {this.getSavedFilters}
        />

        <ContinueUseApplicationPopup
          id={'continue-use-app'}
          changeLoader={this.props.changeLoader}
        />
      </div>
    );
  }
}

export default KitsList;
