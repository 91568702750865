export const GET_WHOLE_ZMCC_TRANSACTION_DETAILS = 'GET_WHOLE_ZMCC_TRANSACTION_DETAILS';
export const FETCH_RMS_ZMCC_USER = 'FETCH_RMS_ZMCC_USER';
export const FETCH_ZMCC_PRIORITES = 'FETCH_ZMCC_PRIORITES';
export const SET_SEM_FORM_VALUES = 'SET_SEM_FORM_VALUES';
export const FETCH_ZMCC_TRANSACTION_DETAILS_STATUSES = 'FETCH_ZMCC_TRANSACTION_DETAILS_STATUSES';
export const FETCH_CONNECTED_TRANSACTIONS_LIST = 'FETCH_CONNECTED_TRANSACTIONS_LIST';
export const SET_INFORMATION_TAB_DATA = 'SET_INFORMATION_TAB_DATA';
export const FETCH_ZMCC_OPERATOR_FOR_STOCK = 'FETCH_ZMCC_OPERATOR_FOR_STOCK'
export const FETCH_ZMCC_SUBSCRIBERS_FOR_STOCK = 'FETCH_ZMCC_SUBSCRIBERS_FOR_STOCK'
export const FETCH_ZMCC_OPERATOR_SCHEDULES = 'FETCH_ZMCC_OPERATOR_SCHEDULES';
export const FETCH_SAMPLE_AT = 'FETCH_SAMPLE_AT';
export const ADD_PREPARATION_PERIOD_MODAL_ID = "add-preparation-period-modal"

const getTransactionName = urlData => {
  let transactionType = '';
  urlData &&
    urlData.split('-').forEach((item, index) => {
      if (index !== 0) {
        transactionType += item.charAt(0).toUpperCase() + item.slice(1);
      } else {
        transactionType = item;
      }
    });
  return transactionType;
};
export const breadcrumbsData = (transactionType, route) => [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: getTransactionName(transactionType),
    url: `/${transactionType}`,
  },
  {
    name: route,
    url: '',
  },
];
