import React from 'react';

import strings from 'resources/locales/Translate';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import Button from 'library/common/commonComponents/Button/Button';

import './addressItemStyles.scss';

const AddressItem = ({ address, containerClass, onEditAddress, deleteAddress, openAddressDeleteModel }) => {
  return (
    <div className={containerClass}>
      <div className="address-item mt-3 border border-#e0e1dd">
        <div className="address-content">
          <p className="text-capitalize">{address.addressType}</p>
          <p className="name">{address.firstName} {address.lastName}</p>
          <p className="organization">{address.organization}</p>
          <p className="street">{address.street} {address.houseNumber}</p>
          <p className="city">{address.postalCode} {address.city}</p>
          <p className="country">{address.countryName}</p>
        </div>
        <div className="address-actions d-flex">
          <Button
            dataTest="open-edit-address-modal-button"
            bgFill={false}
            iconWidth={20}
            iconHeight={20}
            iconName="edit"
            onClick={() => onEditAddress(address)}
          />
          <div style={{ width: "100px" }}></div>
          {address.deletionPossible && (
            <Button
              dataTest="open-delete-address-modal-button"
              bgFill={false}
              iconWidth={20}
              iconHeight={20}
              iconName="delete"
              onClick={() => openAddressDeleteModel(address.userAddressId)}
            />
          )}
          <div style={{ width: "1200px" }}></div>
          <div style={{ width: "1200px" }}></div>
          <div style={{ width: "1200px" }}></div>
          <div style={{ width: "1200px" }}></div>
        </div>
      </div>

      <ConfirmDeletionPopup
        id={`confirmAddressDeleteModal${address.userAddressId}`}
        onConfirm={() => deleteAddress(address.userAddressId)}
        title={strings.deleteDeliveryAddress}
        subtitle={strings.deleteTheAddress}
      />
    </div>
  )
};

export default AddressItem;
