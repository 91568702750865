import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useCustomerContactPopup } from './CustomerContactPopup.hook';
import { connect } from 'react-redux';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

export const CustomerContactPopup = ({
  id,
  allDropdownData,
  setCustomerContactList,
  customerContactList,
  toggleActionMessage,
}) => {
  const { getCustomerContactPopupModel, handleForm, customerContactPopupForm, onCloseClick, onSaveClick } =
    useCustomerContactPopup({ id, allDropdownData, setCustomerContactList, customerContactList, toggleActionMessage });
  return (
    <div>
      <div>
        <Modal
          id={id}
          title={strings.customerContact}
          confirmAction={onSaveClick}
          confirmTitle={strings.confirm}
          //   loading={loading}
          onCloseClick={onCloseClick}
          className='add-location-comment-popup-width'
          newVersionUi={true}
          titleOfCancel={strings.cancel}
        >
          <div className='mt-4'>
            <FormComponent
              formName='customerContactPopupForm'
              model={getCustomerContactPopupModel()}
              formValue={customerContactPopupForm.formData}
              onChange={handleForm}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, iqsRequestReducer }) => ({
  user: authReducer.user,
  allDropdownData: iqsRequestReducer.allDropdownData,
});

export default connect(mapStateToProps, { toggleActionMessage })(CustomerContactPopup);
