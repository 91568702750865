import React, { useEffect, useState, useCallback } from 'react';

import strings from 'resources/locales/Translate';
import {
  astronautColor,
  toreaBayColor,
  ceruleanColor,
  malibuColor,
  limeadeColor,
  javaColor,
  sanMarinoColor,
} from 'resources/styles/variables.scss';
import { getDateInUTCFormat, getDateInFormat } from 'library/utilities/getDateInFormat';

import {  FETCH_STOCK_BY_COUNTRY_FOR_KIT_DATA, FETCH_SYSTEM_CLASS_FOR_KIT_DATA } from '../../Statistics.constants';

export const useKitDataTab = ({
  kitStatusChartStatistics,
  systemClassKitData,
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  fetchKitStatusChartStatistics,
  clearKitStatusChartStatistics,
  fetchKitDataStatistics,
  clearKitDataStatistics,
  fetchUtilizedKits,
  fetchWorkingCapitalInStock,
  clearWorkingCapitalInStock,
  language,
}) => {
  const [filters, setFilters] = useState({
    businessGroup: null,
    businessGroupFullValue: null,
    country: null,
    countryFullValue: null,
    stock: null,
    stockFullValue: null,
    systemClass: null,
    systemClassFullValue: null,
    loanTypes: null,
    loanTypesFullValue: null,
    dateRange: null,
  });

  useEffect(() => {
    return () => {
      clearKitStatusChartStatistics();
      clearKitDataStatistics();
      clearWorkingCapitalInStock();
    }
  }, [clearKitStatusChartStatistics, clearKitDataStatistics, clearWorkingCapitalInStock]);

  useEffect(() => {
    if (filters.country) {
      getStocksByBussinessUnitandCountryIds(filters.country, filters.businessGroup , FETCH_STOCK_BY_COUNTRY_FOR_KIT_DATA);
      setFilters(prevState => ({
        ...prevState,
        stock: null,
        stockFullValue: null,
      }));
    }
  }, [filters.country, filters.businessGroup, getStocksByBussinessUnitandCountryIds]);

  useEffect(() => {
    if (filters.businessGroup) {
      getSystemClassesByBU(filters.businessGroup, FETCH_SYSTEM_CLASS_FOR_KIT_DATA);
      setFilters(prevState => ({
        ...prevState,
        systemClass: null,
        systemClassFullValue: null,
      }));
    }
  }, [filters.businessGroup, filters.businessGroupFullValue, getSystemClassesByBU]);


  useEffect(() => {
    if (filters.dateRange) {
      const dataToSend = {
        businessUnitIds: filters.businessGroup,
        countryIds: filters.country,
        stockIds: filters.stock,
        systemMainComponentIds: filters.systemClass,
         from: getDateInFormat('YYYY-MM-DD', filters.dateRange[0]),
         to: getDateInFormat('YYYY-MM-DD', filters.dateRange[1]),
      };
      fetchUtilizedKits(dataToSend);
      fetchKitDataStatistics(dataToSend);
      fetchWorkingCapitalInStock(dataToSend);
      fetchKitStatusChartStatistics(dataToSend);
    }
  }, [filters.businessGroup, filters.country, filters.stock, filters.systemClass, filters.dateRange, fetchKitDataStatistics, fetchWorkingCapitalInStock]);

  useEffect(() => {
    if (!filters.businessGroup || !filters.dateRange || !filters.dateRange.length) {
      clearKitDataStatistics();
      clearWorkingCapitalInStock();
      clearKitStatusChartStatistics();
    }
  }, [filters.businessGroup, filters.dateRange, clearKitDataStatistics, clearWorkingCapitalInStock]);

  const getSystemClass = useCallback(() => {
    if (filters.stock && filters.stock.length > 0) {
      const { stockSystemClasses, systemClasses } = systemClassKitData || {};

      return filters.stock.reduce((acc, current) => {
        const data = stockSystemClasses && stockSystemClasses[current];
    
        if (!data) {   
          return acc;
        }

        return systemClasses.filter(systemClass => data.includes(systemClass.systemClassId));
      }, []);
    }

    return systemClassKitData.systemClasses || [];
  }, [systemClassKitData, filters.stock]);

  const openKitDetailsPage = kitId => {
    window.open(`${process.env.REACT_APP_UI_CALLBACK_URL}/#/kit_details/${kitId}`, '_blank');
  };

  const getTableCols = () => [
    {
      title: strings.serialNumber,
      name: 'serialNumber',
      isSort: false,
      render: item => {
        return <>{item.serialNumber}</>;
      },
    },
    {
      title: strings.kitInformationId,
      name: 'kitId',
      isSort: false,
      render: item => {
        return <>{item.kitId}</>;
      },
    },
    {
      title: strings.kitName,
      name: 'kitName',
      isSort: false,
      render: item => {
        const { kitId, kitName } = item;
        return <div className='cursor-pointer' onClick={() => openKitDetailsPage(kitId)}>{kitName}</div>;
      },
    },
    {
      title: strings.utilizationRate,
      name: 'utilizationRate',
      isSort: false,
      render: item => {
        return <div className='text-center'>{item.utilizationRate} %</div>;
      },
    },
  ];

  const systemClass = getSystemClass().filter(item => item.systemMainComponent.length);
  const currentDate = getDateInUTCFormat('DD.MM.YYYY', new Date());
  const colors = [
    toreaBayColor,
    ceruleanColor,
    astronautColor,
    malibuColor,
    limeadeColor,
    sanMarinoColor,
    javaColor,
  ];
  const chartLegend = kitStatusChartStatistics.map((item, index) => ({
    name: item.statusName,
    color: colors[index],
    statusId: item.statusId,
  }));
  const chartLabels = kitStatusChartStatistics.map(item => item.statusName);
  const chartData = kitStatusChartStatistics.map(item => item.percent);
  const hasDataForChart = chartLabels.length > 0;
  const data = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: colors,
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 10,
        },
      },
    ],
  };
  const tableCols = getTableCols();

  return {
    filters,
    systemClass,
    hasDataForChart,
    data,
    currentDate,
    chartLegend,
    setFilters,
    tableCols,
  }
}
