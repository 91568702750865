import { get } from 'lodash';
import { batchActions } from 'redux-batched-actions';

import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';

import * as actionTypes from '../commonConstants/storeConstants';

/**
 * This action will fetch master data and save it to redux configureStore for future use in app
 * @returns {Function}
 */
export const fetchMasterData = (url, actionType, graphql = null) => dispatch => {
  if (graphql) {
    url = `${url}?graphql=${encodeURIComponent(graphql)}`;
  }

  return fetchMasterDataService(url).then(res => {
    if (res && res.status === 200) {
      let payload = res.data;
      if (actionType === 'FETCH_BUSINESS_UNIT_LIST') {
        payload = payload.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
      if (actionType === 'FETCH_KIT_STATUS') {
        payload = payload.sort((a, b) => (a.translatedStatus > b.translatedStatus ? 1 : -1));
      }
      dispatch({ type: actionType, payload });

      if (actionType === actionTypes.FETCH_COUNTRY_LIST || actionType === actionTypes.COUNTIRES_THAT_ENABLED_SEARCH_TERM) {
        batchActions([
          dispatch({
            type: actionTypes.SET_MASTER_DATA_COUNTRY_OF_ORIGIN,
            payload: populateCountryOfOrigin(res.data),
          }),
          dispatch({
            type: actionType,
            payload: createAdditionalFieldsForCountryDropdown(res.data),
          }),
        ]);
      }
    }

    return true;
  });
};

export const fetchMasterDataPost = (url, actionType, data) => dispatch => {
  return fetchMasterDataServicePost(url, data).then(res => {
    if (res.status === 200) {
      if (actionType === actionTypes.FETCH_COUNTRY_LIST) {
        dispatch({
          type: actionType,
          payload: createAdditionalFieldsForCountryDropdown(res.data.content),
        });
      } else {
        dispatch({
          type: actionType,
          payload: res.data.content,
        });
      }
    }
  });
};

const populateCountryOfOrigin = countries => {
  const filterBy = ['CN', 'US', 'DE', 'UK', 'BY', 'KR','SG'];
  let countriesArray = [];
  countries.forEach(country => {
    filterBy.forEach(filter => {
      if (filter === country.shortName) {
        countriesArray.push(country);
      }
    });
  });
  return countriesArray;
};

function createAdditionalFieldsForCountryDropdown(countries = []) {
  let arrayOfCountries = [];

  if (countries.length > 0) {
    countries.forEach(country => {
      if (get(country, 'stockInformation')) {
        country.stockInformation = country.stockInformation.sort((a, b) => (a.locationName > b.locationName ? 1 : -1));
        country.stockInformation.map(stockInfo => {
          stockInfo['countryName'] = country.shortName;
          stockInfo['businessUnitName'] = stockInfo.businessUnit.name;
        });
        arrayOfCountries.push(country);
      } else {
        arrayOfCountries.push(country);
      }
    });
  }
  arrayOfCountries = arrayOfCountries.sort((a, b) => (a.name > b.name ? 1 : -1));
  return arrayOfCountries;
}
