export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const REMOVE_AUTHENTICATION = 'REMOVE_AUTHENTICATION';
export const FETCH_COUNTRY_LIST = 'FETCH_COUNTRY_LIST';
export const FETCH_CATEGORY_LIST = 'FETCH_CATEGORY_LIST';
export const FETCH_LOAN_TYPE_LIST = 'FETCH_LOAN_TYPE_LIST';
export const FETCH_BUSINESS_UNIT_LIST = 'FETCH_BUSINESS_UNIT_LIST';
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const SET_MASTER_DATA_STOCK_FORM = 'SET_MASTER_DATA_STOCK_FORM';
export const SET_MASTER_DATA_COUNTRY_OF_ORIGIN = 'SET_MASTER_DATA_COUNTRY_OF_ORIGIN';
export const STOCK_CREATED = 'STOCK_CREATED';
export const CHANGE_LOADER = 'CHANGE_LOADER';
export const DISPLAY_ACTION_MESSAGE = 'DISPLAY_ACTION_MESSAGE';
export const FETCH_ROLES_LIST = 'FETCH_ROLES_LIST';
export const SET_MASTER_DATA_TO_ADD_USERS_FORM = 'SET_MASTER_DATA_TO_ADD_USERS_FORM';
export const FETCH_MASTER_STOCK_WORKFLOWS = 'FETCH_MASTER_STOCK_WORKFLOWS';
export const CREATE_KIT_GENERAL_INFO_FORM_UPDATE = 'CREATE_KIT_GENERAL_INFO_FORM_UPDATE';
export const FETCH_SYSTEM_CLASS_MASTER_DATA = 'FETCH_SYSTEM_CLASS_MASTER_DATA';
export const FETCH_SYSTEM_COMPONENT_MASTER_DATA = 'FETCH_SYSTEM_COMPONENT_MASTER_DATA';
export const SET_CREATE_KIT_DROPDOWN_VALUES = 'SET_CREATE_KIT_DROPDOWN_VALUES';
export const SET_KIT_ID = 'SET_KIT_ID';
export const SET_MASTER_DATA_DELIVERY_TIMES = 'SET_MASTER_DATA_DELIVERY_TIMES';
export const SET_DELIVERY_TIMES = 'SET_DELIVERY_TIMES';
export const DELIVERY_TIMES_EXITING_RECORDS_HANDLING = 'DELIVERY_TIMES_EXITING_RECORDS_HANDLING';
export const ENTITLEMENTS_EXISTING_RECORDS_HANDLING = 'ENTITLEMENTS_EXISTING_RECORDS_HANDLING';
export const SET_EMAIL_MESSAGES = 'SET_EMAIL_MESSAGES';
export const CREATE_KIT_SUCCESSFULL = 'CREATE_KIT_SUCCESSFULL';
export const RESET_CREATE_STOCK = 'RESET_CREATE_STOCK';
export const FETCH_STOCKS_LIST = 'FETCH_STOCKS_LIST';
export const FETCH_KIT_STATUS = 'FETCH_KIT_STATUS';
export const FETCH_KITS_LIST = 'FETCH_KITS_LIST';
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const SET_REQUEST_STOCK_PERMISSION_DROPDOWN = 'SET_REQUEST_STOCK_PERMISSION_DROPDOWN';
export const FETCH_REQUEST_ACCESS_PERMISSION = 'FETCH_REQUEST_ACCESS_PERMISSION';
export const ADD_REMOVE_DOCUMENTS = 'ADD_REMOVE_DOCUMENTS';
export const FETCH_STOCK_DELIVERY_TIMES = 'FETCH_STOCK_DELIVERY_TIMES';
export const FETCH_STOCK_DETAILS = 'FETCH_STOCK_DETAILS';
export const ADD_REMOVE_KIT_IMAGE = 'ADD_REMOVE_KIT_IMAGE';
export const ADD_REMOVE_KIT_ATTACHMENTS = 'ADD_REMOVE_KIT_ATTACHMENTS';
export const ADD_REMOVE_STOCK_IMAGE = 'ADD_REMOVE_STOCK_IMAGE';
export const FETCH_STOCK_IMAGES = 'FETCH_STOCK_IMAGES';
export const ADD_KIT_IMAGE_ID_FOR_REMOVE = 'ADD_KIT_IMAGE_ID_FOR_REMOVE';
export const ADD_KIT_ATTACHMENT_ID_FOR_REMOVE = 'ADD_KIT_ATTACHMENT_ID_FOR_REMOVE';
export const SET_MIN_DATE_FOR_AVAILABLE_TO_FIELD = 'SET_MIN_DATE_FOR_AVAILABLE_TO_FIELD';
export const FETCH_KIT_DETAILS = 'FETCH_KIT_DETAILS';
export const FETCH_KIT_ATTACHMENTS = 'FETCH_KIT_ATTACHMENTS';
export const REMOVE_KIT_IMAGES_IDS = 'REMOVE_KIT_IMAGES_IDS';
export const FETCH_STOCK_ENTITLEMENTS = 'FETCH_STOCK_ENTITLEMENTS';
export const SET_KIT_DETAIL_BLANK = 'SET_KIT_DETAIL_BLANK';
export const FETCH_CONDITIONS = 'FETCH_CONDITIONS';
export const VALIDATE_KIT_FORM = 'VALIDATE_KIT_FORM';
export const FETCH_CATEGORY_AND_SYSTEM = 'FETCH_CATEGORY_AND_SYSTEM';
export const VALIDATE_STOCK_DELIVERY_TIMES_FORM = 'VALIDATE_STOCK_DELIVERY_TIMES_FORM';
export const KIT_ATTACHMENTS_DELETED_SUCCESSFULLY = 'KIT_ATTACHMENTS_DELETED_SUCCESSFULLY';
export const EXCLUDE_ADDED_STOCK_CONTROLLERS = 'EXCLUDE_ADDED_STOCK_CONTROLLERS';
export const FETCH_HOMEPAGE_NEW_KIT_LIST = 'FETCH_HOMEPAGE_NEW_KIT_LIST';
export const FETCH_FREQUENTLY_BORROWED_LIST = 'FETCH_FREQUENTLY_BORROWED_LIST';
export const ADD_FORM_FOR_COPY_KIT = 'ADD_FORM_FOR_COPY_KIT';
export const ADD_KIT_TO_CART = 'ADD_KIT_TO_CART';
export const ADD_REASON_FOR_LOAN_DATA = 'ADD_REASON_FOR_LOAN_DATA';
export const SET_DROPDOWN_VALUES = 'SET_DROPDOWN_VALUES';
export const ADD_OR_REMOVE_LOAN_ATTACHMENTS = 'ADD_OR_REMOVE_LOAN_ATTACHMENTS';
export const FETCH_TRANSACTION_DETAILS = 'FETCH_TRANSACTION_DETAILS';
export const UPDATE_TRANSACTION_DETAILS = 'UPDATE_TRANSACTION_DETAILS';
export const FETCH_TRANSACTIONS_COMMENTS = 'FETCH_TRANSACTIONS_COMMENTS';
export const FETCH_TRANSACTIONS_DAMAGE_LOSS_REPORT = 'FETCH_TRANSACTIONS_DAMAGE_LOSS_REPORT';
export const FETCH_TRANSACTIONS_CHANGE_HISTORY = 'FETCH_TRANSACTIONS_CHANGE_HISTORY';
export const ADD_TRANSACTIONS_COMMENTS = 'ADD_TRANSACTIONS_MESSAGES';
export const ADD_TRANSACTIONS_DAMAGE_LOSS_REPORT = 'ADD_TRANSACTIONS_DAMAGE_LOSS_REPORT';
export const FETCH_ACTIVE_AND_NON_DELETED_STOCKS = 'FETCH_ACTIVE_AND_NON_DELETED_STOCKS';
export const SET_DELIVERY_RETURN_DELAY = '@@TRANSACTION_DETAIL/SET_DELIVERY_RETURN_DELAY';
export const FETCH_POSSIBLE_C2C_LOANS = 'FETCH_POSSIBLE_C2C_LOANS';
export const FETCH_C2C_LOANS = 'FETCH_C2C_LOANS';
export const FETCH_USER_TRANSACTIONS = 'FETCH_USER_TRANSACTIONS';
export const SET_TRANSACTION_RETURN_GOODS_BOOKING = '@@TRANSACTION_DETAIL/SET_TRANSACTION_RETURN_GOODS_BOOKING';
export const LOAN_LIST_ALTERNATE_DATE_MESSAGE = '@@LOAN_LIST/LOAN_LIST_ALTERNATE_DATE_MESSAGE';
export const UPDATE_STOCK_ADDRESS_FORM = '@@CREATE_STOCK/UPDATE_STOCK_ADDRESS_FORM';
export const SET_BUSINESS_UNIT_DISABLED = 'SET_BUSINESS_UNIT_DISABLED';
export const FETCH_SYSTEM_CLASS_WITH_STOCKS = 'FETCH_SYSTEM_CLASS_WITH_STOCKS';
export const FETCH_PROBLEM_LIST = 'FETCH_PROBLEM_LIST';
export const SET_SERIAL_NUMBER_OPTIONAL = 'SET_SERIAL_NUMBER_OPTIONAL';
export const ADD_LIST_OF_CONNECTED_TRANSACTION = 'ADD_LIST_OF_CONNECTED_TRANSACTION';
export const FETCH_STOCK_VIEW_DURATION = 'FETCH_STOCK_VIEW_DURATION';
export const FETCH_SELL_OFF_CONDITION = 'FETCH_SELL_OFF_CONDITION';
export const FETCH_SELL_OFF_VISIBILITY = 'FETCH_SELL_OFF_VISIBILITY';
export const FETCH_SELL_OFF_CURRENCY = 'FETCH_SELL_OFF_CURRENCY';
export const FETCH_SHOP_LIST = 'FETCH_SHOP_LIST';
export const FETCH_SELL_OFF_COUNTRY_LIST = 'FETCH_SELL_OFF_COUNTRY_LIST';
export const FETCH_SHOP_SUBSCRIPTIONS = 'FETCH_SHOP_SUBSCRIPTIONS';
export const FETCH_OBSERVATION_LIST = 'FETCH_OBSERVATION_LIST';
export const FETCH_ARCHIVE_LIST = 'FETCH_ARCHIVE_LIST';
export const FETCH_RESERVATION_LIST = 'FETCH_RESERVATION_LIST';
export const SET_STATISTICS = 'SET_STATISTICS';
export const SET_CONTROLLER = 'SET_CONTROLLER';
export const GET_PURCHASE_REQUESTS = 'GET_PURCHASE_REQUESTS';
export const UPDATE_PURCHASE_REQUESTS = 'UPDATE_PURCHASE_REQUESTS';
export const FETCH_RMS_SYSTEM_CLASS = 'FETCH_RMS_SYSTEM_CLASS';
export const RESET_DOCUMENTS_AND_FOLDERS = 'RESET_DOCUMENTS_AND_FOLDERS';
export const FETCH_ORDER_LIST = 'FETCH_ORDER_LIST';
export const SET_AUTHENTICATION_LOADING = 'SET_AUTHENTICATION_LOADING';
export const SET_IS_OPEN_NOTIFICATIONS_BAR = 'SET_IS_OPEN_NOTIFICATIONS_BAR';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const FETCH_ALL_NOTIFICATIONS = 'FETCH_ALL_NOTIFICATIONS';
export const SET_IS_NOTIFICATIONS_LOADING = 'SET_IS_NOTIFICATIONS_LOADING';
export const FETCH_PRODUCT_HIERARCHY = 'FETCH_PRODUCT_HIERARCHY';
export const ADD_TRANSACTION_ATTACHMENTS = 'ADD_TRANSACTION_ATTACHMENTS';
export const DELETE_TRANSACTION_ATTACHMENTS = 'DELETE_TRANSACTION_ATTACHMENTS';
export const CLEAR_TRANSACTION_ATTACHMENTS = 'CLEAR_TRANSACTION_ATTACHMENTS';
export const FETCH_COUNTRIES_WITH_ACTIVE_STOCKS = 'FETCH_COUNTRIES_WITH_ACTIVE_STOCKS';
export const FETCH_LABS_LOCATION_COUNTRIES = 'FETCH_LABS_LOCATION_COUNTRIES';
export const FETCH_TYPES_OF_JOBS = 'FETCH_TYPES_OF_JOBS';
export const FETCH_LABS_LOCATION_SYSTEM_CLASSES = '@@BOOKING/FETCH_LABS_LOCATION_SYSTEM_CLASSES';
export const FETCH_TRANSACTIONS_PICKUP_REQUESTS = 'FETCH_TRANSACTIONS_PICKUP_REQUESTS';
export const ADD_TRANSACTIONS_PICKUP_REQUESTS = 'ADD_TRANSACTIONS_PICKUP_REQUESTS';
export const FETCH_RMS_ZMCC_COUNTRIES = 'FETCH_RMS_ZMCC_COUNTRIES';
export const FETCH_LOANS_SUB_MENU = 'FETCH_LOANS_SUB_MENU';
export const MARKETPLACE_COUNTRY_LIST = 'MARKETPLACE_COUNTRY_LIST';
export const SCHEDULE_TYPES = 'SCHEDULE_TYPES';
export const MYSCHEDULED_EVENT_LIST = 'MYSCHEDULED_EVENT_LIST';
export const IS_OPERATOR = 'IS_OPERATOR';
export const SCHEDULED_DETAILS = 'SCHEDULED_DETAILS';
export const ACTIVE_PROVINCE_COUNTRIES = 'ACTIVE_PROVINCE_COUNTRIES';
export const LL_BUSINESS_UNIT = 'RMS labs@location Partner';
export const STOCK_CONTROLLER = 'Stock Controller';
export const DISPATCHER = 'Dispatcher';
export const RMS_L_L = 'RMS_L_L';
export const SPECIAL = 'SPECIAL';
export const RMS = 'RMS';
export const MAX_STOCKS_LIMIT = 20;
export const SAP_CREATED = 'CREATED';
export const SAP_WAITING = 'WAITING_FOR_ZLA';
export const SAP_RETURNED = 'RETURNED';
export const EARNED_COL = 'earned';
export const DEDUCTED_COL = 'deducted';
export const START_YEAR = '2023-01-01';
export const ACTIVE_STATUS = 'ACTIVE';
export const INACTIVE_STATUS = 'INACTIVE';
export const COUNTIRES_THAT_ENABLED_SEARCH_TERM = 'COUNTIRES_THAT_ENABLED_SEARCH_TERMS';
export const ZMCC_CREATED = 'CREATED';
export const WELCOME_POPUP = 'WELCOME';
export const VR_WELCOME_POPUP = 'VR_WELCOME';
export const APP_URL_CHANGE_POPUP = 'APP_URL_CHANGE';
export const GERMAN_LANG = 'de';
