import React from 'react';
import 'react-big-scheduler/lib/css/style.css';
import withDndContext from 'library/utilities/withDndContext';
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler';
import { useEffect, useState } from 'react';
import CalendarHeader from 'modules/Calendar/IQSCalendar/components/CalendarHeader';
import RightHeader from '../AppSchedular/components/RightHeader/RightHeader';
import { cloneDeep } from 'lodash';
import moment from 'moment';

const PopupScheduler = ({
  view = ViewTypes.Month,
  events = [],
  date = new Date(),
  headerTemplateResolver = null,
  eventItemResolver = null,
  config = {},
  shchedulerWidth = 600,
  startDate = new Date(),
  handleDateChange = () => {},
}) => {

  const getScrollSpecialMomentFunc = (schedulerData, start, end) => {
    return moment(date);
  };

  const createSchedulerData = (selectedDate = date) => {
    console.log('selectedDate', selectedDate);
    let schedulerData1 = new SchedulerData(selectedDate, ViewTypes.Month, false, false, {
      weekResourceTableWidth: -10,
      monthResourceTableWidth: -10,
      dayResourceTableWidth: -10,
      resourceName: '',
      headerEnabled: false,
      schedulerWidth: shchedulerWidth,
      monthCellWidth: 60,
      eventItemHeight: 10,
      eventItemLineHeight: 40,
      tableHeaderHeight: 80,
      nonWorkingTimeHeadBgColor: '#fff',
      nonWorkingTimeBodyBgColor: '#fff',
    }, {
      scrollToSpecialMomentEnabled: true,
      getScrollSpecialMomentFunc,
    });
    schedulerData1.localeMoment.locale('en');
    schedulerData1.setResources([{ name: 'title', id: '1' }]);
    schedulerData1.setEvents(events);
    return schedulerData1;
  };
  const [schedulerData, setSchedulerData] = useState(createSchedulerData());

  useEffect(() => {
    if (schedulerData) {
      setSchedulerData(createSchedulerData(date));
    }
  }, [date]);

  useEffect(() => {
    if (events) {
      setSchedulerData(prev => {
        prev.setEvents(events);
        return cloneDeep(prev);
      });
    }
  }, [events]);

  const nextClick = schedulerData => {
    setSchedulerData(prev => {
      prev.next();
      handleDateChange(prev.startDate);
      prev.setEvents(events);
      return cloneDeep(prev);
    });
  };

  const prevClick = schedulerData => {
    setSchedulerData(prev => {
      prev.prev();
      handleDateChange(prev.startDate);
      prev.setEvents(events);
      return cloneDeep(prev);
    });
  };

  return (
    <div>
      <RightHeader
        dateToDisplay={schedulerData?.startDate || new Date()}
        zmccPrevClick={prevClick}
        zmccNextClick={nextClick}
        onChangeDate={handleDateChange}
      />
      <Scheduler
        schedulerData={schedulerData}
        nonAgendaCellHeaderTemplateResolver={headerTemplateResolver}
        prevClick={prevClick}
        nextClick={nextClick}
        eventItemTemplateResolver={eventItemResolver}
        eventItemPopoverTemplateResolver={() => null}
      />
    </div>
  );
};

export default withDndContext(PopupScheduler);
