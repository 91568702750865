import { batchActions } from 'redux-batched-actions';
import moment from 'moment';

import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { scrollToTop } from 'library/utilities/scrollActions';
import Validators from 'library/utilities/Validators';
import {
  furtherImportantComponentModel,
  salesComponentModel,
} from 'library/common/commonComponents/ComponentsForm/Components.constants';
import { freeAccessoriesModel } from 'library/common/commonComponents/AccessoriesForm/Accessories.constants';
import { updateForm } from '../CreateZmccKit/CreateZmccKit.actions';
import {
  FETCH_CREATE_KIT_DETAILS,
  UPDATE_CREATE_KIT_FORM,
  RESET_CREATE_KIT,
  DIVISION_LIST,
  ERP_STATUS,
  REFURBISHMENTFEES_STATUS,
  STOCK_SEARCH_TERMS,
} from './CreateKit.constants';
import services from './CreateKit.service';
import { generalInformationModel } from './CreateKit.constants';
import {
  assignmentFormModel,
  availabilityFormModel,
  defaultPeriodsModel,
  kitStatusModel,
  mainComponentModel,
  packageDimensionsModel,
} from './Tabs/Information/Information.constants';
import { checkActiveLoanorder } from '../KitDetails/KitDetailsAction'
import { regex } from 'library/common/commonConstants/RegexConstants';
import {VrDetailsModel} from "../../Kits/KitDetails/Components/VrDetails/VrDetails.constants";
import { FETCH_NEW_COMPONENTS_LIST, IBASE_NEW_COMPONENTS_LIST } from '../CreateZmccKit/CreateZmccKit.constants';
import { kitCompoennetUseStatusOptions } from 'library/utilities/constants';
import { formatKitComponentID, formatKitProductID } from 'library/utilities/kitComponentsUtils';
import { fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { validSystemClassValue } from './Tabs/Information/Information.constants';

export const fetchKitDetail = (id, isCopyKit = false) => dispatch => {
    dispatch(changeLoader(true));
    return services
      .fetchKitDetailService(id, isCopyKit)
      .then(({ status, data }) => {
        dispatch(changeLoader(false));
        let kitDetails = {};
        if (status === 200) {
          kitDetails = data;
          if (isCopyKit) {
            kitDetails = {
              ...data,
              kitInformationId: null,
              availabilityTo: '',
              serialNumber: '',
              purchaseOrder:'',
              sapERP: '',
              storageLocation: '',
              ibase: '',
              marketingMaterial: false,
              dateOfManufacture: '',
              supportingAsset: false,
              windowsUserPassword: null,
              vrDetailsUserName: null,
              vrDetailsPassword: null,
              fixedAsset: false
            };
          }
          if(!isCopyKit){
            dispatch(checkActiveLoanorder(id));
          }
        }
        const formData = createFormData(kitDetails);
        dispatch({
          type: FETCH_CREATE_KIT_DETAILS,
          payload: { kitDetails, ...formData },
        });
        if (isCopyKit){
          dispatch({
            type: FETCH_NEW_COMPONENTS_LIST,
            payload: {content: []}
          });
        }
      })
      .catch(err => {
        logNetworkError(err);
        dispatch(changeLoader(false));
      });
  };


const createKitWithIBaseData = (ibaseData, createKitReducer) =>  { 
  const generalInformationForm = {
    formData: {
      ...createKitReducer.generalInformationForm.formData,
      kitName:  createKitReducer.generalInformationForm.formData.kitName ? createKitReducer.generalInformationForm.formData.kitName : ibaseData.IC2Description,
      hideKit: ibaseData.UserStatus == 'DACT' ? true : false,
    },
    isFormValid: false,
  };

  const mainComponentForm = {
    formData: {
      ...createKitReducer.mainComponentForm.formData,
      serialNumber : createKitReducer.mainComponentForm.formData.serialNumber ? createKitReducer.mainComponentForm.formData.serialNumber : ibaseData.SerialNumber,
      divisionId: ibaseData?.HeaderDivision || '',
      divisionIdFullValue : ibaseData?.HeaderDivision ? createKitReducer?.divisionsList.find(item => item.id === ibaseData.HeaderDivision) : ''
    },
    isFormValid: false,
  }

  const assignmentForm = {
    formData: {
      ...createKitReducer.assignmentForm.formData,
      firstSalesOrder: ibaseData.SalesOrderNumber,
    },
    isFormValid: false,
  };

  return {
    generalInformationForm,
    assignmentForm,
    mainComponentForm
  }
  
}

const createFormData = kitDetails => {
  const kitReparation =
    kitDetails.kitReparation &&
    kitDetails.kitReparation.length &&
    kitDetails.kitReparation.find(item => item.isReparationTimeActive);
  const generalInformationForm = {
    formData: {
      kitInformationId: kitDetails.kitInformationId,
      kitName: kitDetails.kitName,
      description: kitDetails.description,
      systemValue: kitDetails.systemValue,
      hideKit: kitDetails.hideKit,
    },
    isFormValid: false,
  };
  const furtherImportantComponentForm = {
    formData: {
      furtherImportantComponents: kitDetails ? kitDetails.furtherImportantComponents : '',
    },
    isFormValid: false,
  };
  const salesComponentForm = {
    formData: {
      salesComponents: kitDetails ? kitDetails.salesComponents : '',
    },
    isFormValid: false,
  };
  const assignmentForm = {
    formData: {
      stockInformation: kitDetails && kitDetails.stockInformation && kitDetails.stockInformation.id,
      stockInformationFullValue: kitDetails && kitDetails.stockInformation && kitDetails.stockInformation,
      storageLocation: kitDetails ? kitDetails.storageLocation : '',
      businessUnit: kitDetails && kitDetails.businessUnit && kitDetails.businessUnit.id,
      businessUnitFullValue: kitDetails && kitDetails.businessUnit && kitDetails.businessUnit,
      sapERP: kitDetails && kitDetails.sapERP ? kitDetails.sapERP : '',
      walkOffSalesOrder: kitDetails && kitDetails.walkOffSalesOrder ? kitDetails.walkOffSalesOrder : '',
      firstSalesOrder: kitDetails && kitDetails.firstSalesOrder ? kitDetails.firstSalesOrder : '',
      searchTermIds: (kitDetails?.searchTermEnabled && kitDetails?.kitSearchTerms?.map(val => val.searchTermId)) ?? [],
      searchTermIdsFullValue: (kitDetails?.searchTermEnabled && kitDetails?.kitSearchTerms?.map(value => ({searchTermId: value.searchTermId, searchTermValue: value.term}))) ?? []
    },
    isFormValid: false,
  };
  const mainComponentForm = {
    formData: {
      systemClass: kitDetails && kitDetails.systemClass && kitDetails.systemClass.systemClassId,
      systemClassFullValue: kitDetails && kitDetails.systemClass && kitDetails.systemClass,
      systemMainComponent:
        kitDetails && kitDetails.systemMainComponent && kitDetails.systemMainComponent.systemMainComponentId,
      systemMainComponentFullValue: kitDetails && kitDetails.systemMainComponent && kitDetails.systemMainComponent,
      marketingMaterial: kitDetails && !!kitDetails.marketingMaterial,
      supportingAsset: kitDetails && !!kitDetails.supportingAsset,
      fixedAsset : kitDetails && !!kitDetails.fixedAsset,
      ibase: kitDetails && kitDetails.ibase ? kitDetails.ibase : '',
      serialNumber: kitDetails && kitDetails.serialNumber ? kitDetails.serialNumber : '',
      purchaseOrder: kitDetails && kitDetails.purchaseOrder ? kitDetails.purchaseOrder : '',
      dateOfManufacture: kitDetails && kitDetails.dateOfManufacture && new Date(kitDetails.dateOfManufacture),
      commodityCode: kitDetails && kitDetails.commodityCode ? kitDetails.commodityCode : '',
      country: kitDetails && kitDetails.country && kitDetails.country.id,
      countryFullValue: kitDetails && kitDetails.country,
      itemNumber: kitDetails && kitDetails.itemNumber ? kitDetails.itemNumber : '',
      divisionId:
        kitDetails && kitDetails.divisions && kitDetails.divisions.divisionsId ? kitDetails.divisions.divisionsId : '',
      materialNumberOfStand: kitDetails && kitDetails.materialNumberOfStand ? kitDetails.materialNumberOfStand : '',
    },
    isFormValid: false,
  };
  const availabilityForm = {
    formData: {
      availabilityFrom:
        kitDetails && moment(kitDetails.availabilityFrom).isValid() ? `${kitDetails.availabilityFrom.substring(0, 10)}T00:00:00` : '',
      availabilityTo:
        kitDetails && moment(kitDetails.availabilityTo).isValid() ? `${kitDetails.availabilityTo.substring(0, 10)}T00:00:00` : '',
    },
    isFormValid: false,
  };
  const defaultPeriodsForm = {
    formData: {
      deliveryDays: kitDetails && kitDetails.deliveryDays === 0 ? '0' : kitDetails.deliveryDays,
      returnDeliveryDays: kitDetails && kitDetails.returnDeliveryDays === 0 ? '0' : kitDetails.returnDeliveryDays,
      reconditioningDeliveryDays:
        kitDetails && kitDetails.reconditioningDeliveryDays === 0 ? '0' : kitDetails.reconditioningDeliveryDays,
      maxLoanDuration: kitDetails && kitDetails.maxLoanDuration ? kitDetails.maxLoanDuration : null,
    },
    isFormValid: false,
  };
  const packageDimensionsForm = {
    formData: {
      packageDimensions: kitDetails && kitDetails.packageDimensions ? kitDetails.packageDimensions : '',
    },
    isFormValid: false,
  };
  const kitStatusForm = {
    formData: {
      kitCondition: kitDetails && kitDetails.kitCondition && kitDetails.kitCondition.kitConditionId,
      kitConditionFullValue: kitDetails &&
        kitDetails.kitCondition && {
          kitConditionId: kitDetails.kitCondition.kitConditionId,
          kitStatusId: kitDetails.kitCondition.kitConditionId,
          translatedStatus:
            kitDetails.kitCondition.kitConditionTranslated &&
            kitDetails.kitCondition.kitConditionTranslated[0] &&
            kitDetails.kitCondition.kitConditionTranslated[0].translatedCondition,
        },
      comment: kitDetails && kitDetails.comment,
      refurbishmentFrom: kitReparation && kitReparation.reparationFrom ? new Date(kitReparation.reparationFrom) : null,
      refurbishmentTo: kitReparation && kitReparation.reparationTo ? new Date(kitReparation.reparationTo) : null,
      reparationComment: kitReparation && kitReparation.reparationComment ? kitReparation.reparationComment: ''
    },
    isFormValid: false,
  };
  const freeAccessoriesForm = {
    formData: {
      freeAccessories: kitDetails && kitDetails.freeAccessories ? kitDetails.freeAccessories : '',
    },
    isFormValid: false,
  };
  const vrDetalisForm = {
    formData: {
      windowsUserPassword: kitDetails && kitDetails.windowsUserPassword,
      vrDetailsUserName: kitDetails && kitDetails.vrDetailsUserName,
      vrDetailsPassword: kitDetails && kitDetails.vrDetailsPassword,
      linkToOnboardingMaterial: kitDetails && kitDetails.linkToOnboardingMaterial,
    },
    isFormValid: false,
  }
  let kitImages = kitDetails.kitImages && kitDetails.kitImages.length ? kitDetails.kitImages : [];
  kitImages = kitImages.map(image => ({ ...image, name: image.file }));
  let kitAttachments = kitDetails.kitAttachments && kitDetails.kitAttachments.length ? kitDetails.kitAttachments : [];
  kitAttachments = kitAttachments.map(file => ({ ...file, name: file.file }));
  return {
    generalInformationForm,
    assignmentForm,
    mainComponentForm,
    availabilityForm,
    defaultPeriodsForm,
    packageDimensionsForm,
    kitStatusForm,
    salesComponentForm,
    furtherImportantComponentForm,
    freeAccessoriesForm,
    kitImages,
    kitImagesToDelete: [],
    kitAttachments,
    kitAttachmentsToDelete: [],
    vrDetalisForm
  };
};

export const resetCreateKit = () => ({ type: RESET_CREATE_KIT });

export const updateFormCreateKit = payload => ({
  type: UPDATE_CREATE_KIT_FORM,
  payload,
});


export const getKitInfoByIbase = (iBase) =>  (dispatch, getState) => {
  let { createKitReducer } = getState();
  dispatch(changeLoader(true));

  return fetchMasterDataService(URLS.getKitInfoWithIbase(1,iBase))
  .then(({ status, data }) => {
    if (status === 200) {
      const formData = createKitWithIBaseData(data, createKitReducer);    
      dispatch(updateFormCreateKit(formData));
      dispatch(changeLoader(false));
      dispatch({
        type: IBASE_NEW_COMPONENTS_LIST,
        payload:
        {
          content: data.Components
            .filter(component => component.ProductID && component.Description)
            .map((component, index) => ({
              id: index + 1,
              componentName: component.Description,
              componentNumber: formatKitComponentID(component.ComponentNumber),
              materialNumber: formatKitProductID(component.ProductID),
              serialNumber: component.SerialNumber,
              isMainComponent : component.IsMainComponent,
              userStatus: component.UserStatus,
              userStatusFullValue: kitCompoennetUseStatusOptions.find(option => option.value === component.UserStatus),
              newStateComponent: true
            }))
            .sort((a, b) => b.isMainComponent - a.isMainComponent)
            ,
          ibaseComponents: true
        }
      });
      return data;
    }
  })
  .catch(err => {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'errorOnGettingIbaseData'));
    return {};
  });
}


export const fetchSystemClassData = businessUnitId => dispatch => {
  return fetchMasterDataService(URLS.systemClass.replace('{id}', businessUnitId))
    .then(({ status, data }) => {
      if (status === 200) {
        return data.map(item => ({
          ...item,
          systemClassName: item.systemClassText,
        }));
      }
      return [];
    })
    .catch(err => {
      logNetworkError(err);
      return [];
    });
};

export const fetchSystemMainComponentData = systemClassId => dispatch => {
  return fetchMasterDataService(URLS.systemMainComponent.replace('{id}', systemClassId))
    .then(({ status, data }) => {
      if (status === 200) {
        return data.map(item => ({
          ...item,
          systemMainComponentName: item.systemMainComponentText,
        }));
      }
      return [];
    })
    .catch(err => {
      logNetworkError(err);
      return [];
    });
};

export const fetchUniqueIBaseValue = kitId => dispatch => {
  return services
    .fetchUniqueIBaseService(kitId || 0)
    .then(({ status, data }) => {
      if (status === 200) {
        return data;
      }
      return {};
    })
    .catch(err => {
      logNetworkError(err);
      return {};
    });
};

export const uploadKitImage = (files, kitInformationId) => () => {
  if (files.length > 0) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('imgFile', file);
    });
    return services.uploadKitImagesService(kitInformationId, formData);
  }
};

export const uploadKitAttachment =
  (files, kitInformationId, updateOnEnd = false) =>
  dispatch => {
    if (updateOnEnd) {
      dispatch(changeLoader(true));
    }
    if (files.length) {
      const formData = new FormData();
      let count = 0;
      files.forEach(file => {
        if (!file.kitAttachmentsId) {
          formData.append('attachmentFile', file);
          count++;
        }
      });
      if (!count) {
        if (updateOnEnd) {
          scrollToTop(500);
          dispatch(
            batchActions([
              dispatch(toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage')),
              dispatch(changeLoader(false)),
            ]),
          );
        }
        return { success: false };
      }
      return services
        .uploadKitAttachmentsService(kitInformationId, formData)
        .then(({ status }) => {
          if (status === 200 && updateOnEnd) {
            dispatch(toggleActionMessage(true, 'success', 'kitAttachmentUploadedSuccessfully'));
            dispatch(changeLoader(false));
            scrollToTop(500);
          }
        })
        .catch(err => {
          logNetworkError(err);
          if (updateOnEnd) {
            scrollToTop(500);
            dispatch(
              batchActions([
                dispatch(changeLoader(false)),
                dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
              ]),
            );
          }
        });
    } else {
      if (updateOnEnd) {
        scrollToTop(500);
        dispatch(toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage'));
      }
    }
  };

export const saveCreateKit =
  (tab, isCopyKit = false, isKitForSale) =>
  async (dispatch, getState) => {
    let { createKitReducer, createZmccKitReducer } = getState();
    let formValues = {
      generalInformationForm: createKitReducer.generalInformationForm,
      assignmentForm: createKitReducer.assignmentForm,
      mainComponentForm: createKitReducer.mainComponentForm,
      defaultPeriodsForm: createKitReducer.defaultPeriodsForm,
      availabilityForm: createKitReducer.availabilityForm,
      packageDimensionsForm: createKitReducer.packageDimensionsForm,
      kitStatusForm: createKitReducer.kitStatusForm,
      salesComponentForm: createKitReducer.salesComponentForm,
      furtherImportantComponentForm: createKitReducer.furtherImportantComponentForm,
      freeAccessoriesForm: createKitReducer.freeAccessoriesForm,
      vrDetalisForm: createKitReducer.vrDetalisForm,
    };
    const mainModel = [...mainComponentModel];
    if (
      (formValues.mainComponentForm.formData &&
        formValues.mainComponentForm.formData.marketingMaterial &&
        formValues.mainComponentForm.formData.ibase) ||
      (formValues.assignmentForm.formData &&
        formValues.assignmentForm.formData.systemClassFullValue &&
        validSystemClassValue.includes(formValues.assignmentForm.formData.systemClassFullValue.systemClassName))
    ) {
      mainModel[1].validators = [];
    } else {
      mainModel[1].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
    }
    const validateArray = [
      { formName: 'generalInformationForm', form: formValues.generalInformationForm, model: generalInformationModel },
      { formName: 'assignmentForm', form: formValues.assignmentForm, model: assignmentFormModel },
      { formName: 'availabilityForm', form: formValues.availabilityForm, model: availabilityFormModel },
      { formName: 'defaultPeriodsForm', form: formValues.defaultPeriodsForm, model: defaultPeriodsModel },
      { formName: 'kitStatusForm', form: formValues.kitStatusForm, model: kitStatusModel },
      { formName: 'mainComponentForm', form: formValues.mainComponentForm, model: mainModel },
      {
        formName: 'packageDimensionsForm',
        form: formValues.packageDimensionsForm,
        model: packageDimensionsModel,
      },
      {
        formName: 'furtherImportantComponentForm',
        form: formValues.furtherImportantComponentForm,
        model: furtherImportantComponentModel,
      },
      ...(!createKitReducer?.kitDetails?.kitCreatedThroughIbaseData ? [{ formName: 'salesComponentForm', form: formValues.salesComponentForm, model: salesComponentModel }] : []),
      { formName: 'freeAccessoriesForm', form: formValues.freeAccessoriesForm, model: freeAccessoriesModel },
      { formName: 'vrDetalisForm', form: formValues.vrDetalisForm, model: VrDetailsModel },
    ];
    validateArray.forEach(({ formName, form, model }) => {
      const newForm = validateForm({ form, model });
      formValues[formName] = newForm;
    });
    if (!formValues.generalInformationForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'fillKitHeaderInformation'));
      dispatch(updateFormCreateKit({ generalInformationForm: formValues.generalInformationForm }));
      scrollToTop(500);
      return { success: false };
    }
    if (!formValues.defaultPeriodsForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'fillDefaultPeriods'));
      scrollToTop(500);
      return { success: false };
    }
    if (!formValues.availabilityForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
      dispatch(updateFormCreateKit({ availabilityForm: formValues.availabilityForm }));
      scrollToTop(500);
      return { success: false };
    }
    if (!formValues.vrDetalisForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'urlErrorMessage'));
      dispatch(updateFormCreateKit({ vrDetalisForm: formValues.vrDetalisForm }));
      scrollToTop(500);
      return { success: false };
    }
    if (
      formValues.kitStatusForm.formData &&
      formValues.kitStatusForm.formData.refurbishmentFrom &&
      formValues.kitStatusForm.formData.refurbishmentTo
    ) {
      if (
        !moment(formValues.kitStatusForm.formData.refurbishmentFrom).isBefore(
          formValues.kitStatusForm.formData.refurbishmentTo,
        )
      ) {
        dispatch(toggleActionMessage(true, 'error', 'refurbishmentDateError'));
        scrollToTop(500);
        return { success: false };
      }
    }
    if (!formValues.assignmentForm.formData.stockInformation && tab === 1) {
      dispatch(toggleActionMessage(true, 'error', 'stockDataMandatory'));
      dispatch(
        updateFormCreateKit({
          assignmentForm: {
            ...formValues.assignmentForm,
            formData: { ...formValues.assignmentForm.formData, systemClassError: null },
          },
        }),
      );
      scrollToTop(500);
      return { success: false };
    } else if (!formValues.assignmentForm.formData.businessUnit && tab === 1) {
      dispatch(toggleActionMessage(true, 'error', 'businessUnitMandatory'));
      dispatch(
        updateFormCreateKit({
          assignmentForm: {
            ...formValues.assignmentForm,
            formData: { ...formValues.assignmentForm.formData, systemClassError: null },
          },
        }),
      );
      scrollToTop(500);
      return { success: false };
    }
    else if (formValues.assignmentForm.formData.firstSalesOrder) {
      if (regex.number.test(formValues.assignmentForm.formData.firstSalesOrder) === false) {
        dispatch(toggleActionMessage(true, 'error', 'inavalidFirstSalesOrderMessage'));
      dispatch(
        updateFormCreateKit({
          assignmentForm: {
            ...formValues.assignmentForm,
            formData: { ...formValues.assignmentForm.formData, systemClassError: null },
          },
        }),
      );
      scrollToTop(500);
      return { success: false };
      }

    }


    let successMessage = isCopyKit ? 'kitCopySuccessMessage' : 'kitSavedSuccessMessage';
    let messageType = 'success';
    if (formValues.generalInformationForm.formData.hideKit) {
      successMessage = isCopyKit ? 'kitCopySuccessMessageWithHideKit' : 'kitSavedSuccessMessageWithHideKit';
      messageType = 'info';
    }
    if (formValues.kitStatusForm.formData && formValues.kitStatusForm.formData.kitCondition) {
      if (formValues.kitStatusForm.formData.kitCondition === 1) {
        successMessage = isCopyKit ? 'kitCopySuccessMessageWithIncomplete' : 'kitSavedSuccessMessageWithIncomplete';
        messageType = 'info';
      } else if (formValues.kitStatusForm.formData.kitCondition === 2) {
        successMessage = isCopyKit ? 'kitCopySuccessMessageWithDefective' : 'kitSavedSuccessMessageWithDefective';
        messageType = 'info';
      } else if (formValues.kitStatusForm.formData.kitCondition === 5) {
        successMessage = isCopyKit
          ? 'kitCopySuccessMessageWithRefurbishment'
          : 'kitSavedSuccessMessageWithRefurbishment';
        messageType = 'info';
      } else if (formValues.kitStatusForm.formData.kitCondition === 7) {
        successMessage = isCopyKit ? 'kitCopySuccessMessageWithLost' : 'kitSavedSuccessMessageWithLost';
        messageType = 'info';
      } else if (formValues.kitStatusForm.formData.kitCondition === 8) {
        successMessage = isCopyKit ? 'kitCopySuccessMessageWithHideKit' : 'kitSavedSuccessMessageWithHideKit';
        messageType = 'info';
      } else if (formValues.kitStatusForm.formData.kitCondition === 9) {
        successMessage = isCopyKit ? 'kitCopySuccessMessageWithHideKit' : 'kitSavedSuccessMessageWithHideKit';
        messageType = 'info';
      }
    }
    if (
      !formValues.generalInformationForm.isFormValid ||
      !formValues.assignmentForm.isFormValid ||
      !formValues.mainComponentForm.isFormValid ||
      !formValues.defaultPeriodsForm.isFormValid ||
      !formValues.availabilityForm.isFormValid ||
      !formValues.kitStatusForm.isFormValid ||
      (createKitReducer?.kitDetails && !createKitReducer?.kitDetails?.kitCreatedThroughIbaseData && !formValues.furtherImportantComponentForm.isFormValid) ||
      (createKitReducer?.kitDetails && !createKitReducer?.kitDetails?.kitCreatedThroughIbaseData && !formValues.salesComponentForm.isFormValid) ||
      !formValues.freeAccessoriesForm.isFormValid
    ) {
      successMessage = isCopyKit
        ? 'kitDetailsCopiedWithMandatoryFieldsUnfilled'
        : 'kitDetailsSavedWithMandatoryFieldsUnfilled';
      messageType = 'info';
    }

    if (
      (mainModel[5].isDisplayed === false && formValues.mainComponentForm.formData.itemNumber == '') ||
      (mainModel[5].isDisplayed === false && formValues.mainComponentForm.formData.divisionId == '')
    ) {
      successMessage = 'kitDetailesSavedWithoutDivisionAndItemNumber';
      messageType = 'info';
    }
    if (
      ((mainModel[5].isDisplayed === false && formValues.mainComponentForm.formData.itemNumber == '') ||
        (mainModel[5].isDisplayed === false && formValues.mainComponentForm.formData.divisionId == '')) &&
      (!formValues.generalInformationForm.isFormValid ||
        !formValues.assignmentForm.isFormValid ||
        !formValues.mainComponentForm.isFormValid ||
        !formValues.defaultPeriodsForm.isFormValid ||
        !formValues.availabilityForm.isFormValid ||
        !formValues.kitStatusForm.isFormValid ||
        (createKitReducer?.kitDetails && !createKitReducer?.kitDetails?.kitCreatedThroughIbaseData && !formValues.furtherImportantComponentForm.isFormValid) ||
        (createKitReducer?.kitDetails && !createKitReducer?.kitDetails?.kitCreatedThroughIbaseData && !formValues.salesComponentForm.isFormValid) ||
        !formValues.freeAccessoriesForm.isFormValid)
    ) {
      successMessage = isCopyKit
        ? 'kitCopiedWithoutmandatoryFiledAndItemNumberDivision'
        : 'kitWithoutmandatoryFiledAndItemNumberDivision';
      messageType = 'info';
    }

    let valuesToSend = {
      ...formValues.generalInformationForm.formData,
      ...formValues.assignmentForm.formData,
      ...formValues.mainComponentForm.formData,
      ...formValues.defaultPeriodsForm.formData,
      ...formValues.availabilityForm.formData,
      ...formValues.packageDimensionsForm.formData,
      ...formValues.kitStatusForm.formData,
      ...formValues.furtherImportantComponentForm.formData,
      ...formValues.salesComponentForm.formData,
      ...formValues.freeAccessoriesForm.formData,
      ...formValues.vrDetalisForm.formData,
      deleteImageIds: createKitReducer.kitImagesToDelete,
      deleteAttachmentIds: createKitReducer.kitAttachmentsToDelete,
    };
    Object.keys(valuesToSend).forEach(key => {
      if (key.includes('Error') || key.includes('FullValue')) {
        delete valuesToSend[key];
      }
      if (key === 'stockInformation' || key === 'businessUnit' || key === 'country') {
        valuesToSend[key] = { id: valuesToSend[key] };
      }
      if (key === 'systemClass') {
        valuesToSend[key] = { systemClassId: valuesToSend[key] };
      }
      if (key === 'systemMainComponent') {
        valuesToSend[key] = { systemMainComponentId: valuesToSend[key] };
      }
      if (key === 'kitCondition') {
        valuesToSend[key] = valuesToSend[key] ? { kitConditionId: valuesToSend[key] } : null;
      }
      if (key === 'refurbishmentFrom') {
        valuesToSend[key] = valuesToSend[key] ? moment(valuesToSend[key]).format('YYYY-MM-DD') : null;
      }
      if (key === 'refurbishmentTo') {
        valuesToSend[key] = valuesToSend[key] ? moment(valuesToSend[key]).format('YYYY-MM-DD') : null;
      }
      if (key === 'dateOfManufacture') {
        valuesToSend[key] = valuesToSend[key] ? moment(valuesToSend[key]).format('YYYY-MM-DD') : '';
      }
      if (key === 'divisionId') {
        valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : null;
      }
      if (key === 'reparationComment') {
        valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : '';
      }
    });
    valuesToSend.deliveryDays = valuesToSend.deliveryDays ? parseInt(valuesToSend.deliveryDays, 10) : null;
    valuesToSend.returnDeliveryDays = valuesToSend.returnDeliveryDays
      ? parseInt(valuesToSend.returnDeliveryDays, 10)
      : null;
    valuesToSend.reconditioningDeliveryDays = valuesToSend.reconditioningDeliveryDays
      ? parseInt(valuesToSend.reconditioningDeliveryDays, 10)
      : null;
    valuesToSend.maxLoanDuration = valuesToSend.maxLoanDuration
      ? parseInt(valuesToSend.maxLoanDuration, 10)
      : null;
    valuesToSend.systemValue = valuesToSend['systemValue'] ? parseInt(valuesToSend.systemValue, 10) : null;
    if (createKitReducer.kitInformationId) {
      valuesToSend = {
        ...valuesToSend,
        kitInformationId: createKitReducer.kitInformationId,
      };
    }
    if (isKitForSale) {
      valuesToSend.quantity = 1;
    }

    let kitImages = createKitReducer.kitImages;
    let kitAttachments = createKitReducer.kitAttachments;
    let imagesToUpload = [];
    let attachmentsToUpload = [];
    
    if ((!createKitReducer.kitInformationId || createZmccKitReducer?.componentsFetchedFromIbase)){
      if (createZmccKitReducer?.newComponentsList?.length > 0) {
        const modifiedComponentsList = createZmccKitReducer?.newComponentsList?.map(component => {
          if (component.newStateComponent) {
            const { newStateComponent, ...rest } = component; 
            return { ...rest, id: null };
          }
          return component; 
        });
        valuesToSend.iqsResourceComponents = modifiedComponentsList;
      }
    }
  

    if (isCopyKit) {
      const newKitImages = [];
      if (kitImages.length) {
        kitImages.forEach(image => {
          if (image.kitImagesId) {
            newKitImages.push({ ...image, kitImagesId: 0, preview: image.actualImageUrl });
          } else {
            imagesToUpload.push(image);
          }
        });
      }
      const newKitAttachments = [];
      if (kitImages.length) {
        kitAttachments.forEach(file => {
          if (file.kitAttachmentsId) {
            newKitAttachments.push({ ...file, kitAttachmentsId: 0 });
          } else {
            attachmentsToUpload.push(file);
          }
        });
      }
      valuesToSend = {
        ...valuesToSend,
        kitInformationId: 0,
        kitImages: newKitImages,
        kitAttachments: newKitAttachments,
      };
    } else {
      imagesToUpload = kitImages.filter(image => !image.kitImagesId);
      attachmentsToUpload = kitAttachments.filter(file => !file.kitAttachmentsId);
    }
    if (valuesToSend.availabilityFrom) {
      valuesToSend = { ...valuesToSend, availabilityFrom: moment(valuesToSend.availabilityFrom).format('YYYY-MM-DD') };
    }
    if (valuesToSend.availabilityTo) {
      valuesToSend = { ...valuesToSend, availabilityTo: moment(valuesToSend.availabilityTo).format('YYYY-MM-DD') };
    }

    dispatch(changeLoader(true));
    try {
      const { data } = await services.saveKitService(valuesToSend);
      const uploadAttachments = !!attachmentsToUpload.length;
      if (uploadAttachments) {
        await dispatch(uploadKitAttachment(attachmentsToUpload, data.kitInformationId, tab === 4 && !isCopyKit));
      }
      if (imagesToUpload) {
        await dispatch(uploadKitImage(imagesToUpload, data.kitInformationId));
      }

      if (tab !== 4 || !uploadAttachments || isCopyKit) {
        dispatch(
          batchActions([
            dispatch(changeLoader(false)),
            dispatch(toggleActionMessage(true, messageType, successMessage)),
            dispatch(
              updateFormCreateKit({
                kitInformationId: data.kitInformationId,
                kitDetails: valuesToSend,
                mainComponentForm: {
                  ...formValues.mainComponentForm,
                  formData: { ...formValues.mainComponentForm.formData, kitInformationId: data.kitInformationId },
                },
              }),
            ),
          ]),
        );
      } else {
        dispatch(
          batchActions([
            dispatch(changeLoader(false)),
            dispatch(updateFormCreateKit({ kitInformationId: data.kitInformationId, kitDetails: valuesToSend })),
          ]),
        );
      }
      dispatch({type: IBASE_NEW_COMPONENTS_LIST, payload: {content : [], ibaseComponents : false}})
      scrollToTop(500);
      return { success: true, kitInformationId: data.kitInformationId };
    } catch (err) {
      logNetworkError(err);
      dispatch(changeLoader(false));
      if (err && err.response) {
        if (err.response.status === 409) {
          dispatch(toggleActionMessage(true, 'error', 'iBaseConflictError'));
        } else if (err.response.status === 422) {
          dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
        } else if (err.response.status === 403) {
          dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
        }
      } else {
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      }
      scrollToTop(500);
      return { success: false };
    }
  };

export const saveSaleKit = (valuesToSend, attachments) => async dispatch => {
  try {
    Object.keys(valuesToSend).forEach(key => {
      if (key.includes('Error') || key.includes('FullValue') || key.includes('currencyForTransferPrice')) {
        delete valuesToSend[key];
      }
    });
    const { data } = await services.saveKitForSale(valuesToSend);
    if (attachments && attachments.length && data && data.selloffInformatioId) {
      const formData = new FormData();
      attachments.forEach(file => {
        formData.append('attachments', file);
      });
      await services.saveKitAttachmentsForSale(data.selloffInformatioId, formData);
    }

    return { success: true };
  } catch (err) {
    return { success: false };
  }
};

export const fetchStockInfo = id => () => {
  return services
    .fetchStockById(id)
    .then(({ status, data }) => {
      if (status === 200 && data.stockSellOffPlatformModel && data.stockSellOffPlatformModel.shopManagers) {
        return data.stockSellOffPlatformModel.shopManagers;
      }
      return [];
    })
    .catch(err => {
      logNetworkError(err);
      return [];
    });
};

export const fetchActiveUnitDate = id => async () => {
  try {
    const res = await fetchMasterDataService(URLS.activeUntilDate(id));

    return res.data;
  } catch (err) {
    return null;
  }
};

export const fetchdivisionList = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.division);
    dispatch({
      type: DIVISION_LIST,
      payload: data,
    });
    return data;
  } catch (err) {
    return null;
  }
};

export const checkErpStatus = stockId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.checkErpInterfaceStatus(stockId));
    dispatch({
      type: ERP_STATUS,
      payload: data,
    });
    return data;
  } catch (error) {
    return null;
  }
};
export const refurbishmentFessStatus = stockId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.checkRefurbishmentStatusCheck(stockId));
    dispatch({
      type: REFURBISHMENTFEES_STATUS,
      payload: data,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getStockSearchTerms = stockIds => async dispatch => {
  try {
    dispatch(changeLoader(true))
    const { data } = await fetchMasterDataServicePost(URLS.getStokcSearchTerms, stockIds);
  
    let dropdownData = []
    data.forEach(value => {
      value.headerItems.forEach(item => dropdownData.push({...item, stockId: value.stockId}));
    })
    dispatch({
      type: STOCK_SEARCH_TERMS,
      payload: dropdownData,
    })
  }catch(err){
    dispatch({
      type: STOCK_SEARCH_TERMS,
      payload: []
    })
  }finally{
    dispatch(changeLoader(false))
  }
}
