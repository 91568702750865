import React from 'react';
import cn from 'classnames';

import DotLoader from 'library/common/commonComponents/DotLoader';
import Icon from 'library/common/commonComponents/Icon/Icon';
import './button.styles.scss';

const Button = props => {
  const {
    styleClass,
    bgFill,
    isGhost,
    iconStyle,
    iconName,
    dataTest,
    iconPosition = 'right',
    onClick,
    value,
    loading,
    disabled,
    iconWidth = 24,
    iconHeight = 24,
    viewBox,
    newVersionUi,
    icon,
    contentWidth,
  } = props;
  
  const buttonClasses = cn({
    button: true,
    contained: bgFill && !isGhost,
    outlined: !bgFill && !isGhost,
    ghost: !bgFill && isGhost,
    'button-loading': loading,
    'w-auto': contentWidth
  }, styleClass);
  const iconClasses = cn('button-icon', iconStyle);
  const textClasses = cn({
    'left-text': iconPosition === 'right',
    'right-text': iconPosition === 'left',
  });
  const buttonContent = cn('button-content', {
    'content-space-between': iconName && value,
    'content-center': !iconName || !value,
  });


  return (
    <button
      className={buttonClasses}
      data-test={dataTest}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && (
        <div className='button-loading-wrapper'>
          <DotLoader bgFill={bgFill} />
        </div>
      )}
      {!newVersionUi && <div className={buttonContent}>
        {value && <span className={textClasses}>{value}</span>}
        {iconName && (
          <Icon
            className={iconClasses}
            name={iconName}
            width={iconWidth}
            height={iconHeight}
            viewBox={viewBox}
          />
        )}
      </div>}
      {newVersionUi && <div className= {`${disabled ? 'new-ui-disabled-button' : "adjust-new-version-button-text"}`}>
      {iconName && (
          <Icon
            className={iconClasses}
            name={iconName}
            width={iconWidth}
            height={iconHeight}
            viewBox={viewBox}
          />
        )}
        {icon && (
          icon
        )}
        {value && <span className={textClasses}>{value}</span>}
      </div>}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  bgFill: true,
  styleClass: '',
  iconName: null,
  iconPosition: 'right',
  onClick: () => {},
  newVersionUi: false,
  icon: null,
};

export default Button;
